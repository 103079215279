import React, { useState, useEffect } from "react";
import Wrapper from "../../../../components/UI/Wrapper";
import Card from "../../../../components/UI/Card";
import "./TitularRegister.css";
import Input from "../../../../components/UI/Input";
import { useFormik } from "formik";
import { RegisterNoSchema } from "../../../../schemas";
import Button from "../../../../../Common/components/UI/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-hot-toast";
import axios from "../../../../../Common/Api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const TitularRegister = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  const [show, setshow] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [AccountHolder, setAccountHolder] = useState("Yes");
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const Navigate = useNavigate();

  const initialValues = {
    Name: "",
    Surname: "",
    Email: "",
    Number: "",
    // AccountHolder: AccountHolder,
  };

  const onSubmit = async () => {
    console.log("Form Values...", values);
    setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   toast.success("Form Submitted");
    // }, 500);
    try {
      const response = await axios.post(
        // "/register-step-3",
        "/customer-register-step-3",
        {
          AccountHolder: AccountHolder,
          AccountHolderName: values.Name,
          AccountHolderSurName: values.Surname,
          AccountHolderMobileNumber: values.Number,
          AccountHolderEmail: values.Email,
          RegsterId: UserData.id,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      console.log("🚀 ~ file: index.js:56 ~ onSubmit ~ response:", response);

      if (response) {
        setLoading(false);
      }
      if (response.data.message == "Step 3 Registration Successfully") {
        toast.success(`Paso 3 Registro Exitoso`);
        Navigate("/ContinuePayment");
      }
    } catch (error) {
      // console.log("error....", error)
      setLoading(false);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: show ? RegisterNoSchema : null,
      onSubmit,
    });

  return (
    <div>
      <span className="d-none">{AccountHolder}</span>
      <Wrapper>
        <Card>
          <form action="" onSubmit={handleSubmit} className={"tutaler"}>
            <h1>Titular</h1>
            <div id="check">
              {/* <span>¿Serás el titular del vehículo?</span> */}
              <span>¿Serás el usuario principal del vehículo?</span>
              <div className="form-group2">
                <div onClick={() => setAccountHolder("Yes")}>
                  <input
                    onClick={() => setshow(false)}
                    type="radio"
                    name="RadioYesNo"
                    id="radio1"
                    value={"Yes"}
                    className="radio"
                    defaultChecked
                  />
                  <label htmlFor="radio1">Sí</label>
                </div>
                <div onClick={() => setAccountHolder("No")}>
                  <input
                    onClick={() => setshow(true)}
                    type="radio"
                    name="RadioYesNo"
                    id="radio2"
                    value={"No"}
                    className="radio"
                  />
                  <label htmlFor="radio2">No</label>
                </div>
              </div>
            </div>
            <div>
              {/* {!show && (
            <div className="tulerbtn">
              <Button variant={"red"} type={"submit"}>
                Continuar
              </Button>
            </div>
          )} */}
              {show && (
                <div className="inputmaindivvv">
                  <div className="inputsdiv">
                    <Input
                      value={values.Name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="Name"
                      text={"Nombre"}
                      type={"text"}
                      placeholder={"Nombre del titular"}
                    />
                    {errors.Name && touched.Name ? (
                      <p className="form-error"> {errors.Name} </p>
                    ) : null}
                  </div>
                  <div className="inputsdiv">
                    <Input
                      value={values.Surname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="Surname"
                      text={"Apellidos"}
                      type={"text"}
                      placeholder={"Apellidos del titular"}
                    />
                    {errors.Surname && touched.Surname ? (
                      <p className="form-error"> {errors.Surname} </p>
                    ) : null}
                  </div>
                  <div className="inputsdiv">
                    <Input
                      value={values.Email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="Email"
                      text={"Correo electrónico"}
                      type={"email"}
                      placeholder={"Ingresa tu correo electrónico"}
                    />
                    {errors.Email && touched.Email ? (
                      <p className="form-error"> {errors.Email} </p>
                    ) : null}
                  </div>
                  <div className="inputsdiv">
                    <Input
                      value={values.Number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="Number"
                      text={"Teléfono"}
                      type={"number"}
                      placeholder={"Escribe tu teléfono"}
                    />
                    {errors.Number && touched.Number ? (
                      <p className="form-error"> {errors.Number}</p>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <div className="tulerbtn">
              <Button variant={"red"} type={"submit"}>
                Continuar
              </Button>
            </div>
          </form>
        </Card>
        {!Loading && <div></div>}
        {Loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </Wrapper>
    </div>
  );
};

export default TitularRegister;
