import React, { Fragment, useEffect, useState } from "react";
import "./ModalMain.css";
import Navbar from "../../../components/UI/Navbar";
import Footer from "../../../../Common/components/UI/Footer";
import Model1 from "../Model1";
import Model2 from "../Model2";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

const ModalMain = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  const data = localStorage.getItem("cardindex");
  const nav = useLocation();

  const [steps, setsteps] = useState(localStorage.getItem("cardindex"));

  let route = useLocation();
  // // const data = localStorage.getItem("cardindex");
  // const [steps, setsteps] = useState(localStorage.getItem("cardindex"));

  // let route = useLocation();
  // const maindata = route.state.newindex;
  // console.log("ghhg", maindata);
  return (
    <>
      {" "}
      <Helmet>
        <title>Nissan | Explora LEAF</title>
        <meta
          name="description"
          content="Colores y especificaciones del modelo LEAF"
        />
      </Helmet>
      <Navbar setsteps={setsteps} steps={steps} />
      <div className="cardetailpage" style={{ marginTop: "101px" }}>
        <div className="modelselectdiv">
          <span className="d-flex flex-column align-items-center justify-content-center">
            <span onClick={() => setsteps(0)}>LEAF S</span>
            <span className={steps == 0 ? "spanactive" : ""}></span>
          </span>
          <span className="d-flex flex-column align-items-center justify-content-center">
            <span onClick={() => setsteps(1)}>LEAF SV Plus</span>
            <span className={steps == 1 ? "spanactive1" : ""}></span>
          </span>
        </div>
        <div className="allmo">{steps == 0 ? <Model1 /> : null}</div>
        <div className="allmo">{steps == 1 ? <Model2 /> : null}</div>
      </div>
      <Footer />
    </>
  );
};

export default ModalMain;
