import { createContext, useState } from "react";

export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [FirstModalShow, setFirstModalShow] = useState(false);
  const [SecondModalShow, setSecondModalShow] = useState(false);
  const [ThirdModalShow, setThirdModalShow] = useState(false);
  const [FourModalShow, setFourModalShow] = useState(false);
  const [FiveModalShow, setFiveModalShow] = useState(false);
  const [LoginModalshow, setLoginModalshow] = useState(false);
  const [Canclemodelinterestshow, setCanclemodelinterestlshow] =
    useState(false);
  const [ThankYouConfirmationshow, setThankYouConfirmationshow] =
    useState(false);
  const [Sorrymodalshow, setSorrymodalshow] = useState(false);
  const [conformshow, setConformshow] = useState(false);
  const [Cardissue, setCardissue] = useState(false);
  const [Trems, setTrems] = useState(false);
  const [Policy, setPolicy] = useState(false);
  const [ForLogin, setForLogin] = useState(false);

  return (
    <GlobalContext.Provider
      value={{
        FirstModalShow,
        setFirstModalShow,
        SecondModalShow,
        setSecondModalShow,
        ThirdModalShow,
        setThirdModalShow,
        FourModalShow,
        setFourModalShow,
        FiveModalShow,
        setFiveModalShow,
        LoginModalshow,
        setLoginModalshow,
        Canclemodelinterestshow,
        setCanclemodelinterestlshow,
        ThankYouConfirmationshow,
        setThankYouConfirmationshow,
        Sorrymodalshow,
        setSorrymodalshow,
        conformshow,
        setConformshow,
        Cardissue,
        setCardissue,
        Trems,
        setTrems,
        Policy,
        setPolicy,
        ForLogin,
        setForLogin,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
