import * as Yup from "yup";

const phoneRegExp =
  /^(\+?\d{0,6})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{6}\)?)?$/;

export const LoginSchema = Yup.object({
  Email: Yup.string()
    .email()
    .required("El correo electrónico es un campo obligatorio."),
  Password: Yup.string().required("El contraseña es un campo obligatorio."),
});

export const RegisterYesSchema = Yup.object({
  Name: Yup.string().required("El nombre es un campo obligatorio."),
  Surname: Yup.string().required("Los apellidos es un campo obligatorio."),
  Email: Yup.string()
    .email()
    .required("El correo electrónico es un campo obligatorio."),
  Number: Yup.string()
    .matches(phoneRegExp, "El teléfono debe ser adecuado.")
    .required("El teléfono es un campo obligatorio."),
  // Number: Yup.number()
  //   .positive("El teléfono debe ser adecuado.")
  //   .required("El teléfono es un campo obligatorio."),
  Password: Yup.string().required("El contraseña es un campo obligatorio."),
  ConfirmEmail: Yup.string()
    .required("Repita correo electrónico es un campo obligatorio.")
    .oneOf(
      [Yup.ref("Email"), null],
      "El correo electrónico ingresado no coincide con el anterior"
    ),
  DealerName: Yup.number().required(
    "El concesionario es un campo obligatorio."
  ),
  // .oneOf(
  //   ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  // "El concesionario es un campo obligatorio."
  // ),
  // .required("El concesionario es un campo obligatorio."),
  RepeatPassword: Yup.string()
    .required("Repetir la contraseña es un campo obligatorio.")
    .oneOf([Yup.ref("Password"), null], "Las contraseñas no coinciden."),
});

export const RegisterDealerSchema = Yup.object({
  Name: Yup.string().required("El nombre del titular es un campo obligatorio."),
  Surname: Yup.string().required("Los apellidos es un campo obligatorio."),
  Email: Yup.string()
    .email("El correo electrónico debe ser un correo electrónico válido")
    .required("El correo electrónico es un campo obligatorio."),
  ConfirmEmail: Yup.string()
    .email("El correo electrónico ingresado no coincide con el anterior")
    .oneOf([Yup.ref("Email"), null], "repita correo electrónica no coinciden")
    .required("repita correo electrónica es un campo obligatorio."),
  Number: Yup.string()
    .matches(phoneRegExp, "El teléfono debe ser adecuado.")
    .required("El teléfono es un campo obligatorio."),
  // Number: Yup.number()
  //   .positive("El teléfono debe ser adecuado.")
  //   .required("El teléfono es un campo obligatorio."),
  DealerName: Yup.number().required(
    "El concesionario es un campo obligatorio."
  ),
  // .oneOf(
  //   ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  //   "El concesionario es un campo obligatorio."
  // ),
  // .required("El concesionario es un campo obligatorio."),
  Vendor: Yup.string().required("El vendedor es un campo obligatorio."),
  Password: Yup.string().required("El contraseña es un campo obligatorio."),
  RepeatPassword: Yup.string()
    .required("Repetir la contraseña es un campo obligatorio.")
    .oneOf([Yup.ref("Password"), null], "Las contraseñas no coinciden."),
});

export const RegisterAddressSchema = Yup.object({
  Address: Yup.string().required("La dirección es un campo obligatorio."),
  Country: Yup.string().required("El país es un campo obligatorio."),
  PostalCode: Yup.number().required(
    "El código postal es un campo obligatorio."
  ),
  Village: Yup.string().required("El pueblo es un campo obligatorio."),
});

export const RegisterNoSchema = Yup.object({
  Name: Yup.string().required("Nombre es un campo obligatorio."),
  Surname: Yup.string().required("Apellidos es un campo obligatorio."),
  Email: Yup.string().required("Correo electrónico es un campo obligatorio."),
  Number: Yup.string()
    .matches(phoneRegExp, "El teléfono debe ser adecuado.")
    .required("Teléfono es un campo obligatorio."),
  // Number: Yup.number().required("Teléfono es un campo obligatorio."),
});

export const ForgotPassSchema = Yup.object({
  Email: Yup.string()
    .email("Debes ingresar un correo electrónico válido")
    .required("El correo electrónico es un campo obligatorio."),
});

export const ContactUsSchema = Yup.object({
  FullName: Yup.string().required(
    "El nombre completo es un campo obligatorio."
  ),
  Email: Yup.string()
    .email()
    .required("El correo electrónico es un campo obligatorio."),
  Phone: Yup.string()
    .matches(phoneRegExp, "El teléfono debe ser adecuado.")
    .required("El teléfono es un campo obligatorio."),
  // Phone: Yup.number()
  //   .positive()
  //   .required("El teléfono es un campo obligatorio."),
  Subject: Yup.string().required("Asunto es un campo obligatorio.."),
  Comment: Yup.string().required("Comentarios es un campo obligatorio."),
  checkBox1: Yup.boolean().oneOf(
    [true],
    "Para poder mantener la comunicación es importante que aceptes recibir información al correoelectrónico y/o número de teléfono provisto, víallamadas, llamadas grabadas o de voz artificial y/omensaje de texto, sobre productos y servicios de partede Nissan de Puerto Rico, su distribuidora Motorambar,Inc., y/o sus concesionarios autorizados por lo que debes marcar el encasillado."
  ),
  // checkBox2: Yup.boolean().oneOf([true], "requied"),
  checkBox3: Yup.boolean().oneOf(
    [true],
    "Para poder someter tu información de contacto es importante que aceptes nuestros Términos y Condiciones y Política de Privacidad"
  ),
});

export const UpdateProfileSchema = Yup.object({
  // AccountName: Yup.string().required("required"),
  // AccountSurnameName: Yup.string().required("required"),
  // AccountHolderPhoneNumber: Yup.string()
  //   .matches(phoneRegExp, "El teléfono debe ser adecuado.")
  //   .required("required"),
  // AccountHolderEmail: Yup.string()
  //   .email()
  //   .required("El correo electrónico es un campo obligatorio."),
  Name: Yup.string().required("El nombre del titular es un campo obligatorio."),
  SurName: Yup.string().required("Los apellidos es un campo obligatorio."),
  Email: Yup.string()
    .email("El correo electrónico debe ser un correo electrónico válido")
    .required("El correo electrónico es un campo obligatorio."),
  MobileNumber: Yup.string()
    .matches(phoneRegExp, "El teléfono debe ser adecuado.")
    .required("El teléfono es un campo obligatorio."),
  AddressLine1: Yup.string().required("La dirección es un campo obligatorio."),
  // AddressLine2: Yup.string().required("required"),
  Country: Yup.string().required("El país es un campo obligatorio."),
  PostalCode: Yup.string().required(
    "El código postal es un campo obligatorio."
  ),
  DealerName: Yup.number().required(
    "El concesionario es un campo obligatorio."
  ),
  // .oneOf(
  //   ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  //   "El concesionario es un campo obligatorio."
  // )
  // .required("El concesionario es un campo obligatorio."),
  Village: Yup.string().required("El pueblo es un campo obligatorio."),
});

export const ProfileChangePassSchema = Yup.object({
  OldPassword: Yup.string().required("Contraseña enviada requerida."),
  Password: Yup.string().required("El contraseña es un campo obligatorio."),
  RepeatPassword: Yup.string()
    .required("Repetir la contraseña es un campo obligatorio.")
    .oneOf([Yup.ref("Password"), null], "Las contraseñas no coinciden."),
});

export const PaymentSchema = Yup.object({
  CardName: Yup.string().required(
    "El nombre en la tarjeta es un campo obligatorio."
  ),
});

export const ContratoSchema = Yup.object({
  checkbox: Yup.boolean()
    .oneOf(
      [true],
      "Para continuar con el pago usted debe aceptar los Términos de este Contrato"
    )
    .required(
      "Para continuar con el pago usted debe aceptar los Términos de este Contrato"
    ),
});
