import { React, useEffect } from "react";
import "./LandingPage.css";
import Navbar from "../../components/UI/Navbar";
import Section1 from "../../pages/LandingPage/Sections/Section1";
import Section2 from "../../pages/LandingPage/Sections/Section2";
import Section3 from "../../pages/LandingPage/Sections/Section3";
import Section4 from "../../pages/LandingPage/Sections/Section4";
import Section5 from "../../pages/LandingPage/Sections/Section5";
import Section6 from "../../pages/LandingPage/Sections/Section6";
import Footer from "../../../Common/components/UI/Footer";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const LandingPage = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  const ico = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.09998V8H12.0498Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  return (
    <>
      <Helmet>
        <title>Nissan | Reserva online tu ARIYA® 100% eléctrico</title>
        <meta
          name="description"
          content="Conoce todo sobre tu próximo crossover eléctrico y comienza el proceso de reservación 
fácilmente."
        />
      </Helmet>

      <div className="main_landing_div">
        <Navbar />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        {/* <Section6 /> */}
        <div className="last_text">
          <p>
            <span>{ico}</span>
            Las ilustraciones, fotografías y especificaciones en este portal se
            refieren a la más reciente información disponible sobre el producto
            al momento de la impresión. Nissan de Puerto Rico se reserva el
            derecho de hacer cambios en cualquier momento, sin que medie previa
            notificación en cuanto a precios, colores, materiales, equipo,
            especificaciones y modelos y también para proceder a descontinuar
            aquellos que así se requieran.
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
