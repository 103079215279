import React, { useEffect, useState } from "react";
import "./UserRegister.css";
import { useFormik } from "formik";
import { RegisterYesSchema } from "../../../../schemas";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-hot-toast";
import Wrapper from "../../../../components/UI/Wrapper";
import Card from "../../../../components/UI/Card";
import Input from "../../../../components/UI/Input";
import Button from "../../../../../Common/components/UI/Button";
import axios from "../../../../../Common/Api";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const UserRegister = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  const initialValues = {
    Name: "",
    Surname: "",
    Email: "",
    ConfirmEmail: "",
    Number: "",
    Password: "",
    DealerName: "",
    RepeatPassword: "",
    termCheckbox: false,
    termCheckbox2: false,
    termCheckbox3: false,
  };

  const Navigate = useNavigate();

  function RegisterModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered>
        <div className="pt-3"></div>
        <Modal.Body className="white text-center">
          <h4 className="pt-2 pb-2">¡Alerta!</h4>
          <p className="pt-2 pb-2 Modal-text">
            Para poder la comunicación en relación a tu reserva, es importante
            que aceptes recibir información al correo electrónico y/o número de
            teléfono provisto vía llamadas, llamadas grabadas o de voz
            artificial y/o mensaje de texto, sobre productos y servicios de
            parte de Nissan en Puerto Rico, su distribuidor Motorambar, Inc. y/o
            sus concesionarios autorizados.
          </p>
          <div className="p-2"></div>
          <Button variant="red" onClick={props.onHide}>
            OK
          </Button>
          <div className="p-3"></div>
        </Modal.Body>
      </Modal>
    );
  }

  function TermsModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered>
        <div className="pt-3"></div>

        <Modal.Body className="white text-center ">
          <h4 className="pt-2 pb-2">¡Alerta!</h4>
          <p className="pt-2 pb-2 Modal-text">
            Para poder realizar el registro es importante que aceptes nuestros{" "}
            <Link to="/" style={{ color: "black" }}>
              Términos y Condiciones
            </Link>{" "}
            y{" "}
            <Link to="/" style={{ color: "black" }}>
              Política de Privacidad.
            </Link>
          </p>
          <div className="p-2"></div>
          <Button variant="red" onClick={props.onHide}>
            OK
          </Button>
          <div className="p-3"></div>
        </Modal.Body>
      </Modal>
    );
  }

  const [DealerList, setDealerList] = useState([]);
  const [TermModalState, setTermModalState] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [IsError, setIsError] = useState("");

  const getDealerList = async () => {
    try {
      const res = await axios.get("/get-dealer-names");
      setDealerList(res.data.data);
      // console.log("🚀 res....", res);
    } catch (error) {
      // setGetApiError(error);
      console.log("🚀 Catch-error....", error);
    }
  };

  useEffect(() => {
    getDealerList();
  }, []);

  const onSubmit = async (values) => {
    // console.log("Form values. ......", values);

    if (values.termCheckbox === false) {
      setModalShow(true);
    } else if (values.termCheckbox2 === false) {
      setTermModalState(true);
    } else {
      setLoading(true);
      // setTimeout(() => {
      //   setLoading(false);
      //   toast.success("Form Submitted");
      //   setIsError("");
      // }, 500);
      try {
        const response = await axios.post(
          // "/register-step-1",
          "/customer-register-step-1",
          {
            Name: values.Name,
            SurName: values.Surname,
            Email: values.Email,
            MobileNumber: values.Number,
            Password: values.Password,
            DealerName: values.DealerName,
            ConfirmPassword: values.RepeatPassword,
            CheckMark1: values.termCheckbox,
            CheckMark2: values.termCheckbox2,
            CheckMark3: values.termCheckbox3,
            UserType: "User",
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );
        console.log("🚀 response.....", response);
        setIsError(response.data.message);
        //
        if (response) {
          // var res = response.data.data;
          // console.log("🚀 res", res);
          // localStorage.setItem("Register-token", JSON.stringify(res));
          setLoading(false);
          if (response.data.status == "success") {
            localStorage.setItem(
              "user-info",
              JSON.stringify(response.data.data)
            );

            toast.success(`Registro Exitoso`);

            if (localStorage.getItem("StaticCartData")) {
              Navigate("/Interest");
            } else {
              toast(() => <span>Please Select a modal to purchase</span>);
              Navigate("/");
              // ! Old Flow k liye ye kaam ka hai hoo
              // !===============
              localStorage.setItem("Register-Start", "start");
              // !===============
            }
            // Navigate("/RegistrateAddress");
          }
        }
      } catch (error) {
        // console.log("🚀error....", error.message);
        setLoading(false);
      }
    }
    // const Token = JSON.parse(localStorage.getItem("Register-token"));
    // if (Token) {
    // }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: RegisterYesSchema,
      onSubmit,
    });

  return (
    <div>
      <Helmet>
        <title>Nissan | Registra tu cuenta de reservaciones</title>
        <meta
          name="description"
          content="Estás a un paso de comenzar el proceso de reservación de tu próximo
          vehículo eléctrico."
        />
      </Helmet>
      <Wrapper>
        <Card head={""}>
          <Form onSubmit={handleSubmit} className="mainform4">
            <div className="inputs4">
              <h1>Registro</h1>
              <p>
                Crea tu cuenta para mantenerte informado sobre la unidad de tu
                interés.
              </p>
              <div>
                <Input
                  name="Name"
                  text={"Nombre"}
                  type={"text"}
                  placeholder={"Escribe tu nombre"}
                  value={values.Name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.Name && touched.Name ? (
                  <p className="form-error"> {errors.Name} </p>
                ) : null}
              </div>
              <div>
                <Input
                  name="Surname"
                  text={"Apelidos"}
                  type={"text"}
                  placeholder={"Escribe tus apellidos"}
                  value={values.Surname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.Surname && touched.Surname ? (
                  <p className="form-error"> {errors.Surname} </p>
                ) : null}
              </div>

              <div>
                <Input
                  name="Email"
                  text={"Correo electrónico"}
                  type={"email"}
                  placeholder={"Ingresa tu correo electrónico"}
                  value={values.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.Email && touched.Email ? (
                  <p className="form-error"> {errors.Email} </p>
                ) : null}
              </div>
              {IsError == "The email has already been taken." ? (
                <span className="form-error">
                  {"El email ya ha sido registrado."}
                </span>
              ) : (
                <></>
              )}
              <div>
                <Input
                  name="ConfirmEmail"
                  text={"Repite tu correo electrónico"}
                  type={"email"}
                  placeholder={"Repite tu correo electrónico"}
                  value={values.ConfirmEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.ConfirmEmail && touched.ConfirmEmail ? (
                  <p className="form-error"> {errors.ConfirmEmail} </p>
                ) : null}
              </div>
              <div>
                <Input
                  name="Number"
                  text={"Teléfono"}
                  type={"number"}
                  placeholder={"Ej. 7877877878"}
                  value={values.Number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.Number && touched.Number ? (
                  <p className="form-error"> {errors.Number} </p>
                ) : null}
              </div>
              {IsError === "The mobile number has already been taken." ? (
                <span className="form-error">
                  {"El número de móvil ya ha sido tomado."}
                </span>
              ) : (
                <></>
              )}
              {/* <div>
            <div id="input2">
              <span>Consecionario</span>
              <select
                placeholder={"Seleccione el concesionario"}
                name="DealerName"
                id="option"
                value={values.DealerName}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="" disabled defaultChecked className="place">
                  Seleccione el concesionario
                </option>
                {DealerList?.map((item, index) => {
                  const { MainDealer, SubDealer } = item;
                  return (
                    <optgroup label={MainDealer} key={index}>
                      {SubDealer?.map((_item, index) => {
                        return (
                          <option value={`${_item.dealer_id}`} key={index}>
                            {_item.dealer_name}
                          </option>
                        );
                      })}
                    </optgroup>
                  );
                })}
              </select>
            </div>
            {errors.DealerName && touched.DealerName ? (
              <p className="form-error"> {errors.DealerName} </p>
            ) : null}
          </div> */}

              {/* <button onClick={() => console.log(values.DealerName)}>
                He rkoe jiw
              </button> */}

              <div>
                <div id="input2">
                  <span>Concesionario</span>
                  <select
                    placeholder={"Seleccione el concesionario"}
                    name="DealerName"
                    id="option"
                    value={values.DealerName}
                    onChange={handleChange}
                    onBlur={handleBlur}>
                    <option value="" disabled defaultChecked className="place">
                      Selecciona el concesionario de preferencia
                    </option>
                    {/* <optgroup label={MainDealer} key={index}>
                          {SubDealer.map((_item, index) => {
                            return (
                              <option value={_item.dealer_id} key={index}>
                                {_item.dealer_name}
                              </option>
                            );
                          })}
                        </optgroup> */}
                    {/* <option value="1">Adriel Auto - Toa Baja</option>
                    <option value="2">Yokomuro - Bayamón</option> */}
                    {DealerList.map((item, index) => {
                      const { MainDealer, SubDealer } = item;
                      return (
                        <>
                          {SubDealer.map((_item, index) => {
                            return (
                              <option value={_item.dealer_id} key={index}>
                                {MainDealer} - {_item.dealer_name}
                              </option>
                            );
                          })}
                        </>
                      );
                    })}
                  </select>
                </div>
                {errors.DealerName && touched.DealerName ? (
                  <p className="form-error"> {errors.DealerName} </p>
                ) : null}
              </div>

              <div>
                <Input
                  name="Password"
                  text={"Contraseña"}
                  type={"password"}
                  placeholder={"Ingresa una contraseña"}
                  value={values.Password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.Password && touched.Password ? (
                  <p className="form-error"> {errors.Password} </p>
                ) : null}
              </div>

              <div>
                <Input
                  name="RepeatPassword"
                  text={"Repita la contraseña"}
                  type={"password"}
                  placeholder={"Repite la contraseña"}
                  value={values.RepeatPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.RepeatPassword && touched.RepeatPassword ? (
                  <p className="form-error"> {errors.RepeatPassword} </p>
                ) : null}
              </div>
              <span className="form-error">
                {IsError === "The password must be at least 8 characters." ? (
                  <span>
                    {"La contraseña debe contener al menos 8 letras."}
                  </span>
                ) : (
                  <></>
                )}
              </span>
            </div>
            <div className="d-flex flex-column">
              <label className="pra align-items-center" htmlFor="html2">
                {/* <Checkbox name="termCheckbox" id={"html2"} /> */}
                <div className="form-group">
                  <input
                    type="checkbox"
                    id={"html2"}
                    name="termCheckbox"
                    value={values.termCheckbox}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {/* <label htmlFor="id"></label> */}
                </div>
                <p>
                  Acepto recibir información al número de teléfono provisto, vía
                  llamadas, llamadas grabadas o de voz artificial y/o mensaje de
                  texto, sobre productos y servicios de parte de Nissan de
                  Puerto Rico, su distribuidora Motorambar, Inc., y/o sus
                  concesionarios autorizados..
                </p>
              </label>
            </div>
            <div className="d-flex flex-column">
              <label className="pra align-items-center" htmlFor="html5">
                {/* <Checkbox name="termCheckbox" id={"html2"} /> */}
                <div className="form-group">
                  <input
                    type="checkbox"
                    id={"html5"}
                    name="termCheckbox"
                    value={values.termCheckbox3}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {/* <label htmlFor="id"></label> */}
                </div>
                <p>
                  Suscribirme al servicio de correo electrónico sobre productos
                  y servicios de Nissan.
                </p>
              </label>
            </div>
            <div className="d-flex flex-column">
              <label className="pra align-items-center" htmlFor="html3">
                {/* <Checkbox name="termCheckbox" id={"html2"} /> */}
                <div className="form-group">
                  <input
                    type="checkbox"
                    id={"html3"}
                    name="termCheckbox2"
                    value={values.termCheckbox2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {/* <label htmlFor="id"></label> */}
                </div>
                <p>
                  Acepto los Términos y Condiciones y Política de Privacidad.
                </p>
              </label>
            </div>

            <div className="">
              <Button variant={"red"} type={"submit"}>
                <span className="p-4">Continuar con la reserva</span>
              </Button>
            </div>
          </Form>
        </Card>
        {!Loading && <div></div>}
        {Loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </Wrapper>
      <RegisterModal show={modalShow} onHide={() => setModalShow(false)} />
      <TermsModal
        show={TermModalState}
        onHide={() => setTermModalState(false)}
      />
    </div>
  );
};

export default UserRegister;
