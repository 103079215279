import React, { useState } from "react";
import "./Drop_down.css";
import { Link, useNavigate } from "react-router-dom";

import Model_data from "../../Data/model_dropdown";
import Leaf_data from "../../Data/Leaf_data";
import img1 from "../../../assets/images/Modal_of_menu/4.png";
import ReactGA from "react-ga4";
// import img1 from "../../../assets/images/Evolve+ e-4orce/Exterior/Black Diamond Pearl/1.png";
// import img2 from "../../../../Leaf/assets/images/SV Plus/Exterior/Super Black - Pearl White/1.png";

const Dropdownmodel = ({ steps, setsteps }) => {
  const UserData = JSON.parse(localStorage.getItem("user-info"));
  const navigate = useNavigate();
  // const [indexx, setindex] = useState(0);
  const handleclick = (index, modelType, model) => {
    localStorage.setItem("cardindex", index);
    steps != undefined && setsteps(index);
    // localStorage.setItem("data", "istrue");
    // ReactGA.event(
    //   window.dataLayer.push({
    //     event: "megamenu_modelos",
    //     user_id: UserData ? UserData.id : "null",
    //     option: "Modelos",
    //     action: model,
    //   })
    // );
    ReactGA.event({
      category: "megamenu_modelos",
      action: "Modelos",
      label: model,
    });

    // Log event to Google Tag Manager
    window.dataLayer.push({
      event: "megamenu_modelos",
      user_id: UserData ? UserData.id : "null",
      option: "Modelos",
      action: model,
    });

    console.log(index);
    console.log(model);
    // setindex(index);
    // setTimeout(() => {
    navigate(modelType == "leaf" ? "/Leaf/Model" : "/Model");
    // }, 500);
  };

  const [isclick, setisclick] = useState(false);
  const [isclick1, setisclick1] = useState(true);

  return (
    <>
      <div className="main_dox">
        <div className="dropdown_main">
          <div className="dropdown_sub">
            <div className="Main_model">
              <Link
                className="link_box"
                onClick={() => {
                  // setisclick1(true);
                }}
                to="/Inicio">
                <img className="carimg_main" src={img1} alt="" />
                <h2 className="headingtext">
                  ARIYA<span>&#174;</span>
                </h2>
              </Link>
              {/* <Link
                className="link_box"
                onClick={() => {
                  // prompt("sd");
                  // setisclick1(false);
                }}
                to="/Leaf/Inicio"
              >
                <img className="carimg_main" src={img2} alt="" />
                <h2 className="headingtext">LEAF</h2>
              </Link> */}
            </div>

            {isclick1 ? (
              <div className="Sub_model">
                <div className="Sub_model_text">
                  <h1>
                    ARIYA<span>&#174;</span>
                  </h1>
                </div>
                <div className="model_img_box">
                  {Model_data.map((model, index) => {
                    return (
                      <span
                        className={
                          steps !== index ? "sub_model_box" : "actvelink"
                        }
                        style={{ cursor: "pointer" }}
                        key={index}
                        onClick={() =>
                          handleclick(index, "ariya", model.title)
                        }>
                        <img className="carimg" src={model.img} alt="" />
                        <p className="title_text">{model.title} </p>
                      </span>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="Sub_model">
                <div className="Sub_model_text">
                  <h1>LEAF</h1>
                </div>
                <div className="model_img_box">
                  {Leaf_data.map((leafmodel, index) => {
                    return (
                      <span
                        className={
                          steps !== index ? "sub_model_box" : "actvelink"
                        }
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleclick(index, "leaf")}>
                        <img className="carimg" src={leafmodel.img} alt="" />
                        <p className="title_text">{leafmodel.title}</p>
                      </span>
                    );
                  })}
                </div>
              </div>
            )}

            {/* {isclick ? (

            ) : null} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropdownmodel;
