import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../../Common/components/UI/Button";
import Loader from "../../../components/UI/Loader";
import { Form, Formik, Field } from "formik";
import { UpdateProfileSchema } from ".././../../schemas";
import "./MyProfile.css";
import { toast } from "react-hot-toast";
import axios from "../../../../Common/Api";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const MyProfile = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  const [options, setOptions] = useState([]);
  const Navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const [ApiData, setApiData] = useState([]);
  const [ApiError, setApiError] = useState("");

  const [DealerList, setDealerList] = useState([]);

  const [EditProfile, setEditProfile] = useState(false);

  const [Loading, setLoading] = useState(true);
  const [GetApiError, setGetApiError] = useState();

  const getpueblo = async () => {
    try {
      const res = await axios.get("get-pueblo");
      setOptions(res.data.data);
      // console.log("🚀 res....", res);
    } catch (error) {
      // setGetApiError(error);
      // console.log("🚀 Catch-error....", error);
    }
  };
  useEffect(() => {
    getpueblo();
  }, []);

  const getApiData = async () => {
    setLoading(true);
    try {
      const res = await axios.get("get-user-profile", {
        headers: {
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      console.log("🚀 res.getuser....", res);
      setApiData(res.data.data);
      setLoading(true);
      if (res.data.status == "success") {
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      // console.log("🚀 Error", error);
      setApiError(error.message);
      if (error.message == "Request failed with status code 401") {
        localStorage.removeItem("user-info");
        Navigate("/Inicio");
      }
    }
  };

  const initialValues = {
    AccountName: ApiData.AccountName,
    AccountSurnameName: ApiData.AccountSurnameName,
    AccountHolderPhoneNumber: ApiData.AccountHolderPhoneNumber,
    AccountHolderEmail: ApiData.AccountHolderEmail,
    Name: ApiData.Name,
    SurName: ApiData.SurName,
    Email: ApiData.Email,
    MobileNumber: ApiData.MobileNumber,
    AddressLine1: ApiData.AddressLine1,
    AddressLine2: ApiData.AddressLine2,
    Country: ApiData.Country,
    PostalCode: ApiData.PostalCode,
    DealerName: ApiData.DealerId,
    Village: ApiData.Pueblo,
  };
  // console.log(
  //   "🚀 ~ file: index.js:82 ~ MyProfile ~ initialValues:",
  //   initialValues
  // );

  const handleSubmit = async (values) => {
    console.log("edit profile values....", values);
    setLoading(true);
    try {
      const response = await axios.post(
        "edit-user-profile",
        {
          Name: values.Name,
          SurName: values.SurName,
          MobileNumber: values.MobileNumber,
          Email: values.Email,
          PostalCode: values.PostalCode,
          AccountName: values.AccountName,
          AccountSurnameName: values.AccountSurnameName,
          AccountHolderEmail: values.AccountHolderEmail,
          AccountHolderPhoneNumber: values.AccountHolderPhoneNumber,
          AddressLine1: values.AddressLine1,
          AddressLine2: values.AddressLine2,
          Country: values.Country,
          DealerName: values.DealerName,
          Pueblo: values.Village,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData?.Token}`,
          },
        }
      );
      console.log("🚀 res.editprofile....", response);
      // console.log(values.Village);
      if (response.data.status == "success") {
        setLoading(false);
        setEditProfile(false);
        getApiData();
      }
    } catch (error) {
      // console.log("🚀 error", error);
      if (error) {
        setLoading(false);
      }
    }
  };

  const getDealerList = async () => {
    try {
      const res = await axios.get("get-dealer-names");
      setDealerList(res.data.data);
      // console.log("🚀 res....", res);
    } catch (error) {
      setGetApiError(error);
      // console.log("🚀 error....", error.message);
    }
  };

  useEffect(() => {
    getApiData();
    getDealerList();
  }, []);

  // console.log(initialValues.Name);

  return (
    <div>
      <Helmet>
        <title>Nissan | Configuración de tu perfil</title>
        <meta
          name="description"
          content="Explora diferentes opciones y ajustes de tu cuenta."
        />
      </Helmet>
      {!Loading && (
        <>
          {!EditProfile ? (
            <section className="white profile-yes-main-sec">
              <>
                <div className="w-100 pb-3">
                  <div className="d-flex justify-content-end">
                    {/* <Link to="/" className="ProfileHead"> */}
                    <span
                      className="ProfileHead"
                      style={{ cursor: "pointer" }}
                      onClick={() => setEditProfile(true)}>
                      Editar
                    </span>
                    {/* </Link> */}
                  </div>
                </div>

                {ApiData.AccountHolder == "No" && (
                  <>
                    <span style={{ fontSize: "22px", fontWeight: "600" }}>
                      TITULAR
                    </span>
                    <div className="d-flex w-100 overflow-hidden profile-yes-div">
                      <div className="d-flex flex-column gap-2 w-100">
                        <div className="d-flex flex-column">
                          <span className="ProfileHead">
                            Nombre y Apellidos
                          </span>
                          <span className="ProfileText">
                            {ApiData.AccountName} {ApiData.AccountSurnameName}
                          </span>
                        </div>

                        <div className="d-flex flex-column">
                          <span className="ProfileHead">Teléfono</span>
                          <span className="ProfileText">
                            {ApiData.AccountHolderPhoneNumber}
                          </span>
                        </div>
                      </div>

                      <div className="d-flex flex-column gap-2 w-100">
                        <div className="d-flex flex-column">
                          <span className="ProfileHead">
                            Correo electrónico
                          </span>
                          <span className="ProfileText">
                            {ApiData.AccountHolderEmail}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="p-4"></div>
                  </>
                )}
                <div className="d-flex w-100 overflow-hidden profile-yes-div">
                  <div className="d-flex flex-column gap-2 w-100">
                    {ApiData.AccountHolder == "No" ? (
                      <div className="d-flex flex-column">
                        <span className="ProfileHead">Nombre y Apellidos</span>
                        <span className="ProfileText">
                          {" "}
                          {ApiData.AccountName}{" "}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="d-flex flex-column">
                      <span className="ProfileHead">Nombre</span>
                      {/* <span className="ProfileHead">
                        Nombre del titular de la cuenta
                      </span> */}
                      <span className="ProfileText"> {ApiData.Name} </span>
                      {/* <span className="ProfileText"> John </span> */}
                    </div>

                    <div className="d-flex flex-column">
                      <span className="ProfileHead">Apellidos</span>
                      {/* <span className="ProfileHead">
                        Apellidos del titular de la cuenta
                      </span> */}
                      <span className="ProfileText">{ApiData.SurName}</span>
                      {/* <span className="ProfileText">Doe</span> */}
                    </div>

                    <div className="d-flex flex-column">
                      <span className="ProfileHead">Correo electrónico</span>
                      {/* <span className="ProfileHead">Correo electrónico</span> */}
                      <span className="ProfileText"> {ApiData.Email} </span>
                      {/* <span className="ProfileText"> johndoe@email.com </span> */}
                    </div>

                    <div className="d-flex flex-column">
                      <span className="ProfileHead">Teléfono</span>
                      <span className="ProfileText">
                        {" "}
                        {ApiData.MobileNumber}{" "}
                      </span>
                      {/* <span className="ProfileText">787-787-7894</span> */}
                    </div>
                    {ApiData.DealerData ? (
                      <div className="d-flex flex-column">
                        <span className="ProfileHead">Concesionario</span>
                        <span className="ProfileText">
                          {ApiData.DealerData.MainDealer} -{" "}
                          {ApiData.DealerData.SubDealer.dealer_name}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="d-flex flex-column gap-2 w-100">
                    <div className="d-flex flex-column">
                      <span className="ProfileHead">Dirección</span>
                      <span className="ProfileText">
                        {" "}
                        {ApiData.AddressLine1}{" "}
                      </span>
                      {/* <span className="ProfileText">
                        Barrio Juan Del Pueblo
                      </span> */}
                    </div>
                    {ApiData.AddressLine2 ? (
                      <div className="d-flex flex-column">
                        <span className="ProfileHead">
                          Segunda línea de dirección
                        </span>
                        <span className="ProfileText">
                          {ApiData.AddressLine2}
                        </span>
                        {/* <span className="ProfileText">
                          Barrio Juan Del Pueblo
                        </span> */}
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="d-flex flex-column">
                      <span className="ProfileHead">País</span>
                      <span className="ProfileText"> {ApiData.Country} </span>
                    </div>

                    <div className="d-flex flex-column">
                      <span className="ProfileHead">Pueblo</span>
                      <span className="ProfileText">{ApiData.Pueblo}</span>
                    </div>

                    <div className="d-flex flex-column">
                      <span className="ProfileHead">Código postal</span>
                      <span className="ProfileText">{ApiData.PostalCode}</span>
                    </div>
                  </div>
                </div>
              </>
            </section>
          ) : (
            <div className="back-btn" style={{ width: "100%" }}>
              <svg
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "-45px",
                  left: "0",
                }}
                onClick={() => setEditProfile(false)}
                width="40"
                height="40"
                viewBox="0 0 108 108"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M38.07 57.1951L63.54 82.6201C63.9584 83.0419 64.4561 83.3766 65.0044 83.6051C65.5528 83.8335 66.141 83.9512 66.735 83.9512C67.3291 83.9512 67.9172 83.8335 68.4656 83.6051C69.014 83.3766 69.5117 83.0419 69.93 82.6201C70.7681 81.7769 71.2386 80.6364 71.2386 79.4476C71.2386 78.2587 70.7682 77.1182 69.93 76.2751L47.655 53.7751L69.93 31.5001C70.7682 30.657 71.2386 29.5164 71.2386 28.3276C71.2386 27.1387 70.7682 25.9982 69.93 25.1551C69.5133 24.7299 69.0163 24.3916 68.4678 24.1599C67.9194 23.9281 67.3304 23.8076 66.735 23.8051C66.1396 23.8076 65.5507 23.9281 65.0022 24.1599C64.4538 24.3916 63.9568 24.7299 63.54 25.1551L38.07 50.5801C37.6132 51.0015 37.2487 51.5129 36.9994 52.0821C36.75 52.6514 36.6213 53.2661 36.6213 53.8876C36.6213 54.509 36.75 55.1238 36.9994 55.693C37.2487 56.2623 37.6132 56.7737 38.07 57.1951Z"
                  fill="#d40000"
                />
              </svg>
              <section
                className="white profile-yes-main-sec"
                style={{ width: "100%" }}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={UpdateProfileSchema}
                  onSubmit={handleSubmit}>
                  {(props) => (
                    <Form>
                      {ApiData.AccountHolder == "No" && (
                        <>
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "600",
                            }}>
                            TITULAR
                          </span>
                          <div className="d-flex w-100 mt-2 overflow-hidden profile-yes-div">
                            <div className="d-flex flex-column gap-3 w-100">
                              <div className="d-flex flex-column">
                                <div className="input--">
                                  <span className="">Nombre del titular</span>
                                  <Field
                                    type="text"
                                    name="AccountName"
                                    placeholder="Nombre del titular"
                                    className="input-field"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.AccountName}
                                    style={{ padding: "5px 0" }}
                                  />
                                </div>
                                {props.errors.AccountName &&
                                props.touched.AccountName ? (
                                  <p className="form-error">
                                    {props.errors.AccountName}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="d-flex flex-column">
                                <div className="input--">
                                  <span className="">
                                    Apellidos del titular
                                  </span>
                                  <Field
                                    type="text"
                                    name="AccountSurnameName"
                                    placeholder="Apellidos del titular"
                                    className="input-field"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.AccountSurnameName}
                                    style={{ padding: "5px 0" }}
                                  />
                                </div>
                                {props.errors.AccountSurnameName &&
                                props.touched.AccountSurnameName ? (
                                  <p className="form-error">
                                    {props.errors.AccountSurnameName}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="d-flex flex-column">
                                <div className="input--">
                                  <span className="">Escriba su teléfono</span>
                                  <Field
                                    type="number"
                                    name="AccountHolderPhoneNumber"
                                    placeholder="Escriba su teléfono"
                                    className="input-field"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={
                                      props.values.AccountHolderPhoneNumber
                                    }
                                    style={{ padding: "5px 0" }}
                                  />
                                </div>
                                {props.errors.AccountHolderPhoneNumber &&
                                props.touched.AccountHolderPhoneNumber ? (
                                  <p className="form-error">
                                    {props.errors.AccountHolderPhoneNumber}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="d-flex flex-column gap-2 w-100">
                              <div className="d-flex flex-column">
                                <div className="input--">
                                  <span className="">
                                    Ingrese su correo electrónico
                                  </span>
                                  <Field
                                    type="email"
                                    name="AccountHolderEmail"
                                    placeholder="Ingrese su correo electrónico"
                                    className="input-field"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.AccountHolderEmail}
                                    style={{ padding: "5px 0" }}
                                  />
                                </div>
                                {props.errors.AccountHolderEmail &&
                                props.touched.AccountHolderEmail ? (
                                  <p className="form-error">
                                    {props.errors.AccountHolderEmail}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="p-4"></div>
                        </>
                      )}
                      <div className="d-flex w-100 overflow-hidden profile-yes-div">
                        <div className="d-flex flex-column gap-2 w-100">
                          <div className="d-flex flex-column">
                            <div className="input--">
                              <span className="">Nombre</span>
                              <Field
                                type="text"
                                name="Name"
                                placeholder="Escriba su nombre"
                                className="input-field"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.Name}
                                style={{ padding: "5px 0" }}
                              />
                            </div>
                            {props.errors.Name && props.touched.Name ? (
                              <p className="form-error">{props.errors.Name}</p>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="d-flex flex-column">
                            <div className="input--">
                              <span className="">Apellidos</span>
                              <Field
                                type="text"
                                name="SurName"
                                placeholder="Escriba sus apellidos"
                                className="input-field"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.SurName}
                                style={{ padding: "5px 0" }}
                              />
                            </div>
                            {props.errors.SurName && props.touched.SurName ? (
                              <p className="form-error">
                                {props.errors.SurName}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="d-flex flex-column">
                            <div className="input--">
                              <span className="">Correo electrónico</span>
                              <Field
                                type="email"
                                name="Email"
                                placeholder="Ingrese su correo electrónico"
                                className="input-field"
                                disabled
                                // onChange={props.handleChange}
                                // onBlur={props.handleBlur}
                                value={props.values.Email}
                                style={{ padding: "5px 0" }}
                              />
                            </div>
                            {props.errors.Email && props.touched.Email ? (
                              <p className="form-error">{props.errors.Email}</p>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="d-flex flex-column">
                            <div className="input--">
                              <span className="">Teléfono</span>
                              <Field
                                type="number"
                                name="MobileNumber"
                                placeholder="Ej. 7877877878"
                                className="input-field"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.MobileNumber}
                                style={{ padding: "5px 0" }}
                              />
                            </div>
                            {props.errors.MobileNumber &&
                            props.touched.MobileNumber ? (
                              <p className="form-error">
                                {props.errors.MobileNumber}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="d-flex flex-column ">
                            <div>
                              <div id="input2" style={{ width: "100%" }}>
                                <span>Consecionario</span>
                                <select
                                  placeholder={"Seleccione el concesionario"}
                                  name="DealerName"
                                  id="option"
                                  value={props.values.DealerName}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  style={{ width: "100%" }}>
                                  <option
                                    value=""
                                    disabled
                                    defaultChecked
                                    className="place">
                                    Seleccione el concesionario
                                  </option>
                                  {DealerList.map((item, index) => {
                                    const { MainDealer, SubDealer } = item;
                                    return (
                                      <>
                                        {SubDealer.map((_item, index) => {
                                          return (
                                            <option
                                              value={_item.dealer_id}
                                              key={index}>
                                              {MainDealer} - {_item.dealer_name}
                                            </option>
                                          );
                                        })}
                                      </>
                                    );
                                  })}
                                </select>
                              </div>
                              {props.errors.DealerName &&
                              props.touched.DealerName ? (
                                <p className="form-error">
                                  {props.errors.DealerName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex flex-column gap-2 w-100">
                          <div className="d-flex flex-column">
                            <div className="input--">
                              <span className="">Dirección</span>
                              <Field
                                type="text"
                                name="AddressLine1"
                                placeholder="Escriba su dirección"
                                className="input-field"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.AddressLine1}
                                style={{ padding: "5px 0" }}
                              />
                            </div>
                            {props.errors.AddressLine1 &&
                            props.touched.AddressLine1 ? (
                              <p className="form-error">
                                {props.errors.AddressLine1}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          {ApiData.AddressLine2 ? (
                            <div className="d-flex flex-column">
                              <div className="input--">
                                <span className="">
                                  Segunda línea de dirección
                                </span>
                                <Field
                                  type="text"
                                  name="AddressLine2"
                                  placeholder="Escriba su dirección"
                                  className="input-field"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.AddressLine2}
                                  style={{ padding: "5px 0" }}
                                />
                              </div>
                              {props.errors.AddressLine2 &&
                              props.touched.AddressLine2 ? (
                                <p className="form-error">
                                  {props.errors.AddressLine2}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="d-flex flex-column">
                            <div className="input--">
                              <span className="">País</span>
                              <Field
                                type="text"
                                name="Country"
                                placeholder="Escriba el país"
                                className="input-field"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.Country}
                                style={{ padding: "5px 0" }}
                              />
                            </div>
                            {props.errors.Country && props.touched.Country ? (
                              <p className="form-error">
                                {props.errors.Country}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="d-flex flex-column">
                            <div className="input--">
                              <span>Elige tu pueblo</span>
                              <select
                                name="Village"
                                text={"Pueblo"}
                                type={"text"}
                                placeholder={"Elige tu pueblo"}
                                value={props.values.Village}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}>
                                <option
                                  value=""
                                  disabled
                                  defaultChecked
                                  className="place">
                                  Elige tu pueblo
                                </option>
                                {/* {options.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.label}
                    </option>
                  ))} */}

                                <optgroup>
                                  {options.map((item, index) => {
                                    return (
                                      <option
                                        value={item.pueblo_title}
                                        key={index}>
                                        {item.pueblo_title}
                                      </option>
                                    );
                                  })}
                                </optgroup>
                              </select>
                            </div>

                            {props.errors.Village && props.touched.Village ? (
                              <p className="form-error">
                                {" "}
                                {props.errors.Village}{" "}
                              </p>
                            ) : null}
                          </div>

                          {/* <div className="d-flex flex-column">
                            <div className="input--">
                              <span className="">Pueblo</span>
                              <Field
                                type="text"
                                name="Village"
                                placeholder="Escriba el Pueblo"
                                className="input-field"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.Village}
                                style={{ padding: "5px 0" }}
                              />
                            </div>
                            {props.errors.Village && props.touched.Village ? (
                              <p className="form-error">
                                {props.errors.Village}
                              </p>
                            ) : (
                              ""
                            )}
                          </div> */}

                          <div className="d-flex flex-column">
                            <div className="input--">
                              <span className="">Código postal</span>
                              <Field
                                type="text"
                                name="PostalCode"
                                placeholder="Escriba el código postal"
                                className="input-field"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.PostalCode}
                                style={{ padding: "5px 0" }}
                              />
                            </div>
                            {props.errors.PostalCode &&
                            props.touched.PostalCode ? (
                              <p className="form-error">
                                {props.errors.PostalCode}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="w-75 pt-3">
                        <div className="d-flex gap-3 justify-content-center">
                          <Button variant={"red"} type="submit">
                            Guardar
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </section>
            </div>
          )}
        </>
      )}
      {Loading && <Loader />}
    </div>
  );
};

export default MyProfile;
