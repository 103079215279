import React, { useEffect, useState, useContext } from "react";
import "./ConfirmOrder.css";
import Button from "../../../Common/components/UI/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/NissanBlackLogo.png";
import { AllImages } from "../../constants/images";
import axios from "../../../Common/Api";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "../../components/UI/Loader";
import { GlobalContext } from "../../../GlobalContext";

import {
  Ariya_EngageFWD,
  Ariya_Engage_e_4orce,
  Ariya_Engage__e_4orce,
  VENTURE,
  EVOLVE,
  evolvepe4orce,
} from "../../constants/Data";
import { LEAFS, LeafSVPlus } from "../../../Leaf/constants/Data";
import ReactGA from "react-ga";

const ConfirmOrder = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  const { setTrems, setPolicy } = useContext(GlobalContext);

  var nf = new Intl.NumberFormat();

  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const [disable, setdisable] = useState(false);
  const [Specs, setshow] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const [Loading, setLoading] = useState(true);

  const [OrderHistory, setOrderHistory] = useState([]);

  const getOrderHistory = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/get-history", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      // console.log("OrderHistory...............", res);
      if (res.data.status == "success") {
        setOrderHistory(res.data);
        setLoading(false);
      } else {
        // setLoading(false);
        // console.log("res Error....", res);
      }
    } catch (error) {
      // setLoading(false);
      console.log("Catched Error....", error);
    }
  };

  useEffect(() => {
    getOrderHistory();
  }, []);

  let Pathnamee = useLocation();

  return (
    <>
      {Loading ? (
        // <div>
        //   <Backdrop
        //     sx={{
        //       color: "#fff",
        //       zIndex: (theme) => theme.zIndex.drawer + 1,
        //     }}
        //     open={true}

        //   >
        //     <CircularProgress color="inherit" />
        //   </Backdrop>
        // </div>
        <div className="loder_div">
          <Loader />
        </div>
      ) : (
        <div className="OrderDetail-Main-parent-div">
          <div
            className="pt-5 pb-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img
              src={logo}
              style={{ cursor: "pointer", width: "30%" }}
              onClick={() => Navigate("/")}
            />
          </div>
          <div className="ConfirmOrder-Body-parent-div">
            <div className="ConfirmOrder-Body-header-div">
              Confirmación de la orden
            </div>
            <div className="ConfirmOrder-Body-content-div">
              <div className="ConfirmOrder-Body-image-div d-flex justify-content-center align-items-center w-100">
                <img
                  src={
                    OrderHistory.data.model == "ENGAGE FWD"
                      ? Ariya_EngageFWD.exterior[
                          OrderHistory.data.Ext_Color_Code
                        ].images[0]
                      : OrderHistory.data.model == "ENGAGE e-4ORCE"
                      ? Ariya_Engage_e_4orce.exterior[
                          OrderHistory.data.Ext_Color_Code
                        ].images[0]
                      : OrderHistory.data.model == "ENGAGE+ e-4ORCE"
                      ? Ariya_Engage__e_4orce.exterior[
                          OrderHistory.data.Ext_Color_Code
                        ].images[0]
                      : OrderHistory.data.model == "VENTURE+"
                      ? VENTURE.exterior[OrderHistory.data.Ext_Color_Code]
                          .images[0]
                      : OrderHistory.data.model == "EVOLVE+ FWD"
                      ? EVOLVE.exterior[OrderHistory.data.Ext_Color_Code]
                          .images[0]
                      : OrderHistory.data.model == "EVOLVE+ e-4ORCE"
                      ? evolvepe4orce.exterior[OrderHistory.data.Ext_Color_Code]
                          .images[0]
                      : OrderHistory.data.model == "LEAF S"
                      ? LEAFS.exterior[OrderHistory.data.Ext_Color_Code]
                          .images[0]
                      : OrderHistory.data.model == "LEAF SV Plus"
                      ? LeafSVPlus.exterior[OrderHistory.data.Ext_Color_Code]
                          .images[0]
                      : null
                  }
                  width="90%"
                />
              </div>
              <div className="ConfirmOrder-Body-content">
                <div className="Confirm-order-Specs-div">
                  <span>
                    Número de transacción: {OrderHistory.data.trans_id}
                  </span>
                  <span>Número de orden: {OrderHistory.data.order_id}</span>
                </div>
                <div className="ModelName-Price-div">
                  <span>{OrderHistory.data.model}</span>
                </div>
                <div className="Confirm-order-Specs-div">
                  <span>
                    Color exterior: {OrderHistory.data.Ext_Color_Name}
                  </span>
                  <span>
                    Color interior: {OrderHistory.data.Int_Color_Name}
                  </span>
                  <span>Depósito: $500</span>
                  <span>
                    MSRP: ${nf.format(parseInt(OrderHistory.data.Price))}
                  </span>
                </div>
                <div className="additionalSpecs">
                  <button
                    className="additionalSpecs-btn"
                    type="button"
                    onClick={
                      OrderHistory.data.model == "ENGAGE FWD"
                        ? () =>
                            Navigate("/Especificaciones/Engage/FWD", {
                              state: {
                                Pathnamee,
                              },
                            })
                        : OrderHistory.data.model == "ENGAGE e-4ORCE"
                        ? () =>
                            Navigate("/Especificaciones/Engage/E-4FORCE", {
                              state: {
                                Pathnamee,
                              },
                            })
                        : OrderHistory.data.model == "ENGAGE+ e-4ORCE"
                        ? () =>
                            Navigate("/Especificaciones/Engage+/E-4FORCE", {
                              state: {
                                Pathnamee,
                              },
                            })
                        : OrderHistory.data.model == "VENTURE+"
                        ? () =>
                            Navigate("/Especificaciones/Engage/Venture+", {
                              state: {
                                Pathnamee,
                              },
                            })
                        : OrderHistory.data.model == "EVOLVE+ FWD"
                        ? () =>
                            Navigate("/Especificaciones/Evolve+/FWD", {
                              state: {
                                Pathnamee,
                              },
                            })
                        : OrderHistory.data.model == "EVOLVE+ e-4ORCE"
                        ? () =>
                            Navigate("/Especificaciones/Evolve+/e-4FORCE", {
                              state: {
                                Pathnamee,
                              },
                            })
                        : OrderHistory.data.Model == "LEAF-S"
                        ? Navigate("/Especificaciones/LEAF-S", {
                            state: {
                              Pathnamee,
                            },
                          })
                        : OrderHistory.data.Model == "LEAF-SV-Pus"
                        ? Navigate("/Especificaciones/LEAF-SV-Pus", {
                            state: {
                              Pathnamee,
                            },
                          })
                        : null
                    }>
                    Especificaciones adicionales
                  </button>
                </div>
                <div className="red_btn_Rpay w-100 d-flex flex-column gap-3">
                  <Button
                    variant={"red"}
                    onClick={() => Navigate("/Perfil/History")}>
                    Ir al perfil
                  </Button>
                </div>
                {/* <div className="termsandpolicy">
                  <Link to={"/Terminos-y-Condiciones"} className="link_of_trem">
                    Terminos y Condiciones
                  </Link>
                  <Link className="link_of_trem">y</Link>
                  <Link to={"/Politica-Privacidad"} className="link_of_trem">
                    Politica Privacidad
                  </Link>
                </div> */}
                <div className="termsandpolicy">
                  <p onClick={() => setTrems(true)} className="link_of_trem">
                    Términos y Condiciones
                  </p>
                  <p className="link_of_trem">y</p>
                  <p onClick={() => setPolicy(true)} className="link_of_trem">
                    Política de Privacidad
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmOrder;
