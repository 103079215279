import img7 from "../../../Leaf/assets/images/S/Exterior/Deep Blue Pearl/1.png";
import img8 from "../../../Leaf/assets/images/SV Plus/Exterior/Super Black - Pearl White/1.png";
const Leaf_data = [
  {
    id: 1,
    img: img7,
    title: "LEAF S",
  },
  {
    id: 2,
    img: img8,
    title: "LEAF SV Plus",
  },
];
export default Leaf_data;
