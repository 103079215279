import { AllImages } from "./images";

export const LEAFS = {
  exterior: {
    "#132E5B": {
      Ext_name: "Deep Blue Pearl ",
      Ext_code: "#132E5B",
      images: [
        AllImages.Leafs.DeepBluePearl.img1,
        AllImages.Leafs.DeepBluePearl.img2,
        AllImages.Leafs.DeepBluePearl.img3,
        AllImages.Leafs.DeepBluePearl.img4,
        AllImages.Leafs.DeepBluePearl.img5,
        AllImages.Leafs.DeepBluePearl.img6,
        AllImages.Leafs.DeepBluePearl.img7,
        AllImages.Leafs.DeepBluePearl.img8,
      ],
    },
    "#6A6E6E": {
      Ext_name: "Gun Metallic",
      Ext_code: "#6A6E6E",
      images: [
        AllImages.Leafs.GunMetallic.img1,
        AllImages.Leafs.GunMetallic.img2,
        AllImages.Leafs.GunMetallic.img3,
        AllImages.Leafs.GunMetallic.img4,
        AllImages.Leafs.GunMetallic.img5,
        AllImages.Leafs.GunMetallic.img6,
        AllImages.Leafs.GunMetallic.img7,
        AllImages.Leafs.GunMetallic.img8,
      ],
    },
    "#EEF5F4": {
      Ext_name: "Glacier White",
      Ext_code: "#EEF5F4",
      images: [
        AllImages.Leafs.GlacierWhite.img1,
        AllImages.Leafs.GlacierWhite.img2,
        AllImages.Leafs.GlacierWhite.img3,
        AllImages.Leafs.GlacierWhite.img4,
        AllImages.Leafs.GlacierWhite.img5,
        AllImages.Leafs.GlacierWhite.img6,
        AllImages.Leafs.GlacierWhite.img7,
        // AllImages.Leafs.GunMetallic.img8,
      ],
    },
    "#BDBEBD": {
      Ext_name: "Brilliant Silver",
      Ext_code: "#BDBEBD",
      images: [
        AllImages.Leafs.Silver.img1,
        AllImages.Leafs.Silver.img2,
        AllImages.Leafs.Silver.img3,
        AllImages.Leafs.Silver.img4,
        AllImages.Leafs.Silver.img5,
        AllImages.Leafs.Silver.img6,
        AllImages.Leafs.Silver.img7,
        AllImages.Leafs.Silver.img8,
      ],
    },
    "#050708": {
      Ext_name: "Super Black",
      Ext_code: "#050708",
      images: [
        AllImages.Leafs.SuperBlack.img1,
        AllImages.Leafs.SuperBlack.img2,
        AllImages.Leafs.SuperBlack.img3,
        AllImages.Leafs.SuperBlack.img4,
        AllImages.Leafs.SuperBlack.img5,
        AllImages.Leafs.SuperBlack.img6,
        AllImages.Leafs.SuperBlack.img7,
        AllImages.Leafs.SuperBlack.img8,
      ],
    },
  },
  interior: {
    "#000000": {
      Int_name: "Telanegra",
      Int_code: "#000000",
      images: [
        AllImages.Leafs.Telanegra.img1,
        AllImages.Leafs.Telanegra.img2,
        AllImages.Leafs.Telanegra.img3,
        AllImages.Leafs.Telanegra.img4,
        AllImages.Leafs.Telanegra.img5,
        AllImages.Leafs.Telanegra.img6,
        AllImages.Leafs.Telanegra.img7,
        AllImages.Leafs.Telanegra.img8,
        AllImages.Leafs.Telanegra.img9,
        AllImages.Leafs.Telanegra.img10,
        AllImages.Leafs.Telanegra.img11,
        AllImages.Leafs.Telanegra.img12,
        AllImages.Leafs.Telanegra.img13,
        AllImages.Leafs.Telanegra.img14,
      ],
    },
  },
  specs: [
    {
      Head: "Mecánica",
      content: [
        "Motor –  110 KW",
        "Batería	Ion de Litio – 40kWh",
        "Caballos de fuerza – 147HP",
        "Torsión – 236ft-lbs @ 0-3283rpm",
        "Límite de RPM – 10,5000",
        "Módulo de carga rápida – 6.6kW",
      ],
    },
    {
      Head: "Consumo",
      content: [
        "Consumo de energía (kW-horas por 100 millas) - 30",
        "Economía de energía en ciudad, carretera, combinado (mpg) – 123/99/111",
        "Rango de millas por carga – 149",
      ],
    },
    {
      Head: "Exterior",
      content: [
        "Luces delanteras de halógeno",
        "Luces delanteras inteligentes",
        "Cubierta aerodinámica debajo de la carrocería y difusor trasero",
        "Espejos retrovisores del color del auto con ajuste eléctrico",
        "Spoiler aerodinámico trasero",
        "Puerto de carga con iluminación y seguridad",
        "Limpia parabrisas de intermitencia variable",
        "Limpia parabrisas trasero con limpiador",
        "Puerto de carga rápida (CHAdeMO)",
      ],
    },
    {
      Head: "Interior - Asientos",
      content: [
        "Capacidad para 5 pasajeros",
        "Asientos con terminación en tela",
        "Asiento del conductor ajustable eléctrico de 8 posiciones con soporte lumbar",
        "Asiento del pasajero ajustable manual de 4 posiciones",
        "Sistema de calefacción en asientos delanteros",
      ],
    },
    {
      Head: "Interior - Infoentretenimiento",
      content: [
        "Monitor avanzado de asistencia al conductor de 7",
        "Sistema de audio con 4 bocinas",
        "Sistema Bluetooth® para llamadas y transferencia de audio",
        "Controles de audio integrados al guía",
        "Puertos de USB: 2 delanteros y 2 traseros",
        "Monitor a color de 8",
        "Apple CarPlay & Android Auto",
        "Asistencia de mensajería de texto y Siri Eyes Free",
      ],
    },
    {
      Head: "Comodidad - Conveniencia",
      content: [
        "Aire acondicionado de temperatura automática",
        "Guía de ajuste telescópico manual",
        "Bolsillo en puertas delanteras con porta botellas (x4)",
        "Portavasos en consola central (x2)",
        "Consola central con área de almacenaje",
        "Salida para accesorios de 12V",
        "Ventanas eléctricas con bajado y subido automático para el conductor",
        "Sistema de llave inteligente (i-Key) con apertura automática del puerto de carga",
        "Sistema de encendido de motor por botón",
        "Control de Viaje (Cruise Control)",
        "Sistema de cámara de reversa",
        "Sistema de Alerta de Puerta Trasera",
        "Viseras con extensores para conductor y pasajero",
        "Asiento trasero plegable 60/40",
        "Modo E-Pedal",
        "Apertura automática del puerto de carga desde el interior",
        " Monitor avanzado de asistencia al conductor de 7",
        "Cable de carga portátil (120V/240V EVSE)",
      ],
    },
    {
      Head: "Seguridad",
      content: [
        "Sistema avanzado de bolsas de aire (delanteras, laterales, tipo cortina)",
        "Sistemas de cinturones de seguridad de 3 puntos ALR para conductor y ALR / ELR para todos los ocupantes",
        "Luz y sonido indicador de recordatorio para cinturones de seguridad",
        "Sistema de anclaje para asiento protector de niños (LATCH)",
        "Cabezales de asientos delanteros de altura ajustable",
        "Barras tubulares de acero para protección de puertas",
        "Zonas de absorción de impactos delanteros y traseros",
        "Columna del guía con sistema de absorción de energía",
        "Seguros en puertas traseras a prueba de niños",
        "Sistema de monitoreo de presión de gomas (TPMS) con aviso de presión ideal",
        "Luz de frenado central",
        "Sistema de seguridad del vehículo (VSS)",
        "Inmovilizador de motor",
        "Aviso de Colisión Frontal Inteligente (I-FCW)",
        "Frenado Automático de Emergencia con detector de Peatones (PAEB)",
        "Aviso de Cambio de Carril Involuntario (LDW)",
        "Intervención de Cambio de Carril Involuntario (I-LI)",
        "Alerta de Cruce de Tráfico Trasero (RCTA)",
        "Aviso de Punto Ciego (BSW)",
        "Intervención de Punto Ciego (BSI)",
        "Asistencia de Luz Alta (HBA)",
        "Frenado Automático Trasero (RAB)",
        "ProPILOT Assist",
      ],
    },
    {
      Head: "Dimensiones",
      content: [
        "Distancia entre ejes (Pulg.) - 106.3",
        "Largo (Pulg.) - 176.4",
        "Ancho (Pulg.) - 70.5",
        "Alto (Pulg.) - 61.5",
        "Altura del vehículo al suelo - 5.9",
        "Volumen interior (Pies cúbicos) - 116.0",
        "Espacio de cabeza delantero / trasero (Pulg.) - 41.2 / 37.3",
        "Espacio de hombros delantero / trasero (Pulg.) - 54.3 / 52.5",
        "Espacio de caderas delantero / trasero (Pulg.) - 51.7 / 50.0",
        "Espacio de piernas delantero / trasero (Pulg.) - 42.1 / 33.5",
      ],
    },
    {
      Head: "Pesos",
      content: [
        " Peso del vehículo (lbs.) - 3,509",
        "Peso del vehículo con carga máxima GVWR (lbs.) - 4,509",
        "Distribución de peso delantero/trasero (lbs.) - 58/42",
      ],
    },
    {
      Head: "Suspensión",
      content: [
        'Delantera Independiente "MacPherson" con amortiguadores',
        "Trasera Independiente multi-link",
        "Barra estabilizadora Delantera y trasera",
      ],
    },
  ],
};

export const LeafSVPlus = {
  exterior: {
    "#132E5B": {
      Ext_name: "Deep Blue Pearl ",
      Ext_code: "#132E5B",
      images: [
        AllImages.LeafSVPlus.DeepBluePearl.img1,
        AllImages.LeafSVPlus.DeepBluePearl.img2,
        AllImages.LeafSVPlus.DeepBluePearl.img3,
        AllImages.LeafSVPlus.DeepBluePearl.img4,
        AllImages.LeafSVPlus.DeepBluePearl.img5,
        AllImages.LeafSVPlus.DeepBluePearl.img6,
        AllImages.LeafSVPlus.DeepBluePearl.img7,
        AllImages.LeafSVPlus.DeepBluePearl.img8,
      ],
    },
    "#6A6E6E": {
      Ext_name: "Gun Metallic",
      Ext_code: "#6A6E6E",
      images: [
        AllImages.Leafs.GunMetallic.img1,
        AllImages.Leafs.GunMetallic.img2,
        AllImages.Leafs.GunMetallic.img3,
        AllImages.Leafs.GunMetallic.img4,
        AllImages.Leafs.GunMetallic.img5,
        // AllImages.Leafs.GunMetallic.img6,
        AllImages.Leafs.GunMetallic.img7,
        AllImages.Leafs.GunMetallic.img8,
      ],
    },
    "#EFEFEF": {
      Ext_name: "Pearl White TriCoat",
      Ext_code: "#EFEFEF",
      images: [
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img1,
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img2,
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img3,
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img4,
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img5,
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img6,
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img7,
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img8,
      ],
    },
    "#000000": {
      Ext_name: "Super Black",
      Ext_code: "#000000",
      images: [
        AllImages.LeafSVPlus.superblack.img1,
        AllImages.LeafSVPlus.superblack.img2,
        AllImages.LeafSVPlus.superblack.img3,
        // AllImages.LeafSVPlus.superblack.img4,
        AllImages.LeafSVPlus.superblack.img5,
        AllImages.LeafSVPlus.superblack.img6,
        AllImages.LeafSVPlus.superblack.img7,
        AllImages.LeafSVPlus.superblack.img8,
      ],
    },
    "#BDBEBD": {
      Ext_name: "Brilliant Silver",
      Ext_code: "#BDBEBD",
      images: [
        AllImages.LeafSVPlus.Brilliant_Silver.img1,
        AllImages.LeafSVPlus.Brilliant_Silver.img2,
        AllImages.LeafSVPlus.Brilliant_Silver.img3,
        AllImages.LeafSVPlus.Brilliant_Silver.img4,
        AllImages.LeafSVPlus.Brilliant_Silver.img5,
        AllImages.LeafSVPlus.Brilliant_Silver.img6,
        AllImages.LeafSVPlus.Brilliant_Silver.img7,
        AllImages.LeafSVPlus.Brilliant_Silver.img8,
      ],
    },
    "#DFE9E3": {
      Ext_name: "SuperBlackPearlWhite",
      Ext_code: "#DFE9E3",
      images: [
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img1,
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img2,
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img3,
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img4,
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img5,
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img6,
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img7,
        AllImages.LeafSVPlus.PearlWhiteTriCoat.img8,
      ],
    },
  },
  interior: {
    "#000000": {
      Int_name: "Telanegra",
      Int_code: "#000000",
      images: [
        AllImages.LeafSVPlus.Telanegra.img1,
        AllImages.LeafSVPlus.Telanegra.img2,
        AllImages.LeafSVPlus.Telanegra.img3,
        AllImages.LeafSVPlus.Telanegra.img4,
        AllImages.LeafSVPlus.Telanegra.img5,
        AllImages.LeafSVPlus.Telanegra.img6,
        AllImages.LeafSVPlus.Telanegra.img7,
        AllImages.LeafSVPlus.Telanegra.img8,
        AllImages.LeafSVPlus.Telanegra.img9,
        AllImages.LeafSVPlus.Telanegra.img10,
        AllImages.LeafSVPlus.Telanegra.img11,
        AllImages.LeafSVPlus.Telanegra.img12,
        AllImages.LeafSVPlus.Telanegra.img13,
        AllImages.LeafSVPlus.Telanegra.img14,
      ],
    },
  },
  specs: [
    {
      Head: "Especificaciones Mecánicas",
      content: [
        " Motor - 160 KW",
        "Batería	Ion de Litio - 60kWh",
        "Caballos de fuerza - 214HP",
        "Torsión - 250ft-lbs @ 800-4000rpm",
        "Limite de RPM - 10,5000",
        "Módulo de carga rápida - 6.6kW",
      ],
    },
    {
      Head: "Dimensiones",
      content: [
        "Distancia entre ejes (Pulg.) – 106.3",
        "Largo (Pulg.) – 176.4",
        "Ancho (Pulg.) – 70.5",
        "Alto (Pulg.) – 61.7",
        "Altura del vehículo al suelo – 5.9",
        "Volumen interior (Pies cúbicos) – 116.0",
        "Espacio de cabeza delantero / trasero (Pulg.) – 41.2 / 37.3",
        "Espacio de hombros delantero / trasero (Pulg.) – 54.3 / 52.5",
        "Espacio de caderas delantero / trasero (Pulg.) – 51.7 / 50.0",
        "Espacio de piernas delantero / trasero (Pulg.) – 42.1 / 33.5",
      ],
    },
    {
      Head: "Pesos",
      content: [
        "Peso del vehículo (lbs.) – 3,901 ",
        "Peso del vehículo con carga máxima GVWR (lbs.) – 4,872",
        "Distribución de peso delantero / trasero (lbs.) – 57/43",
      ],
    },
    {
      Head: "Suspensión",
      content: [
        "Suspensión delantera independiente MacPherson con amortiguadores",
        "Suspensión trasera de barra de torsión",
        "Barras estabilizadoras delantera y trasera",
      ],
    },
    {
      Head: "Exterior",
      content: [
        "Aros en aleación de aluminio de 17",
        "Gomas P215/50 R17 ",
        "Luces delanteras en LED ",
        "Luces de neblina fog lights",
        "Luces delanteras inteligentes",
        "Luces diurnas en LED",
        "Cubierta aerodinámica debajo de la carrocería y difusor trasero",
        "Espejos retrovisores del color del auto con ajuste eléctrico y sistema de calefacción",
        "Spoiler aerodinámico trasero",
        "Puerto de carga con iluminación y seguridad",
        "Limpia parabrisas de intermitencia variable",
        "Limpia parabrisas trasero con limpiador",
        "Puerto de carga rápida (CHAdeMO)",
      ],
    },
    {
      Head: "Interior - Información/Entretenimiento",
      content: [
        "Monitor avanzado de asistencia al conductor de 7",
        "Sistema de audio con 6 bocinas",
        "Sistema Bluetooth® para llamadas y transferencia de audio",
        "Controles de audio integrados al guía",
        "Puertos de USB: 2 delanteros y 2 traseros",
        "Monitor a color de 8",
        "Apple CarPlay & Android Auto",
        "Sistema de navegación",
        "Asistencia de mensajería de texto y Siri Eyes Fre",
      ],
    },
    {
      Head: "Comodidad/Conveniencia",
      content: [
        "Aire acondicionado de temperatura automática",
        "Guía de ajuste telescópico manual",
        "Bolsillo en puertas delanteras con porta botellas (x4)",
        "Portavasos en consola central (x2)",
        "Consola central con área de almacenaje",
        "Espejo retrovisor interior con anti-deslumbramiento",
        "Salida para accesorios de 12V",
        "Ventanas eléctricas con bajado y subido automático para el conductor",
        "Sistema de llave inteligente (i-Key) con apertura automática del puerto de carga",
        "Sistema de encendido de motor por botón",
        "Control de Viaje Inteligente (CCI)",
        "Sistema de cámara de visión periférica inteligente (I-AVM)",
        "Alerta de Atención al Conductor Inteligente (I-DA)",
        "Sistema de Alerta de Puerta Trasera",
        "Cubierta en área de carga",
        "Viseras con extensores para conductor y pasajero",
        "Asiento trasero plegable 60/40",
        "Modo E-Pedal",
        "Sistema de calefacción híbrido",
        "Apertura automática del puerto de carga desde el interior",
        "Monitor avanzado de asistencia al conductor de 7",
        "Guía cubierto en piel y con sistema de calefacción",
        "Cable de carga portátil (120V/240V EVSE)",
      ],
    },
    {
      Head: "Asientos/Accesorios",
      content: [
        "Capacidad para 5 pasajeros",
        "Asientos con terminación en tela",
        "Asiento del conductor ajustable manual de 6 posiciones",
        "Asiento del pasajero ajustable manual de 4 posiciones",
      ],
    },
    {
      Head: "Seguridad",
      content: [
        "Sistema avanzado de bolsas de aire (delanteras, laterales, tipo cortina)",
        "Sistemas de cinturones de seguridad de 3 puntos ALR para conductor y ALR / ELR para todos los ocupantes",
        "Luz y sonido indicador de recordatorio para cinturones de seguridad",
        "Sistema de anclaje para asiento protector de niños (LATCH)",
        "Cabezales de asientos delanteros de altura ajustable",
        "Barras tubulares de acero para protección de puertas",
        "Zonas de absorción de impactos delanteros y traseros",
        "Columna del guía con sistema de absorción de energía",
        "Seguros en puertas traseras a prueba de niños",
        "Sistema de monitoreo de presión de gomas (TPMS) con aviso de presión ideal",
        "Luz de frenado central",
        "Sistema de seguridad del vehículo (VSS)",
        "Inmovilizador de motor",
        "Aviso de Colisión Frontal Inteligente (I-FCW)",
        "Frenado Automático de Emergencia con detector de Peatones (PAEB)",
        "Aviso de Cambio de Carril Involuntario (LDW)",
        "Intervención de Cambio de Carril Involuntario (I-LI)",
        "Alerta de Cruce de Tráfico Trasero (RCTA)",
        "Aviso de Punto Ciego (BSW)",
        "Intervención de Punto Ciego (BSI)",
        "Asistencia de Luz Alta (HBA)",
        "Frenado Automático Trasero (RAB)",
      ],
    },
    {
      Head: "Consumo",
      content: [
        "Consumo de energía (kW-horas por 100 millas) - 31",
        "Economía de energía en ciudad, carretera, combinado (mpg) – 121/98/109",
        "Rango de millas por carga – 212",
      ],
    },
  ],
};

export const CompareDataLeaf = {
  "LEAF S": {
    engine: "160 kW",
    baterry: "66 kWh",
    car: "FWD",
    Alcance: "149 millas",
    MPGe: "123/99/111",
    MSRP: 33520,
    img: AllImages.Leafs.DeepBluePearl.img1,
    features: [],
  },
  "LEAF SV PLUS": {
    engine: "250 kW",
    baterry: "66 kWh",
    car: "e-4ORCE (AWD)",
    Alcance: "212 millas",
    MPGe: "121/98/109",
    MSRP: 43150,
    img: AllImages.LeafSVPlus.PearlWhiteTriCoat.img1,
    features: [],
  },
};

export const features = [
  {
    head: "Colores exteriores",
    body: [
      {
        name: "Northern Lights Metallic",
        name: "Northern Lights Metallic",
        name: "Northern Lights Metallic",
        name: "Northern Lights Metallic",
      },
    ],
  },
];
