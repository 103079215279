import React, { useState, useEffect, useRef } from "react";
import "./Section2.css";
import "../../../Gallery/Exterior/Exterior.css";
import Carousel from "react-bootstrap/Carousel";

import inter1 from "../../../../assets/images/GalleryInterior/img5.png";
import inter2 from "../../../../assets/images/GalleryInterior/img4.png";
import inter3 from "../../../../assets/images/GalleryInterior/img3.png";
// import inter2 from "../../../../assets/images/Section2/1.png";
// import inter3 from "../../../../assets/images/Section2/2.png";
// import inter4 from "../../../../assets/images/Section2/3.png";

import inter4 from "../../../../assets/images/GalleryExterior/img3.png";
import inter5 from "../../../../assets/images/Section2/5.1.png";
import inter6 from "../../../../assets/images/Section2/10.png";

import Modal from "react-bootstrap/Modal";
import downarrow from "../../../../assets/images/chevrondown.png";
import { useNavigate } from "react-router-dom";
import close from "../../../../assets/images/close.png";
import ReactGA from "react-ga4";

const Section2 = () => {
  const [Pic1ModalShow, setPic1ModalShow] = useState(false);
  const [Pic2ModalShow, setPic2ModalShow] = useState(false);
  const [Pic3ModalShow, setPic3ModalShow] = useState(false);
  const navigate = useNavigate();
  const pic1ref = useRef();
  const pic2ref = useRef();
  const pic3ref = useRef();
  const [show, setshow] = useState(false);
  const [image, setimage] = useState("");

  const menuref = useRef(null);
  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setshow(false);
        }
      }
    };
    document.addEventListener("scroll", handler);
    return () => {
      document.removeEventListener("scroll", handler);
    };
  }, []);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const Imagelink = (e) => {
    ReactGA.event({
      category: "Landing page",
      action: "See More Images",
    });
    setimage(e.target.src);
    setshow(true);
    console.log(e.target.className);
    if (e.target.className === "first_img_box") {
      setimage(inter1);
    } else if (e.target.className === "second_img_box") {
      setimage(inter2);
    } else if (e.target.className === "thred_img_box") {
      setimage(inter3);
    } else if (e.target.className === "four_img_box") {
      setimage(inter4);
    } else if (e.target.className === "fifth_img_box") {
      setimage(inter5);
    } else if (e.target.className === "six_img_box") {
      setimage(inter6);
    }
  };

  return (
    <>
      <div className="gellerimaindiv">
        <span className="heding_text_span">
          ¡Reserva tu futuro eléctrico hoy! Ahora puedes reservar en línea tu
          Nissan ARIYA®, el nuevo crossover 100% eléctrico con hasta 304 millas
          de alcance y 389 HP. Vive la experiencia de manejar un EV con la
          tecnología más avanzada de Nissan hasta la fecha.
        </span>
        <h1 className="heading_text_h2">Galería </h1>

        <Carousel
          id="Galeria"
          interval={null}
          activeIndex={index}
          onSelect={handleSelect}>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="one_img_box">
                  <div className="first_img_box" onClick={Imagelink}>
                    <p>Recarga inalámbrica</p>
                  </div>
                  <div className="second_img_box" onClick={Imagelink}>
                    <p>Controles táctiles hápticos</p>
                  </div>

                  {/* <img
                    className="first_img_box"
                    onClick={Imagelink}
                    src={inter2}
                    alt=""
                  />
                  <img
                    className="first_img_box"
                    onClick={Imagelink}
                    src={inter3}
                    alt=""
                  /> */}
                </div>
                <div className="Two_img_box">
                  <div className="thred_img_box" onClick={Imagelink}>
                    <p>Espejo retrovisor inteligente</p>
                  </div>
                  {/* <img onClick={Imagelink} src={inter4} alt="" /> */}
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="one_img_box">
                  <div className="four_img_box" onClick={Imagelink}>
                    <p>Puerto de recarga</p>
                  </div>
                  <div className="fifth_img_box" onClick={Imagelink}>
                    <p>Luces LED delgadas y estilizadas</p>
                  </div>
                  {/* <img
                    className="first_img_box"
                    onClick={Imagelink}
                    src={inter5}
                    alt=""
                  />
                  <img
                    className="first_img_box"
                    onClick={Imagelink}
                    src={inter6}
                    alt=""
                  /> */}
                </div>
                <div className="Two_img_box">
                  <div className="six_img_box" onClick={Imagelink}>
                    <p>Diseño atemporal japonés</p>
                  </div>
                  {/* <img onClick={Imagelink} src={inter7} alt="" /> */}
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
        {/* ========================== mobile view div  start here ====================  */}
        <Carousel
          id="Galeriambile"
          interval={null}
          activeIndex={index}
          onSelect={handleSelect}>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="Two_img_box">
                  <div className="first_img_box" onClick={Imagelink}>
                    <p>Recarga inalámbrica</p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="Two_img_box">
                  <div className="second_img_box" onClick={Imagelink}>
                    <p>Controles táctiles hápticos</p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="Two_img_box">
                  <div className="thred_img_box" onClick={Imagelink}>
                    <p>Espejo retrovisor inteligente</p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="Two_img_box">
                  <div className="four_img_box" onClick={Imagelink}>
                    <p>Puerto de recarga</p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="Two_img_box">
                  <div className="fifth_img_box" onClick={Imagelink}>
                    <p>Luces LED delgadas y estilizadas</p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="Two_img_box">
                  <div className="six_img_box" onClick={Imagelink}>
                    <p>Diseño atemporal japonés</p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>

        <div className="viewarrowdiv">
          <div className="smajhniaraha" onClick={() => navigate("/Galeria")}>
            <span>Ver más imágenes</span>
            <div>
              <img onClick={Imagelink} src={downarrow} alt="" />
            </div>
          </div>
        </div>
        {show && (
          <div className="model" ref={menuref}>
            <div className="closeicon">
              <img src={close} alt="" onClick={() => setshow(false)} />
              <img src={image} alt="" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Section2;
