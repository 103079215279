import React, { useState, useEffect } from "react";
import Wrapper from "../../../components/UI/Wrapper";
import "./PrivacyPolicy.css";
import Loader from "../../../components/UI/Loader";
import Footer from "../../../../Common/components/UI/Footer";
import Navbar from "../../../components/UI/Navbar";
import axios from "../../../../Common/Api";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  // Store Api Data in useState Variable
  const [ApiData, setApiData] = useState([]);

  // Api Error Data useState Variable
  const [ApiError, setApiError] = useState("");

  // Loader useState Variable
  const [Loading, setLoading] = useState(false);

  // Privacy Get-Data Function
  const getApiData = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/get-privacy-policy");
      // console.log("🚀 res....", res)
      if (res.data.status == "success") {
        setApiData(res.data.data.Description);
        setLoading(false);
      }
    } catch (error) {
      setApiError(error);
      setLoading(false);
      // console.log("🚀 error....", error.message)
    }
  };

  useEffect(() => {
    // Privacy Policy Get-Data Function call
    getApiData();
  }, []);

  return (
    <>
      <Navbar />
      <div style={{ marginTop: "101px" }}></div>
      <Wrapper bgWhite={true}>
        <section className="white position-relative privacy-sec">
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="d-flex justify-content-center heading-policy">
              {/* <div className="p-4"></div> */}

              <span>Política de Privacidad</span>

              {/* <div className="p-4"></div> */}
            </div>
            <div className="border-bottom-divider-policy"></div>
          </div>

          <div className="p-3"></div>

          {!Loading && (
            <div className="d-flex justify-content-center">
              <div
                className="d-flex flex-column content-privacy-policy w-80"
                dangerouslySetInnerHTML={{ __html: ApiData }}></div>
              {/* Wasd */}
            </div>
          )}
        </section>
        {Loading && <Loader />}
        <div className="p-5"></div>
      </Wrapper>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
