import React, { useState } from "react";
import "./Drop_down.css";
import { Link, useNavigate } from "react-router-dom";
import Model_data from "../../components/Data/model_dropdown";
import Leaf_data from "../../components/Data/Leaf_data";
import img1 from "../../../Ariya/assets/images/Evolve+ e-4orce/Exterior/Black Diamond Pearl/1.png";
import img2 from "../../assets/images/SV Plus/Exterior/Super Black - Pearl White/1.png";

const Dropdownmodel = ({ steps, setsteps }) => {
  const navigate = useNavigate();
  // const [indexx, setindex] = useState(0);
  const handleclick = (index, modelType) => {
    localStorage.setItem("cardindex", index);
    steps != undefined && setsteps(index);
    // console.log({ index }, "fdfdf");
    // localStorage.setItem("data", "istrue");
    // console.log(index);
    // setindex(index);
    // setTimeout(() => {
    navigate(modelType == "ariya" ? "/Model" : "/Leaf/Model");
    // }, 500);
  };

  // const [isclick, setisclick] = useState(false);
  const [isclick1, setisclick1] = useState(true);
  console.log({ steps });
  return (
    <>
      <div className="main_dox">
        <div className="dropdown_main">
          <div className="dropdown_sub">
            <div className="Main_model">
              <Link
                className="link_box"
                onClick={() => {
                  // navigate("/wdw");
                  // setisclick1(false);
                }}
                to="/Inicio"
              >
                <img className="carimg_main" src={img1} alt="" />
                <h2 className="headingtext">ARIYA</h2>
              </Link>
              <Link
                className="link_box"
                onClick={() => {
                  // prompt("sd");
                  // setisclick1(true);
                }}
                to="/Leaf/Inicio"
              >
                <img className="carimg_main" src={img2} alt="" />
                <h2 className="headingtext">LEAF</h2>
              </Link>
            </div>

            {isclick1 ? (
              <div className="Sub_model">
                <div className="Sub_model_text">
                  <h1>LEAF</h1>
                </div>
                <div className="model_img_box">
                  {Leaf_data.map((leafmodel, index) => {
                    {
                      /* console.log(steps == index); */
                    }
                    return (
                      <span
                        className={
                          steps == index ? "actvelink" : "sub_model_box"
                        }
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleclick(index, "leaf")}
                      >
                        <img className="carimg" src={leafmodel.img} alt="" />
                        <p className="title_text">{leafmodel.title}</p>
                      </span>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="Sub_model">
                <div className="Sub_model_text">
                  <h1>ARIYA</h1>
                </div>
                <div className="model_img_box">
                  {Model_data.map((model, index) => {
                    return (
                      <span
                        className={
                          steps !== index ? "sub_model_box" : "actvelink"
                        }
                        style={{ cursor: "pointer" }}
                        key={index}
                        onClick={() => handleclick(index, "ariya")}
                      >
                        <img className="carimg" src={model.img} alt="" />
                        <p className="title_text">{model.title}</p>
                      </span>
                    );
                  })}
                </div>
              </div>
            )}

            {/* {isclick ? (

            ) : null} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropdownmodel;
