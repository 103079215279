import React from "react";
import "./Button.css";

const Button = ({ children, type, onClick, variant, disable }) => {
  return (
    <div className="mainbtn">
      <button
        disabled={disable}
        className={variant}
        type={type}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
