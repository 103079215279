import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "../../../../Ariya/components/UI/Card";
import { LoginSchema } from "../../../../Ariya/schemas";
import axios from "../../../../Common/Api";
import Button from "../../../../Common/components/UI/Button";
import { GlobalContext } from "../../../../GlobalContext";
import Loader from "../../../../Ariya/components/UI/Loader";

export const Canclemodal1 = () => {
  const {
    FirstModalShow,
    setFirstModalShow,
    setSecondModalShow,
    setFourModalShow,
  } = useContext(GlobalContext);

  const ChangeBtn = () => {
    setFirstModalShow(false);
    setSecondModalShow(true);
  };

  return (
    <Modal
      size="md"
      show={FirstModalShow}
      onHide={() => setFirstModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
      <Modal.Body className="white text-center pt-3">
        <h4 className="pb-3">¿Deseas continuar?</h4>
        <p className="Modal-text">
          Favor de confirmar si deseas continuar con el proceso o cancelarlo.
        </p>
        <p className="Modal-text">
          Si deseas cancelar en este momento se te hará la devolución completa
          del depósito dado ($500).
        </p>

        <div className="p-2"></div>
        <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
          <Button
            variant="black"
            onClick={() => {
              setFirstModalShow(false);
              setFourModalShow(true);
            }}>
            DESEO CANCELAR
            {/* No deseo cancelar */}
          </Button>
          {/* <Button variant="red" onClick={ChangeBtn}> */}
          <Button
            variant="red"
            onClick={ChangeBtn}
            // onClick={() => {

            //   OrderHistory.data.status == "Pending"
            //     ? CancleOrderFromCart()
            //     : CancleOrder();
            // }}
          >
            SÍ, DESEO CONTINUAR
          </Button>
        </div>
        <div className="p-3"></div>
      </Modal.Body>
    </Modal>
  );
};
export const Canclemodal2 = () => {
  const {
    SecondModalShow,
    setSecondModalShow,
    setFirstModalShow,
    setConformshow,
    // setThirdModalShow,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [OrderHistory, setOrderHistory] = useState();

  const trackid = localStorage.getItem("trackid");
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  // OrderHistory API
  const getOrderHistory = async () => {
    try {
      // setLoading(true);
      const res = await axios.get("get-history", {
        headers: {
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      setOrderHistory(res.data.data);
      // setLoading(true);
      if (res.data.status == "success") {
        // setLoading(false);
      } else {
        // setLoading(false);
      }

      console.log("History Data_________", res);

      if (res) {
        localStorage.setItem("trackid", res.data.data.track_id);
        if (res.data.message == "No Record") {
          if (OrderHistory.data.track_status == "Confirmation") {
            setFirstModalShow(true);
          }
          // setLoading(false);
        } else {
          setOrderHistory(res.data);
          // setLoading(false);
          // console.log("res.....", res);
        }
      }
    } catch (error) {
      // setLoading(false);
      console.log("Catched Error....", error);
    }
  };

  const Acepta = async () => {
    try {
      const response = await axios.post(
        "change-confirm-order-status",
        {
          TrackId: trackid,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData?.Token}`,
          },
        }
      );

      if (response.data.status == "success") {
        getOrderHistory();
        setConformshow(true);
      }
      console.log(response, "change-confirm-order-status");
    } catch (error) {}
  };

  const ChangeBtn = () => {
    Acepta();

    // getOrderHistory();
    setSecondModalShow(false);
    // setThirdModalShow(true);
  };

  return (
    <Modal
      size="md"
      show={SecondModalShow}
      onHide={() => setSecondModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
      <Modal.Body className="white text-center pt-3">
        <h4 className="pb-3">¡Gracias!</h4>
        <p className="Modal-text">
          Has confirmado que deseas continuar con el proceso.
        </p>

        <div className="p-2"></div>
        <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
          <Button
            variant="red"
            onClick={ChangeBtn}
            // onClick={() => {

            //   OrderHistory.data.status == "Pending"
            //     ? CancleOrderFromCart()
            //     : CancleOrder();
            // }}
          >
            Aceptar
          </Button>
        </div>
        <div className="p-3"></div>
      </Modal.Body>
    </Modal>
  );
};

export const Canclemodal3 = () => {
  const { ThirdModalShow, setThirdModalShow } = useContext(GlobalContext);
  //! cacncle api calls here
  const UserData = JSON.parse(localStorage.getItem("user-info"));
  const [Loading, setLoading] = useState(true);
  const [OrderHistory, setOrderHistory] = useState([]);
  const [disable, setdisable] = useState(false);
  const [Ordertrackk, setOrdertrack] = useState({});
  const navigate = useNavigate();
  // OrderHistory API
  const getOrderHistory = async () => {
    try {
      setLoading(true);
      const res = await axios.get("get-history", {
        headers: {
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      setOrderHistory(res.data.data);
      setLoading(true);
      if (res.data.status == "success") {
        setLoading(false);
      } else {
        setLoading(false);
      }

      // console.log("History Data_________", res);

      if (res) {
        localStorage.setItem("trackid", res.data.data.track_id);
        if (res.data.message == "No Record") {
          // if (res.data.data.confirm_status == "TRUE") {
          //   setThirdModalShow(true);
          // }
          setLoading(false);
        } else {
          setOrderHistory(res.data);
          setLoading(false);
          // console.log("res.....", res);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };
  useEffect(() => {
    getOrderHistory();
  }, []);

  const trackid = localStorage.getItem("trackid");

  const Ordertrack = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "get-order-status",
        {
          track_id: trackid,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData?.Token}`,
          },
        }
      );
      console.log(response, "order-status track");
      if (response) {
        setOrdertrack(response.data.data);
        // if (
        //   response.data.data.Step_3_Status == "Yes" &&
        //   OrderHistory.data.track_status == "Reservation"

        //   // response.data.data.Step_3_Status == "Yes" &&
        //   // OrderHistory.data.confirm_status != "TRUE" &&
        //   // checkstatus == true
        // ) {
        //   setFirstModalShow(true);
        //   setContent(false);
        // }

        setLoading(false);
      }
      // console.log("responsessss . . . . track", response);
    } catch (error) {
      // console.log(error, "errorrr");
    }
  };

  const CancleOrder = async () => {
    setThirdModalShow(false);
    setLoading(true);

    try {
      const res = await axios.post(
        "cancel-order",
        {
          TrackId: OrderHistory.data.track_id,
        },

        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        }
      );
      console.log("API Response:", res);
      if (res) {
        console.log("...............", res);
        if (res.data.status == "success") {
          navigate("/Perfil/CanceledOrders");
          toast.success(`${res.data.message}`);
          Ordertrack();
          setLoading(false);
          // setContent(true);
          setdisable(true);
          getOrderHistory();
        } else {
          setLoading(false);
          console.log("API Error Data:", res.data); // Log the error data
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("Catched Error....++", error);
    }
  };
  const [refreshed, setRefreshed] = useState(false);
  const noclicks = () => {
    setThirdModalShow(false);
    // if (!refreshed) {
    //   window.location.reload();
    //   setRefreshed(true);
    // }
  };

  const ChangeBtn = () => {
    CancleOrder();
    console.log("check btn work__________________");
    setThirdModalShow(false);
  };

  return (
    <Modal
      size="md"
      show={ThirdModalShow}
      onHide={() => setThirdModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
      <Modal.Body className="white text-center pt-3">
        <h4 className="pb-3">Cancelar pedido</h4>
        <p className="Modal-text">
          ¿Estás seguro que desea cancelar? Al cancelar en este momento en donde
          ya habías confirmado seguir con el proceso, conlleva una penalidad del
          50% ($250) del depósito dado.
        </p>

        <div className="p-2"></div>
        <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
          <Button variant="black" onClick={noclicks}>
            No deseo cancelar
          </Button>
          {/* <Button variant="red" onClick={ChangeBtn}> */}
          <Button variant="red" disable={disable} onClick={ChangeBtn}>
            SÍ, deseo Cancelar
          </Button>
        </div>
        <div className="p-3"></div>
      </Modal.Body>
    </Modal>
  );
};

export const Canclemodal4 = () => {
  const { FourModalShow, setFourModalShow, setFiveModalShow } =
    useContext(GlobalContext);
  const ChangeBtn = () => {
    setFourModalShow(false);
    setFiveModalShow(true);
  };
  return (
    <Modal
      size="md"
      show={FourModalShow}
      onHide={() => setFourModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
      <Modal.Body className="white text-center pt-3">
        <h4 className="pb-3">Cancelar pedido</h4>
        <p className="Modal-text">
          ¿Estás seguro que deseas cancelar? Se te hará la devolución del
          depósito dado ($500).
        </p>

        <div className="p-2"></div>
        <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
          <Button variant="black" onClick={() => setFourModalShow(false)}>
            No deseo cancelar
          </Button>
          <Button variant="red" onClick={ChangeBtn}>
            SÍ, deseo Cancelar
          </Button>
        </div>
        <div className="p-3"></div>
      </Modal.Body>
    </Modal>
  );
};

export const Canclemodal5 = () => {
  const { FiveModalShow, setFiveModalShow } = useContext(GlobalContext);
  //! cacncle api calls here
  const UserData = JSON.parse(localStorage.getItem("user-info"));
  const [Loading, setLoading] = useState(true);
  const [OrderHistory, setOrderHistory] = useState([]);
  const [disable, setdisable] = useState(false);
  const [Ordertrackk, setOrdertrack] = useState({});
  const navigate = useNavigate();
  // OrderHistory API
  const getOrderHistory = async () => {
    try {
      setLoading(true);
      const res = await axios.get("get-history", {
        headers: {
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      setOrderHistory(res.data.data);
      setLoading(true);
      if (res.data.status == "success") {
        setLoading(false);
      } else {
        setLoading(false);
      }

      // console.log("History Data_________", res);

      if (res) {
        localStorage.setItem("trackid", res.data.data.track_id);
        if (res.data.message == "No Record") {
          // if (res.data.data.confirm_status == "TRUE") {
          //   setThirdModalShow(true);
          // }
          setLoading(false);
        } else {
          setOrderHistory(res.data);
          setLoading(false);
          // console.log("res.....", res);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };
  useEffect(() => {
    getOrderHistory();
  }, []);

  const CancleOrder = async () => {
    setFiveModalShow(false);
    setLoading(true);

    const trackid = localStorage.getItem("trackid");

    const Ordertrack = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          "get-order-status",
          {
            track_id: trackid,
          },
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${UserData?.Token}`,
            },
          }
        );
        console.log(response, "order-status track");
        if (response) {
          setOrdertrack(response.data.data);
          // if (
          //   response.data.data.Step_3_Status == "Yes" &&
          //   OrderHistory.data.track_status == "Reservation"

          //   // response.data.data.Step_3_Status == "Yes" &&
          //   // OrderHistory.data.confirm_status != "TRUE" &&
          //   // checkstatus == true
          // ) {
          //   setFirstModalShow(true);
          //   setContent(false);
          // }

          setLoading(false);
        }
        // console.log("responsessss . . . . track", response);
      } catch (error) {
        // console.log(error, "errorrr");
      }
    };

    try {
      const res = await axios.post(
        "cancel-order",
        {
          TrackId: OrderHistory.data.track_id,
        },

        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        }
      );
      console.log("API Response:", res);
      if (res) {
        console.log("...............", res);
        if (res.data.status == "success") {
          navigate("/Perfil/CanceledOrders");
          toast.success(`${res.data.message}`);
          Ordertrack();
          setLoading(false);
          // setContent(true);
          setdisable(true);
          // getOrderHistory();
        } else {
          setLoading(false);
          console.log("API Error Data:", res.data); // Log the error data
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("Catched Error....++", error);
    }
  };

  const ChangeBtn = () => {
    CancleOrder();
    setFiveModalShow(false);
  };

  return (
    <Modal
      size="md"
      show={FiveModalShow}
      onHide={() => setFiveModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
      <Modal.Body className="white text-center pt-3">
        <h4 className="pb-3">¡Lo lamentamos!</h4>
        <p className="Modal-text">
          Lamentamos que no desearas continuar con el proceso. En los próximos
          días verás reflejado en tu cuenta la devolución del depósito dado
          ($500).
        </p>

        <div className="p-2"></div>
        <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
          <Button variant="red" onClick={ChangeBtn}>
            Aceptar
          </Button>
        </div>
        <div className="p-3"></div>
      </Modal.Body>
    </Modal>
  );
};

export const Canclemodelinterest = () => {
  const {
    Canclemodelinterestshow,
    setCanclemodelinterestlshow,
    setThankYouConfirmationshow,
    setSorrymodalshow,
  } = useContext(GlobalContext);

  const UserData = JSON.parse(localStorage.getItem("user-info"));
  const [Loading, setLoading] = useState(true);
  const [OrderHistory, setDataHistory] = useState();

  // const [DataHistory, setDataHistory] = useState();

  const [disable, setdisable] = useState(false);
  const [Ordertrackk, setOrdertrack] = useState({});
  const navigate = useNavigate();
  // OrderHistory API
  const getOrderHistory = async () => {
    try {
      setLoading(true);
      const res = await axios.get("get-history", {
        headers: {
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      setLoading(true);
      // console.log("check res", res.data.data);
      if (res.data.status == "success") {
        setDataHistory(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }

      // console.log("History Data_________====>", res.data.data);
      if (res) {
        localStorage.setItem("trackid", res.data.data.track_id);
        if (res.data.message == "No Record") {
          // if (res.data.data.confirm_status == "TRUE") {
          //   setThirdModalShow(true);
          // }
          setLoading(false);
        } else {
          setDataHistory(res.data);
          setLoading(false);
          // console.log("res.....", res);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };

  useEffect(() => {
    getOrderHistory();
  }, [Canclemodelinterestshow]);

  // console.log(DataHistory);

  const CancleOrderFromCart = async () => {
    setCanclemodelinterestlshow(false);
    setLoading(true);
    try {
      const res = await axios.post(
        "/cancel-temp-order",
        {
          OrderId: OrderHistory.data.order_id,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        }
      );
      console.log("cancel_temp_order Response....", res);
      setLoading(false);

      if (res.data.status == "success") {
        toast.success(`${res.data.message}`);
        navigate("/Perfil/CanceledOrders");
      }
    } catch (error) {
      console.log(error, "catched Error.....");
      setLoading(false);
    }
  };

  const confirmnodisponibleorderstatus = async (orderStaus) => {
    setLoading(true);
    console.log(" check status====", orderStaus);
    try {
      const res = await axios.post(
        "/confirm-no-disponible-order-status",
        { TrackId: OrderHistory.data.track_id, OrderStatus: orderStaus },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        }
      );
      setLoading(false);
      getOrderHistory();
      console.log("order ka status kya mila ", res);
    } catch (error) {
      console.log("error kya hai  ", error);
    }
  };

  const ChangeBtn = () => {
    // CancleOrderFromCart();
    setSorrymodalshow(true);
    setCanclemodelinterestlshow(false);
  };
  return (
    <Modal
      size="md"
      show={Canclemodelinterestshow}
      onHide={() => setCanclemodelinterestlshow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
      <Modal.Body className="white text-center pt-3">
        <h4 className="pb-3">¿Sigues interesado?</h4>
        <p className="Modal-text">
          De no desear continuar con el proceso puede hacerlo sin ninguna
          penalidad.
        </p>

        <div className="p-2"></div>
        <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
          {(OrderHistory?.data?.status == "No_Disponible" &&
            OrderHistory?.data?.track_status == "Confirm") ||
          OrderHistory?.data?.status == "In_Inventory" ? (
            <Button
              variant="black"
              onClick={() => {
                setThankYouConfirmationshow(true);
                setCanclemodelinterestlshow(false);
              }}>
              SÍ, Tengo interés
            </Button>
          ) : (
            <Button
              variant="black"
              onClick={
                OrderHistory?.data?.status == "No_Disponible"
                  ? () => {
                      confirmnodisponibleorderstatus("No");
                      setSorrymodalshow(true);
                      setCanclemodelinterestlshow(false);
                    }
                  : () => {
                      setCanclemodelinterestlshow(false);
                    }
              }>
              No, ya no TEngo interés
            </Button>
          )}
          {(OrderHistory?.data?.status == "No_Disponible" &&
            OrderHistory?.data?.track_status == "Confirm") ||
          OrderHistory?.data?.status == "In_Inventory" ? (
            <Button
              variant="red"
              onClick={() => {
                setSorrymodalshow(true);
                setCanclemodelinterestlshow(false);
              }}>
              No, ya no TEngo interés
            </Button>
          ) : (
            <Button
              variant="red"
              onClick={
                OrderHistory?.data?.status == "No_Disponible"
                  ? () => {
                      confirmnodisponibleorderstatus("Yes");
                      setThankYouConfirmationshow(true);

                      setCanclemodelinterestlshow(false);
                    }
                  : () => {
                      CancleOrderFromCart();
                    }
              }>
              SÍ, Tengo interés
            </Button>
          )}
        </div>
        <div className="p-3"></div>
      </Modal.Body>
    </Modal>
  );
};

export const Loginmodel = () => {
  const { LoginModalshow, setLoginModalshow, setForLogin } =
    useContext(GlobalContext);
  const location = useLocation();
  const Navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [OrderHistory, setOrderHistory] = useState([]);

  const ChangeBtn = () => {
    setLoginModalshow(false);
  };
  const UserData = JSON.parse(localStorage.getItem("user-info"));
  const getOrderHistory = async () => {
    try {
      setLoading(true);
      const res = await axios.get("get-history", {
        headers: {
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      setOrderHistory(res.data.data);
      setLoading(true);
      if (res.data.status == "success") {
        setLoading(false);
      } else {
        setLoading(false);
      }

      // console.log("History Data_________", res);

      if (res) {
        localStorage.setItem("trackid", res.data.data.track_id);
        if (res.data.message == "No Record") {
          // if (res.data.data.confirm_status == "TRUE") {
          //   setThirdModalShow(true);
          // }
          setLoading(false);
        } else {
          setOrderHistory(res.data);
          setLoading(false);
          // console.log("res.....", res);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };
  useEffect(() => {
    getOrderHistory();
  }, []);

  const initialValues = {
    Email: "",
    Password: "",
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "/login",
        {
          Email: values.Email,
          Password: values.Password,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      // setIsError(response.data.message);
      if (response.data.status == "success") {
        setLoading(false);
        setLoginModalshow(false);
        setForLogin(true);
        let result = response.data.data;
        localStorage.setItem("user-info", JSON.stringify(result));
        toast.success(`${response.data.message}`);
        if (OrderHistory.data.status == "Próximo_embarque") {
          Navigate("/Perfil/History");
          setLoading(true);
          getOrderHistory();
        } else {
          Navigate("/Deposit");
          setLoading(true);
        }
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
      setLoading(false);
    }
  };
  const userData = localStorage.getItem("user-info");

  return (
    <Modal
      size="md"
      show={LoginModalshow}
      onHide={() => setLoginModalshow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
      <Modal.Body
        style={{ background: "#000 !important" }}
        className="black text-center pt-3 login_model">
        <Card head={"Iniciar sesión"}>
          <div className="p-2"></div>
          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            onSubmit={handleSubmit}>
            {(props) => (
              <Form className="d-flex flex-column gap-3">
                <div className="d-flex flex-column w-100">
                  <div className="input--" style={{ width: "400px" }}>
                    <span>Correo electrónico</span>
                    <Field
                      type="email"
                      name="Email"
                      placeholder="Ingresa tu correo electrónico"
                      className="input-field"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.Email}
                    />
                    <div className="p-1"></div>
                  </div>
                  {props.errors.Email && props.touched.Email ? (
                    <p className="form-error"> {props.errors.Email} </p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="d-flex flex-column">
                  <div className="input--">
                    <span>Contraseña</span>
                    <Field
                      type="password"
                      name="Password"
                      placeholder={"Ingresa tu contraseña"}
                      className="input-field"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.Password}
                    />
                    <div className="p-1"></div>
                  </div>
                  {props.errors.Password && props.touched.Password ? (
                    <p className="form-error"> {props.errors.Password} </p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="d-flex justify-content-center pt-3 pb-3">
                  <Button
                    variant={"red"}
                    type="submit"
                    onClick={() => ChangeBtn()}>
                    {"Iniciar sesión"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export const Thankyouconfirmationmodal = () => {
  const { ThankYouConfirmationshow, setThankYouConfirmationshow } =
    useContext(GlobalContext);
  const ChangeBtn = () => {
    setThankYouConfirmationshow(false);
  };
  return (
    <Modal
      size="md"
      show={ThankYouConfirmationshow}
      onHide={() => setThankYouConfirmationshow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
      <Modal.Body className="white text-center pt-3">
        <h4 className="pb-3">¡Gracias!</h4>
        <p className="Modal-text">
          Gracias por confirmar tu interés en explorar otras alternativas. Nos
          comunicaremos contigo en breve.
        </p>

        <div className="p-2"></div>
        <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
          <Button variant="red" onClick={() => ChangeBtn()}>
            Aceptar
          </Button>
        </div>
        <div className="p-3"></div>
      </Modal.Body>
    </Modal>
  );
};

export const Sorrymodal = () => {
  const { Sorrymodalshow, setSorrymodalshow } = useContext(GlobalContext);
  const UserData = JSON.parse(localStorage.getItem("user-info"));
  const [Loading, setLoading] = useState(true);
  const [OrderHistory, setDataHistory] = useState();
  const navigate = useNavigate();
  const [Ordertrackk, setOrdertrack] = useState({});
  const [disable, setdisable] = useState(false);

  const getOrderHistory = async () => {
    try {
      setLoading(true);
      const res = await axios.get("get-history", {
        headers: {
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      setLoading(true);
      // console.log("check res", res.data.data);
      if (res.data.status == "success") {
        setDataHistory(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }

      // console.log("History Data_________====>", res.data.data);
      if (res) {
        localStorage.setItem("trackid", res.data.data.track_id);
        if (res.data.message == "No Record") {
          // if (res.data.data.confirm_status == "TRUE") {
          //   setThirdModalShow(true);
          // }
          setLoading(false);
        } else {
          setDataHistory(res.data);
          setLoading(false);
          // console.log("res.....", res);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };

  useEffect(() => {
    getOrderHistory();
  }, [setSorrymodalshow]);

  const CancleOrderFromCart = async () => {
    setSorrymodalshow(false);
    setLoading(true);
    try {
      const res = await axios.post(
        "/cancel-temp-order",
        {
          OrderId: OrderHistory.data.order_id,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        }
      );
      console.log("cancel_temp_order Response....", res);
      setLoading(false);

      if (res.data.status == "success") {
        toast.success(`${res.data.message}`);
        navigate("/Perfil/CanceledOrders");
      }
    } catch (error) {
      // console.log(error, "catched Error.....");
      setLoading(false);
    }
  };
  const CancleOrder = async () => {
    setSorrymodalshow(false);
    setLoading(true);

    const trackid = localStorage.getItem("trackid");

    const Ordertrack = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          "get-order-status",
          {
            track_id: trackid,
          },
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${UserData?.Token}`,
            },
          }
        );
        console.log(response, "order-status track");
        if (response) {
          setOrdertrack(response.data.data);
          // if (
          //   response.data.data.Step_3_Status == "Yes" &&
          //   OrderHistory.data.track_status == "Reservation"

          //   // response.data.data.Step_3_Status == "Yes" &&
          //   // OrderHistory.data.confirm_status != "TRUE" &&
          //   // checkstatus == true
          // ) {
          //   setFirstModalShow(true);
          //   setContent(false);
          // }

          setLoading(false);
        }
        // console.log("responsessss . . . . track", response);
      } catch (error) {
        // console.log(error, "errorrr");
      }
    };

    try {
      const res = await axios.post(
        "cancel-order",
        {
          TrackId: OrderHistory.data.track_id,
        },

        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        }
      );
      console.log("API Response:", res);
      if (res) {
        console.log("...............", res);
        if (res.data.status == "success") {
          navigate("/Perfil/CanceledOrders");
          toast.success(`${res.data.message}`);
          Ordertrack();
          setLoading(false);
          // setContent(true);
          setdisable(true);
          // getOrderHistory();
        } else {
          setLoading(false);
          console.log("API Error Data:", res.data); // Log the error data
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("Catched Error....++", error);
    }
  };
  return (
    <Modal
      size="md"
      show={Sorrymodalshow}
      onHide={() => setSorrymodalshow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
      <Modal.Body className="white text-center pt-3">
        <h4 className="pb-3">Lamentamos que no desees continuar</h4>
        <p className="Modal-text">
          Te exhortamos a que visites frecuentemente nuestra página para conocer
          de otros modelos.
        </p>

        <div className="p-2"></div>
        <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
          {OrderHistory?.data?.status == "No_Disponible" &&
          OrderHistory?.data?.track_status == "Confirm" ? (
            <Button
              variant="red"
              onClick={() => {
                CancleOrder();
                setSorrymodalshow(false);
              }}>
              Aceptar
            </Button>
          ) : (
            <Button
              variant="red"
              onClick={() => {
                setSorrymodalshow(false);
              }}>
              Aceptar
            </Button>
          )}
        </div>
        <div className="p-3"></div>
      </Modal.Body>
    </Modal>
  );
};

export const CardIssue = () => {
  const { Cardissue, setCardissue } = useContext(GlobalContext);
  return (
    <Modal
      size="md"
      show={Cardissue}
      onHide={() => setCardissue(false)}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
      <Modal.Body className="white text-center pt-3">
        <h4 className="pb-3">Lamentamos que no desees continuar</h4>
        <p className="Modal-text">
          Se produjo un error con tu método de pago. Favor de actualizarlo y
          vuelve a intentarlo para poder completar el proceso de reserva.
        </p>

        <div className="p-2"></div>
        <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
          <Button
            variant="red"
            onClick={() => {
              setCardissue(false);
            }}>
            ok
          </Button>
        </div>
        <div className="p-3"></div>
      </Modal.Body>
    </Modal>
  );
};

export const Trems = () => {
  const { Trems, setTrems } = useContext(GlobalContext);

  const [ApiData, setApiData] = useState([]);
  const [ApiError, setApiError] = useState("");
  const [Loading, setLoading] = useState(false);

  // Purchase Policy Get-Data Function
  const getApiData = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/get-terms-condition");
      console.log("🚀 res....", res);
      if (res.data.status == "success") {
        setApiData(res.data.data.Description);
        setLoading(false);
      }
    } catch (error) {
      setApiError(error);
      setLoading(false);
      console.log("🚀 error....", error.message);
    }
  };

  useEffect(() => {
    // Privacy Policy Get-Data Function call
    getApiData();
  }, []);
  return (
    <Modal
      size="xl"
      show={Trems}
      onHide={() => setTrems(false)}
      style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4 className="pb-3 pt-3 Tittle_text">TÉRMINOS Y CONDICIONES</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="white pt-3"
        style={{
          height: "80vh",
          overflowY: "scroll",
          scrollBehavior: "smooth",
        }}>
        {!Loading && (
          <div className="d-flex justify-content-center">
            <div
              className="d-flex flex-column content-privacy-policy w-90"
              dangerouslySetInnerHTML={{ __html: ApiData }}></div>
          </div>
        )}

        {Loading && <Loader />}
      </Modal.Body>
    </Modal>
  );
};
export const Policy = () => {
  const { Policy, setPolicy } = useContext(GlobalContext);

  const [ApiData, setApiData] = useState([]);
  const [ApiError, setApiError] = useState("");
  const [Loading, setLoading] = useState(false);

  // Privacy Get-Data Function
  const getApiData = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/get-privacy-policy");
      // console.log("🚀 res....", res)
      if (res.data.status == "success") {
        setApiData(res.data.data.Description);
        setLoading(false);
      }
    } catch (error) {
      setApiError(error);
      setLoading(false);
      // console.log("🚀 error....", error.message)
    }
  };

  useEffect(() => {
    // Privacy Policy Get-Data Function call
    getApiData();
  }, []);

  return (
    <Modal
      size="xl"
      show={Policy}
      onHide={() => setPolicy(false)}
      style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4 className="pb-3  pt-3 Tittle_text">POLÍTICA DE PRIVACIDAD</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="white "
        style={{
          height: "80vh",
          overflowY: "scroll",
          scrollBehavior: "smooth",
        }}>
        {!Loading && (
          <div className="d-flex justify-content-center">
            <div
              className="d-flex flex-column content-privacy-policy w-90"
              dangerouslySetInnerHTML={{ __html: ApiData }}></div>
          </div>
        )}
        {Loading && <Loader />}
      </Modal.Body>
    </Modal>
  );
};
