import React, { useState, useEffect, useContext } from "react";
import "./OrderHistory.css";
import Button from "../../../../Common/components/UI/Button";
import trakertick from "../../../assets/images/trackertick.png";
import trakerPROG from "../../../assets/images/trackprog.png";
import { motion } from "framer-motion";
import { AllImages } from "../../../constants/images";
import { Modal } from "react-bootstrap";
import axios from "../../../../Common/Api";
import Loader from "../../../components/UI/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Ariya_EngageFWD,
  Ariya_Engage_e_4orce,
  Ariya_Engage__e_4orce,
  VENTURE,
  EVOLVE,
  evolvepe4orce,
} from "../../../constants/Data";
import { LEAFS, LeafSVPlus } from "../../../../Leaf/constants/Data";
import { toast } from "react-hot-toast";

import { GlobalContext } from "../../../../GlobalContext";
import ReactGA from "react-ga4";
import { event } from "react-ga";

const OrderHistory = () => {
  const location = useLocation();
  const [OrderHistory, setOrderHistory] = useState();
  console.log("🚀 ~ OrderHistory ~ OrderHistory:", OrderHistory?.data);
  const {
    setFirstModalShow,
    setSecondModalShow,
    conformshow,
    setThirdModalShow,
    setFourModalShow,
    setCanclemodelinterestlshow,
    setLoginModalshow,
    ForLogin,
  } = useContext(GlobalContext);

  const Navigate = useNavigate();

  const [Content, setContent] = useState(true);
  //new code =============
  const [alertModalShow, setalertModalShow] = useState(false);

  const Has_confirmado = localStorage.getItem("Has-confirmado");

  useEffect(() => {
    if (Has_confirmado) {
      setTimeout(() => {
        setalertModalShow(true);
        localStorage.removeItem("Has-confirmado");
      }, 500);
    }
  }, []);
  const checkstatus = localStorage.getItem("Confirmstatus");
  function AlertModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ zIndex: "10000" }}>
        <Modal.Header
        // closeButton
        ></Modal.Header>
        <Modal.Body className="white text-center">
          <div className="Dropdown-Car-info-body-Head">
            {/* <i> */}
            ¡Gracias!
            {/* </i> */}
          </div>
          <p className="Modal-text d-flex flex-column gap-4">
            <div>Has confirmado que deseas continuar con el proceso. </div>
            <Button
              variant="red"
              onClick={() => {
                localStorage.removeItem("Has-confirmado");
                setalertModalShow(false);
                setContent(false);
              }}>
              OK
            </Button>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  function CancleOrderModal(props) {
    function ChangeBtn() {
      setdisable(true);
      setModalShow(false);
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
        <Modal.Body className="white text-center pt-3">
          <h4 className="pb-3">Cancelar pedido</h4>
          <p className="Modal-text">
            Si deseas cancelar en este momento, puedes hacerlo sin ninguna
            penalidad.
            {/* Si deseas cancelar en este momento se te hará la devolución completa
            del depósito dado ($500). */}
            {/* El Usuario entiende y acepta que la Reservación podrá ser cancelada
            por Nissan por cualquier razón. Usuario acepta que, de darse una
            Cancelación, el único remedio contractual o extracontractual al que
            tendrá derecho será la restitución del Monto pagado. */}
          </p>
          <div className="p-2"></div>
          <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
            <Button variant="black" onClick={props.onHide}>
              No deseo cancelar
            </Button>
            {/* <Button variant="red" onClick={ChangeBtn}> */}
            <Button
              variant="red"
              onClick={() => {
                OrderHistory.data.status == "Pending"
                  ? CancleOrderFromCart()
                  : CancleOrder();
              }}>
              SÍ, deseo Cancelar
            </Button>
          </div>
          <div className="p-3"></div>
        </Modal.Body>
      </Modal>
    );
  }

  const cancelOrderCancelPending = () => {
    const eventData = {
      event: "cancelar_pedido",
      user_id: UserData?.id ? UserData?.id : " ",
      modelo: OrderHistory?.data?.model,
      price: OrderHistory?.data?.Price,
      color: OrderHistory?.data?.Ext_Color_Name,
      action: "CANCELAR PEDIDO",
    };
    ReactGA.event({
      event: eventData.event,
      action: eventData.action,
      event_params: {
        modelo: eventData.modelo,
        user_id: eventData.user_id,
        option: eventData.opcon,
        color: eventData.color,
        Price: eventData.price,
      },
    });
    window.dataLayer.push({
      event: eventData.event,
      user_id: eventData.user_id,
      modelo: eventData.modelo,
      price: eventData.price,
      color: eventData.color,
      action: eventData.action,
    });
  };
  const CancelIn_Inventory = () => {
    const eventData = {
      event: "cancelar_pedido",
      user_id: UserData?.id ? UserData?.id : " ",
      modelo: OrderHistory?.data?.model,
      price: OrderHistory?.data?.Price,
      color: OrderHistory?.data?.Ext_Color_Name,
      action: "CANCELAR PEDIDO",
    };
    ReactGA.event({
      event: eventData.event,
      action: eventData.action,
      event_params: {
        modelo: eventData.modelo,
        user_id: eventData.user_id,
        option: eventData.opcon,
        color: eventData.color,
        Price: eventData.price,
      },
    });
    window.dataLayer.push({
      event: eventData.event,
      user_id: eventData.user_id,
      modelo: eventData.modelo,
      price: eventData.price,
      color: eventData.color,
      action: eventData.action,
    });
  };
  const CancelNot_In_Inventory = () => {
    const eventData = {
      event: "cancelar_pedido",
      user_id: UserData?.id ? UserData?.id : " ",
      modelo: OrderHistory?.data?.model,
      price: OrderHistory?.data?.Price,
      color: OrderHistory?.data?.Ext_Color_Name,
      action: "CANCELAR PEDIDO",
    };
    ReactGA.event({
      event: eventData.event,
      action: eventData.action,
      event_params: {
        modelo: eventData.modelo,
        user_id: eventData.user_id,
        option: eventData.opcon,
        color: eventData.color,
        Price: eventData.price,
      },
    });
    window.dataLayer.push({
      event: eventData.event,
      user_id: eventData.user_id,
      modelo: eventData.modelo,
      price: eventData.price,
      color: eventData.color,
      action: eventData.action,
    });
  };
  const CancelNo_Disponible = () => {
    const eventData = {
      event: "cancelar_pedido",
      user_id: UserData?.id ? UserData?.id : " ",
      modelo: OrderHistory?.data?.model,
      price: OrderHistory?.data?.Price,
      color: OrderHistory?.data?.Ext_Color_Name,
      action: "CANCELAR PEDIDO",
    };
    ReactGA.event({
      event: eventData.event,
      action: eventData.action,
      event_params: {
        modelo: eventData.modelo,
        user_id: eventData.user_id,
        option: eventData.opcon,
        color: eventData.color,
        Price: eventData.price,
      },
    });
    window.dataLayer.push({
      event: eventData.event,
      user_id: eventData.user_id,
      modelo: eventData.modelo,
      price: eventData.price,
      color: eventData.color,
      action: eventData.action,
    });
  };
  const CancelPuesto_a_fábrica = () => {
    const eventData = {
      event: "cancelar_pedido",
      user_id: UserData?.id ? UserData?.id : " ",
      modelo: OrderHistory?.data?.model,
      price: OrderHistory?.data?.Price,
      color: OrderHistory?.data?.Ext_Color_Name,
      action: "CANCELAR PEDIDO",
    };
    ReactGA.event({
      event: eventData.event,
      action: eventData.action,
      event_params: {
        modelo: eventData.modelo,
        user_id: eventData.user_id,
        option: eventData.opcon,
        color: eventData.color,
        Price: eventData.price,
      },
    });
    window.dataLayer.push({
      event: eventData.event,
      user_id: eventData.user_id,
      modelo: eventData.modelo,
      price: eventData.price,
      color: eventData.color,
      action: eventData.action,
    });
  };

  const UserData = JSON.parse(localStorage.getItem("user-info"));
  const [disable, setdisable] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const [Loading, setLoading] = useState(true);

  const [OrderHistoryshow, setOrderHistoryShow] = useState();

  const [Ordertrackk, setOrdertrack] = useState({});

  // OrderHistory API
  const getOrderHistory = async () => {
    try {
      setLoading(true);
      const res = await axios.get("get-history", {
        headers: {
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      setOrderHistory(res.data.data);
      setOrderHistoryShow(res.data);

      setLoading(true);
      if (res.data.status == "success") {
        // console.log("check history", res.data);
        if (res.data.data.status == "In_Inventory") {
          CancelIn_Inventory();
          setOrderHistory(res.data);
        } else if (
          res.data.data.track_status == "Confirmation" &&
          res.data.data.confirm_status == "TRUE"
        ) {
          setOrderHistory(res.data);
          setFirstModalShow(false);
        } else if (res.data.data.track_status == "Confirmation") {
          setFirstModalShow(true);
          setOrderHistory(res.data);
          setContent(false);
        } else if (
          (res.data.data.status == "No_Disponible" &&
            res.data.data.track_status == "Cancel") ||
          res.data.data.track_status == "Confirm"
        ) {
          setCanclemodelinterestlshow(false);
          setContent(false);
          setOrderHistory(res.data);
        } else if (res.data.data.status == "No_Disponible") {
          CancelNo_Disponible();
          setCanclemodelinterestlshow(true);
          setContent(false);
          setOrderHistory(res.data);
        } else {
          setOrderHistory(res.data);
          setLoading(false);
        }
        setLoading(false);
      } else if (res.data.message === "No Record") {
        console.log("check error");
        setLoading(false);
      }
      console.log("History Data_________", res);

      if (res) {
        localStorage.setItem("trackid", res.data.data.track_id);

        // if (res.data.message == "No Record") {
        //   setLoading(false);
        // } else {
        //   setLoading(false);
        //   // console.log("res.....", res);
        // }
      }
    } catch (error) {
      setLoading(false);
      console.log("Catched Error....", error);
    }
  };
  // console.log("chck history ka data===>", OrderHistoryshow);
  // console.log("chck history ka data", OrderHistory);

  // Order track API
  const trackid = localStorage.getItem("trackid");
  const Ordertrack = async () => {
    try {
      // setLoading(true);
      const response = await axios.post(
        "get-order-status",
        {
          track_id: trackid,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData?.Token}`,
          },
        }
      );
      // console.log("order-status track", response);
      if (response) {
        setOrdertrack(response.data.data);
        // if (
        //   response.data.data.Step_3_Status == "Yes" &&
        //   OrderHistory.data.track_status == "Reservation"

        //   // response.data.data.Step_3_Status == "Yes" &&
        //   // OrderHistory.data.confirm_status != "TRUE" &&
        //   // checkstatus == true
        // ) {
        //   setFirstModalShow(true);
        //   setContent(false);
        // }

        // setLoading(false);
      }
      // console.log("responsessss . . . . track", response);
    } catch (error) {
      // console.log(error, "errorrr");
    }
  };

  useEffect(() => {
    getOrderHistory();
  }, []);
  useEffect(() => {
    getOrderHistory();
  }, [ForLogin]);

  useEffect(() => {
    getOrderHistory();
  }, [conformshow]);

  const userData = localStorage.getItem("user-info");
  useEffect(() => {
    if (!userData) {
      setLoginModalshow(true);
    }
  }, [OrderHistory]);
  useEffect(() => {
    Ordertrack();
  }, [OrderHistory]);

  // useEffect(() => {}, []);
  // console.log(OrderHistory);
  const navigate = useNavigate();

  const CancleOrder = async () => {
    setModalShow(false);
    setLoading(true);
    try {
      const res = await axios.post(
        "cancel-order",
        {
          TrackId: OrderHistory.data.track_id,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        }
      );
      if (res) {
        // console.log("...............", res);
        if (res.data.status == "success") {
          // navigate("/Modelos");
          toast.success(`${res.data.message}`);
          Navigate("/Perfil/CanceledOrders");
          Ordertrack();
          setLoading(false);
          // setContent(true);
          setdisable(true);
          getOrderHistory();
        } else {
          setLoading(false);
          // console.log("res Error....", res);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };

  const CancleOrderFromCart = async () => {
    setModalShow(false);
    setLoading(true);
    try {
      const res = await axios.post(
        "/cancel-temp-order",
        {
          OrderId: OrderHistory.data.order_id,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        }
      );
      console.log("cancel_temp_order Response....", res);
      setLoading(false);
      Navigate("/Perfil/CanceledOrders");
    } catch (error) {
      // console.log(error, "catched Error.....");
      setLoading(false);
    }
  };

  var nf = new Intl.NumberFormat();

  let Price = 64163;

  let Pathnamee = useLocation();
  const [refreshed, setRefreshed] = useState(false);
  const changescreen = () => {
    {
      setContent(false);
    }
  };

  return (
    <section className="white pt-4">
      <AlertModal
        show={alertModalShow}
        onHide={() => setalertModalShow(false)}
      />
      {Loading ? (
        <div className="loder_div" style={{ height: "70vh" }}>
          <Loader />
        </div>
      ) : (
        <>
          {Loading == false &&
          OrderHistoryshow?.status == "success" &&
          OrderHistoryshow.message == "No Record" ? (
            <>
              <div className="norecod_text">
                <h4>Aún no has realizado una orden.</h4>
              </div>
            </>
          ) : (
            OrderHistory?.status == "success" &&
            OrderHistory?.message == "Record" && (
              <>
                {Content ? (
                  <div
                    className="OrderHistoryDiv d-flex gap-4 align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={changescreen}>
                    <div>
                      {/* <img src={AllImages.engageAWDRed1} width="150px" /> */}
                      <img
                        src={
                          OrderHistory?.data?.model == "ENGAGE FWD"
                            ? Ariya_EngageFWD?.exterior[
                                OrderHistory?.data.Ext_Color_Code
                              ].images[0]
                            : OrderHistory?.data?.model == "ENGAGE e-4ORCE"
                            ? Ariya_Engage_e_4orce.exterior[
                                OrderHistory.data.Ext_Color_Code
                              ].images[0]
                            : OrderHistory?.data?.model == "ENGAGE+ e-4ORCE"
                            ? Ariya_Engage__e_4orce.exterior[
                                OrderHistory.data.Ext_Color_Code
                              ].images[0]
                            : OrderHistory?.data?.model == "VENTURE+"
                            ? VENTURE.exterior[OrderHistory.data.Ext_Color_Code]
                                .images[0]
                            : OrderHistory?.data?.model == "EVOLVE+ FWD"
                            ? EVOLVE.exterior[OrderHistory.data.Ext_Color_Code]
                                .images[0]
                            : OrderHistory?.data?.model == "EVOLVE+ e-4ORCE"
                            ? evolvepe4orce.exterior[
                                OrderHistory.data.Ext_Color_Code
                              ].images[0]
                            : OrderHistory.data.model == "LEAF S"
                            ? LEAFS.exterior[OrderHistory.data.Ext_Color_Code]
                                .images[0]
                            : OrderHistory.data.model == "LEAF SV Plus"
                            ? LeafSVPlus.exterior[
                                OrderHistory.data.Ext_Color_Code
                              ].images[0]
                            : // : OrderHistory?.data?.model == "LEAFS"
                              // ? LEAFS.exterior[OrderHistory.data.Ext_Color_Code]
                              //     .images[0]
                              // : OrderHistory?.data?.model == "LeafSVPlus"
                              // ? LeafSVPlus.exterior[
                              //     OrderHistory.data.Ext_Color_Code
                              //   ].images[0]

                              null
                        }
                        width="150px"
                      />
                    </div>
                    <div>
                      <div className="d-flex gap-4 align-items-center ">
                        <div className="d-flex flex-column gap-3">
                          <div className="d-flex flex-column">
                            <span className="OrderText">
                              Número de orden: {OrderHistory?.data?.order_id}
                            </span>
                            <span className="OrderText">
                              Fecha de la orden: {OrderHistory?.data?.order_at}{" "}
                              {/* {OrderHistory?.data.Mng_Year} */}
                            </span>
                          </div>
                          <div className="d-flex flex-column">
                            <span className="OrderText">
                              Modelo: {OrderHistory?.data?.model}
                            </span>
                            <span className="OrderText">
                              Color exterior:{" "}
                              {OrderHistory?.data?.Ext_Color_Name}
                            </span>
                            <span className="OrderText">
                              Color interior:{" "}
                              {OrderHistory?.data?.Int_Color_Name}
                            </span>
                          </div>
                        </div>
                        <div>
                          <svg
                            width="15"
                            height="25"
                            viewBox="0 0 15 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M14.3723 11.1213L3.33586 0.553275C3.1546 0.377961 2.93894 0.238811 2.70132 0.143851C2.46371 0.0488906 2.20885 0 1.95144 0C1.69403 0 1.43917 0.0488906 1.20156 0.143851C0.963943 0.238811 0.748283 0.377961 0.567015 0.553275C0.203845 0.903726 0 1.37779 0 1.87194C0 2.36608 0.203845 2.84015 0.567015 3.1906L10.219 12.5428L0.567015 21.8015C0.203845 22.152 0 22.6261 0 23.1202C0 23.6143 0.203845 24.0884 0.567015 24.4389C0.747603 24.6156 0.962959 24.7562 1.2006 24.8525C1.43825 24.9488 1.69345 24.999 1.95144 25C2.20942 24.999 2.46463 24.9488 2.70228 24.8525C2.93992 24.7562 3.15528 24.6156 3.33586 24.4389L14.3723 13.8708C14.5702 13.6957 14.7281 13.4831 14.8362 13.2465C14.9442 13.0099 15 12.7544 15 12.4961C15 12.2378 14.9442 11.9822 14.8362 11.7456C14.7281 11.509 14.5702 11.2964 14.3723 11.1213V11.1213Z"
                              fill="#C3002F"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  Loading == false && (
                    <div className="OrderHistory-parent-div">
                      <div className="OrderHistory-img-div">
                        {/* <img src={AllImages.engageAWDRed1} width="100%" alt="" /> */}
                        <img
                          src={
                            OrderHistory?.data?.model == "ENGAGE FWD"
                              ? Ariya_EngageFWD?.exterior[
                                  OrderHistory?.data.Ext_Color_Code
                                ].images[0]
                              : OrderHistory?.data?.model == "ENGAGE e-4ORCE"
                              ? Ariya_Engage_e_4orce.exterior[
                                  OrderHistory.data.Ext_Color_Code
                                ].images[0]
                              : OrderHistory?.data?.model == "ENGAGE+ e-4ORCE"
                              ? Ariya_Engage__e_4orce.exterior[
                                  OrderHistory.data.Ext_Color_Code
                                ].images[0]
                              : OrderHistory?.data?.model == "VENTURE+"
                              ? VENTURE.exterior[
                                  OrderHistory.data.Ext_Color_Code
                                ].images[0]
                              : OrderHistory?.data?.model == "EVOLVE+ FWD"
                              ? EVOLVE.exterior[
                                  OrderHistory.data.Ext_Color_Code
                                ].images[0]
                              : OrderHistory?.data?.model == "EVOLVE+ e-4ORCE"
                              ? evolvepe4orce.exterior[
                                  OrderHistory.data.Ext_Color_Code
                                ].images[0]
                              : OrderHistory.data.model == "LEAF S"
                              ? LEAFS.exterior[OrderHistory.data.Ext_Color_Code]
                                  .images[0]
                              : OrderHistory.data.model == "LEAF SV Plus"
                              ? LeafSVPlus.exterior[
                                  OrderHistory.data.Ext_Color_Code
                                ].images[0]
                              : null
                          }
                          width="100%"
                          alt=""
                        />
                      </div>
                      <div>
                        <div className="w-100 d-flex flex-column gap-4">
                          <div className="w-100 d-flex flex-column gap-2">
                            <span className="OrderText">
                              Número de transacción:{" "}
                              {OrderHistory?.data.trans_id}
                            </span>
                            <span className="OrderText">
                              Número de orden: {OrderHistory?.data.order_id}
                            </span>
                          </div>
                          <span className="OrderHead">
                            {OrderHistory?.data?.model}
                          </span>
                          <div className="w-100 d-flex flex-column gap-3">
                            <span className="OrderText">
                              Color exterior:{" "}
                              {OrderHistory?.data?.Ext_Color_Name}
                            </span>
                            <span className="OrderText">
                              Color interior:{" "}
                              {OrderHistory?.data?.Int_Color_Name}
                            </span>
                            {/* {OrderHistory?.data?.status == "In_Inventory" ||
                            OrderHistory?.data?.status == "Pending" ? null : (
                              <span className="OrderText">
                                <span className="dollor-txt">Depósito $</span>
                                500
                              </span>
                            )} */}
                            <span className="OrderText">
                              MSRP: ${nf.format(parseInt(Price))}
                            </span>
                          </div>
                          <div className="ecebtn">
                            <button
                              className="additionalSpecs-btn"
                              type="button"
                              onClick={
                                OrderHistory.data.model == "ENGAGE FWD"
                                  ? () =>
                                      Navigate("/Especificaciones/Engage/FWD", {
                                        state: { Pathnamee },
                                      })
                                  : OrderHistory.data.model == "ENGAGE e-4ORCE"
                                  ? () =>
                                      Navigate(
                                        "/Especificaciones/Engage/E-4FORCE",
                                        {
                                          state: { Pathnamee },
                                        }
                                      )
                                  : OrderHistory.data.model == "ENGAGE+ e-4ORCE"
                                  ? () =>
                                      Navigate(
                                        "/Especificaciones/Engage+/E-4FORCE",
                                        {
                                          state: { Pathnamee },
                                        }
                                      )
                                  : OrderHistory.data.model == "VENTURE+"
                                  ? () =>
                                      Navigate(
                                        "/Especificaciones/Engage/Venture+",
                                        {
                                          state: { Pathnamee },
                                        }
                                      )
                                  : OrderHistory.data.model == "EVOLVE+ FWD"
                                  ? () =>
                                      Navigate(
                                        "/Especificaciones/Evolve+/FWD",
                                        {
                                          state: { Pathnamee },
                                        }
                                      )
                                  : OrderHistory.data.model == "EVOLVE+ e-4ORCE"
                                  ? () =>
                                      Navigate(
                                        "/Especificaciones/Evolve+/e-4FORCE",
                                        {
                                          state: { Pathnamee },
                                        }
                                      )
                                  : OrderHistory.data.model == "LEAF-S"
                                  ? () =>
                                      Navigate("/Especificaciones/LEAF-S", {
                                        state: { Pathnamee },
                                      })
                                  : OrderHistory.data.model == "LEAF-SV-Pus"
                                  ? () =>
                                      Navigate(
                                        "/Especificaciones/LEAF-SV-Pus",
                                        {
                                          state: { Pathnamee },
                                        }
                                      )
                                  : null
                              }>
                              Especificaciones adicionales
                            </button>
                          </div>
                          <div className="w-100 d-flex">
                            {(OrderHistory.data.status === "In_Inventory" &&
                              OrderHistory.data.track_status === "Delivered") ||
                            // OrderHistory.data.status === "Próximo_embarque" ||
                            OrderHistory.data.track_status === "Deposit" ||
                            Ordertrackk?.Step_4_Status === "Yes" ? null : (
                              <Button
                                disable={disable}
                                variant={"red"}
                                onClick={
                                  OrderHistory.data.status == "Pending"
                                    ? () => {
                                        cancelOrderCancelPending();
                                        setModalShow(true);
                                        // setCanclemodelinterestlshow(true);
                                      }
                                    : OrderHistory.data.track_status ==
                                      "Reservation"
                                    ? () => {
                                        CancelNot_In_Inventory();
                                        setModalShow(true);
                                      }
                                    : OrderHistory.data.track_status ==
                                      "Puesto_a_fábrica"
                                    ? () => {
                                        CancelPuesto_a_fábrica();
                                        // setModalShow(true);
                                        setFourModalShow(true);
                                      }
                                    : OrderHistory.data.track_status ==
                                        "Confirmation" &&
                                      OrderHistory.data.confirm_status ==
                                        "FALSE"
                                    ? () => setFirstModalShow(true)
                                    : OrderHistory.data.confirm_status == "TRUE"
                                    ? () => setThirdModalShow(true)
                                    : OrderHistory.data.status ==
                                        "No_Disponible" &&
                                      OrderHistory.data.track_status ==
                                        "Confirm"
                                    ? () => setCanclemodelinterestlshow(true)
                                    : OrderHistory.data.status ==
                                        "No_Disponible" &&
                                      OrderHistory.data.track_status == "Cancel"
                                    ? () => CancleOrder()
                                    : OrderHistory.data.status == "In_Inventory"
                                    ? () => setCanclemodelinterestlshow(true)
                                    : OrderHistory.data.status ===
                                      "Próximo_embarque"
                                    ? () => setFourModalShow(true)
                                    : null
                                }>
                                {/* setCanclemodelinterestlshow(true); */}
                                {disable
                                  ? "PEDIDO CANCELADO"
                                  : Ordertrackk?.OTW == "Yes" &&
                                    Ordertrackk?.OTW_Status == "FALSE"
                                  ? "¿Deseas continuar?"
                                  : "CANCELAR PEDIDO"}
                              </Button>
                            )}
                          </div>
                          <div className="ordertrackmain">
                            <div
                              className={
                                Ordertrackk?.Step_4_Status == ""
                                  ? "ordertrack_two"
                                  : "ordertrack"
                              }>
                              {/* round div  start*/}
                              {/* <div className="ringactive"> */}
                              <div
                                className={
                                  Ordertrackk?.Step_1_Status == "Yes"
                                    ? "ringactive"
                                    : "ring"
                                }>
                                {Ordertrackk?.Step_1_Status == "Yes" ? (
                                  <motion.img
                                    initial={{ opacity: 0, x: -10 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.3 }}
                                    src={trakertick}
                                    alt=""
                                  />
                                ) : null}
                              </div>
                              {/* round div  end */}
                              {/* line  div start*/}
                              <div
                                style={{
                                  backgroundColor:
                                    Ordertrackk?.Step_1_Status == "Yes"
                                      ? "#C3002F"
                                      : "#53545c",
                                }}
                                className={
                                  Ordertrackk?.Step_4_Status == ""
                                    ? "newringborder"
                                    : "ringborder"
                                }></div>
                              {/* line  div end */}
                              {/* round div start */}
                              <div
                                className={
                                  Ordertrackk?.Step_2_Status == "Yes"
                                    ? "ringactive"
                                    : "ring"
                                }>
                                {Ordertrackk?.Step_2_Status == "Yes" ? (
                                  <motion.img
                                    initial={{ opacity: 0, x: -10 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.3 }}
                                    src={trakertick}
                                    alt=""
                                  />
                                ) : null}
                              </div>
                              {/* round div and  */}
                              {/* line  div start*/}
                              <div
                                style={{
                                  backgroundColor:
                                    Ordertrackk?.Step_3_Status == ""
                                      ? null
                                      : Ordertrackk?.Step_2_Status == "Yes"
                                      ? "#C3002F"
                                      : "#53545c",
                                }}
                                className={
                                  Ordertrackk?.Step_4_Status == ""
                                    ? "newringborder"
                                    : "ringborder"
                                }></div>
                              {/* line  div end */}
                              {/* round div start */}
                              <div
                                className={
                                  Ordertrackk?.Step_3_Status == ""
                                    ? null
                                    : Ordertrackk?.Step_3_Status == "Yes"
                                    ? "ringactive"
                                    : "ring"
                                }>
                                {Ordertrackk?.Step_3_Status == "Yes" ? (
                                  <motion.img
                                    initial={{ opacity: 0, x: -10 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.3 }}
                                    src={trakertick}
                                    alt=""
                                  />
                                ) : null}
                              </div>
                              {/* round div end   */}
                              {/* line  div satrt*/}
                              <div
                                style={{
                                  backgroundColor:
                                    Ordertrackk?.Step_4_Status == ""
                                      ? null
                                      : Ordertrackk?.Step_3_Status == "Yes"
                                      ? "#C3002F"
                                      : "#53545c",
                                }}
                                className={
                                  Ordertrackk?.Step_4_Status == ""
                                    ? "newringborder"
                                    : "ringborder"
                                }></div>
                              {/* line  div end */}
                              {/* round div */}
                              <div
                                className={
                                  Ordertrackk?.Step_4_Status == ""
                                    ? null
                                    : Ordertrackk?.Step_4_Status == "Yes"
                                    ? "ringactive"
                                    : "ring"
                                }>
                                {Ordertrackk?.Step_4_Status == "Yes" ? (
                                  <motion.img
                                    initial={{ opacity: 0, x: -10 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.3 }}
                                    src={trakertick}
                                    alt=""
                                  />
                                ) : null}
                              </div>
                              {/* round div end   */}
                            </div>

                            <div
                              className={
                                Ordertrackk?.Step_4_Status == ""
                                  ? "tracknameee"
                                  : "trackname"
                              }>
                              <h6>Reservación recibida</h6>

                              {Ordertrackk.Step_2_Title ==
                              "Próximo embarque" ? (
                                <h6>Próximo embarque</h6>
                              ) : Ordertrackk?.Step_3_Status == "" ? (
                                <h6>No disponible</h6>
                              ) : Ordertrackk?.Step_4_Status == "" ? null : (
                                <h6>Reservación en progreso</h6>
                              )}
                              {Ordertrackk?.Step_3_Title == "Coordinar cita" ? (
                                <h6>Coordinar cita</h6>
                              ) : Ordertrackk?.Step_3_Status ==
                                "" ? null : OrderHistory.data.status ==
                                "No_Disponible_Fábrica" ? (
                                <h6>Puesto a fábrica</h6>
                              ) : (
                                <h6>En progreso</h6>
                              )}
                              {Ordertrackk?.Step_3_Status == "" ? null : (
                                <h6>Entregada</h6>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </>
            )
          )}
        </>
      )}
      <CancleOrderModal show={modalShow} onHide={() => setModalShow(false)} />
      {/* <Canclemodal5
        OrderHistory={OrderHistory}
        setOrderHistory={setOrderHistory()}
      /> */}
    </section>
  );
};

export default OrderHistory;
