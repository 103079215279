import React from "react";
import "./Card.css";
import Card from "react-bootstrap/Card";
import Logo from "../../../assets/images/NissanBrandLogoauthnew.png";
import { Link } from "react-router-dom";

const MainCard = ({ children, head }) => {
  return (
    <>
      <section className="d-flex flex-column align-items-center justify-content-center height-100">
        <div className="p-3"></div>
        <div className="logo_div_new">
          <Link className="link_box" to={"/"}>
            <img className="rlogo" src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className="p-3"></div>
        <Card className="Main-card">
          <Card.Body className="cardbody">
            <div className="">
              <div id="card-head" className="width-90 white">
                {head}
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="flex-column d-flex justify-content-start align-items-center object-center">
                {children}
              </div>
            </div>
          </Card.Body>
        </Card>
        <div className="p-5"></div>
      </section>
    </>
  );
};

export default MainCard;
