import "./Section4.css";
import React, { useState } from "react";
// import VideoSlider from "../UI/VideoSlider";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
// import "swiper/css/navigation";
import { EffectCards } from "swiper";
import { Navigation } from "swiper";

// import Modeldata from " ../../ ../../../../../Leaf/components/Data/Variants";
import Modeldata from "../../../../components/Data/Variants";

import downarrow from "../../../../assets/images/chevrondown.png";
import { Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const Section4 = () => {
  const navigate = useNavigate();
  const [indexx, setindex] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  localStorage.setItem("cardindex", indexx);

  function ModelModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        centered
        className="wasd-modal-ko-badla"
        style={{ zIndex: "10000" }}>
        <Modal.Header className="new_model" closeButton></Modal.Header>
        <Modal.Body className="new_model text-center">
          <div className="Dropdown-Car-info-body-Head">
            <i>Información importante</i>
          </div>
          <p className="Modal-text">
            <div>
              El precio ilustrado no incluye accesorios. El precio es el Precio
              de venta sugerido por el fabricante (MSRP, por sus siglas en
              inglés). El MSRP no incluye impuestos, título, placas, opciones ni
              cobro por destino. El concesionario fija el precio final.
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  const ico = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => {
        e.stopPropagation();
        setModalShow(true);
      }}
      style={{ cursor: "pointer" }}>
      <path
        d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.09998V8H12.0498Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const ico2 = (
    <svg
      width="17"
      height="17"
      viewBox="0 0 24 24"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => {
        e.stopPropagation();
        setModalShow(true);
      }}>
      <path
        d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.09998V8H12.0498Z"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const handleclick = (index, name) => {
    ReactGA.event({
      category: "Landing page",
      action: name,
    });
    setindex(index);
    setTimeout(() => {
      navigate("/Leaf/Model", {
        state: "active",
      });
    }, 100);
  };

  return (
    <>
      <ModelModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="moulasdiv" id="Specs">
        <span>Variantes{ico}</span>
        <div className="swiperkhain">
          <div className="cardmaindivv">
            {Modeldata.map((curelem, index) => {
              return (
                <div key={index}>
                  <div className="modulcard">
                    <div className="cardimg">
                      <motion.img
                        initial={{ x: 70, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        src={curelem.img}
                        alt=""
                        onClick={() => handleclick(index, curelem.name)}
                      />
                      {/* <span onClick={() => setModalShow(true)}> <img src={info} alt="" /></span> */}
                    </div>
                    <div
                      className="masinfo"
                      onClick={() => handleclick(index, curelem.name)}>
                      <span>Más información</span>
                    </div>
                    <div
                      onClick={() => handleclick(index, curelem.name)}
                      className="modulscontent">
                      <span>{curelem.name}</span>
                      <p className="msrp_div ">
                        {curelem.price}
                        <span>{ico2}</span>
                      </p>
                      <div className="km">
                        <div className="first">
                          {curelem.icon3}
                          {/* <img src={curelem.icon3} alt="" /> */}
                          <span>{curelem.vorte}</span>
                        </div>
                        <div className="first">
                          {curelem.icon2}
                          {/* <img src={curelem.icon2} alt="" /> */}
                          <span>{curelem.km}</span>
                        </div>
                        <div className="first">
                          {curelem.icon}
                          {/* <img src={curelem.icon} alt="" /> */}
                          <span>{curelem.rwd}</span>
                        </div>
                      </div>
                      <div className="mphdiv">
                        <div className="linediv3"></div>
                        <div className="mph">
                          <p className="pragraphs">Alcance:</p>
                          <span>
                            {curelem.Alcance}
                            <p className="pragraphs">millas</p>
                          </span>
                          <p className="pragraphs">MPGe:</p>
                          <p className="pragraphs">{curelem.MPGe} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="cardmaindivvv">
          <Swiper
            effect={"cards"}
            grabCursor={true}
            loop={false}
            modules={[EffectCards]}
            className="mySwiper"
            tabIndex={0}
            initialSlide={0}>
            {Modeldata.map((curelem, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="modulcard">
                    <div className="cardimg">
                      <img
                        src={curelem.img}
                        alt=""
                        onClick={() => handleclick(index, curelem.name)}
                      />
                      {/* <span onClick={() => setModalShow(true)}><img src={info} alt="" /></span> */}
                    </div>
                    <div
                      onClick={() => handleclick(index, curelem.name)}
                      className="masinfo">
                      <span>Más información</span>
                    </div>
                    <div
                      onClick={() => handleclick(index, curelem.name)}
                      className="modulscontent">
                      <span>{curelem.name}</span>
                      <p className="msrp_div ">
                        {curelem.price}
                        <span>{ico2}</span>
                      </p>
                      <div className="km">
                        <div className="first">
                          {curelem.icon3}
                          {/* <img src={curelem.icon3} alt="" /> */}
                          <span>{curelem.vorte}</span>
                        </div>
                        <div className="first">
                          {curelem.icon2}
                          {/* <img src={curelem.icon2} alt="" /> */}
                          <span>{curelem.km}</span>
                        </div>
                        <div className="first">
                          {curelem.icon}
                          {/* <img src={curelem.icon} alt="" /> */}
                          <span>{curelem.rwd}</span>
                        </div>
                      </div>
                      <div className="mphdiv">
                        <div className="linediv3"></div>
                        <div className="mph">
                          <p className="pragraphs">Alcance:</p>
                          <span>
                            {curelem.Alcance}
                            <p className="pragraphs">millas</p>
                          </span>
                          <p className="pragraphs">MPGe:</p>
                          <p className="pragraphs">{curelem.MPGe} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="viewarrowdiv">
          <div
            className="smajhniaraha"
            onClick={() => navigate("/Leaf/Variantes")}>
            <span>Ver más variantes</span>
            <div>
              <img src={downarrow} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section4;
