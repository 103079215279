// TrackPageView.js
import { useEffect } from "react";
import ReactGA4 from "react-ga4";

const TrackPageView = ({ title, screenName }) => {
  useEffect(() => {
    ReactGA4.send({
      hitType: "pageview",
      page_title: title,
      screen_name: screenName,
    });
  }, [title, screenName]);

  return null;
};

export default TrackPageView;
