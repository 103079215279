import React, { useState, useEffect } from "react";
import Wrapper from "../../../components/UI/Wrapper";
import Card from "../../../components/UI/Card";
import Input from "../../../components/UI/Input";
import Button from "../../../../Common/components/UI/Button";
import "./ForgotPassword.css";
import { useFormik } from "formik";
import { ForgotPassSchema } from "../../../schemas";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import axios from "../../../../Common/Api";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const ForgotPassword = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  const initialValues = {
    Email: "",
  };
  const Navigate = useNavigate();

  const [IsError, setIsError] = useState("");
  const [Loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    console.log("Form values...", values);
    setLoading(true);

    try {
      const response = await axios.post("forgot-password", {
        Email: values.Email,
      });
      console.log("response......pppp", response);
      setIsError(response.data.message);

      setLoading(false);
      if (response.data.message == "Password send to your Email") {
        localStorage.setItem(
          "ForgotPass-Email",
          JSON.stringify(response.data.data)
        );
        Navigate("/Iniciar-Sesion");
      }
    } catch (error) {
      // console.log("🚀 ~ file: index.js:47 ~ onSubmit ~ error:", error);
      console.log(error);
      setLoading(false);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ForgotPassSchema,
      onSubmit,
    });

  return (
    <div>
      <Wrapper>
        <Card head={"Olvidaste contraseña"}>
          <div className="p-2"></div>
          <form onSubmit={handleSubmit} className="mainform10">
            <div className="inputs3">
              <p className="heading_text_p">
                Ingresa el correo electrónico con el que te registraste para
                <br />
                enviarte una contraseña nueva.
              </p>
              <p className="heading_text_p_mobile">
                Ingresa el correo electrónico con el que te registraste para
                enviarte una contraseña nueva.
              </p>

              <span className="form-error-head"> {IsError} </span>
              <div>
                <Input
                  text={"Correo electrónico"}
                  type={"text"}
                  placeholder={"Ingrese su correo electrónico"}
                  name="Email"
                  value={values.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.Email && touched.Email ? (
                  <p className="form-error"> {errors.Email} </p>
                ) : null}
              </div>
            </div>
            <div className="p-3"></div>
            <div className="d-flex justify-content-center align-items-center w-100">
              <Button variant={"red"} type={"submit"}>
                ENVIAR
              </Button>
            </div>
          </form>
        </Card>
        s{!Loading && <div></div>}
        {Loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </Wrapper>
    </div>
  );
};

export default ForgotPassword;
