import React, { useState, useEffect } from "react";
import "./Gallery.css";
import Exterior from "./Exterior";
import Interior from "./Interior";
import Navbar from "../../components/UI/Navbar";
import loder1 from "../../../Ariya/assets/images/heading_loder.png";
import loder2 from "../../../Ariya/assets/images/lg_loder.png";
import loder3 from "../../../Ariya/assets/images/sml_loder.png";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const Gallery = () => {
  const [active, setactive] = useState(1);
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  return (
    <>
      <Navbar />
      <div style={{ marginTop: "101px" }}></div>
      <nav className="gellnav">
        <ul>
          <li onClick={() => setactive(1)}>
            <span className={active === 1 ? "borderactive2" : null}>
              Exterior
            </span>
          </li>
          <div className="linediv"></div>
          <li onClick={() => setactive(2)}>
            <span className={active === 2 ? "borderactive2" : null}>
              Interior
            </span>
          </li>
        </ul>
        <div>
          <span className="gelle">Galería</span>
        </div>
      </nav>
      {active === 1 && <Exterior />}
      {active === 2 && <Interior />}
    </>
  );
};

export default Gallery;
