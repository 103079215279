import React, { useEffect, useState } from "react";
import "./Cart.css";
import Navbar from "../../../Ariya/components/UI/Navbar";
import Wrapper from "../../../Ariya/components/UI/Wrapper";
import Button from "../../../Common/components/UI/Button";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "../../Api";
import { Modal } from "react-bootstrap";
import Loader from "../../../Ariya/components/UI/Loader";
import {
  Ariya_EngageFWD,
  Ariya_Engage__e_4orce,
  Ariya_Engage_e_4orce,
  EVOLVE,
  VENTURE,
  evolvepe4orce,
} from "../../../Ariya/constants/Data";
import { LEAFS, LeafSVPlus } from "../../../Leaf/constants/Data";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const Cart = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  const navigate = useNavigate();
  function Orderplaced(props) {
    function ChangeBtn() {
      setModalShow(false);
      navigate("/Perfil/History");
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="white text-center pt-3">
          <h4 className="pb-3">Pedido ya realizado</h4>
          <p className="Modal-text">
            Ya has realizado una reserva por lo que con este usuario no puedes
            volver a realizar la reserva.
          </p>
          <div className="p-2"></div>
          <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
            <Button variant="red" onClick={ChangeBtn}>
              Ir al perfil
            </Button>
          </div>
          <div className="p-3"></div>
        </Modal.Body>
      </Modal>
    );
  }

  // Get User Data
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  // Store Api Data in useState Variable
  const [CartData, setCartData] = useState([]);
  const [disable, setdisable] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);

  // Api Error Data useState Variable
  const [ApiError, setApiError] = useState("");

  // Loader useState Variable
  const [Loading, setLoading] = useState(false);

  const getCartData = async () => {
    setLoading(true);
    if (UserData) {
      try {
        const res = await axios.get("/get-cart", {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        });
        console.log("Cart ...............", res);
        if (res.data.message == "Record") {
          setCartData(res.data);
          setLoading(false);
        } else {
          setApiError(res);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setApiError(error);
        // console.log("errorr..." ,error)
      }
    } else {
      setLoading(false);
    }
  };

  // gethistory api

  const getOrderHistory = async () => {
    setLoading(true);
    try {
      const res = await axios.get("get-history", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      if (res) {
        // console.log("OrderHistory...............", res);
        if (res.data.message == "Record") {
          setModalShow(true);
        }
        if (res.data.status == "success") {
          setLoading(false);
          setModalShow2(false);
        } else {
          setLoading(false);
          // console.log("res Error....", res);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };
  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      getCartData();
    }
    if (localStorage.getItem("user-info")) {
      getOrderHistory();
    }
  }, []);

  const StaticCartData = JSON.parse(localStorage.getItem("StaticCartData"));
  console.log(
    "🚀 ~ file: index.js:139 ~ Cart ~ StaticCartData:",
    StaticCartData
  );

  var nf = new Intl.NumberFormat();

  const DeleteSVG = (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width="55%"
    //   height="55%"
    // >
    //   <path d="M 10 2 L 9 3 L 3 3 L 3 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 4.3652344 7 L 6.0683594 22 L 17.931641 22 L 19.634766 7 L 4.3652344 7 z" />
    // </svg>
    <svg
      viewBox="0 0 24 24"
      width="60%"
      height="60%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M10 12V17"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"></path>{" "}
        <path
          d="M14 12V17"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"></path>{" "}
        <path
          d="M4 7H20"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"></path>{" "}
        <path
          d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"></path>{" "}
        <path
          d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"></path>{" "}
      </g>
    </svg>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width="96px"
    //   height="96px"
    // >
    //   <path d="M 10 2 L 9 3 L 3 3 L 3 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 4.3652344 7 L 6.0683594 22 L 17.931641 22 L 19.634766 7 L 4.3652344 7 z" />
    // </svg>
  );

  const clearCart = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        "delete-cart",
        {
          OrderId: CartData.data.order_id,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData?.Token}`,
          },
        }
      );
      console.log("🚀 ~ file: index.js:227 ~ clearCart ~ res:", res);
      if (res) {
        setLoading(false);
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };

  // delete model
  function DeletModal(props) {
    function ChangeBtn() {
      setdisable(true);
      setModalShow2(false);
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
        <Modal.Body className="white text-center pt-3">
          <h4 className="pb-3">¡Alerta!</h4>
          <p className="Modal-text">
            ¿Estás seguro que deseas eliminarlo del carrito?
          </p>
          <div className="p-2"></div>
          <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
            <Button variant="black" onClick={props.onHide}>
              No
            </Button>
            {/* <Button variant="red" onClick={ChangeBtn}> */}
            <Button variant="red" onClick={handleclick}>
              Sí
            </Button>
          </div>
          <div className="p-3"></div>
        </Modal.Body>
      </Modal>
    );
  }

  const handleclick = () => {
    localStorage.removeItem("StaticCartData");
    getCartData();
    setModalShow2(false);
  };

  return (
    <>
      <Helmet>
        <title>Nissan | Carrito con tu selección</title>
        <meta
          name="description"
          content="Aquí se guardan las diferentes opciones de modelo que hayas añadido."
        />
      </Helmet>
      <Navbar />
      <Wrapper bgWhite>
        <div className="cart">
          <div className="d-flex justify-content-center white pt-4 ">
            <div className="d-flex justify-content-center flex-column align-items-center heading-policy border-bottom-for-policy">
              <div className="p-4"></div>
              {StaticCartData ||
              (CartData.status == "success" &&
                CartData.message == "Record" &&
                CartData.data.status == "Pending") ? (
                <>
                  <div className="w-100 d-flex flex-column align-items-center justify-content-center pb-4">
                    <span className="Heading-contactUs">Carrito</span>
                    <div className="border-bottom-divider-policy"></div>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-100 d-flex flex-column align-items-center justify-content-center pb-4">
                    <span
                      className="Heading-contactUs"
                      onClick={() => setModalShow(true)}>
                      Carrito
                    </span>
                    <div className="border-bottom-divider-policy"></div>
                  </div>
                </>
              )}
              <div className="p-4"></div>
            </div>
          </div>
          {Loading && (
            <div className="loder_div" style={{ height: "50vh" }}>
              <Loader />
            </div>
          )}
          <div style={{ color: "white" }}>
            {!Loading &&
            CartData.status == "success" &&
            CartData.message == "Record" ? (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="p-3"></div>
                <div
                  className=" d-flex gap-4 align-items-center cartdiv"
                  style={{
                    cursor: "pointer",
                    // backgroundColor: "#9f9f9f",
                    borderRadius: "18px",
                    padding: "0 20px",
                  }}
                  onClick={() => navigate("/Interest")}>
                  <div style={{ width: "150px" }}>
                    <img
                      src={
                        CartData.data.Model == "ENGAGE FWD"
                          ? Ariya_EngageFWD.exterior[
                              CartData.data.Ext_Color_Code
                            ].images[0]
                          : CartData.data.Model == "ENGAGE e-4ORCE"
                          ? Ariya_Engage_e_4orce.exterior[
                              CartData.data.Ext_Color_Code
                            ].images[0]
                          : CartData.data.Model == "ENGAGE+ e-4ORCE"
                          ? Ariya_Engage__e_4orce.exterior[
                              CartData.data.Ext_Color_Code
                            ].images[0]
                          : CartData.data.Model == "VENTURE+"
                          ? VENTURE.exterior[CartData.data.Ext_Color_Code]
                              .images[0]
                          : CartData.data.Model == "EVOLVE+ FWD"
                          ? EVOLVE.exterior[CartData.data.Ext_Color_Code]
                              .images[0]
                          : CartData.data.Model == "EVOLVE+ e-4ORCE"
                          ? evolvepe4orce.exterior[CartData.data.Ext_Color_Code]
                              .images[0]
                          : CartData.data.Model == "LEAF S"
                          ? LEAFS.exterior[CartData.data.Ext_Color_Code]
                              .images[0]
                          : CartData.data.Model == "LEAF SV Plus"
                          ? LeafSVPlus.exterior[CartData.data.Ext_Color_Code]
                              .images[0]
                          : null
                      }
                      width="100%"
                      className="car-img"
                    />
                  </div>
                  <div style={{ width: "70%" }}>
                    <div className="d-flex gap-4 align-items-center justify-content-between w-100">
                      <div className="d-flex flex-column">
                        <span className="OrderText">
                          Modelo: {CartData.data.Model}
                        </span>
                        <span className="OrderText">
                          MSRP: ${nf.format(parseInt(CartData.data.Price))}
                        </span>
                      </div>
                      <div className="d-flex flex-column justify-content-center gap-3 align-items-center">
                        <button
                          className="delete-btn"
                          onClick={(e) => {
                            // e.stopPropagation();
                            // clearCart();
                            setModalShow2(true);
                          }}>
                          {DeleteSVG}
                          {/* <i className="fa fa-trash" aria-hidden="true"></i> */}
                        </button>
                        {/* <button
                          className="delete-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            clearCart();
                          }}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </button> */}
                        <svg
                          width="15"
                          height="25"
                          viewBox="0 0 15 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14.3723 11.1213L3.33586 0.553275C3.1546 0.377961 2.93894 0.238811 2.70132 0.143851C2.46371 0.0488906 2.20885 0 1.95144 0C1.69403 0 1.43917 0.0488906 1.20156 0.143851C0.963943 0.238811 0.748283 0.377961 0.567015 0.553275C0.203845 0.903726 0 1.37779 0 1.87194C0 2.36608 0.203845 2.84015 0.567015 3.1906L10.219 12.5428L0.567015 21.8015C0.203845 22.152 0 22.6261 0 23.1202C0 23.6143 0.203845 24.0884 0.567015 24.4389C0.747603 24.6156 0.962959 24.7562 1.2006 24.8525C1.43825 24.9488 1.69345 24.999 1.95144 25C2.20942 24.999 2.46463 24.9488 2.70228 24.8525C2.93992 24.7562 3.15528 24.6156 3.33586 24.4389L14.3723 13.8708C14.5702 13.6957 14.7281 13.4831 14.8362 13.2465C14.9442 13.0099 15 12.7544 15 12.4961C15 12.2378 14.9442 11.9822 14.8362 11.7456C14.7281 11.509 14.5702 11.2964 14.3723 11.1213V11.1213Z"
                            fill="#c3002f"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-3"></div>
                <div className="d-flex justify-content-center">
                  <div className="d-flex cart-btns-div">
                    <Button variant="black" onClick={() => navigate("/")}>
                      Conocer más
                    </Button>

                    <Button
                      variant="red"
                      onClick={() => {
                        CartData.status == "success" &&
                        CartData.message == "Record"
                          ? navigate("/Interest")
                          : navigate("/Model");
                      }}>
                      Me interesa
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {!Loading && StaticCartData ? (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3"></div>

                    <div
                      className="cartdiv cart_box"
                      onClick={
                        localStorage.getItem("Register-Start")
                          ? () => {
                              navigate("/Interest");
                              localStorage.removeItem("Register-Start");
                            }
                          : () => navigate("/Intro")
                      }>
                      <p
                        className="colour_name_span  pt-3"
                        style={{ textAlign: "center" }}>
                        Haz clic aquí para continuar al próximo paso
                      </p>
                      <div className="inner_div">
                        <div className="cart-img-div">
                          <img
                            src={StaticCartData.image}
                            // width="100%"
                            className="cart_img"
                          />
                        </div>
                        <div className="txt-div-cart">
                          <div className="text_box_cart">
                            <div className="sub_main_box">
                              <div className="model_name_div">
                                <span className="model_info_div">
                                  Modelo: {StaticCartData.ModelName}
                                </span>
                                <span className="model_info_div">
                                  MSRP: $
                                  {nf.format(parseInt(StaticCartData.price))}
                                </span>
                              </div>
                              <div className="color_name_div">
                                <span className="colour_name_span">
                                  Color exterior: {StaticCartData.extColorName}
                                </span>
                                <span className="colour_name_span">
                                  Color interior: {StaticCartData.intColorName}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-column justify-content-center gap-3 align-items-center">
                              <button
                                className="delete-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // localStorage.removeItem("StaticCartData");
                                  setModalShow2(true);
                                }}>
                                {DeleteSVG}
                                {/* <i className="fa fa-trash" aria-hidden="true"></i> */}
                              </button>
                              <svg
                                width="15"
                                height="25"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "15px",
                                }}
                                viewBox="0 0 15 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M14.3723 11.1213L3.33586 0.553275C3.1546 0.377961 2.93894 0.238811 2.70132 0.143851C2.46371 0.0488906 2.20885 0 1.95144 0C1.69403 0 1.43917 0.0488906 1.20156 0.143851C0.963943 0.238811 0.748283 0.377961 0.567015 0.553275C0.203845 0.903726 0 1.37779 0 1.87194C0 2.36608 0.203845 2.84015 0.567015 3.1906L10.219 12.5428L0.567015 21.8015C0.203845 22.152 0 22.6261 0 23.1202C0 23.6143 0.203845 24.0884 0.567015 24.4389C0.747603 24.6156 0.962959 24.7562 1.2006 24.8525C1.43825 24.9488 1.69345 24.999 1.95144 25C2.20942 24.999 2.46463 24.9488 2.70228 24.8525C2.93992 24.7562 3.15528 24.6156 3.33586 24.4389L14.3723 13.8708C14.5702 13.6957 14.7281 13.4831 14.8362 13.2465C14.9442 13.0099 15 12.7544 15 12.4961C15 12.2378 14.9442 11.9822 14.8362 11.7456C14.7281 11.509 14.5702 11.2964 14.3723 11.1213V11.1213Z"
                                  fill="#c3002f"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="p-3"></div>
                  </div>
                ) : (
                  <div>
                    {!Loading && (
                      <div>
                        <div className="d-flex justify-content-center">
                          <div className="d-flex cart-btns-div">
                            <Button
                              variant="black"
                              onClick={() => navigate("/")}>
                              Conocer más
                            </Button>

                            <Button
                              variant="red"
                              onClick={() => {
                                CartData.status == "success" &&
                                CartData.message == "Record"
                                  ? navigate("/Interest")
                                  : navigate("/Variantes");
                              }}>
                              Me interesa
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <Orderplaced show={modalShow} onHide={() => setModalShow(false)} />
        <DeletModal show={modalShow2} onHide={() => setModalShow2(false)} />
      </Wrapper>
    </>
  );
};

export default Cart;
