import Modal from "react-bootstrap/Modal";
import "./ConfirmModelModal.css";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

export default function ConfirmModelModal(props) {
  const navigate = useNavigate();
  const {
    image,
    extColor,
    extColorName,
    intColor,
    intColorName,
    HorsePower,
    ModelName,
    battery,
    engine,
    onHide,
    price,
    AddtoCart,
  } = props;

  const Añadiralcarrito = (props) => {
    ReactGA.event({
      category: "Model",
      action: `${ModelName} Añadir al carrito`,
    });
    AddtoCart();
  };
  const Regresar_a_las_variantes = (props) => {
    ReactGA.event({
      category: "Model",
      action: `${ModelName} Regresar a las variantes`,
    });
    onHide();
  };

  const engineIcon = (
    <svg
      style={{ margin: "7px 0" }}
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.3478 3.26113L18.689 3.24849L18.3471 4.08935C18.1572 4.55439 17.904 5.1913 17.7836 5.49688L17.5683 6.05746L17.2581 6.03826L16.9479 6.01906L16.6376 4.93631C16.4731 4.33756 16.302 3.74514 16.2641 3.61144L16.2008 3.37564H14.5611V1.97443H11.9022V1.14644H13.9913V0H5.06471V1.14667H7.15382V1.97466L5.81159 1.98731L4.46308 2.0065L4.444 2.75183L4.42492 3.50348H2.15218V7.26127H1.13951V4.64992H0V10.9558H1.13951L1.15208 9.6628L1.17116 8.37609L1.66504 8.3569L2.15263 8.3377V12.102H4.7419L5.74222 13.4843L6.74884 14.8728L11.4779 14.892L16.2008 14.9047L16.302 14.5734C16.3525 14.3886 16.5173 13.7899 16.6693 13.2485L16.9479 12.2613H17.5683L18.1192 13.6306L18.6636 15H22V3.28033L20.3478 3.26113ZM12.8835 7.91286C12.8776 7.96097 12.8583 8.00637 12.8276 8.04409L9.27951 12.3837C9.25167 12.4185 9.2162 12.4467 9.1758 12.4661C9.13539 12.4855 9.09116 12.4957 9.04604 12.4957C9.00271 12.4957 8.95984 12.4864 8.92033 12.4682C8.88104 12.4501 8.84602 12.4237 8.81818 12.3907C8.79035 12.3579 8.76992 12.3193 8.75869 12.2778C8.74747 12.2362 8.74545 12.1928 8.75286 12.1506V12.1488L9.2079 9.67635H7.44633C7.39739 9.67635 7.34957 9.66257 7.30826 9.63682C7.26696 9.61108 7.23373 9.57404 7.21263 9.53045C7.19153 9.48685 7.18345 9.43807 7.18906 9.38996C7.19467 9.34186 7.2142 9.29646 7.24496 9.25874L10.7926 4.91914C10.833 4.86833 10.8891 4.83219 10.9522 4.81593C11.0153 4.79967 11.0822 4.80441 11.1423 4.82925C11.2025 4.8541 11.2528 4.89791 11.2856 4.9537C11.3183 5.00949 11.3316 5.07453 11.3235 5.13868C11.3235 5.14342 11.3221 5.14816 11.3215 5.1529L10.8649 7.62603H12.6262C12.6751 7.62603 12.723 7.6398 12.7643 7.66555C12.8056 7.6913 12.8388 7.72834 12.8599 7.77193C12.881 7.81552 12.8891 7.86431 12.8835 7.91241V7.91286Z"
        fill="black"
      />
    </svg>
  );

  const batteryIcon = (
    <svg
      style={{ margin: "7px 0" }}
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.49219 14H15.5078C17.149 14 18 13.1602 18 11.5629V4.39422C18 2.797 17.149 1.96465 15.5078 1.96465H14.596V1.01982C14.596 0.382432 14.2161 0 13.5703 0H11.4504C10.797 0 10.4247 0.382432 10.4247 1.01982V1.96465H7.56015V1.01982C7.56015 0.382432 7.18784 0 6.5344 0H4.41452C3.76108 0 3.38877 0.382432 3.38877 1.01982V1.96465H2.49219C0.850992 1.96465 0 2.7895 0 4.39422V11.5629C0 13.1676 0.850992 14 2.49219 14ZM6.542 8.47349C6.542 8.391 6.57999 8.31602 6.64838 8.24103L9.75602 4.41671C10.0068 4.10177 10.4323 4.31923 10.2803 4.70166L9.26973 7.36369H11.1693C11.3212 7.36369 11.458 7.48366 11.458 7.63364C11.458 7.71612 11.42 7.79861 11.344 7.8661L8.24399 11.6904C7.99325 11.9979 7.56775 11.7804 7.71211 11.398L8.73027 8.73594H6.83073C6.67117 8.73594 6.542 8.62346 6.542 8.47349Z"
        fill="black"
      />
    </svg>
  );

  const carIcon = (
    <svg
      style={{ margin: "7px 0" }}
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 119.7 119.64"
      fill="#000"
      width="20px">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="m59.82,119.64c32.8,0,59.88-27.07,59.88-59.82S92.56,0,59.76,0,0,27.07,0,59.82s27.07,59.82,59.82,59.82Zm0-11.92c-26.55,0-47.79-21.35-47.79-47.9S33.26,11.98,59.76,11.98s47.9,21.29,47.96,47.84c.06,26.55-21.35,47.9-47.9,47.9Zm-30.95-29.1c0,1.91,1.5,3.41,3.47,3.41h3.12c1.91,0,3.41-1.5,3.41-3.41v-4.45c5.79.35,14.17.64,20.94.64s15.21-.23,20.94-.64v4.45c0,1.91,1.5,3.41,3.47,3.41h3.07c1.97,0,3.47-1.5,3.47-3.41v-15.21c0-4.45-.81-7-3.24-10.07l-2.26-2.89c-.98-4.69-2.66-9.55-3.53-11.4-1.45-3.01-4.22-4.8-7.75-5.26-1.85-.23-7.35-.4-14.17-.4s-12.32.17-14.17.4c-3.53.41-6.31,2.26-7.69,5.26-.93,1.85-2.6,6.71-3.53,11.4l-2.31,2.89c-2.37,3.07-3.24,5.61-3.24,10.07v15.21Zm12.79-30.78c.52-2.49,1.62-5.61,2.31-6.88.69-1.27,1.45-1.79,2.89-1.97,1.74-.29,5.79-.41,12.96-.41s11.22.12,12.96.41c1.5.17,2.2.69,2.95,1.97.75,1.21,1.74,4.4,2.26,6.88.29.93-.12,1.39-1.16,1.33-3.7-.23-8.33-.46-17.01-.46s-13.25.23-16.95.46c-1.1.06-1.45-.4-1.21-1.33Zm-4.92,15.56c0-2.49,1.85-4.28,4.28-4.28s4.28,1.79,4.28,4.28-1.91,4.22-4.28,4.22-4.28-1.79-4.28-4.22Zm37.6,0c0-2.49,1.91-4.28,4.28-4.28s4.28,1.79,4.28,4.28-1.85,4.22-4.28,4.22-4.28-1.79-4.28-4.22Zm-24.53.12c0-1.79,1.27-3.07,3.12-3.07h13.77c1.85,0,3.12,1.27,3.12,3.07s-1.27,3.07-3.12,3.07h-13.77c-1.85,0-3.12-1.21-3.12-3.07Z"
        />
      </g>
    </svg>
  );

  var nf = new Intl.NumberFormat();

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      //   centered
    >
      <Modal.Body className="confirm-model-modal">
        <div className="Main-div-confirm-model-modal">
          <div className="confirm-model-modal-header">
            <span>Resumen de su reserva</span>
            <div className="underline-for-model-modal-header"></div>
          </div>
          <div className="confirm-model-modal-image-div">
            <img src={image} alt="img" width="auto" height="100%" />
          </div>
          <div className="confirm-model-modal-icons-parent-div">
            <div className="confirm-model-modal-icons-div">
              <div className="confirm-model-modal-icons">
                {engineIcon}
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <span>{engine} kW</span>
                  <span style={{ fontSize: "9px" }}>motor</span>
                </div>
              </div>
              <div className="confirm-model-modal-icons">
                {carIcon}
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <span>{HorsePower} HP</span>
                  {/* <span style={{ fontSize: "9px" }}>motor</span> */}
                  <span style={{ height: "15px" }}></span>
                </div>
              </div>
              <div className="confirm-model-modal-icons">
                {batteryIcon}
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <span>{battery} millas</span>
                  <span style={{ fontSize: "9px" }}>rango</span>
                </div>
              </div>
            </div>
          </div>
          <div className="Name-and-Price-confirm-model-modal">
            <div className="Name-confirm-model-modal">
              <span>{ModelName}</span>
            </div>
            <div className="Price-confirm-model-modal">
              <span>
                <span style={{ fontSize: "18px" }}>MSRP: </span>$
                {nf.format(price)}
              </span>
            </div>
          </div>

          <div className="specs-ext-int-confirm-model-modal">
            <span>Exterior</span>
            <div className="specs-color-confirm-model-modal">
              <button
                style={{ backgroundColor: extColor }}
                type="button"
                className="Model-color-btn"></button>
              <span>{extColorName}</span>
            </div>
          </div>

          <div className="specs-ext-int-confirm-model-modal">
            <span>Interior</span>
            <div className="specs-color-confirm-model-modal">
              <button
                style={{ backgroundColor: intColor }}
                type="button"
                className="Model-color-btn"></button>
              <span>{intColorName}</span>
            </div>
          </div>
          {/* 
          <div className="deposite-div-confirm-model-modal">
            <span>Depósito: $500</span>
          </div> */}

          <div className="btn-div-confirm-model-modal">
            <Button variant="black" onClick={Regresar_a_las_variantes}>
              Regresar a las variantes
            </Button>
            <Button
              variant="red"
              onClick={
                Añadiralcarrito
                // navigate("/Carrito");
              }>
              SOLICITAR
              {/* Añadir al carrito */}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
