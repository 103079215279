export const AllImages = {
  engageAWDRed1: require("../assets/images/Section3/1.png"),
  engageAWDRed2: require("../assets/images/Section3/2.png"),
  engageAWDRed3: require("../assets/images/Section3/3.png"),
  engageAWDRed4: require("../assets/images/Section3/4.png"),
  engageAWDRed5: require("../assets/images/Section3/5.png"),
  engageAWDRed6: require("../assets/images/Section3/6.png"),
  engageAWDRed7: require("../assets/images/Section3/7.png"),
  engageAWDRed8: require("../assets/images/Section3/8.png"),

  engageFWD: {
    BlackPearl: {
      img1: require("../assets/images/Engage FWD/Exterior/Black Diamond Pearl/1.png"),
      img2: require("../assets/images/Engage FWD/Exterior/Black Diamond Pearl/2.png"),
      img3: require("../assets/images/Engage FWD/Exterior/Black Diamond Pearl/3.png"),
      img4: require("../assets/images/Engage FWD/Exterior/Black Diamond Pearl/4.png"),
      img5: require("../assets/images/Engage FWD/Exterior/Black Diamond Pearl/5.png"),
      img6: require("../assets/images/Engage FWD/Exterior/Black Diamond Pearl/6.png"),
      img7: require("../assets/images/Engage FWD/Exterior/Black Diamond Pearl/7.png"),
      img8: require("../assets/images/Engage FWD/Exterior/Black Diamond Pearl/8.png"),
    },
    GunMetallic: {
      img1: require("../assets/images/Engage FWD/Exterior/Gun Metallic/1.png"),
      img2: require("../assets/images/Engage FWD/Exterior/Gun Metallic/2.png"),
      img3: require("../assets/images/Engage FWD/Exterior/Gun Metallic/3.png"),
      img4: require("../assets/images/Engage FWD/Exterior/Gun Metallic/4.png"),
      img5: require("../assets/images/Engage FWD/Exterior/Gun Metallic/5.png"),
      img6: require("../assets/images/Engage FWD/Exterior/Gun Metallic/6.png"),
      img7: require("../assets/images/Engage FWD/Exterior/Gun Metallic/7.png"),
      img8: require("../assets/images/Engage FWD/Exterior/Gun Metallic/8.png"),
    },
    EverestWhite: {
      img1: require("../assets/images/Engage FWD/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/1.png"),
      img2: require("../assets/images/Engage FWD/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/2.png"),
      img3: require("../assets/images/Engage FWD/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/3.png"),
      img4: require("../assets/images/Engage FWD/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/4.png"),
      img5: require("../assets/images/Engage FWD/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/5.png"),
      img6: require("../assets/images/Engage FWD/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/6.png"),
      img7: require("../assets/images/Engage FWD/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/7.png"),
      img8: require("../assets/images/Engage FWD/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/8.png"),
    },
    DeapOceanBlue: {
      img1: require("../assets/images/Engage FWD/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/1.png"),
      img2: require("../assets/images/Engage FWD/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/2.png"),
      img3: require("../assets/images/Engage FWD/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/3.png"),
      img4: require("../assets/images/Engage FWD/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/4.png"),
      img5: require("../assets/images/Engage FWD/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/5.png"),
      img6: require("../assets/images/Engage FWD/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/6.png"),
      img7: require("../assets/images/Engage FWD/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/7.png"),
      img8: require("../assets/images/Engage FWD/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/8.png"),
    },
    Silver: {
      img1: require("../assets/images/Engage FWD/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/1.png"),
      img2: require("../assets/images/Engage FWD/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/2.png"),
      img3: require("../assets/images/Engage FWD/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/3.png"),
      img4: require("../assets/images/Engage FWD/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/4.png"),
      img5: require("../assets/images/Engage FWD/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/5.png"),
      img6: require("../assets/images/Engage FWD/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/6.png"),
      img7: require("../assets/images/Engage FWD/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/7.png"),
      img8: require("../assets/images/Engage FWD/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/8.png"),
    },
    BoulderGray: {
      img1: require("../assets/images/Engage FWD/Exterior/Boulder Gray Pearl - Black Diamond Pearl/1.png"),
      img2: require("../assets/images/Engage FWD/Exterior/Boulder Gray Pearl - Black Diamond Pearl/2.png"),
      img3: require("../assets/images/Engage FWD/Exterior/Boulder Gray Pearl - Black Diamond Pearl/3.png"),
      img4: require("../assets/images/Engage FWD/Exterior/Boulder Gray Pearl - Black Diamond Pearl/4.png"),
      img5: require("../assets/images/Engage FWD/Exterior/Boulder Gray Pearl - Black Diamond Pearl/5.png"),
      img6: require("../assets/images/Engage FWD/Exterior/Boulder Gray Pearl - Black Diamond Pearl/6.png"),
      img7: require("../assets/images/Engage FWD/Exterior/Boulder Gray Pearl - Black Diamond Pearl/7.png"),
      img8: require("../assets/images/Engage FWD/Exterior/Boulder Gray Pearl - Black Diamond Pearl/1.png"),
    },
    CharcoalLeatherette: {
      img1: require("../assets/images/Engage FWD/Interior/Charcoal Leatherette/1.png"),
      img2: require("../assets/images/Engage FWD/Interior/Charcoal Leatherette/2.png"),
      img3: require("../assets/images/Engage FWD/Interior/Charcoal Leatherette/3.png"),
      img4: require("../assets/images/Engage FWD/Interior/Charcoal Leatherette/4.png"),
      img5: require("../assets/images/Engage FWD/Interior/Charcoal Leatherette/5.png"),
      img6: require("../assets/images/Engage FWD/Interior/Charcoal Leatherette/6.png"),
      img7: require("../assets/images/Engage FWD/Interior/Charcoal Leatherette/7.png"),
      img8: require("../assets/images/Engage FWD/Interior/Charcoal Leatherette/8.png"),
      img9: require("../assets/images/Engage FWD/Interior/Charcoal Leatherette/9.png"),
      img10: require("../assets/images/Engage FWD/Interior/Charcoal Leatherette/10.png"),
      img11: require("../assets/images/Engage FWD/Interior/Charcoal Leatherette/11.png"),
      img12: require("../assets/images/Engage FWD/Interior/Charcoal Leatherette/12.png"),
    },
    LightGrayLeatherette: {
      img1: require("../assets/images/Engage FWD/Interior/Light Gray Leatherette/12.png"),
      img2: require("../assets/images/Engage FWD/Interior/Light Gray Leatherette/2.png"),
      img3: require("../assets/images/Engage FWD/Interior/Light Gray Leatherette/3.png"),
      img4: require("../assets/images/Engage FWD/Interior/Light Gray Leatherette/4.png"),
      img5: require("../assets/images/Engage FWD/Interior/Light Gray Leatherette/5.png"),
      img6: require("../assets/images/Engage FWD/Interior/Light Gray Leatherette/6.png"),
      img7: require("../assets/images/Engage FWD/Interior/Light Gray Leatherette/7.png"),
      img8: require("../assets/images/Engage FWD/Interior/Light Gray Leatherette/8.png"),
      img9: require("../assets/images/Engage FWD/Interior/Light Gray Leatherette/9.png"),
      img10: require("../assets/images/Engage FWD/Interior/Light Gray Leatherette/10.png"),
      img11: require("../assets/images/Engage FWD/Interior/Light Gray Leatherette/11.png"),
      img12: require("../assets/images/Engage FWD/Interior/Light Gray Leatherette/12.png"),
    },
  },
  engageE4orce: {
    BlackPearl: {
      img1: require("../assets/images/Engage e-4orce/Exterior/Black Diamond Pearl/1.png"),
      img2: require("../assets/images/Engage e-4orce/Exterior/Black Diamond Pearl/2.png"),
      img3: require("../assets/images/Engage e-4orce/Exterior/Black Diamond Pearl/3.png"),
      img4: require("../assets/images/Engage e-4orce/Exterior/Black Diamond Pearl/4.png"),
      // img5: require("../assets/images/Engage e-4orce/Exterior/Black Diamond Pearl/5.png"),
      img6: require("../assets/images/Engage e-4orce/Exterior/Black Diamond Pearl/6.png"),
      img7: require("../assets/images/Engage e-4orce/Exterior/Black Diamond Pearl/7.png"),
      img8: require("../assets/images/Engage e-4orce/Exterior/Black Diamond Pearl/8.png"),
    },
    GunMetallic: {
      img1: require("../assets/images/Engage e-4orce/Exterior/Gun Metallic/1.png"),
      img2: require("../assets/images/Engage e-4orce/Exterior/Gun Metallic/2.png"),
      img3: require("../assets/images/Engage e-4orce/Exterior/Gun Metallic/3.png"),
      img4: require("../assets/images/Engage e-4orce/Exterior/Gun Metallic/4.png"),
      img5: require("../assets/images/Engage e-4orce/Exterior/Gun Metallic/5.png"),
      img6: require("../assets/images/Engage e-4orce/Exterior/Gun Metallic/6.png"),
      img7: require("../assets/images/Engage e-4orce/Exterior/Gun Metallic/7.png"),
      img8: require("../assets/images/Engage e-4orce/Exterior/Gun Metallic/8.png"),
    },
    EverestWhite: {
      img1: require("../assets/images/Engage e-4orce/Exterior/Everest White Pearl TriCoat/1.png"),
      img2: require("../assets/images/Engage e-4orce/Exterior/Everest White Pearl TriCoat/2.png"),
      img3: require("../assets/images/Engage e-4orce/Exterior/Everest White Pearl TriCoat/3.png"),
      img4: require("../assets/images/Engage e-4orce/Exterior/Everest White Pearl TriCoat/4.png"),
      img5: require("../assets/images/Engage e-4orce/Exterior/Everest White Pearl TriCoat/5.png"),
      img6: require("../assets/images/Engage e-4orce/Exterior/Everest White Pearl TriCoat/6.png"),
      img7: require("../assets/images/Engage e-4orce/Exterior/Everest White Pearl TriCoat/7.png"),
      img8: require("../assets/images/Engage e-4orce/Exterior/Everest White Pearl TriCoat/8.png"),
    },
    DeapOceanBlue: {
      img1: require("../assets/images/Engage e-4orce/Exterior/Deep Ocean Blue Pearl/1.png"),
      img2: require("../assets/images/Engage e-4orce/Exterior/Deep Ocean Blue Pearl/2.png"),
      img3: require("../assets/images/Engage e-4orce/Exterior/Deep Ocean Blue Pearl/3.png"),
      img4: require("../assets/images/Engage e-4orce/Exterior/Deep Ocean Blue Pearl/4.png"),
      img5: require("../assets/images/Engage e-4orce/Exterior/Deep Ocean Blue Pearl/5.png"),
      img6: require("../assets/images/Engage e-4orce/Exterior/Deep Ocean Blue Pearl/6.png"),
      img7: require("../assets/images/Engage e-4orce/Exterior/Deep Ocean Blue Pearl/7.png"),
      img8: require("../assets/images/Engage e-4orce/Exterior/Deep Ocean Blue Pearl/8.png"),
    },
    Silver: {
      img1: require("../assets/images/Engage e-4orce/Exterior/Brilliant Silver - Black Diamond/1.png"),
      img2: require("../assets/images/Engage e-4orce/Exterior/Brilliant Silver - Black Diamond/2.png"),
      img3: require("../assets/images/Engage e-4orce/Exterior/Brilliant Silver - Black Diamond/3.png"),
      img4: require("../assets/images/Engage e-4orce/Exterior/Brilliant Silver - Black Diamond/4.png"),
      img5: require("../assets/images/Engage e-4orce/Exterior/Brilliant Silver - Black Diamond/5.png"),
      img6: require("../assets/images/Engage e-4orce/Exterior/Brilliant Silver - Black Diamond/6.png"),
      img7: require("../assets/images/Engage e-4orce/Exterior/Brilliant Silver - Black Diamond/7.png"),
      img8: require("../assets/images/Engage e-4orce/Exterior/Brilliant Silver - Black Diamond/8.png"),
    },
    BoulderGray: {
      img1: require("../assets/images/Engage e-4orce/Exterior/Boulder Gray Pearl/1.png"),
      img2: require("../assets/images/Engage e-4orce/Exterior/Boulder Gray Pearl/2.png"),
      img3: require("../assets/images/Engage e-4orce/Exterior/Boulder Gray Pearl/3.png"),
      img4: require("../assets/images/Engage e-4orce/Exterior/Boulder Gray Pearl/4.png"),
      img5: require("../assets/images/Engage e-4orce/Exterior/Boulder Gray Pearl/5.png"),
      img6: require("../assets/images/Engage e-4orce/Exterior/Boulder Gray Pearl/6.png"),
      img7: require("../assets/images/Engage e-4orce/Exterior/Boulder Gray Pearl/7.png"),
      img8: require("../assets/images/Engage e-4orce/Exterior/Boulder Gray Pearl/8.png"),
    },
    CharcoalLeatherette: {
      img1: require("../assets/images/Engage e-4orce/Interior/Charcoal Leatherette/1.png"),
      img2: require("../assets/images/Engage e-4orce/Interior/Charcoal Leatherette/2.png"),
      img3: require("../assets/images/Engage e-4orce/Interior/Charcoal Leatherette/3.png"),
      img4: require("../assets/images/Engage e-4orce/Interior/Charcoal Leatherette/4.png"),
      img5: require("../assets/images/Engage e-4orce/Interior/Charcoal Leatherette/5.png"),
      img6: require("../assets/images/Engage e-4orce/Interior/Charcoal Leatherette/6.png"),
      img7: require("../assets/images/Engage e-4orce/Interior/Charcoal Leatherette/7.png"),
      img8: require("../assets/images/Engage e-4orce/Interior/Charcoal Leatherette/8.png"),
      img9: require("../assets/images/Engage e-4orce/Interior/Charcoal Leatherette/9.png"),
      img10: require("../assets/images/Engage e-4orce/Interior/Charcoal Leatherette/10.png"),
      img11: require("../assets/images/Engage e-4orce/Interior/Charcoal Leatherette/11.png"),
      img12: require("../assets/images/Engage e-4orce/Interior/Charcoal Leatherette/12.png"),
    },
    LightGrayLeatherette: {
      img1: require("../assets/images/Engage e-4orce/Interior/Light Gray Leatherette/1.png"),
      img2: require("../assets/images/Engage e-4orce/Interior/Light Gray Leatherette/2.png"),
      img3: require("../assets/images/Engage e-4orce/Interior/Light Gray Leatherette/3.png"),
      img4: require("../assets/images/Engage e-4orce/Interior/Light Gray Leatherette/4.png"),
      img5: require("../assets/images/Engage e-4orce/Interior/Light Gray Leatherette/5.png"),
      img6: require("../assets/images/Engage e-4orce/Interior/Light Gray Leatherette/6.png"),
      img7: require("../assets/images/Engage e-4orce/Interior/Light Gray Leatherette/7.png"),
      img8: require("../assets/images/Engage e-4orce/Interior/Light Gray Leatherette/8.png"),
      img9: require("../assets/images/Engage e-4orce/Interior/Light Gray Leatherette/9.png"),
      img10: require("../assets/images/Engage e-4orce/Interior/Light Gray Leatherette/10.png"),
      img11: require("../assets/images/Engage e-4orce/Interior/Light Gray Leatherette/11.png"),
      img12: require("../assets/images/Engage e-4orce/Interior/Light Gray Leatherette/12.png"),
    },
  },
  engageEE4orce: {
    BlackPearl: {
      img1: require("../assets/images/Engage+ e-4orce/Exterior/Black Diamond Pearl/1.png"),
      img2: require("../assets/images/Engage+ e-4orce/Exterior/Black Diamond Pearl/2.png"),
      img3: require("../assets/images/Engage+ e-4orce/Exterior/Black Diamond Pearl/3.png"),
      img4: require("../assets/images/Engage+ e-4orce/Exterior/Black Diamond Pearl/4.png"),
      img5: require("../assets/images/Engage+ e-4orce/Exterior/Black Diamond Pearl/5.png"),
      img6: require("../assets/images/Engage+ e-4orce/Exterior/Black Diamond Pearl/6.png"),
      img7: require("../assets/images/Engage+ e-4orce/Exterior/Black Diamond Pearl/7.png"),
      img8: require("../assets/images/Engage+ e-4orce/Exterior/Black Diamond Pearl/8.png"),
    },

    GunMetallic: {
      img1: require("../assets/images/Engage+ e-4orce/Exterior/Gun Metallic/1.png"),
      img2: require("../assets/images/Engage+ e-4orce/Exterior/Gun Metallic/2.png"),
      img3: require("../assets/images/Engage+ e-4orce/Exterior/Gun Metallic/3.png"),
      img4: require("../assets/images/Engage+ e-4orce/Exterior/Gun Metallic/4.png"),
      img5: require("../assets/images/Engage+ e-4orce/Exterior/Gun Metallic/5.png"),
      img6: require("../assets/images/Engage+ e-4orce/Exterior/Gun Metallic/6.png"),
      img7: require("../assets/images/Engage+ e-4orce/Exterior/Gun Metallic/7.png"),
      img8: require("../assets/images/Engage+ e-4orce/Exterior/Gun Metallic/8.png"),
    },
    EverestWhite: {
      img1: require("../assets/images/Engage+ e-4orce/Exterior/Everest White Pearl TriCoat/1.png"),
      img2: require("../assets/images/Engage+ e-4orce/Exterior/Everest White Pearl TriCoat/2.png"),
      img3: require("../assets/images/Engage+ e-4orce/Exterior/Everest White Pearl TriCoat/3.png"),
      img4: require("../assets/images/Engage+ e-4orce/Exterior/Everest White Pearl TriCoat/4.png"),
      img5: require("../assets/images/Engage+ e-4orce/Exterior/Everest White Pearl TriCoat/5.png"),
      img6: require("../assets/images/Engage+ e-4orce/Exterior/Everest White Pearl TriCoat/6.png"),
      img7: require("../assets/images/Engage+ e-4orce/Exterior/Everest White Pearl TriCoat/7.png"),
      img8: require("../assets/images/Engage+ e-4orce/Exterior/Everest White Pearl TriCoat/8.png"),
    },
    DeapOceanBlue: {
      img1: require("../assets/images/Engage+ e-4orce/Exterior/Deep Ocean Blue Pearl/1.png"),
      img2: require("../assets/images/Engage+ e-4orce/Exterior/Deep Ocean Blue Pearl/2.png"),
      img3: require("../assets/images/Engage+ e-4orce/Exterior/Deep Ocean Blue Pearl/3.png"),
      img4: require("../assets/images/Engage+ e-4orce/Exterior/Deep Ocean Blue Pearl/4.png"),
      img5: require("../assets/images/Engage+ e-4orce/Exterior/Deep Ocean Blue Pearl/5.png"),
      img6: require("../assets/images/Engage+ e-4orce/Exterior/Deep Ocean Blue Pearl/6.png"),
      img7: require("../assets/images/Engage+ e-4orce/Exterior/Deep Ocean Blue Pearl/7.png"),
      img8: require("../assets/images/Engage+ e-4orce/Exterior/Deep Ocean Blue Pearl/8.png"),
    },
    Silver: {
      img1: require("../assets/images/Engage+ e-4orce/Exterior/Brilliant Silver - Black Diamond/1.png"),
      img2: require("../assets/images/Engage+ e-4orce/Exterior/Brilliant Silver - Black Diamond/2.png"),
      img3: require("../assets/images/Engage+ e-4orce/Exterior/Brilliant Silver - Black Diamond/3.png"),
      img4: require("../assets/images/Engage+ e-4orce/Exterior/Brilliant Silver - Black Diamond/4.png"),
      img5: require("../assets/images/Engage+ e-4orce/Exterior/Brilliant Silver - Black Diamond/5.png"),
      img6: require("../assets/images/Engage+ e-4orce/Exterior/Brilliant Silver - Black Diamond/6.png"),
      img7: require("../assets/images/Engage+ e-4orce/Exterior/Brilliant Silver - Black Diamond/7.png"),
      img8: require("../assets/images/Engage+ e-4orce/Exterior/Brilliant Silver - Black Diamond/8.png"),
    },
    BoulderGray: {
      img1: require("../assets/images/Engage+ e-4orce/Exterior/Boulder Gray Pearl/1.png"),
      img2: require("../assets/images/Engage+ e-4orce/Exterior/Boulder Gray Pearl/2.png"),
      img3: require("../assets/images/Engage+ e-4orce/Exterior/Boulder Gray Pearl/3.png"),
      img4: require("../assets/images/Engage+ e-4orce/Exterior/Boulder Gray Pearl/4.png"),
      img5: require("../assets/images/Engage+ e-4orce/Exterior/Boulder Gray Pearl/5.png"),
      img6: require("../assets/images/Engage+ e-4orce/Exterior/Boulder Gray Pearl/6.png"),
      img7: require("../assets/images/Engage+ e-4orce/Exterior/Boulder Gray Pearl/7.png"),
      img8: require("../assets/images/Engage+ e-4orce/Exterior/Boulder Gray Pearl/8.png"),
    },
    CharcoalLeatherette: {
      img1: require("../assets/images/Engage+ e-4orce/Interior/Charcoal Leatherette/1.png"),
      img2: require("../assets/images/Engage+ e-4orce/Interior/Charcoal Leatherette/2.png"),
      img3: require("../assets/images/Engage+ e-4orce/Interior/Charcoal Leatherette/3.png"),
      img4: require("../assets/images/Engage+ e-4orce/Interior/Charcoal Leatherette/4.png"),
      img5: require("../assets/images/Engage+ e-4orce/Interior/Charcoal Leatherette/5.png"),
      img6: require("../assets/images/Engage+ e-4orce/Interior/Charcoal Leatherette/6.png"),
      img7: require("../assets/images/Engage+ e-4orce/Interior/Charcoal Leatherette/7.png"),
      img8: require("../assets/images/Engage+ e-4orce/Interior/Charcoal Leatherette/8.png"),
      img9: require("../assets/images/Engage+ e-4orce/Interior/Charcoal Leatherette/9.png"),
      img10: require("../assets/images/Engage+ e-4orce/Interior/Charcoal Leatherette/10.png"),
      img11: require("../assets/images/Engage+ e-4orce/Interior/Charcoal Leatherette/11.png"),
      img12: require("../assets/images/Engage+ e-4orce/Interior/Charcoal Leatherette/12.png"),
    },
    LightGrayLeatherette: {
      img1: require("../assets/images/Engage+ e-4orce/Interior/Light Gray Leatherette/1.png"),
      img2: require("../assets/images/Engage+ e-4orce/Interior/Light Gray Leatherette/2.png"),
      img3: require("../assets/images/Engage+ e-4orce/Interior/Light Gray Leatherette/3.png"),
      img4: require("../assets/images/Engage+ e-4orce/Interior/Light Gray Leatherette/4.png"),
      img5: require("../assets/images/Engage+ e-4orce/Interior/Light Gray Leatherette/5.png"),
      img6: require("../assets/images/Engage+ e-4orce/Interior/Light Gray Leatherette/6.png"),
      img7: require("../assets/images/Engage+ e-4orce/Interior/Light Gray Leatherette/7.png"),
      img8: require("../assets/images/Engage+ e-4orce/Interior/Light Gray Leatherette/8.png"),
      img9: require("../assets/images/Engage+ e-4orce/Interior/Light Gray Leatherette/9.png"),
      img10: require("../assets/images/Engage+ e-4orce/Interior/Light Gray Leatherette/10.png"),
      img11: require("../assets/images/Engage+ e-4orce/Interior/Light Gray Leatherette/11.png"),
      img12: require("../assets/images/Engage+ e-4orce/Interior/Light Gray Leatherette/12.png"),
    },
  },
  evolvepe4orce: {
    PassionRed: {
      img1: require("../assets/images/Evolve+ e-4orce/Exterior/Passion Red/1.png"),
      img2: require("../assets/images/Evolve+ e-4orce/Exterior/Passion Red/2.png"),
      img3: require("../assets/images/Evolve+ e-4orce/Exterior/Passion Red/3.png"),
      img4: require("../assets/images/Evolve+ e-4orce/Exterior/Passion Red/4.png"),
      img5: require("../assets/images/Evolve+ e-4orce/Exterior/Passion Red/5.png"),
      img6: require("../assets/images/Evolve+ e-4orce/Exterior/Passion Red/6.png"),
      img7: require("../assets/images/Evolve+ e-4orce/Exterior/Passion Red/7.png"),
      img8: require("../assets/images/Evolve+ e-4orce/Exterior/Passion Red/8.png"),
    },
    BlackPearl: {
      img1: require("../assets/images/Evolve+ e-4orce/Exterior/Black Diamond Pearl/1.png"),
      img2: require("../assets/images/Evolve+ e-4orce/Exterior/Black Diamond Pearl/2.png"),
      img3: require("../assets/images/Evolve+ e-4orce/Exterior/Black Diamond Pearl/3.png"),
      img4: require("../assets/images/Evolve+ e-4orce/Exterior/Black Diamond Pearl/4.png"),
      img5: require("../assets/images/Evolve+ e-4orce/Exterior/Black Diamond Pearl/5.png"),
      img6: require("../assets/images/Evolve+ e-4orce/Exterior/Black Diamond Pearl/6.png"),
      img7: require("../assets/images/Evolve+ e-4orce/Exterior/Black Diamond Pearl/7.png"),
      img8: require("../assets/images/Evolve+ e-4orce/Exterior/Black Diamond Pearl/8.png"),
    },

    GunMetallic: {
      img1: require("../assets/images/Evolve+ e-4orce/Exterior/Gun Metallic/1.png"),
      img2: require("../assets/images/Evolve+ e-4orce/Exterior/Gun Metallic/2.png"),
      img3: require("../assets/images/Evolve+ e-4orce/Exterior/Gun Metallic/3.png"),
      img4: require("../assets/images/Evolve+ e-4orce/Exterior/Gun Metallic/4.png"),
      img5: require("../assets/images/Evolve+ e-4orce/Exterior/Gun Metallic/5.png"),
      img6: require("../assets/images/Evolve+ e-4orce/Exterior/Gun Metallic/6.png"),
      img7: require("../assets/images/Evolve+ e-4orce/Exterior/Gun Metallic/7.png"),
      img8: require("../assets/images/Evolve+ e-4orce/Exterior/Gun Metallic/8.png"),
    },
    EverestWhite: {
      img1: require("../assets/images/Evolve+ e-4orce/Exterior/Everest White Pearl TriCoat/1.png"),
      img2: require("../assets/images/Evolve+ e-4orce/Exterior/Everest White Pearl TriCoat/2.png"),
      img3: require("../assets/images/Evolve+ e-4orce/Exterior/Everest White Pearl TriCoat/3.png"),
      img4: require("../assets/images/Evolve+ e-4orce/Exterior/Everest White Pearl TriCoat/4.png"),
      img5: require("../assets/images/Evolve+ e-4orce/Exterior/Everest White Pearl TriCoat/5.png"),
      img6: require("../assets/images/Evolve+ e-4orce/Exterior/Everest White Pearl TriCoat/6.png"),
      img7: require("../assets/images/Evolve+ e-4orce/Exterior/Everest White Pearl TriCoat/7.png"),
      img8: require("../assets/images/Evolve+ e-4orce/Exterior/Everest White Pearl TriCoat/8.png"),
    },
    DeapOceanBlue: {
      img1: require("../assets/images/Evolve+ e-4orce/Exterior/Deep Ocean Blue Pearl/1.png"),
      img2: require("../assets/images/Evolve+ e-4orce/Exterior/Deep Ocean Blue Pearl/2.png"),
      img3: require("../assets/images/Evolve+ e-4orce/Exterior/Deep Ocean Blue Pearl/3.png"),
      img4: require("../assets/images/Evolve+ e-4orce/Exterior/Deep Ocean Blue Pearl/4.png"),
      img5: require("../assets/images/Evolve+ e-4orce/Exterior/Deep Ocean Blue Pearl/5.png"),
      img6: require("../assets/images/Evolve+ e-4orce/Exterior/Deep Ocean Blue Pearl/6.png"),
      img7: require("../assets/images/Evolve+ e-4orce/Exterior/Deep Ocean Blue Pearl/7.png"),
      img8: require("../assets/images/Evolve+ e-4orce/Exterior/Deep Ocean Blue Pearl/8.png"),
    },
    NorthernLightsMetallic: {
      img1: require("../assets/images/Evolve+ e-4orce/Exterior/Northern Lights Metallic/1.png"),
      img2: require("../assets/images/Evolve+ e-4orce/Exterior/Northern Lights Metallic/2.png"),
      img3: require("../assets/images/Evolve+ e-4orce/Exterior/Northern Lights Metallic/3.png"),
      img4: require("../assets/images/Evolve+ e-4orce/Exterior/Northern Lights Metallic/4.png"),
      img5: require("../assets/images/Evolve+ e-4orce/Exterior/Northern Lights Metallic/5.png"),
      img6: require("../assets/images/Evolve+ e-4orce/Exterior/Northern Lights Metallic/6.png"),
      img7: require("../assets/images/Evolve+ e-4orce/Exterior/Northern Lights Metallic/7.png"),
      img8: require("../assets/images/Evolve+ e-4orce/Exterior/Northern Lights Metallic/8.png"),
    },
    BoulderGray: {
      img1: require("../assets/images/Evolve+ e-4orce/Exterior/Boulder Gray Pearl/1.png"),
      img2: require("../assets/images/Evolve+ e-4orce/Exterior/Boulder Gray Pearl/2.png"),
      img3: require("../assets/images/Evolve+ e-4orce/Exterior/Boulder Gray Pearl/3.png"),
      img4: require("../assets/images/Evolve+ e-4orce/Exterior/Boulder Gray Pearl/4.png"),
      img5: require("../assets/images/Evolve+ e-4orce/Exterior/Boulder Gray Pearl/5.png"),
      img6: require("../assets/images/Evolve+ e-4orce/Exterior/Boulder Gray Pearl/6.png"),
      img7: require("../assets/images/Evolve+ e-4orce/Exterior/Boulder Gray Pearl/7.png"),
      img8: require("../assets/images/Evolve+ e-4orce/Exterior/Boulder Gray Pearl/8.png"),
    },
    CharcoalLeatherette: {
      img1: require("../assets/images/Evolve+ e-4orce/Interior/Charcoal Leatherette/1.png"),
      img2: require("../assets/images/Evolve+ e-4orce/Interior/Charcoal Leatherette/2.png"),
      img3: require("../assets/images/Evolve+ e-4orce/Interior/Charcoal Leatherette/3.png"),
      img4: require("../assets/images/Evolve+ e-4orce/Interior/Charcoal Leatherette/4.png"),
      img5: require("../assets/images/Evolve+ e-4orce/Interior/Charcoal Leatherette/5.png"),
      img6: require("../assets/images/Evolve+ e-4orce/Interior/Charcoal Leatherette/6.png"),
      img7: require("../assets/images/Evolve+ e-4orce/Interior/Charcoal Leatherette/7.png"),
      img8: require("../assets/images/Evolve+ e-4orce/Interior/Charcoal Leatherette/8.png"),
      img9: require("../assets/images/Evolve+ e-4orce/Interior/Charcoal Leatherette/9.png"),
      img10: require("../assets/images/Evolve+ e-4orce/Interior/Charcoal Leatherette/10.png"),
      img11: require("../assets/images/Evolve+ e-4orce/Interior/Charcoal Leatherette/11.png"),
      img12: require("../assets/images/Evolve+ e-4orce/Interior/Charcoal Leatherette/12.png"),
    },
    LightGrayLeatherette: {
      img1: require("../assets/images/Evolve+ e-4orce/Interior/Light Gray Leatherette/1.png"),
      img2: require("../assets/images/Evolve+ e-4orce/Interior/Light Gray Leatherette/2.png"),
      img3: require("../assets/images/Evolve+ e-4orce/Interior/Light Gray Leatherette/3.png"),
      img4: require("../assets/images/Evolve+ e-4orce/Interior/Light Gray Leatherette/4.png"),
      img5: require("../assets/images/Evolve+ e-4orce/Interior/Light Gray Leatherette/5.png"),
      img6: require("../assets/images/Evolve+ e-4orce/Interior/Light Gray Leatherette/6.png"),
      img7: require("../assets/images/Evolve+ e-4orce/Interior/Light Gray Leatherette/7.png"),
      img8: require("../assets/images/Evolve+ e-4orce/Interior/Light Gray Leatherette/8.png"),
      img9: require("../assets/images/Evolve+ e-4orce/Interior/Light Gray Leatherette/9.png"),
      img10: require("../assets/images/Evolve+ e-4orce/Interior/Light Gray Leatherette/10.png"),
      img11: require("../assets/images/Evolve+ e-4orce/Interior/Light Gray Leatherette/11.png"),
      img12: require("../assets/images/Evolve+ e-4orce/Interior/Light Gray Leatherette/12.png"),
    },
  },
  VENTURE: {
    BlackPearl: {
      img1: require("../assets/images/VENTURE/Exterior/Black Diamond Pearl/1.png"),
      img2: require("../assets/images/VENTURE/Exterior/Black Diamond Pearl/2.png"),
      img3: require("../assets/images/VENTURE/Exterior/Black Diamond Pearl/3.png"),
      img4: require("../assets/images/VENTURE/Exterior/Black Diamond Pearl/4.png"),
      img5: require("../assets/images/VENTURE/Exterior/Black Diamond Pearl/5.png"),
      img6: require("../assets/images/VENTURE/Exterior/Black Diamond Pearl/6.png"),
      img7: require("../assets/images/VENTURE/Exterior/Black Diamond Pearl/7.png"),
      img8: require("../assets/images/VENTURE/Exterior/Black Diamond Pearl/8.png"),
    },
    GunMetallic: {
      img1: require("../assets/images/VENTURE/Exterior/Gun Metallic/1.png"),
      img2: require("../assets/images/VENTURE/Exterior/Gun Metallic/2.png"),
      img3: require("../assets/images/VENTURE/Exterior/Gun Metallic/3.png"),
      img4: require("../assets/images/VENTURE/Exterior/Gun Metallic/4.png"),
      img5: require("../assets/images/VENTURE/Exterior/Gun Metallic/5.png"),
      img6: require("../assets/images/VENTURE/Exterior/Gun Metallic/6.png"),
      img7: require("../assets/images/VENTURE/Exterior/Gun Metallic/7.png"),
      img8: require("../assets/images/VENTURE/Exterior/Gun Metallic/8.png"),
    },
    EverestWhite: {
      img1: require("../assets/images/VENTURE/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/1.png"),
      img2: require("../assets/images/VENTURE/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/2.png"),
      img3: require("../assets/images/VENTURE/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/3.png"),
      img4: require("../assets/images/VENTURE/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/4.png"),
      img5: require("../assets/images/VENTURE/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/5.png"),
      img6: require("../assets/images/VENTURE/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/6.png"),
      img7: require("../assets/images/VENTURE/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/7.png"),
      img8: require("../assets/images/VENTURE/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/8.png"),
    },
    DeapOceanBlue: {
      img1: require("../assets/images/VENTURE/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/1.png"),
      img2: require("../assets/images/VENTURE/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/2.png"),
      img3: require("../assets/images/VENTURE/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/3.png"),
      img4: require("../assets/images/VENTURE/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/4.png"),
      img5: require("../assets/images/VENTURE/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/5.png"),
      img6: require("../assets/images/VENTURE/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/6.png"),
      img7: require("../assets/images/VENTURE/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/7.png"),
      img8: require("../assets/images/VENTURE/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/8.png"),
    },
    Silver: {
      img1: require("../assets/images/VENTURE/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/1.png"),
      img2: require("../assets/images/VENTURE/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/2.png"),
      img3: require("../assets/images/VENTURE/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/3.png"),
      img4: require("../assets/images/VENTURE/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/4.png"),
      img5: require("../assets/images/VENTURE/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/5.png"),
      img6: require("../assets/images/VENTURE/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/6.png"),
      img7: require("../assets/images/VENTURE/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/7.png"),
      img8: require("../assets/images/VENTURE/Exterior/Brilliant Silver Metallic - Black Diamond Pearl/8.png"),
    },
    BoulderGray: {
      img1: require("../assets/images/VENTURE/Exterior/Boulder Gray Pearl - Black Diamond Pearl/1.png"),
      img2: require("../assets/images/VENTURE/Exterior/Boulder Gray Pearl - Black Diamond Pearl/2.png"),
      img3: require("../assets/images/VENTURE/Exterior/Boulder Gray Pearl - Black Diamond Pearl/3.png"),
      img4: require("../assets/images/VENTURE/Exterior/Boulder Gray Pearl - Black Diamond Pearl/4.png"),
      img5: require("../assets/images/VENTURE/Exterior/Boulder Gray Pearl - Black Diamond Pearl/5.png"),
      img6: require("../assets/images/VENTURE/Exterior/Boulder Gray Pearl - Black Diamond Pearl/6.png"),
      img7: require("../assets/images/VENTURE/Exterior/Boulder Gray Pearl - Black Diamond Pearl/7.png"),
      img8: require("../assets/images/VENTURE/Exterior/Boulder Gray Pearl - Black Diamond Pearl/1.png"),
    },
    CharcoalLeatherette: {
      img1: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/1.png"),
      img2: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/2.png"),
      img3: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/3.png"),
      img4: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/4.png"),
      img5: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/5.png"),
      img6: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/6.png"),
      img7: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/7.png"),
      img8: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/8.png"),
      img9: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/9.png"),
      img10: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/10.png"),
      img11: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/11.png"),
      img12: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/12.png"),
    },
    LightGrayLeatherette: {
      img1: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/1.png"),
      img2: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/2.png"),
      img3: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/3.png"),
      img4: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/4.png"),
      img5: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/5.png"),
      img6: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/6.png"),
      img7: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/7.png"),
      img8: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/8.png"),
      img9: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/9.png"),
      img10: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/10.png"),
      img11: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/11.png"),
      img12: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/12.png"),
    },
  },
  evolve: {
    BlackPearl: {
      img1: require("../assets/images/EVOLVE+ FDW/Exterior/Black Diamond Pearl/1.png"),
      img2: require("../assets/images/EVOLVE+ FDW/Exterior/Black Diamond Pearl/2.png"),
      img3: require("../assets/images/EVOLVE+ FDW/Exterior/Black Diamond Pearl/3.png"),
      img4: require("../assets/images/EVOLVE+ FDW/Exterior/Black Diamond Pearl/4.png"),
      img5: require("../assets/images/EVOLVE+ FDW/Exterior/Black Diamond Pearl/5.png"),
      img6: require("../assets/images/EVOLVE+ FDW/Exterior/Black Diamond Pearl/6.png"),
      img7: require("../assets/images/EVOLVE+ FDW/Exterior/Black Diamond Pearl/7.png"),
      img8: require("../assets/images/EVOLVE+ FDW/Exterior/Black Diamond Pearl/8.png"),
    },
    GunMetallic: {
      img1: require("../assets/images/EVOLVE+ FDW/Exterior/Gun Metallic/1.png"),
      img2: require("../assets/images/EVOLVE+ FDW/Exterior/Gun Metallic/2.png"),
      img3: require("../assets/images/EVOLVE+ FDW/Exterior/Gun Metallic/3.png"),
      img4: require("../assets/images/EVOLVE+ FDW/Exterior/Gun Metallic/4.png"),
      img5: require("../assets/images/EVOLVE+ FDW/Exterior/Gun Metallic/5.png"),
      img6: require("../assets/images/EVOLVE+ FDW/Exterior/Gun Metallic/6.png"),
      img7: require("../assets/images/EVOLVE+ FDW/Exterior/Gun Metallic/7.png"),
      img8: require("../assets/images/EVOLVE+ FDW/Exterior/Gun Metallic/8.png"),
    },
    EverestWhite: {
      img1: require("../assets/images/EVOLVE+ FDW/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/1.png"),
      img2: require("../assets/images/EVOLVE+ FDW/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/2.png"),
      img3: require("../assets/images/EVOLVE+ FDW/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/3.png"),
      img4: require("../assets/images/EVOLVE+ FDW/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/4.png"),
      img5: require("../assets/images/EVOLVE+ FDW/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/5.png"),
      img6: require("../assets/images/EVOLVE+ FDW/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/6.png"),
      img7: require("../assets/images/EVOLVE+ FDW/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/7.png"),
      img8: require("../assets/images/EVOLVE+ FDW/Exterior/Everest White Pearl TriCoat - Black Diamond Pearl/8.png"),
    },
    DeapOceanBlue: {
      img1: require("../assets/images/EVOLVE+ FDW/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/1.png"),
      img2: require("../assets/images/EVOLVE+ FDW/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/2.png"),
      img3: require("../assets/images/EVOLVE+ FDW/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/3.png"),
      img4: require("../assets/images/EVOLVE+ FDW/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/4.png"),
      img5: require("../assets/images/EVOLVE+ FDW/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/5.png"),
      img6: require("../assets/images/EVOLVE+ FDW/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/6.png"),
      img7: require("../assets/images/EVOLVE+ FDW/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/7.png"),
      img8: require("../assets/images/EVOLVE+ FDW/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/8.png"),
    },
    NorthernLightsMetallic: {
      img1: require("../assets/images/EVOLVE+ FDW/Exterior/Northern Lights Metallic/1.png"),
      img2: require("../assets/images/EVOLVE+ FDW/Exterior/Northern Lights Metallic/2.png"),
      img3: require("../assets/images/EVOLVE+ FDW/Exterior/Northern Lights Metallic/3.png"),
      img4: require("../assets/images/EVOLVE+ FDW/Exterior/Northern Lights Metallic/4.png"),
      img5: require("../assets/images/EVOLVE+ FDW/Exterior/Northern Lights Metallic/5.png"),
      img6: require("../assets/images/EVOLVE+ FDW/Exterior/Northern Lights Metallic/6.png"),
      img7: require("../assets/images/EVOLVE+ FDW/Exterior/Northern Lights Metallic/7.png"),
      img8: require("../assets/images/EVOLVE+ FDW/Exterior/Northern Lights Metallic/8.png"),
    },
    BoulderGray: {
      img1: require("../assets/images/EVOLVE+ FDW/Exterior/Boulder Gray Pearl - Black Diamond Pearl/1.png"),
      img2: require("../assets/images/EVOLVE+ FDW/Exterior/Boulder Gray Pearl - Black Diamond Pearl/2.png"),
      img3: require("../assets/images/EVOLVE+ FDW/Exterior/Boulder Gray Pearl - Black Diamond Pearl/3.png"),
      img4: require("../assets/images/EVOLVE+ FDW/Exterior/Boulder Gray Pearl - Black Diamond Pearl/4.png"),
      img5: require("../assets/images/EVOLVE+ FDW/Exterior/Boulder Gray Pearl - Black Diamond Pearl/5.png"),
      img6: require("../assets/images/EVOLVE+ FDW/Exterior/Boulder Gray Pearl - Black Diamond Pearl/6.png"),
      img7: require("../assets/images/EVOLVE+ FDW/Exterior/Boulder Gray Pearl - Black Diamond Pearl/7.png"),
      img8: require("../assets/images/EVOLVE+ FDW/Exterior/Boulder Gray Pearl - Black Diamond Pearl/1.png"),
    },
    PassionRed: {
      img1: require("../assets/images/EVOLVE+ FDW/Exterior/Passion Red -  Black Diamond Pearl/1.png"),
      img2: require("../assets/images/EVOLVE+ FDW/Exterior/Passion Red -  Black Diamond Pearl/2.png"),
      img3: require("../assets/images/EVOLVE+ FDW/Exterior/Passion Red -  Black Diamond Pearl/3.png"),
      img4: require("../assets/images/EVOLVE+ FDW/Exterior/Passion Red -  Black Diamond Pearl/4.png"),
      img5: require("../assets/images/EVOLVE+ FDW/Exterior/Passion Red -  Black Diamond Pearl/5.png"),
      img6: require("../assets/images/EVOLVE+ FDW/Exterior/Passion Red -  Black Diamond Pearl/6.png"),
      img7: require("../assets/images/EVOLVE+ FDW/Exterior/Passion Red -  Black Diamond Pearl/7.png"),
      img8: require("../assets/images/EVOLVE+ FDW/Exterior/Passion Red -  Black Diamond Pearl/8.png"),
    },
    CharcoalLeatherette: {
      img1: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/1.png"),
      img2: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/2.png"),
      img3: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/3.png"),
      img4: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/4.png"),
      img5: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/5.png"),
      img6: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/6.png"),
      img7: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/7.png"),
      img8: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/8.png"),
      img9: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/9.png"),
      img10: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/10.png"),
      img11: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/11.png"),
      img12: require("../assets/images/VENTURE/Interior/Charcoal Leatherette/12.png"),
    },
    LightGrayLeatherette: {
      img1: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/1.png"),
      img2: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/2.png"),
      img3: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/3.png"),
      img4: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/4.png"),
      img5: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/5.png"),
      img6: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/6.png"),
      img7: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/7.png"),
      img8: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/8.png"),
      img9: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/9.png"),
      img10: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/10.png"),
      img11: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/11.png"),
      img12: require("../assets/images/VENTURE/Interior/Light Gray Leatherette/12.png"),
    },
  },
};
