import "./App.css";
import GlobalContextProvider from "./GlobalContext";
import ScrollToTop from "./Ariya/components/ScrollTop";
import { Navigate, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Login from "./Ariya/pages/auth/Login";
import LandingPage from "./Ariya/pages/LandingPage";
import UserRegister from "./Ariya/pages/auth/Register/UserRegister";
import DealerRegister from "./Ariya/pages/auth/Register/DealerRegister";
import RegisterAddress from "./Ariya/pages/auth/Register/RegisterAddress";
import TitularRegister from "./Ariya/pages/auth/Register/TitularRegister";
import Intro from "./Ariya/pages/auth/Intro";
import Variants from "./Ariya/pages/Variants";
import ForgotPassword from "./Ariya/pages/auth/ForgotPassword";
import Gallery from "./Ariya/pages/Gallery";
import PrivacyPolicy from "./Ariya/pages/policy/PrivacyPolicy";
import PurchasePolicy from "./Ariya/pages/policy/PurchasePolicy";
import Terms from "./Ariya/pages/policy/Terms";
import Dealers from "./Ariya/pages/Dealers";
import ContactUs from "./Common/Pages/ContactUs";
import SplashScreen from "./Ariya/pages/SplashScreen";
import ModalMain from "./Ariya/pages/Modelos/Main";
import Profile from "./Ariya/pages/Profile";
import MyProfile from "./Ariya/pages/Profile/MyProfile";
import FAQ from "./Ariya/pages/FAQ";
import ChangePassword from "./Ariya/pages/Profile/ChangePassword";
import OrderHistory from "./Ariya/pages/Profile/OrderHistory";
import Compare from "./Ariya/pages/Compare";
import Contrato from "./Ariya/pages/Contrato/Contrato";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

// leaf
import LeafLandingPage from "./Leaf/page/LandingPage";
import LeafVariants from "./Leaf/page/Variants";
import LeafFAQ from "./Leaf/page/FAQ";
import LeafDealers from "./Leaf/page/Dealers";
import LeafTerms from "./Leaf/page/policy/Terms";
import LeafPolicy from "./Leaf/page/policy/PrivacyPolicy";
import LeafGaleria from "./Leaf/page/Gallery";
import Leafmodel from "./Leaf/page/Modelos/Main";
import LeafLogin from "./Leaf/page/auth/Login";
import LeafUserRegister from "./Leaf/page/auth/Register/UserRegister";
import LeafDealerRegister from "./Leaf/page/auth/Register/DealerRegister";
import LeafRegisterAddress from "./Leaf/page/auth/Register/RegisterAddress";
import LeafTitularRegister from "./Leaf/page/auth/Register/TitularRegister";
import LeafForgotPassword from "./Leaf/page/auth/ForgotPassword";
import SpecsScreen from "./Ariya/pages/Specifications";
import MyCard from "./Ariya/pages/Profile/MyCards";
import ReviewAndPay from "./Ariya/pages/Review & pay";
import ConfirmOrder from "./Ariya/pages/ConfirmOrder";
import LeafCompare from "./Leaf/page/Compare";
// import LeafContactUs from "./Leaf/page/ContactUs";
import Cart from "./Common/Pages/Cart";
// import Test from "./Test";n
import PaymentMethod from "./Ariya/pages/auth/Register/PaymentRegister";
import CanceledOrdersPage from "./Ariya/pages/Profile/Cancelorder";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddNewCard from "./Ariya/pages/AddNewCard";
import Interest from "./Ariya/pages/Interest";
import ThankYou from "./Ariya/pages/Thank you";
import Deposit from "./Ariya/pages/Deposit";

// ? <==================(=(= Modal's =)=)==================>

import {
  Canclemodal1,
  CardIssue,
  Policy,
  Trems,
} from "./Common/components/UI/CancleModals/Canclemodals";
import { Canclemodal2 } from "./Common/components/UI/CancleModals/Canclemodals";
import { Canclemodal3 } from "./Common/components/UI/CancleModals/Canclemodals";
import { Canclemodal4 } from "./Common/components/UI/CancleModals/Canclemodals";
import { Canclemodal5 } from "./Common/components/UI/CancleModals/Canclemodals";
import { Loginmodel } from "./Common/components/UI/CancleModals/Canclemodals";
import { Canclemodelinterest } from "./Common/components/UI/CancleModals/Canclemodals";
import { Thankyouconfirmationmodal } from "./Common/components/UI/CancleModals/Canclemodals";
import { Sorrymodal } from "./Common/components/UI/CancleModals/Canclemodals";

// ? <==================(=(= Modal's =)=)==================>

function App() {
  ReactGA.initialize("GTM-N49BJWQK");
  // ReactGA.initialize("GTM-T3NTLMJT");
  const StripePublicKey = process.env.REACT_APP_STRIP_KEY;
  const stripePromise = loadStripe(StripePublicKey);
  return (
    <>
      <GlobalContextProvider>
        <Helmet>
          <title>NISSAN</title>
        </Helmet>
        <ScrollToTop />
        <Toaster />
        <Canclemodal1 />
        <Canclemodal2 />
        <Canclemodal3 />
        <Canclemodal4 />
        <Canclemodal5 />
        <Loginmodel />
        <Canclemodelinterest />
        <Thankyouconfirmationmodal />
        <Sorrymodal />
        <CardIssue />
        <Trems />
        <Policy />

        <Routes>
          <Route path="/" element={<Navigate replace to="/Inicio" />} />
          {/* <Route path="/" element={<SplashScreen />} /> */}
          <Route path="/Inicio" element={<LandingPage />} />
          <Route path="/Variantes" element={<Variants />} />
          <Route path="/Concesionarios-autorizados" element={<Dealers />} />
          <Route path="/Galeria" element={<Gallery />} />
          <Route path="/Comparaciones" element={<Compare />} />
          <Route path="/Carrito" element={<Cart />} />

          {/* Auth */}
          <Route path="/Intro" element={<Intro />} />
          <Route path="/Iniciar-Sesion" element={<Login />} />
          <Route path="/Registrate" element={<UserRegister />} />
          <Route path="/RegistrateDealer" element={<DealerRegister />} />
          <Route path="/RegistrateAddress" element={<RegisterAddress />} />
          <Route path="/RegistratePayment" element={<PaymentMethod />} />
          <Route path="/Titular" element={<TitularRegister />} />
          <Route path="/Olvidaste-contrasena" element={<ForgotPassword />} />
          <Route path="/Contrato" element={<Contrato />} />

          {/* Specs Routes */}
          <Route
            path="/Especificaciones"
            element={<Navigate replace to="/Especificaciones/EngageFWD" />}
          />
          <Route
            path="/Especificaciones/Engage/FWD"
            element={<SpecsScreen />}
          />
          <Route
            path="/Especificaciones/Engage/E-4FORCE"
            element={<SpecsScreen />}
          />
          <Route
            path="/Especificaciones/Engage+/E-4FORCE"
            element={<SpecsScreen />}
          />
          <Route
            path="/Especificaciones/Engage/Venture+"
            element={<SpecsScreen />}
          />
          <Route
            path="/Especificaciones/Evolve+/FWD"
            element={<SpecsScreen />}
          />
          <Route
            path="/Especificaciones/Evolve+/e-4FORCE"
            element={<SpecsScreen />}
          />

          {/* ------------------- Leaf Specs ------------------ */}

          <Route path="/Especificaciones/LEAF-S" element={<SpecsScreen />} />
          <Route
            path="/Especificaciones/LEAF-SV-Pus"
            element={<SpecsScreen />}
          />

          {/* ------------------------------------------------- */}

          <Route path="/ContinuePayment" element={<ReviewAndPay />} />
          <Route path="/Order-Detail" element={<ConfirmOrder />} />
          <Route path="/Interest" element={<Interest />} />
          <Route path="/Thankyou" element={<ThankYou />} />
          <Route path="/Deposit" element={<Deposit />} />

          {/* profile */}
          <Route path="/Perfil" element={<Profile />}>
            <Route
              path="/Perfil/"
              element={<Navigate replace to="/Perfil/MyPerfil" />}
            />
            <Route path="/Perfil/MyPerfil" element={<MyProfile />} />
            <Route path="/Perfil/ChnagePassword" element={<ChangePassword />} />
            <Route path="/Perfil/History" element={<OrderHistory />} />
            <Route
              path="/Perfil/CanceledOrders"
              element={<CanceledOrdersPage />}
            />
            <Route path="/Perfil/MyCards" element={<MyCard />} />
          </Route>

          {/* Policy Routes */}
          <Route path="/Politica-Privacidad" element={<PrivacyPolicy />} />
          <Route
            path="/Compra-y-Devolucion-Politica"
            element={<PurchasePolicy />}
          />
          <Route path="/Terminos-y-Condiciones" element={<Terms />} />

          <Route path="/Preguntas-frecuentes" element={<FAQ />} />

          <Route path="/Contactanos" element={<ContactUs />} />

          {/* Leaf */}
          <Route path="/Leaf/Inicio" element={<LeafLandingPage />} />
          <Route path="/Leaf/Variantes" element={<LeafVariants />} />
          <Route path="/Leaf/Preguntas-frecuentes" element={<LeafFAQ />} />
          <Route
            path="/Leaf/Concesionarios-autorizados"
            element={<LeafDealers />}
          />

          <Route path="/Leaf/Terminos-y-Condiciones" element={<LeafTerms />} />
          <Route path="/Leaf/Politica-Privacidad" element={<LeafPolicy />} />
          <Route path="/LeafGaleria" element={<LeafGaleria />} />
          <Route path="/Leaf/Comparaciones" element={<LeafCompare />} />
          {/* <Route path="/Leaf/Contactanos" element={<LeafContactUs />} /> */}

          {/* Leaf auth */}
          {/* <Route path="/Leaf/Iniciar-Sesion" element={<LeafLogin />} />
        <Route path="/Leaf/Registrate" element={<LeafUserRegister />} />
        <Route
          path="/Leaf/RegistrateAddress"
          element={<LeafRegisterAddress />}
        />
        <Route path="/Leaf/UserRegister" element={<LeafUserRegister />} />
        <Route path="/Leaf/RegistrateDealer" element={<LeafDealerRegister />} />
        <Route path="/Leaf/Titular" element={<LeafTitularRegister />} />
        <Route
          path="/Leaf/Olvidaste-contrasena"
          element={<LeafForgotPassword />}
        /> */}

          {/* Model Routes */}
          <Route path="/Model" element={<ModalMain />} />
          <Route path="/Leaf/Model" element={<Leafmodel />} />

          <Route
            path="/AddnewCard"
            element={
              <Elements stripe={stripePromise}>
                <AddNewCard />
              </Elements>
            }
          />

          {/* <Route path="/Test" element={<Test />} /> */}
        </Routes>
      </GlobalContextProvider>
    </>
  );
}

export default App;
// ReactGA.initialize("G-WXZ8H4M7EM");
// ReactGA.initialize("GTM-N49BJWQK");
