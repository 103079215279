import React, { useEffect, useState } from "react";
import "./interest.css";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../Common/components/UI/Button";
import { AllImages } from "../../constants/images";
import logo from "../../assets/images/NissanBlackLogo.png";

import { Modal } from "react-bootstrap";
import axios from "../../../Common/Api";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Ariya_EngageFWD,
  Ariya_Engage_e_4orce,
  Ariya_Engage__e_4orce,
  VENTURE,
  EVOLVE,
  evolvepe4orce,
} from "../../constants/Data";
import { LEAFS, LeafSVPlus } from "../../../Leaf/constants/Data";
import ReactGA from "react-ga4";
import Loader from "../../components/UI/Loader";

const Intrest = () => {
  const location = useLocation();
  const Navigate = useNavigate();

  const [OrderHistory, setOrderHistory] = useState([]);

  // Get User Data
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const StaticCartData = JSON.parse(localStorage.getItem("StaticCartData"));
  console.log("🚀 ~ Intrest ~ StaticCartData:", StaticCartData);
  // const UserData = JSON.parse(localStorage.getItem("user-info"));
  // Store Api Data in useState Variable
  const [CartData, setCartData] = useState([]);
  // console.log("🚀 ~ Intrest ~ CartData:", CartData);

  // Loader useState Variable
  const [Loading, setLoading] = useState(true);

  // Address add Modal useState Variable
  const [ModalShow, setModalShow] = useState(false);

  // User Profile Data useState Variable
  const [UserProfileData, setUserProfileData] = useState({});

  const AddtoCart = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "/add-to-cart",
        {
          ExtColorName: StaticCartData.extColorName,
          IntColorName: StaticCartData.intColorName,
          ExtColorCode: StaticCartData.extColor,
          IntColorCode: StaticCartData.intColor,
          Price: StaticCartData.price,
          Model: StaticCartData.ModelName,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        }
      );
      console.log("🚀 AddtoCart ~ response:", response);
      if (response) {
        // setLoading(false);
        if (response.data.status == "success") {
          localStorage.removeItem("StaticCartData");
          getCartData();
        }
      }
    } catch (error) {
      console.log(error);
      getCartData();
      // setLoading(false);
    }
  };

  const getUserProfile = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/get-user-profile", {
        headers: { Authorization: `Bearer ${UserData?.Token}` },
      });
      console.log("🚀  getUserProfile  res:", res);
      setUserProfileData(res.data);
      AddtoCart();
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("user-info")) {
      Navigate("/Modelos");
    } else {
      getUserProfile();
    }
  }, []);

  const getCartData = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/get-cart", {
        headers: {
          Authorization: `Bearer ${UserData.Token}`,
        },
      });
      console.log("🚀 getCartData ~ res:", res);
      if (res) {
        setCartData(res.data);
        setOrderHistory(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  var nf = new Intl.NumberFormat();
  const Price = 999999;

  let Pathnamee = useLocation();
  console.log(CartData, "cartdata......");

  const intresteed = () => {
    const evenatdata = {
      event: "reserva_exito",
      user_id: UserData?.id ? UserData?.id : " ",
      modelo: CartData?.data?.Model,
      price: CartData?.data?.Price,
      color: CartData?.data?.Ext_Color_Name,
      opcon: "¡Gracias!",
      action: "CONTINUAR",
    };
    ReactGA.event({
      event: evenatdata.event,
      action: evenatdata.action,
      event_params: {
        modelo: evenatdata.modelo,
        user_id: evenatdata.user_id,
        option: evenatdata.opcon,
        color: evenatdata.color,
        Price: evenatdata.price,
      },
    });
    window.dataLayer.push({
      event: evenatdata.event,
      user_id: evenatdata.user_id,
      modelo: evenatdata.modelo,
      price: evenatdata.price,
      color: evenatdata.color,
      opcon: evenatdata.opcon,
      action: evenatdata.action,
    });
    Navigate("/Thankyou", {
      state: { CartData },
    });
  };
  const Especificacioness = () => {
    ReactGA.event({
      category: "Intrest",
      action: "Intrest_especificacion",
    });
    return (
      <>
        {CartData.data.Model == "ENGAGE FWD"
          ? Navigate("/Especificaciones/Engage/FWD", {
              state: {
                Pathnamee,
              },
            })
          : CartData.data.Model == "ENGAGE e-4ORCE"
          ? Navigate("/Especificaciones/Engage/E-4FORCE", {
              state: {
                Pathnamee,
              },
            })
          : CartData.data.Model == "ENGAGE+ e-4ORCE"
          ? Navigate("/Especificaciones/Engage+/E-4FORCE", {
              state: {
                Pathnamee,
              },
            })
          : CartData.data.Model == "VENTURE+"
          ? Navigate("/Especificaciones/Engage/Venture+", {
              state: {
                Pathnamee,
              },
            })
          : CartData.data.Model == "EVOLVE+ FWD"
          ? Navigate("/Especificaciones/Evolve+/FWD", {
              state: {
                Pathnamee,
              },
            })
          : CartData.data.Model == "EVOLVE+ e-4ORCE"
          ? Navigate("/Especificaciones/Evolve+/e-4FORCE", {
              state: {
                Pathnamee,
              },
            })
          : CartData.data.Model == "LEAF-S"
          ? Navigate("/Especificaciones/LEAF-S", {
              state: {
                Pathnamee,
              },
            })
          : CartData.data.Model == "LEAF-SV-Pus"
          ? Navigate("/Especificaciones/LEAF-SV-Pus", {
              state: {
                Pathnamee,
              },
            })
          : null}
      </>
    );
  };

  return (
    <>
      <div className="white Main-Sec-ContinuePayment">
        {Loading && (
          <div className="loder_div">
            {/* <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            onClick={handleClose}
            > */}
            {/* <CircularProgress color="inherit" /> */}
            {/* </Backdrop> */}
            <Loader />
          </div>
        )}
        {!Loading &&
          CartData.status == "success" &&
          CartData.message == "Record" && (
            <>
              <div
                className="pt-5 pb-5"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <img
                  src={logo}
                  style={{ cursor: "pointer", width: "30%" }}
                  onClick={() => Navigate("/")}
                />
              </div>
              <div className="head-ContinuePay">Vehículo de interés</div>
              <div className="ContinuePay-body">
                <div className="ContinuePay-body-image  d-flex justify-content-center align-items-center w-100">
                  <img
                    src={
                      CartData?.data?.Model == "ENGAGE FWD"
                        ? Ariya_EngageFWD.exterior[CartData.data.Ext_Color_Code]
                            .images[0]
                        : CartData.data.Model == "ENGAGE e-4ORCE"
                        ? Ariya_Engage_e_4orce.exterior[
                            CartData.data.Ext_Color_Code
                          ].images[0]
                        : CartData.data.Model == "ENGAGE+ e-4ORCE"
                        ? Ariya_Engage__e_4orce.exterior[
                            CartData.data.Ext_Color_Code
                          ].images[0]
                        : CartData.data.Model == "VENTURE+"
                        ? VENTURE.exterior[CartData.data.Ext_Color_Code]
                            .images[0]
                        : CartData.data.Model == "EVOLVE+ FWD"
                        ? EVOLVE.exterior[CartData.data.Ext_Color_Code]
                            .images[0]
                        : CartData.data.Model == "EVOLVE+ e-4ORCE"
                        ? evolvepe4orce.exterior[CartData.data.Ext_Color_Code]
                            .images[0]
                        : CartData.data.Model == "LEAF S"
                        ? LEAFS.exterior[CartData.data.Ext_Color_Code].images[0]
                        : CartData.data.Model == "LEAF SV Plus"
                        ? LeafSVPlus.exterior[CartData.data.Ext_Color_Code]
                            .images[0]
                        : null
                    }
                    width="90%"
                  />
                </div>
                <div className="ContinuePay-body-content">
                  <div className="ModelName-Price-div">
                    <span>
                      Model {CartData && CartData.data.Model}{" "}
                      {CartData && CartData.data.Mng_Year}
                    </span>
                  </div>
                  <div className="Purchase-price-specs-div">
                    <span className="price-txt">
                      <span className="dollor-txt">MSRP: $</span>{" "}
                      {nf.format(parseInt(CartData && CartData.data.Price))}
                    </span>
                    <div className="specs-div">
                      <span>
                        Color exterior:{" "}
                        {CartData && CartData.data.Ext_Color_Name}
                      </span>
                      <span>
                        Color interior:{" "}
                        {CartData && CartData.data.Int_Color_Name}
                      </span>
                      {/* <span>
                        <span className="dollor-txt">Depósito: $</span>
                        500
                      </span> */}
                      <div className="pb-3"></div>
                      <div className="additionalSpecs">
                        <button
                          className="additionalSpecs-btn"
                          type="button"
                          onClick={Especificacioness}
                          // onClick={() => {
                          //   return (
                          //     <>
                          //       {CartData.data.Model == "ENGAGE FWD"
                          //         ? Navigate("/Especificaciones/Engage/FWD", {
                          //             state: {
                          //               Pathnamee,
                          //             },
                          //           })
                          //         : CartData.data.Model == "ENGAGE e-4ORCE"
                          //         ? Navigate(
                          //             "/Especificaciones/Engage/E-4FORCE",
                          //             {
                          //               state: {
                          //                 Pathnamee,
                          //               },
                          //             }
                          //           )
                          //         : CartData.data.Model == "ENGAGE+ e-4ORCE"
                          //         ? Navigate(
                          //             "/Especificaciones/Engage+/E-4FORCE",
                          //             {
                          //               state: {
                          //                 Pathnamee,
                          //               },
                          //             }
                          //           )
                          //         : CartData.data.Model == "VENTURE+"
                          //         ? Navigate(
                          //             "/Especificaciones/Engage/Venture+",
                          //             {
                          //               state: {
                          //                 Pathnamee,
                          //               },
                          //             }
                          //           )
                          //         : CartData.data.Model == "EVOLVE+ FWD"
                          //         ? Navigate("/Especificaciones/Evolve+/FWD", {
                          //             state: {
                          //               Pathnamee,
                          //             },
                          //           })
                          //         : CartData.data.Model == "EVOLVE+ e-4ORCE"
                          //         ? Navigate(
                          //             "/Especificaciones/Evolve+/e-4FORCE",
                          //             {
                          //               state: {
                          //                 Pathnamee,
                          //               },
                          //             }
                          //           )
                          //         : CartData.data.Model == "LEAF-S"
                          //         ? Navigate("/Especificaciones/LEAF-S", {
                          //             state: {
                          //               Pathnamee,
                          //             },
                          //           })
                          //         : CartData.data.Model == "LEAF-SV-Pus"
                          //         ? Navigate("/Especificaciones/LEAF-SV-Pus", {
                          //             state: {
                          //               Pathnamee,
                          //             },
                          //           })
                          //         : null}
                          //     </>
                          //   );
                          // }}
                        >
                          Especificaciones adicionales
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="red_btn_Rpay w-100">
                    <Button
                      variant={"red"}
                      onClick={intresteed}
                      // onClick={
                      //   UserProfileData.data.AddressLine1 == null
                      //     ? () => setModalShow(true)
                      //     : () => Navigate("/Contrato")
                      // }
                    >
                      <span className="btn-space">SOMETER INFORMACIÓN</span>
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default Intrest;
