export const AllImages = {
  Leafs: {
    DeepBluePearl: {
      img1: require("../assets/images/S/Exterior/Deep Blue Pearl/1.png"),
      img2: require("../assets/images/S/Exterior/Deep Blue Pearl/2.png"),
      img3: require("../assets/images/S/Exterior/Deep Blue Pearl/3.png"),
      img4: require("../assets/images/S/Exterior/Deep Blue Pearl/4.png"),
      img5: require("../assets/images/S/Exterior/Deep Blue Pearl/5.png"),
      img6: require("../assets/images/S/Exterior/Deep Blue Pearl/6.png"),
      img7: require("../assets/images/S/Exterior/Deep Blue Pearl/7.png"),
      img8: require("../assets/images/S/Exterior/Deep Blue Pearl/8.png"),
    },
    GunMetallic: {
      img1: require("../assets/images/S/Exterior/Gun Metallic/1.png"),
      img2: require("../assets/images/S/Exterior/Gun Metallic/2.png"),
      img3: require("../assets/images/S/Exterior/Gun Metallic/3.png"),
      img4: require("../assets/images/S/Exterior/Gun Metallic/4.png"),
      img5: require("../assets/images/S/Exterior/Gun Metallic/5.png"),
      img6: require("../assets/images/S/Exterior/Gun Metallic/6.png"),
      img7: require("../assets/images/S/Exterior/Gun Metallic/7.png"),
      img8: require("../assets/images/S/Exterior/Gun Metallic/8.png"),
    },
    GlacierWhite: {
      img1: require("../assets/images/S/Exterior/Glacier White/1.png"),
      img2: require("../assets/images/S/Exterior/Glacier White/2.png"),
      img3: require("../assets/images/S/Exterior/Glacier White/3.png"),
      img4: require("../assets/images/S/Exterior/Glacier White/4.png"),
      img5: require("../assets/images/S/Exterior/Glacier White/5.png"),
      img6: require("../assets/images/S/Exterior/Glacier White/6.png"),
      img7: require("../assets/images/S/Exterior/Glacier White/7.png"),
      // img8: require("../assets/images/S/Exterior/Glacier White/8.png"),
    },
    Silver: {
      img1: require("../assets/images/S/Exterior/Brilliant_Silver/1.png"),
      img2: require("../assets/images/S/Exterior/Brilliant_Silver/2.png"),
      img3: require("../assets/images/S/Exterior/Brilliant_Silver/3.png"),
      img4: require("../assets/images/S/Exterior/Brilliant_Silver/4.png"),
      img5: require("../assets/images/S/Exterior/Brilliant_Silver/5.png"),
      img6: require("../assets/images/S/Exterior/Brilliant_Silver/6.png"),
      img7: require("../assets/images/S/Exterior/Brilliant_Silver/7.png"),
      img8: require("../assets/images/S/Exterior/Brilliant_Silver/8.png"),
    },
    SuperBlack: {
      img1: require("../assets/images/S/Exterior/Super Black/1.png"),
      img2: require("../assets/images/S/Exterior/Super Black/2.png"),
      img3: require("../assets/images/S/Exterior/Super Black/3.png"),
      img4: require("../assets/images/S/Exterior/Super Black/4.png"),
      img5: require("../assets/images/S/Exterior/Super Black/5.png"),
      img6: require("../assets/images/S/Exterior/Super Black/6.png"),
      img7: require("../assets/images/S/Exterior/Super Black/7.png"),
      img8: require("../assets/images/S/Exterior/Super Black/8.png"),
    },

    Telanegra: {
      img1: require("../assets/images/S/Interior/Tela negra/1.png"),
      img2: require("../assets/images/S/Interior/Tela negra/2.png"),
      img3: require("../assets/images/S/Interior/Tela negra/3.png"),
      img4: require("../assets/images/S/Interior/Tela negra/4.png"),
      img5: require("../assets/images/S/Interior/Tela negra/5.png"),
      img6: require("../assets/images/S/Interior/Tela negra/6.png"),
      img7: require("../assets/images/S/Interior/Tela negra/7.png"),
      img8: require("../assets/images/S/Interior/Tela negra/8.png"),
      img9: require("../assets/images/S/Interior/Tela negra/9.png"),
      img10: require("../assets/images/S/Interior/Tela negra/10.png"),
      img11: require("../assets/images/S/Interior/Tela negra/11.png"),
      img12: require("../assets/images/S/Interior/Tela negra/12.png"),
      img13: require("../assets/images/S/Interior/Tela negra/13.png"),
      img14: require("../assets/images/S/Interior/Tela negra/14.png"),
    },
  },
  LeafSVPlus: {
    DeepBluePearl: {
      img1: require("../assets/images/SV Plus/Exterior/Deep Blue Pearl/1.png"),
      img2: require("../assets/images/SV Plus/Exterior/Deep Blue Pearl/2.png"),
      img3: require("../assets/images/SV Plus/Exterior/Deep Blue Pearl/3.png"),
      img4: require("../assets/images/SV Plus/Exterior/Deep Blue Pearl/4.png"),
      img5: require("../assets/images/SV Plus/Exterior/Deep Blue Pearl/5.png"),
      img6: require("../assets/images/SV Plus/Exterior/Deep Blue Pearl/6.png"),
      img7: require("../assets/images/SV Plus/Exterior/Deep Blue Pearl/7.png"),
      img8: require("../assets/images/SV Plus/Exterior/Deep Blue Pearl/8.png"),
    },
    GunMetallic: {
      img1: require("../assets/images/SV Plus/Exterior/Gun Metallic/1.png"),
      img2: require("../assets/images/SV Plus/Exterior/Gun Metallic/2.png"),
      img3: require("../assets/images/SV Plus/Exterior/Gun Metallic/3.png"),
      img4: require("../assets/images/SV Plus/Exterior/Gun Metallic/4.png"),
      img5: require("../assets/images/SV Plus/Exterior/Gun Metallic/5.png"),
      // img6: require("../assets/images/SV Plus/Exterior/Gun Metallic/6.png"),
      img7: require("../assets/images/SV Plus/Exterior/Gun Metallic/7.png"),
      img8: require("../assets/images/SV Plus/Exterior/Gun Metallic/8.png"),
    },
    PearlWhiteTriCoat: {
      img1: require("../assets/images/SV Plus/Exterior/Pearl White TriCoat/1.png"),
      img2: require("../assets/images/SV Plus/Exterior/Pearl White TriCoat/2.png"),
      img3: require("../assets/images/SV Plus/Exterior/Pearl White TriCoat/3.png"),
      img4: require("../assets/images/SV Plus/Exterior/Pearl White TriCoat/4.png"),
      img5: require("../assets/images/SV Plus/Exterior/Pearl White TriCoat/5.png"),
      img6: require("../assets/images/SV Plus/Exterior/Pearl White TriCoat/6.png"),
      img7: require("../assets/images/SV Plus/Exterior/Pearl White TriCoat/7.png"),
      img8: require("../assets/images/SV Plus/Exterior/Pearl White TriCoat/8.png"),
    },
    superblack: {
      img1: require("../assets/images/SV Plus/Exterior/Super Black/1.png"),
      img2: require("../assets/images/SV Plus/Exterior/Super Black/2.png"),
      img3: require("../assets/images/SV Plus/Exterior/Super Black/3.png"),
      // img4: require("../assets/images/SV Plus/Exterior/Super Black/4.png"),
      img5: require("../assets/images/SV Plus/Exterior/Super Black/5.png"),
      img6: require("../assets/images/SV Plus/Exterior/Super Black/6.png"),
      img7: require("../assets/images/SV Plus/Exterior/Super Black/7.png"),
      img8: require("../assets/images/SV Plus/Exterior/Super Black/8.png"),
    },
    Brilliant_Silver: {
      img1: require("../assets/images/SV Plus/Exterior/Brilliant_Silver/1.png"),
      img2: require("../assets/images/SV Plus/Exterior/Brilliant_Silver/2.png"),
      img3: require("../assets/images/SV Plus/Exterior/Brilliant_Silver/3.png"),
      img4: require("../assets/images/SV Plus/Exterior/Brilliant_Silver/4.png"),
      img5: require("../assets/images/SV Plus/Exterior/Brilliant_Silver/5.png"),
      img6: require("../assets/images/SV Plus/Exterior/Brilliant_Silver/6.png"),
      img7: require("../assets/images/SV Plus/Exterior/Brilliant_Silver/7.png"),
      img8: require("../assets/images/SV Plus/Exterior/Brilliant_Silver/8.png"),
    },
    SuperBlackPearlWhite: {
      img1: require("../assets/images/SV Plus/Exterior/Super Black - Pearl White/1.png"),
      img2: require("../assets/images/SV Plus/Exterior/Super Black - Pearl White/2.png"),
      img3: require("../assets/images/SV Plus/Exterior/Super Black - Pearl White/3.png"),
      img4: require("../assets/images/SV Plus/Exterior/Super Black - Pearl White/4.png"),
      img5: require("../assets/images/SV Plus/Exterior/Super Black - Pearl White/5.png"),
      img6: require("../assets/images/SV Plus/Exterior/Super Black - Pearl White/6.png"),
      img7: require("../assets/images/SV Plus/Exterior/Super Black - Pearl White/7.png"),
      img8: require("../assets/images/SV Plus/Exterior/Super Black - Pearl White/8.png"),
    },
    Telanegra: {
      img1: require("../assets/images/SV Plus/Tela negra/1.png"),
      img2: require("../assets/images/SV Plus/Tela negra/2.png"),
      img3: require("../assets/images/SV Plus/Tela negra/3.png"),
      img4: require("../assets/images/SV Plus/Tela negra/4.png"),
      img5: require("../assets/images/SV Plus/Tela negra/5.png"),
      img6: require("../assets/images/SV Plus/Tela negra/6.png"),
      img7: require("../assets/images/SV Plus/Tela negra/7.png"),
      img8: require("../assets/images/SV Plus/Tela negra/8.png"),
      img9: require("../assets/images/SV Plus/Tela negra/9.png"),
      img10: require("../assets/images/SV Plus/Tela negra/10.png"),
      img11: require("../assets/images/SV Plus/Tela negra/11.png"),
      img12: require("../assets/images/SV Plus/Tela negra/12.png"),
      img13: require("../assets/images/SV Plus/Tela negra/13.png"),
      img14: require("../assets/images/SV Plus/Tela negra/14.png"),
    },
  },
};
