import React, { useState, useEffect } from "react";
import "./Compare.css";
import { AllImages } from "../../constants/images";

import check from "../../assets/images/checkred.png";
import close from "../../assets/images/crossegrey.png";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import required modules
import { EffectCards } from "swiper";
import { CompareDataAriya, features } from "../../constants/Data";
import { Helmet } from "react-helmet";
import { Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import ReactGA from "react-ga4";

const Compare = () => {
  const location = useLocation();
  // useEffect(() => {
  //   ReactGA.pageview(location.pathname);
  // }, []);

  // useEffect(() => {
  //   comparedata();
  // }, [ModelThree]);
  const Navigate = useNavigate();
  const defaultModel = localStorage.getItem("compare-screen-default-model");
  const engineIcon = (
    <svg
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.3478 3.26113L18.689 3.24849L18.3471 4.08935C18.1572 4.55439 17.904 5.1913 17.7836 5.49688L17.5683 6.05746L17.2581 6.03826L16.9479 6.01906L16.6376 4.93631C16.4731 4.33756 16.302 3.74514 16.2641 3.61144L16.2008 3.37564H14.5611V1.97443H11.9022V1.14644H13.9913V0H5.06471V1.14667H7.15382V1.97466L5.81159 1.98731L4.46308 2.0065L4.444 2.75183L4.42492 3.50348H2.15218V7.26127H1.13951V4.64992H0V10.9558H1.13951L1.15208 9.6628L1.17116 8.37609L1.66504 8.3569L2.15263 8.3377V12.102H4.7419L5.74222 13.4843L6.74884 14.8728L11.4779 14.892L16.2008 14.9047L16.302 14.5734C16.3525 14.3886 16.5173 13.7899 16.6693 13.2485L16.9479 12.2613H17.5683L18.1192 13.6306L18.6636 15H22V3.28033L20.3478 3.26113ZM12.8835 7.91286C12.8776 7.96097 12.8583 8.00637 12.8276 8.04409L9.27951 12.3837C9.25167 12.4185 9.2162 12.4467 9.1758 12.4661C9.13539 12.4855 9.09116 12.4957 9.04604 12.4957C9.00271 12.4957 8.95984 12.4864 8.92033 12.4682C8.88104 12.4501 8.84602 12.4237 8.81818 12.3907C8.79035 12.3579 8.76992 12.3193 8.75869 12.2778C8.74747 12.2362 8.74545 12.1928 8.75286 12.1506V12.1488L9.2079 9.67635H7.44633C7.39739 9.67635 7.34957 9.66257 7.30826 9.63682C7.26696 9.61108 7.23373 9.57404 7.21263 9.53045C7.19153 9.48685 7.18345 9.43807 7.18906 9.38996C7.19467 9.34186 7.2142 9.29646 7.24496 9.25874L10.7926 4.91914C10.833 4.86833 10.8891 4.83219 10.9522 4.81593C11.0153 4.79967 11.0822 4.80441 11.1423 4.82925C11.2025 4.8541 11.2528 4.89791 11.2856 4.9537C11.3183 5.00949 11.3316 5.07453 11.3235 5.13868C11.3235 5.14342 11.3221 5.14816 11.3215 5.1529L10.8649 7.62603H12.6262C12.6751 7.62603 12.723 7.6398 12.7643 7.66555C12.8056 7.6913 12.8388 7.72834 12.8599 7.77193C12.881 7.81552 12.8891 7.86431 12.8835 7.91241V7.91286Z"
        fill="black"
      />
    </svg>
  );

  const batteryIcon = (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.49219 14H15.5078C17.149 14 18 13.1602 18 11.5629V4.39422C18 2.797 17.149 1.96465 15.5078 1.96465H14.596V1.01982C14.596 0.382432 14.2161 0 13.5703 0H11.4504C10.797 0 10.4247 0.382432 10.4247 1.01982V1.96465H7.56015V1.01982C7.56015 0.382432 7.18784 0 6.5344 0H4.41452C3.76108 0 3.38877 0.382432 3.38877 1.01982V1.96465H2.49219C0.850992 1.96465 0 2.7895 0 4.39422V11.5629C0 13.1676 0.850992 14 2.49219 14ZM6.542 8.47349C6.542 8.391 6.57999 8.31602 6.64838 8.24103L9.75602 4.41671C10.0068 4.10177 10.4323 4.31923 10.2803 4.70166L9.26973 7.36369H11.1693C11.3212 7.36369 11.458 7.48366 11.458 7.63364C11.458 7.71612 11.42 7.79861 11.344 7.8661L8.24399 11.6904C7.99325 11.9979 7.56775 11.7804 7.71211 11.398L8.73027 8.73594H6.83073C6.67117 8.73594 6.542 8.62346 6.542 8.47349Z"
        fill="black"
      />
    </svg>
  );

  const carIcon = (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 119.7 119.64"
      fill="#000"
      width="20px">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="m59.82,119.64c32.8,0,59.88-27.07,59.88-59.82S92.56,0,59.76,0,0,27.07,0,59.82s27.07,59.82,59.82,59.82Zm0-11.92c-26.55,0-47.79-21.35-47.79-47.9S33.26,11.98,59.76,11.98s47.9,21.29,47.96,47.84c.06,26.55-21.35,47.9-47.9,47.9Zm-30.95-29.1c0,1.91,1.5,3.41,3.47,3.41h3.12c1.91,0,3.41-1.5,3.41-3.41v-4.45c5.79.35,14.17.64,20.94.64s15.21-.23,20.94-.64v4.45c0,1.91,1.5,3.41,3.47,3.41h3.07c1.97,0,3.47-1.5,3.47-3.41v-15.21c0-4.45-.81-7-3.24-10.07l-2.26-2.89c-.98-4.69-2.66-9.55-3.53-11.4-1.45-3.01-4.22-4.8-7.75-5.26-1.85-.23-7.35-.4-14.17-.4s-12.32.17-14.17.4c-3.53.41-6.31,2.26-7.69,5.26-.93,1.85-2.6,6.71-3.53,11.4l-2.31,2.89c-2.37,3.07-3.24,5.61-3.24,10.07v15.21Zm12.79-30.78c.52-2.49,1.62-5.61,2.31-6.88.69-1.27,1.45-1.79,2.89-1.97,1.74-.29,5.79-.41,12.96-.41s11.22.12,12.96.41c1.5.17,2.2.69,2.95,1.97.75,1.21,1.74,4.4,2.26,6.88.29.93-.12,1.39-1.16,1.33-3.7-.23-8.33-.46-17.01-.46s-13.25.23-16.95.46c-1.1.06-1.45-.4-1.21-1.33Zm-4.92,15.56c0-2.49,1.85-4.28,4.28-4.28s4.28,1.79,4.28,4.28-1.91,4.22-4.28,4.22-4.28-1.79-4.28-4.22Zm37.6,0c0-2.49,1.91-4.28,4.28-4.28s4.28,1.79,4.28,4.28-1.85,4.22-4.28,4.22-4.28-1.79-4.28-4.22Zm-24.53.12c0-1.79,1.27-3.07,3.12-3.07h13.77c1.85,0,3.12,1.27,3.12,3.07s-1.27,3.07-3.12,3.07h-13.77c-1.85,0-3.12-1.21-3.12-3.07Z"
        />
      </g>
    </svg>
  );

  //? Model Names
  // "ENGAGE FWD"
  // "ENGAGE e-4FORCE"
  // "ENGAGE+ e-4FORCE"
  // "VENTURE+ FWD"
  // "EVOLVE+ FWD"
  // "EVOLVE+ e-4FORCE"

  const [ModelOne, setModelOne] = useState(
    defaultModel ? defaultModel : "ENGAGE FWD"
  );
  console.log("🚀 ~ file: index.js:94 ~ Compare ~ ModelOne:", ModelOne);

  const [ModelTwo, setModelTwo] = useState(
    defaultModel == "ENGAGE e-4FORCE" ? "VENTURE+ FWD" : "ENGAGE e-4FORCE"
  );

  const [ModelThree, setModelThree] = useState(
    defaultModel == "ENGAGE+ e-4FORCE" ? "EVOLVE+ FWD" : "ENGAGE+ e-4FORCE"
  );

  const ModelOneName = Object.keys(CompareDataAriya);

  var nf = new Intl.NumberFormat();

  let ConvertedMSRPModel1 = nf.format(CompareDataAriya[ModelOne].MSRP);
  let ConvertedMSRPModel2 = nf.format(CompareDataAriya[ModelTwo].MSRP);
  let ConvertedMSRPModel3 = nf.format(CompareDataAriya[ModelThree].MSRP);

  const tableFields = [
    "Northern Lights Metallic",
    "Black Diamond Pearl",
    "Gun Metallic",
    "Everest White Pearl / Black Diamond",
    "Brilliant Silver / Black Diamond",
    "Passion Red / Black Diamond",
    "Boulder Gray / Black DiamondDeep ",
    "Ocean Blue / Black Diamond",
  ];
  const tableFields2 = [
    "Motor",
    "Límite de RPM",
    "Caballos de fuerza",
    "Libras pie de torsión",
    "Capacidad de batería",
    "Tipo de tracción ",
    "Tipo de dirección  - Eléctrica del guía sensible a velocidad",
    "Frenos delanteros - Discos ventilados",
    "Frenos Traseros - Discos ventilados",
  ];
  const tableFields3 = [
    "Consumo de energía (kW-horas por 100 millas) ",
    "Economía de energía en ciudad, carretera, combinado (MPGe)",
    "Rango de millas por carga",
  ];
  const tableFields4 = [
    'Aros en aleación de aluminio de 19" con cubierta aerodinámica',
    "Gomas P235/55R19",
    "Luces delanteras en LED",
    "Luces diurnas en LED",
    "Luces delanteras inteligentes",
    "Luces traseras en LED",
    "Luces de neblina en LED (fog lights)",
    "Espejos retrovisores de color negro con ajuste eléctrico y sistema de calefacción",
    "Spoiler aerodinámico trasero",
    "Manijas exteriores en color del auto",
    "Puerto de carga con iluminación y seguridad",
    "Limpiaparabrisas de intermitencia sensible de velocidad",
    "Limpiaparabrisas trasero con limpiador",
    "Limpiaparabrisas con sensor de lluvia",
    "Puerto de carga rápida (CCS)",
  ];
  const tableFields5 = [
    "Capacidad para 5 pasajeros",
    "Asientos con terminación en tela",
    "Asientos con terminación en piel sintética",
    "Asiento del conductor ajustable manual de 6 posiciones",
    "Asiento del conductor ajustable eléctrico de 8 posiciones con soporte lumbar",
    "Asiento del pasajero ajustable manual de 6 posiciones",
    "Asiento del pasajero ajustable eléctrico de 8 posiciones",
    "Asiento del conductor con sistema de memoria",
    "Sistema de calefacción en asientos delanteros y traseros",
  ];
  const tableFields6 = [
    'Monitor avanzado de asistencia al conductor de 12.3"',
    "Sistema de audio con 6 bocinas",
    "Sistema Bluetooth® para llamadas y transferencia de audio",
    "Controles de audio integrados al guía",
    "Puertos de USB: 2 delanteros y 2 traseros (2x tipo-A & 2x tipo-C)",
    'Monitor multitáctil a color de 12.3"',
    "Apple Carplay® inalámbrico & Android Auto™",
    "Sistema de navegación",
    "Asistencia de mensajería de texto",
  ];
  const tableFields7 = [
    "A/C con control de clima automático de 2 zonas y ventanillas en la segunda fila",
    "Bolsillo en puertas delanteras con porta botellas (x4)",
    "Portavasos en consola central (x2)",
    "Espejo retrovisor interior con anti-deslumbramiento",
    "Salida para accesorios de 12V",
    "Ventanas eléctricas con bajado y subido automático",
    "Sistema de llave inteligente Nissan",
    "Manijas capacitivas con desbloqueo de acercamiento y bloqueo de alejamiento",
    "Sistema de encendido de motor por botón",
    "Sistema de cámara de visión periférica inteligente (I-AVM)",
    "Sistema de cámara de reversa inteligente (I-RVM)",
    "Pantalla de visualización frontal a color (HUD)",
    "Alerta de Atención al Conductor Inteligente(I-DA)",
    "Sistema de Alerta de Puerta Trasera",
    "Viseras con extensores e iluminación para conductor y pasajero",
    "Asiento trasero reclinables",
    "Asistente personal virtual",
    "Sistema universal para apertura de garaje",
    '"Moonroof" panorámico',
    "Compuerta trasera con apertura eléctrica",
    "Modo E-Step",
    "Selector de modo de manejo",
    "Sistema de calefacción híbrido",
    "Cargador inalámbrico para teléfonos inteligentes",
    "Batería con calentador y refrigeración",
    "Apertura automática del puerto de carga desde el interior",
    'Monitor avanzado de asistencia al conductor de 12.3"',
    "Guía de ajuste telescópico manual",
    "Guía cubierto en piel y con sistema de calefacción",
    "Consola central con funcionamiento eléctrico y bandeja",
    "Cable de carga portátil (120V/240V EVSE)",
    "Cubierta en área de carga",
  ];
  const tableFields8 = [
    "Sistema avanzado de bolsas de aire (delanteras, laterales, tipo cortina) SAFETY",
    'Sistemas de cinturones de seguridad de 3 puntos "ALR" para conductor y "ALR" / "ELR" para todos los ocupantes',
    "Luz y sonido indicador de recordatorio para cinturones de seguridad",
    "Sistema de anclaje para asiento protector de niños (LATCH)",
    "Cabezales de asientos delanteros de altura ajustable",
    "Barras tubulares de acero para protección de puertas",
    "Zonas de absorción de impactos delanteros y traseros",
    "Columna del guía con sistema de absorción de energía",
    "Seguros en puertas traseras a prueba de niños",
    "Sistema de monitoreo de presión de gomas (TPMS) con aviso de presión ideal",
    "Luz de frenado central",
    "Sistema de seguridad del vehículo (VSS)",
    "Inmovilizador de motor",
    "Aviso de Colisión Frontal Inteligente (I-FCW)",
    "Frenado Automático de Emergencia con detector de Peatones (PAEB)",
    "Aviso de Cambio de Carril Involuntario (LDW)",
    "Intervención de Cambio de Carril Involuntario (I-LI)",
    "Alerta de Cruce de Tráfico Trasero (RCTA)",
    "Aviso de Punto Ciego (BSW)",
    "Intervención Inteligente de Punto Ciego (I-BSI)",
    "Asistencia de Luz Alta (HBA)",
    "Frenado Automático Trasero (RAB)",
    "ProPilot Assist w/ Navi-Link",
    "Control de viaje inteligente (ICC) con asistencia de control de distancia (DCA)",
    "Reconocimiento de letreros de tráfico",
    "Sistema de sensores delanteros y traseros",
    "Asistencia eléctrica de guía",
    "Control dinámico del vehículo (VDC)",
    "Sistema de control de tracción (TCS)",
    "Sistema de frenos antibloqueo (ABS) con distribución de fuerza de frenos electrónica (EBD)",
    "Sistema de asistencia de frenado (BA)",
    "Freno de emergencia eléctrico (ePKB)",
  ];
  const tableFields9 = [
    "Distancia entre ejes (pulg.)",
    "Largo (pulg.)",
    "Ancho (pulg.)",
    "Alto (pulg.)",
    "Altura del vehículo al suelo (pulg.)",
    "Volumen interior (pies cúbicos)",
    "Volumen de pasajeros (pies cúbicos)",
    "Capacidad de carga (pies cúbicos)",
    "Espacio de cabeza delantero / trasero (pulg.)",
    "Espacio de hombro delantero / trasero (pulg.)",
    "Espacio de cadera delantero / trasero (pulg.)",
    "Espacio de piernas delantero / trasero (pulg.)",
  ];
  const tableFields10 = [
    "Peso en vacío (lbs.)",
    'Peso del vehículo con carga máxima "GVWR" (lbs.)',
    "Distribución de peso delantero / trasero (lbs.)",
  ];
  const tableFields11 = [
    'Delantera - Independiente "MacPherson" con amortiguadores',
    "Trasera - Independiente multi-lin",
    "Barra estabilizadora - Delantera y trasera",
  ];

  const car1 = [
    //tableFields1 values
    { id: 1, value: <img src={close} /> },
    { id: 2, value: <img src={check} /> },
    { id: 3, value: <img src={check} /> },
    { id: 4, value: <img src={check} /> },
    { id: 5, value: <img src={check} /> },
    { id: 6, value: <img src={close} /> },
    { id: 7, value: <img src={check} /> },
    { id: 8, value: <img src={check} /> },

    // tableFields2 values
    { id: 9, value: "160  kW" },
    { id: 10, value: "13,520" },
    { id: 11, value: "214 HP" },
    { id: 12, value: "221 ft-lbs" },
    { id: 13, value: "66 kWh" },
    { id: 14, value: "FWD" },
    { id: 15, value: <img src={check} /> },
    { id: 16, value: <img src={check} /> },
    { id: 17, value: <img src={check} /> },

    // tableFields3 values
    { id: 18, value: "33" },
    { id: 19, value: "109/94/101" },
    { id: 20, value: "216" },

    // tableFields4 values
    { id: 21, value: <img src={check} /> },
    { id: 22, value: <img src={check} /> },
    { id: 23, value: <img src={check} /> },
    { id: 24, value: <img src={check} /> },
    { id: 25, value: <img src={check} /> },
    { id: 26, value: <img src={check} /> },
    { id: 27, value: <img src={close} /> },
    { id: 28, value: <img src={check} /> },
    { id: 29, value: <img src={check} /> },
    { id: 30, value: <img src={check} /> },
    { id: 31, value: <img src={check} /> },
    { id: 32, value: <img src={check} /> },
    { id: 33, value: <img src={check} /> },
    { id: 34, value: <img src={close} /> },
    { id: 35, value: <img src={check} /> },

    // tableFields5 values
    { id: 36, value: <img src={check} /> },
    { id: 37, value: <img src={close} /> },
    { id: 38, value: <img src={close} /> },
    { id: 39, value: <img src={close} /> },
    { id: 40, value: <img src={check} /> },
    { id: 41, value: <img src={check} /> },
    { id: 42, value: <img src={close} /> },
    { id: 43, value: <img src={check} /> },
    { id: 44, value: <img src={check} /> },

    // tableFields6 values
    { id: 45, value: <img src={check} /> },
    { id: 46, value: <img src={check} /> },
    { id: 47, value: <img src={check} /> },
    { id: 48, value: <img src={check} /> },
    { id: 49, value: <img src={check} /> },
    { id: 50, value: <img src={check} /> },
    { id: 51, value: <img src={check} /> },
    { id: 52, value: <img src={check} /> },
    { id: 53, value: <img src={check} /> },

    // tableFields7 values
    { id: 54, value: <img src={check} /> },
    { id: 55, value: <img src={check} /> },
    { id: 56, value: <img src={check} /> },
    { id: 57, value: <img src={check} /> },
    { id: 58, value: <img src={check} /> },
    { id: 59, value: <img src={check} /> },
    { id: 60, value: <img src={check} /> },
    { id: 61, value: <img src={check} /> },
    { id: 62, value: <img src={check} /> },
    { id: 63, value: <img src={check} /> },
    { id: 64, value: <img src={check} /> },
    { id: 65, value: <img src={check} /> },
    { id: 66, value: <img src={check} /> },
    { id: 67, value: <img src={check} /> },
    { id: 68, value: <img src={check} /> },
    { id: 69, value: <img src={check} /> },
    { id: 70, value: <img src={check} /> },
    { id: 71, value: <img src={check} /> },
    { id: 72, value: <img src={check} /> },
    { id: 73, value: <img src={check} /> },
    { id: 74, value: <img src={check} /> },
    { id: 75, value: <img src={check} /> },
    { id: 76, value: <img src={check} /> },
    { id: 77, value: <img src={check} /> },
    { id: 78, value: <img src={check} /> },
    { id: 79, value: <img src={check} /> },
    { id: 80, value: <img src={check} /> },
    { id: 81, value: <img src={check} /> },
    { id: 82, value: <img src={check} /> },
    { id: 83, value: <img src={check} /> },
    { id: 84, value: <img src={check} /> },
    { id: 85, value: <img src={check} /> },

    // tableFields8 values
    { id: 86, value: <img src={check} /> },
    { id: 87, value: <img src={check} /> },
    { id: 88, value: <img src={check} /> },
    { id: 89, value: <img src={check} /> },
    { id: 90, value: <img src={check} /> },
    { id: 91, value: <img src={check} /> },
    { id: 92, value: <img src={check} /> },
    { id: 93, value: <img src={check} /> },
    { id: 94, value: <img src={check} /> },
    { id: 95, value: <img src={check} /> },
    { id: 96, value: <img src={check} /> },
    { id: 97, value: <img src={check} /> },
    { id: 98, value: <img src={check} /> },
    { id: 99, value: <img src={check} /> },
    { id: 100, value: <img src={check} /> },
    { id: 101, value: <img src={check} /> },
    { id: 102, value: <img src={check} /> },
    { id: 103, value: <img src={check} /> },
    { id: 104, value: <img src={check} /> },
    { id: 105, value: <img src={check} /> },
    { id: 106, value: <img src={check} /> },
    { id: 107, value: <img src={check} /> },
    { id: 108, value: <img src={check} /> },
    { id: 109, value: <img src={check} /> },
    { id: 110, value: <img src={check} /> },
    { id: 111, value: <img src={check} /> },
    { id: 112, value: <img src={check} /> },
    { id: 113, value: <img src={check} /> },
    { id: 114, value: <img src={check} /> },
    { id: 115, value: <img src={check} /> },
    { id: 116, value: <img src={check} /> },
    { id: 117, value: <img src={check} /> },

    // tableFields9 values
    { id: 118, value: "109.3" },
    { id: 119, value: "182.9" },
    { id: 120, value: "85.5" },
    { id: 121, value: "5.4" },
    { id: 122, value: "6.3" },
    { id: 123, value: "124" },
    { id: 124, value: "101.2" },
    { id: 125, value: "22.8" },
    { id: 126, value: "39.6 / 38.3" },
    { id: 127, value: "56.0 / 55.9" },
    { id: 128, value: "56.0 / 52.5" },
    { id: 129, value: "42.3 / 37.0" },

    // tableFields10 values
    { id: 130, value: "4,323" },
    { id: 131, value: "5,313" },
    { id: 132, value: "56 / 44" },

    // tableFields11 values
    { id: 133, value: <img src={check} /> },
    { id: 134, value: <img src={check} /> },
    { id: 135, value: <img src={check} /> },
  ];

  const car2 = [
    //tableFields1 values
    { id: 1, value: <img src={close} /> },
    { id: 2, value: <img src={check} /> },
    { id: 3, value: <img src={check} /> },
    { id: 4, value: <img src={check} /> },
    { id: 5, value: <img src={check} /> },
    { id: 6, value: <img src={close} /> },
    { id: 7, value: <img src={check} /> },
    { id: 8, value: <img src={check} /> },

    // tableFields2 values
    { id: 9, value: "250 kW" },
    { id: 10, value: "13,520" },
    { id: 11, value: "335 HP" },
    { id: 12, value: "413 ft-lbs" },
    { id: 13, value: "66 kWh" },
    { id: 14, value: "e-4FORCE (AWD)" },
    { id: 15, value: <img src={check} /> },
    { id: 16, value: <img src={check} /> },
    { id: 17, value: <img src={check} /> },

    // tableFields3 values
    { id: 18, value: "35" },
    { id: 19, value: "101/89/95" },
    { id: 20, value: "205" },

    // tableFields4 values
    { id: 21, value: <img src={check} /> },
    { id: 22, value: <img src={check} /> },
    { id: 23, value: <img src={check} /> },
    { id: 24, value: <img src={check} /> },
    { id: 25, value: <img src={check} /> },
    { id: 26, value: <img src={check} /> },
    { id: 27, value: <img src={close} /> },
    { id: 28, value: <img src={check} /> },
    { id: 29, value: <img src={check} /> },
    { id: 30, value: <img src={check} /> },
    { id: 31, value: <img src={check} /> },
    { id: 32, value: <img src={check} /> },
    { id: 33, value: <img src={check} /> },
    { id: 34, value: <img src={close} /> },
    { id: 35, value: <img src={check} /> },

    // tableFields5 values
    { id: 36, value: <img src={check} /> },
    { id: 37, value: <img src={close} /> },
    { id: 38, value: <img src={check} /> },
    { id: 39, value: <img src={close} /> },
    { id: 40, value: <img src={check} /> },
    { id: 41, value: <img src={check} /> },
    { id: 42, value: <img src={close} /> },
    { id: 43, value: <img src={check} /> },
    { id: 44, value: <img src={check} /> },

    // tableFields6 values
    { id: 45, value: <img src={check} /> },
    { id: 46, value: <img src={check} /> },
    { id: 47, value: <img src={check} /> },
    { id: 48, value: <img src={check} /> },
    { id: 49, value: <img src={check} /> },
    { id: 50, value: <img src={check} /> },
    { id: 51, value: <img src={check} /> },
    { id: 52, value: <img src={check} /> },
    { id: 53, value: <img src={check} /> },

    // tableFields7 values
    { id: 54, value: <img src={check} /> },
    { id: 55, value: <img src={check} /> },
    { id: 56, value: <img src={check} /> },
    { id: 57, value: <img src={check} /> },
    { id: 58, value: <img src={check} /> },
    { id: 59, value: <img src={check} /> },
    { id: 60, value: <img src={check} /> },
    { id: 61, value: <img src={check} /> },
    { id: 62, value: <img src={check} /> },
    { id: 63, value: <img src={check} /> },
    { id: 64, value: <img src={check} /> },
    { id: 65, value: <img src={check} /> },
    { id: 66, value: <img src={check} /> },
    { id: 67, value: <img src={check} /> },
    { id: 68, value: <img src={check} /> },
    { id: 69, value: <img src={check} /> },
    { id: 70, value: <img src={check} /> },
    { id: 71, value: <img src={check} /> },
    { id: 72, value: <img src={check} /> },
    { id: 73, value: <img src={check} /> },
    { id: 74, value: <img src={check} /> },
    { id: 75, value: <img src={check} /> },
    { id: 76, value: <img src={check} /> },
    { id: 77, value: <img src={check} /> },
    { id: 78, value: <img src={check} /> },
    { id: 79, value: <img src={check} /> },
    { id: 80, value: <img src={check} /> },
    { id: 81, value: <img src={check} /> },
    { id: 82, value: <img src={check} /> },
    { id: 83, value: <img src={check} /> },
    { id: 84, value: <img src={check} /> },
    { id: 85, value: <img src={check} /> },

    // tableFields8 values
    { id: 86, value: <img src={check} /> },
    { id: 87, value: <img src={check} /> },
    { id: 88, value: <img src={check} /> },
    { id: 89, value: <img src={check} /> },
    { id: 90, value: <img src={check} /> },
    { id: 91, value: <img src={check} /> },
    { id: 92, value: <img src={check} /> },
    { id: 93, value: <img src={check} /> },
    { id: 94, value: <img src={check} /> },
    { id: 95, value: <img src={check} /> },
    { id: 96, value: <img src={check} /> },
    { id: 97, value: <img src={check} /> },
    { id: 98, value: <img src={check} /> },
    { id: 99, value: <img src={check} /> },
    { id: 100, value: <img src={check} /> },
    { id: 101, value: <img src={check} /> },
    { id: 102, value: <img src={check} /> },
    { id: 103, value: <img src={check} /> },
    { id: 104, value: <img src={check} /> },
    { id: 105, value: <img src={check} /> },
    { id: 106, value: <img src={check} /> },
    { id: 107, value: <img src={check} /> },
    { id: 108, value: <img src={check} /> },
    { id: 109, value: <img src={check} /> },
    { id: 110, value: <img src={check} /> },
    { id: 111, value: <img src={check} /> },
    { id: 112, value: <img src={check} /> },
    { id: 113, value: <img src={check} /> },
    { id: 114, value: <img src={check} /> },
    { id: 115, value: <img src={check} /> },
    { id: 116, value: <img src={check} /> },
    { id: 117, value: <img src={check} /> },

    // tableFields9 values
    { id: 118, value: "109.3" },
    { id: 119, value: "182.9" },
    { id: 120, value: "85.5" },
    { id: 121, value: "5.4" },
    { id: 122, value: "5.3" },
    { id: 123, value: "124" },
    { id: 124, value: "101.2" },
    { id: 125, value: "22.8" },
    { id: 126, value: "39.6 / 38.3" },
    { id: 127, value: "56.0 / 55.9" },
    { id: 128, value: "56.0 / 52.5" },
    { id: 129, value: "42.3 / 37.0" },

    // tableFields10 values
    { id: 130, value: "4,608" },
    { id: 131, value: "5,589" },
    { id: 132, value: "52 / 48" },

    // tableFields11 values
    { id: 133, value: <img src={check} /> },
    { id: 134, value: <img src={check} /> },
    { id: 135, value: <img src={check} /> },
  ];

  const car3 = [
    //tableFields1 values
    { id: 1, value: <img src={close} /> },
    { id: 2, value: <img src={check} /> },
    { id: 3, value: <img src={check} /> },
    { id: 4, value: <img src={check} /> },
    { id: 5, value: <img src={check} /> },
    { id: 6, value: <img src={close} /> },
    { id: 7, value: <img src={check} /> },
    { id: 8, value: <img src={check} /> },

    // tableFields2 values
    { id: 9, value: "290 kW" },
    { id: 10, value: "13,520" },
    { id: 11, value: "389 HP" },
    { id: 12, value: "442 ft-lbs" },
    { id: 13, value: "91  kW" },
    { id: 14, value: "e-4FORCE (AWD)" },
    { id: 15, value: <img src={check} /> },
    { id: 16, value: <img src={check} /> },
    { id: 17, value: <img src={check} /> },

    // tableFields3 values
    { id: 18, value: "37" },
    { id: 19, value: "97/86/92" },
    { id: 20, value: "270" },

    // tableFields4 values
    { id: 21, value: <img src={check} /> },
    { id: 22, value: <img src={check} /> },
    { id: 23, value: <img src={check} /> },
    { id: 24, value: <img src={check} /> },
    { id: 25, value: <img src={check} /> },
    { id: 26, value: <img src={check} /> },
    { id: 27, value: <img src={close} /> },
    { id: 28, value: <img src={check} /> },
    { id: 29, value: <img src={check} /> },
    { id: 30, value: <img src={check} /> },
    { id: 31, value: <img src={check} /> },
    { id: 32, value: <img src={check} /> },
    { id: 33, value: <img src={check} /> },
    { id: 34, value: <img src={close} /> },
    { id: 35, value: <img src={check} /> },

    // tableFields5 values
    { id: 36, value: <img src={check} /> },
    { id: 37, value: <img src={close} /> },
    { id: 38, value: <img src={check} /> },
    { id: 39, value: <img src={close} /> },
    { id: 40, value: <img src={check} /> },
    { id: 41, value: <img src={check} /> },
    { id: 42, value: <img src={close} /> },
    { id: 43, value: <img src={check} /> },
    { id: 44, value: <img src={check} /> },

    // tableFields6 values
    { id: 45, value: <img src={check} /> },
    { id: 46, value: <img src={check} /> },
    { id: 47, value: <img src={check} /> },
    { id: 48, value: <img src={check} /> },
    { id: 49, value: <img src={check} /> },
    { id: 50, value: <img src={check} /> },
    { id: 51, value: <img src={check} /> },
    { id: 52, value: <img src={check} /> },
    { id: 53, value: <img src={check} /> },

    // tableFields7 values
    { id: 54, value: <img src={check} /> },
    { id: 55, value: <img src={check} /> },
    { id: 56, value: <img src={check} /> },
    { id: 57, value: <img src={check} /> },
    { id: 58, value: <img src={check} /> },
    { id: 59, value: <img src={check} /> },
    { id: 60, value: <img src={check} /> },
    { id: 61, value: <img src={check} /> },
    { id: 62, value: <img src={check} /> },
    { id: 63, value: <img src={check} /> },
    { id: 64, value: <img src={check} /> },
    { id: 65, value: <img src={check} /> },
    { id: 66, value: <img src={check} /> },
    { id: 67, value: <img src={check} /> },
    { id: 68, value: <img src={check} /> },
    { id: 69, value: <img src={check} /> },
    { id: 70, value: <img src={check} /> },
    { id: 71, value: <img src={check} /> },
    { id: 72, value: <img src={check} /> },
    { id: 73, value: <img src={check} /> },
    { id: 74, value: <img src={check} /> },
    { id: 75, value: <img src={check} /> },
    { id: 76, value: <img src={check} /> },
    { id: 77, value: <img src={check} /> },
    { id: 78, value: <img src={check} /> },
    { id: 79, value: <img src={check} /> },
    { id: 80, value: <img src={check} /> },
    { id: 81, value: <img src={check} /> },
    { id: 82, value: <img src={check} /> },
    { id: 83, value: <img src={check} /> },
    { id: 84, value: <img src={check} /> },
    { id: 85, value: <img src={check} /> },

    // tableFields8 values
    { id: 86, value: <img src={check} /> },
    { id: 87, value: <img src={check} /> },
    { id: 88, value: <img src={check} /> },
    { id: 89, value: <img src={check} /> },
    { id: 90, value: <img src={check} /> },
    { id: 91, value: <img src={check} /> },
    { id: 92, value: <img src={check} /> },
    { id: 93, value: <img src={check} /> },
    { id: 94, value: <img src={check} /> },
    { id: 95, value: <img src={check} /> },
    { id: 96, value: <img src={check} /> },
    { id: 97, value: <img src={check} /> },
    { id: 98, value: <img src={check} /> },
    { id: 99, value: <img src={check} /> },
    { id: 100, value: <img src={check} /> },
    { id: 101, value: <img src={check} /> },
    { id: 102, value: <img src={check} /> },
    { id: 103, value: <img src={check} /> },
    { id: 104, value: <img src={check} /> },
    { id: 105, value: <img src={check} /> },
    { id: 106, value: <img src={check} /> },
    { id: 107, value: <img src={check} /> },
    { id: 108, value: <img src={check} /> },
    { id: 109, value: <img src={check} /> },
    { id: 110, value: <img src={check} /> },
    { id: 111, value: <img src={check} /> },
    { id: 112, value: <img src={check} /> },
    { id: 113, value: <img src={check} /> },
    { id: 114, value: <img src={check} /> },
    { id: 115, value: <img src={check} /> },
    { id: 116, value: <img src={check} /> },
    { id: 117, value: <img src={check} /> },

    // tableFields9 values
    { id: 118, value: "109.3" },
    { id: 119, value: "182.9" },
    { id: 120, value: "85.5" },
    { id: 121, value: "5.4" },
    { id: 122, value: "5.3" },
    { id: 123, value: "124" },
    { id: 124, value: "101.2" },
    { id: 125, value: "22.8" },
    { id: 126, value: "39.6 / 38.3" },
    { id: 127, value: "56.0 / 55.9" },
    { id: 128, value: "56.0 / 52.5" },
    { id: 129, value: "42.3 / 37.0" },

    // tableFields10 values
    { id: 130, value: "4,899" },
    { id: 131, value: "5,930" },
    { id: 132, value: "50 /50" },

    // tableFields11 values
    { id: 133, value: <img src={check} /> },
    { id: 134, value: <img src={check} /> },
    { id: 135, value: <img src={check} /> },
  ];

  const car4 = [
    //tableFields1 values
    { id: 1, value: <img src={close} /> },
    { id: 2, value: <img src={check} /> },
    { id: 3, value: <img src={check} /> },
    { id: 4, value: <img src={check} /> },
    { id: 5, value: <img src={check} /> },
    { id: 6, value: <img src={close} /> },
    { id: 7, value: <img src={check} /> },
    { id: 8, value: <img src={check} /> },

    // tableFields2 values
    { id: 9, value: "178 kW" },
    { id: 10, value: "13,520" },
    { id: 11, value: "238 HP" },
    { id: 12, value: "221 ft-lbs" },
    { id: 13, value: "91 kWh" },
    { id: 14, value: "FWD" },
    { id: 15, value: <img src={check} /> },
    { id: 16, value: <img src={check} /> },
    { id: 17, value: <img src={check} /> },

    // tableFields3 values
    { id: 18, value: "33" },
    { id: 19, value: "111/95/103" },
    { id: 20, value: "304" },

    // tableFields4 values
    { id: 21, value: <img src={check} /> },
    { id: 22, value: <img src={check} /> },
    { id: 23, value: <img src={check} /> },
    { id: 24, value: <img src={check} /> },
    { id: 25, value: <img src={check} /> },
    { id: 26, value: <img src={check} /> },
    { id: 27, value: <img src={close} /> },
    { id: 28, value: <img src={check} /> },
    { id: 29, value: <img src={check} /> },
    { id: 30, value: <img src={check} /> },
    { id: 31, value: <img src={check} /> },
    { id: 32, value: <img src={check} /> },
    { id: 33, value: <img src={check} /> },
    { id: 34, value: <img src={close} /> },
    { id: 35, value: <img src={check} /> },

    // tableFields5 values
    { id: 36, value: <img src={check} /> },
    { id: 37, value: <img src={check} /> },
    { id: 38, value: <img src={close} /> },
    { id: 39, value: <img src={check} /> },
    { id: 40, value: <img src={close} /> },
    { id: 41, value: <img src={check} /> },
    { id: 42, value: <img src={close} /> },
    { id: 43, value: <img src={close} /> },
    { id: 44, value: <img src={close} /> },

    // tableFields6 values
    { id: 45, value: <img src={check} /> },
    { id: 46, value: <img src={check} /> },
    { id: 47, value: <img src={check} /> },
    { id: 48, value: <img src={check} /> },
    { id: 49, value: <img src={check} /> },
    { id: 50, value: <img src={check} /> },
    { id: 51, value: <img src={check} /> },
    { id: 52, value: <img src={check} /> },
    { id: 53, value: <img src={check} /> },

    // tableFields7 values
    { id: 54, value: <img src={check} /> },
    { id: 55, value: <img src={check} /> },
    { id: 56, value: <img src={check} /> },
    { id: 57, value: <img src={close} /> },
    { id: 58, value: <img src={check} /> },
    { id: 59, value: <img src={check} /> },
    { id: 60, value: <img src={check} /> },
    { id: 61, value: <img src={check} /> },
    { id: 62, value: <img src={check} /> },
    { id: 63, value: <img src={close} /> },
    { id: 64, value: <img src={close} /> },
    { id: 65, value: <img src={close} /> },
    { id: 66, value: <img src={check} /> },
    { id: 67, value: <img src={check} /> },
    { id: 68, value: <img src={check} /> },
    { id: 69, value: <img src={check} /> },
    { id: 70, value: <img src={check} /> },
    { id: 71, value: <img src={close} /> },
    { id: 72, value: <img src={close} /> },
    { id: 73, value: <img src={close} /> },
    { id: 74, value: <img src={check} /> },
    { id: 75, value: <img src={check} /> },
    { id: 76, value: <img src={check} /> },
    { id: 77, value: <img src={close} /> },
    { id: 78, value: <img src={check} /> },
    { id: 79, value: <img src={check} /> },
    { id: 80, value: <img src={check} /> },
    { id: 81, value: <img src={check} /> },
    { id: 82, value: <img src={close} /> },
    { id: 83, value: <img src={check} /> },
    { id: 84, value: <img src={check} /> },
    { id: 85, value: <img src={close} /> },

    // tableFields8 values
    { id: 86, value: <img src={check} /> },
    { id: 87, value: <img src={check} /> },
    { id: 88, value: <img src={check} /> },
    { id: 89, value: <img src={check} /> },
    { id: 90, value: <img src={check} /> },
    { id: 91, value: <img src={check} /> },
    { id: 92, value: <img src={check} /> },
    { id: 93, value: <img src={check} /> },
    { id: 94, value: <img src={check} /> },
    { id: 95, value: <img src={check} /> },
    { id: 96, value: <img src={check} /> },
    { id: 97, value: <img src={check} /> },
    { id: 98, value: <img src={check} /> },
    { id: 99, value: <img src={check} /> },
    { id: 100, value: <img src={check} /> },
    { id: 101, value: <img src={check} /> },
    { id: 102, value: <img src={check} /> },
    { id: 103, value: <img src={check} /> },
    { id: 104, value: <img src={check} /> },
    { id: 105, value: <img src={check} /> },
    { id: 106, value: <img src={check} /> },
    { id: 107, value: <img src={check} /> },
    { id: 108, value: <img src={check} /> },
    { id: 109, value: <img src={check} /> },
    { id: 110, value: <img src={check} /> },
    { id: 111, value: <img src={check} /> },
    { id: 112, value: <img src={check} /> },
    { id: 113, value: <img src={check} /> },
    { id: 114, value: <img src={check} /> },
    { id: 115, value: <img src={check} /> },
    { id: 116, value: <img src={check} /> },
    { id: 117, value: <img src={check} /> },

    // tableFields9 values
    { id: 118, value: "109.3" },
    { id: 119, value: "182.9" },
    { id: 120, value: "85.5" },
    { id: 121, value: "5.4" },
    { id: 122, value: "6.3" },
    { id: 123, value: "124" },
    { id: 124, value: "101.2" },
    { id: 125, value: "22.8" },
    { id: 126, value: "39.6 / 38.3" },
    { id: 127, value: "56.0 / 55.9" },
    { id: 128, value: "56.0 / 52.5" },
    { id: 129, value: "42.3 / 37.0" },

    // tableFields10 values
    { id: 130, value: "4,528" },
    { id: 131, value: "5,644" },
    { id: 132, value: "53 / 47" },

    // tableFields11 values
    { id: 133, value: <img src={check} /> },
    { id: 134, value: <img src={check} /> },
    { id: 135, value: <img src={check} /> },
  ];
  const car5 = [
    //tableFields1 values
    { id: 1, value: <img src={close} /> },
    { id: 2, value: <img src={check} /> },
    { id: 3, value: <img src={check} /> },
    { id: 4, value: <img src={check} /> },
    { id: 5, value: <img src={check} /> },
    { id: 6, value: <img src={close} /> },
    { id: 7, value: <img src={check} /> },
    { id: 8, value: <img src={check} /> },

    // tableFields2 values
    { id: 9, value: "178 kW" },
    { id: 10, value: "13,520" },
    { id: 11, value: "238 HP" },
    { id: 12, value: "413 ft-lbs" },
    { id: 13, value: "91 kWh" },
    { id: 14, value: "FWD" },
    { id: 15, value: <img src={check} /> },
    { id: 16, value: <img src={check} /> },
    { id: 17, value: <img src={check} /> },

    // tableFields3 values
    { id: 18, value: "35" },
    { id: 19, value: "105/91/98" },
    { id: 20, value: "289" },

    // tableFields4 values
    { id: 21, value: <img src={check} /> },
    { id: 22, value: <img src={check} /> },
    { id: 23, value: <img src={check} /> },
    { id: 24, value: <img src={check} /> },
    { id: 25, value: <img src={check} /> },
    { id: 26, value: <img src={check} /> },
    { id: 27, value: <img src={check} /> },
    { id: 28, value: <img src={check} /> },
    { id: 29, value: <img src={check} /> },
    { id: 30, value: <img src={check} /> },
    { id: 31, value: <img src={check} /> },
    { id: 32, value: <img src={check} /> },
    { id: 33, value: <img src={check} /> },
    { id: 34, value: <img src={check} /> },
    { id: 35, value: <img src={check} /> },

    // tableFields5 values
    { id: 36, value: <img src={check} /> },
    { id: 37, value: <img src={close} /> },
    { id: 38, value: <img src={check} /> },
    { id: 39, value: <img src={close} /> },
    { id: 40, value: <img src={check} /> },
    { id: 41, value: <img src={close} /> },
    { id: 42, value: <img src={check} /> },
    { id: 43, value: <img src={check} /> },
    { id: 44, value: <img src={check} /> },

    // tableFields6 values
    { id: 45, value: <img src={check} /> },
    { id: 46, value: <img src={check} /> },
    { id: 47, value: <img src={check} /> },
    { id: 48, value: <img src={check} /> },
    { id: 49, value: <img src={check} /> },
    { id: 50, value: <img src={check} /> },
    { id: 51, value: <img src={check} /> },
    { id: 52, value: <img src={check} /> },
    { id: 53, value: <img src={check} /> },

    // tableFields7 values
    { id: 54, value: <img src={check} /> },
    { id: 55, value: <img src={check} /> },
    { id: 56, value: <img src={check} /> },
    { id: 57, value: <img src={check} /> },
    { id: 58, value: <img src={check} /> },
    { id: 59, value: <img src={check} /> },
    { id: 60, value: <img src={check} /> },
    { id: 61, value: <img src={check} /> },
    { id: 62, value: <img src={check} /> },
    { id: 63, value: <img src={check} /> },
    { id: 64, value: <img src={close} /> },
    { id: 65, value: <img src={check} /> },
    { id: 66, value: <img src={check} /> },
    { id: 67, value: <img src={check} /> },
    { id: 68, value: <img src={check} /> },
    { id: 69, value: <img src={check} /> },
    { id: 70, value: <img src={check} /> },
    { id: 71, value: <img src={check} /> },
    { id: 72, value: <img src={check} /> },
    { id: 73, value: <img src={check} /> },
    { id: 74, value: <img src={check} /> },
    { id: 75, value: <img src={check} /> },
    { id: 76, value: <img src={check} /> },
    { id: 77, value: <img src={check} /> },
    { id: 78, value: <img src={check} /> },
    { id: 79, value: <img src={check} /> },
    { id: 80, value: <img src={check} /> },
    { id: 81, value: <img src={check} /> },
    { id: 82, value: <img src={check} /> },
    { id: 83, value: <img src={check} /> },
    { id: 84, value: <img src={close} /> },
    { id: 85, value: <img src={check} /> },

    // tableFields8 values
    { id: 86, value: <img src={check} /> },
    { id: 87, value: <img src={check} /> },
    { id: 88, value: <img src={check} /> },
    { id: 89, value: <img src={check} /> },
    { id: 90, value: <img src={check} /> },
    { id: 91, value: <img src={check} /> },
    { id: 92, value: <img src={check} /> },
    { id: 93, value: <img src={check} /> },
    { id: 94, value: <img src={check} /> },
    { id: 95, value: <img src={check} /> },
    { id: 96, value: <img src={check} /> },
    { id: 97, value: <img src={check} /> },
    { id: 98, value: <img src={check} /> },
    { id: 99, value: <img src={check} /> },
    { id: 100, value: <img src={check} /> },
    { id: 101, value: <img src={check} /> },
    { id: 102, value: <img src={check} /> },
    { id: 103, value: <img src={check} /> },
    { id: 104, value: <img src={check} /> },
    { id: 105, value: <img src={check} /> },
    { id: 106, value: <img src={check} /> },
    { id: 107, value: <img src={check} /> },
    { id: 108, value: <img src={check} /> },
    { id: 109, value: <img src={check} /> },
    { id: 110, value: <img src={check} /> },
    { id: 111, value: <img src={check} /> },
    { id: 112, value: <img src={check} /> },
    { id: 113, value: <img src={check} /> },
    { id: 114, value: <img src={check} /> },
    { id: 115, value: <img src={check} /> },
    { id: 116, value: <img src={check} /> },
    { id: 117, value: <img src={check} /> },

    // tableFields9 values
    { id: 118, value: "109.3" },
    { id: 119, value: "182.9" },
    { id: 120, value: "85.5" },
    { id: 121, value: "5.4" },
    { id: 122, value: "6.3" },
    { id: 123, value: "119.1" },
    { id: 124, value: "96.3" },
    { id: 125, value: "22.8" },
    { id: 126, value: "37.9 / 38.3" },
    { id: 127, value: "56.0 / 55.9" },
    { id: 128, value: "56.0 / 52.5" },
    { id: 129, value: "42.3 / 37.0" },

    // tableFields10 values
    { id: 130, value: "4,608" },
    { id: 131, value: "5,644" },
    { id: 132, value: "53 / 47" },

    // tableFields11 values
    { id: 133, value: <img src={check} /> },
    { id: 134, value: <img src={check} /> },
    { id: 135, value: <img src={check} /> },
  ];

  const car6 = [
    //tableFields1 values
    { id: 1, value: <img src={close} /> },
    { id: 2, value: <img src={check} /> },
    { id: 3, value: <img src={check} /> },
    { id: 4, value: <img src={check} /> },
    { id: 5, value: <img src={check} /> },
    { id: 6, value: <img src={close} /> },
    { id: 7, value: <img src={check} /> },
    { id: 8, value: <img src={check} /> },

    // tableFields2 values
    { id: 9, value: "290 kW" },
    { id: 10, value: "13,520" },
    { id: 11, value: "389 HP" },
    { id: 12, value: "442 ft-lbs" },
    { id: 13, value: "91 kWh" },
    { id: 14, value: "e-4FORCE (AWD)" },
    { id: 15, value: <img src={check} /> },
    { id: 16, value: <img src={check} /> },
    { id: 17, value: <img src={check} /> },

    // tableFields3 values
    { id: 18, value: "37" },
    { id: 19, value: "97/86/92" },
    { id: 20, value: "270" },

    // tableFields4 values
    { id: 21, value: <img src={check} /> },
    { id: 22, value: <img src={check} /> },
    { id: 23, value: <img src={check} /> },
    { id: 24, value: <img src={check} /> },
    { id: 25, value: <img src={check} /> },
    { id: 26, value: <img src={check} /> },
    { id: 27, value: <img src={check} /> },
    { id: 28, value: <img src={check} /> },
    { id: 29, value: <img src={check} /> },
    { id: 30, value: <img src={check} /> },
    { id: 31, value: <img src={check} /> },
    { id: 32, value: <img src={check} /> },
    { id: 33, value: <img src={check} /> },
    { id: 34, value: <img src={check} /> },
    { id: 35, value: <img src={check} /> },

    // tableFields5 values
    { id: 36, value: <img src={check} /> },
    { id: 37, value: <img src={close} /> },
    { id: 38, value: <img src={check} /> },
    { id: 39, value: <img src={close} /> },
    { id: 40, value: <img src={check} /> },
    { id: 41, value: <img src={close} /> },
    { id: 42, value: <img src={check} /> },
    { id: 43, value: <img src={check} /> },
    { id: 44, value: <img src={check} /> },

    // tableFields6 values
    { id: 45, value: <img src={check} /> },
    { id: 46, value: <img src={check} /> },
    { id: 47, value: <img src={check} /> },
    { id: 48, value: <img src={check} /> },
    { id: 49, value: <img src={check} /> },
    { id: 50, value: <img src={check} /> },
    { id: 51, value: <img src={check} /> },
    { id: 52, value: <img src={check} /> },
    { id: 53, value: <img src={check} /> },

    // tableFields7 values
    { id: 54, value: <img src={check} /> },
    { id: 55, value: <img src={check} /> },
    { id: 56, value: <img src={check} /> },
    { id: 57, value: <img src={check} /> },
    { id: 58, value: <img src={check} /> },
    { id: 59, value: <img src={check} /> },
    { id: 60, value: <img src={check} /> },
    { id: 61, value: <img src={check} /> },
    { id: 62, value: <img src={check} /> },
    { id: 63, value: <img src={check} /> },
    { id: 64, value: <img src={close} /> },
    { id: 65, value: <img src={check} /> },
    { id: 66, value: <img src={check} /> },
    { id: 67, value: <img src={check} /> },
    { id: 68, value: <img src={check} /> },
    { id: 69, value: <img src={check} /> },
    { id: 70, value: <img src={check} /> },
    { id: 71, value: <img src={check} /> },
    { id: 72, value: <img src={check} /> },
    { id: 73, value: <img src={check} /> },
    { id: 74, value: <img src={check} /> },
    { id: 75, value: <img src={check} /> },
    { id: 76, value: <img src={check} /> },
    { id: 77, value: <img src={check} /> },
    { id: 78, value: <img src={check} /> },
    { id: 79, value: <img src={check} /> },
    { id: 80, value: <img src={check} /> },
    { id: 81, value: <img src={check} /> },
    { id: 82, value: <img src={check} /> },
    { id: 83, value: <img src={check} /> },
    { id: 84, value: <img src={close} /> },
    { id: 85, value: <img src={check} /> },

    // tableFields8 values
    { id: 86, value: <img src={check} /> },
    { id: 87, value: <img src={check} /> },
    { id: 88, value: <img src={check} /> },
    { id: 89, value: <img src={check} /> },
    { id: 90, value: <img src={check} /> },
    { id: 91, value: <img src={check} /> },
    { id: 92, value: <img src={check} /> },
    { id: 93, value: <img src={check} /> },
    { id: 94, value: <img src={check} /> },
    { id: 95, value: <img src={check} /> },
    { id: 96, value: <img src={check} /> },
    { id: 97, value: <img src={check} /> },
    { id: 98, value: <img src={check} /> },
    { id: 99, value: <img src={check} /> },
    { id: 100, value: <img src={check} /> },
    { id: 101, value: <img src={check} /> },
    { id: 102, value: <img src={check} /> },
    { id: 103, value: <img src={check} /> },
    { id: 104, value: <img src={check} /> },
    { id: 105, value: <img src={check} /> },
    { id: 106, value: <img src={check} /> },
    { id: 107, value: <img src={check} /> },
    { id: 108, value: <img src={check} /> },
    { id: 109, value: <img src={check} /> },
    { id: 110, value: <img src={check} /> },
    { id: 111, value: <img src={check} /> },
    { id: 112, value: <img src={check} /> },
    { id: 113, value: <img src={check} /> },
    { id: 114, value: <img src={check} /> },
    { id: 115, value: <img src={check} /> },
    { id: 116, value: <img src={check} /> },
    { id: 117, value: <img src={check} /> },

    // tableFields9 values
    { id: 118, value: "109.3" },
    { id: 119, value: "182.9" },
    { id: 120, value: "85.5" },
    { id: 121, value: "5.4" },
    { id: 122, value: "5.3" },
    { id: 123, value: "1119.1" },
    { id: 124, value: "96.3" },
    { id: 125, value: "22.8" },
    { id: 126, value: "39.6 / 38.3" },
    { id: 127, value: "56.0 / 55.9" },
    { id: 128, value: "56.0 / 52.5" },
    { id: 129, value: "42.3 / 37.0" },

    // tableFields10 values
    { id: 130, value: "4,899" },
    { id: 131, value: "5,930" },
    { id: 132, value: "50 / 50" },

    // tableFields11 values
    { id: 133, value: <img src={check} /> },
    { id: 134, value: <img src={check} /> },
    { id: 135, value: <img src={check} /> },
  ];

  const Column1 = {
    "ENGAGE FWD": car1,
    "ENGAGE e-4FORCE": car2,
    "ENGAGE+ e-4FORCE": car3,
    "VENTURE+ FWD": car4,
    "EVOLVE+ FWD": car5,
    "EVOLVE+ e-4FORCE": car6,
  };

  const Column2 = {
    "ENGAGE FWD": car1,
    "ENGAGE e-4FORCE": car2,
    "ENGAGE+ e-4FORCE": car3,
    "VENTURE+ FWD": car4,
    "EVOLVE+ FWD": car5,
    "EVOLVE+ e-4FORCE": car6,
  };

  const Column3 = {
    "ENGAGE FWD": car1,
    "ENGAGE e-4FORCE": car2,
    "ENGAGE+ e-4FORCE": car3,
    "VENTURE+ FWD": car4,
    "EVOLVE+ FWD": car5,
    "EVOLVE+ e-4FORCE": car6,
  };

  const kheni2 = [
    {
      name: "Colores exteriores",
      multiRowValues: tableFields.map((item, index) => {
        let _index = index + 1;
        return {
          rowName: item,
          values: [
            Column1[ModelOne].filter((i) => i.id == _index)[0].value,
            Column2[ModelTwo].filter((i) => i.id == _index)[0].value,
            Column3[ModelThree].filter((i) => i.id == _index)[0].value,
          ],
        };
      }),
    },
    {
      name: "Mecánica",
      multiRowValues: tableFields2.map((item, index) => {
        let _index = index + tableFields.length + 1;
        // + parseInt(tableFields.length) + 2;
        return {
          rowName: item,
          values: [
            Column1[ModelOne].filter((i) => i.id == _index)[0].value,
            Column2[ModelTwo].filter((i) => i.id == _index)[0].value,
            Column3[ModelThree].filter((i) => i.id == _index)[0].value,
          ],
        };
      }),
    },
    {
      name: "Consumo",
      multiRowValues: tableFields3.map((item, index) => {
        let _index = index + tableFields.length + tableFields2.length + 1;
        return {
          rowName: item,
          values: [
            Column1[ModelOne].filter((i) => i.id == _index)[0].value,
            Column2[ModelTwo].filter((i) => i.id == _index)[0].value,
            Column3[ModelThree].filter((i) => i.id == _index)[0].value,
          ],
        };
      }),
    },
    {
      name: "Exterior",
      multiRowValues: tableFields4.map((item, index) => {
        let _index =
          index +
          tableFields.length +
          tableFields2.length +
          tableFields3.length +
          1;
        return {
          rowName: item,
          values: [
            Column1[ModelOne].filter((i) => i.id == _index)[0].value,
            Column2[ModelTwo].filter((i) => i.id == _index)[0].value,
            Column3[ModelThree].filter((i) => i.id == _index)[0].value,
          ],
        };
      }),
    },
    {
      name: "Interior - Asientos",
      multiRowValues: tableFields5.map((item, index) => {
        let _index =
          index +
          tableFields.length +
          tableFields2.length +
          tableFields3.length +
          tableFields4.length +
          1;
        return {
          rowName: item,
          values: [
            Column1[ModelOne].filter((i) => i.id == _index)[0].value,
            Column2[ModelTwo].filter((i) => i.id == _index)[0].value,
            Column3[ModelThree].filter((i) => i.id == _index)[0].value,
          ],
        };
      }),
    },
    {
      name: "Interior - Infoentretenimiento",
      multiRowValues: tableFields6.map((item, index) => {
        let _index =
          index +
          tableFields.length +
          tableFields2.length +
          tableFields3.length +
          tableFields4.length +
          tableFields5.length +
          1;
        return {
          rowName: item,
          values: [
            Column1[ModelOne].filter((i) => i.id == _index)[0].value,
            Column2[ModelTwo].filter((i) => i.id == _index)[0].value,
            Column3[ModelThree].filter((i) => i.id == _index)[0].value,
          ],
        };
      }),
    },
    {
      name: "Comodidad - Conveniencia",
      multiRowValues: tableFields7.map((item, index) => {
        let _index =
          index +
          tableFields.length +
          tableFields2.length +
          tableFields3.length +
          tableFields4.length +
          tableFields5.length +
          tableFields6.length +
          1;
        return {
          rowName: item,
          values: [
            Column1[ModelOne].filter((i) => i.id == _index)[0].value,
            Column2[ModelTwo].filter((i) => i.id == _index)[0].value,
            Column3[ModelThree].filter((i) => i.id == _index)[0].value,
          ],
        };
      }),
    },
    {
      name: "Seguridad",
      multiRowValues: tableFields8.map((item, index) => {
        let _index =
          index +
          tableFields.length +
          tableFields2.length +
          tableFields3.length +
          tableFields4.length +
          tableFields5.length +
          tableFields6.length +
          tableFields7.length +
          1;
        return {
          rowName: item,
          values: [
            Column1[ModelOne].filter((i) => i.id == _index)[0].value,
            Column2[ModelTwo].filter((i) => i.id == _index)[0].value,
            Column3[ModelThree].filter((i) => i.id == _index)[0].value,
          ],
        };
      }),
    },
    {
      name: "Dimensiones",
      multiRowValues: tableFields9.map((item, index) => {
        let _index =
          index +
          tableFields.length +
          tableFields2.length +
          tableFields3.length +
          tableFields4.length +
          tableFields5.length +
          tableFields6.length +
          tableFields7.length +
          tableFields8.length +
          1;
        return {
          rowName: item,
          values: [
            Column1[ModelOne].filter((i) => i.id == _index)[0].value,
            Column2[ModelTwo].filter((i) => i.id == _index)[0].value,
            Column3[ModelThree].filter((i) => i.id == _index)[0].value,
          ],
        };
      }),
    },
    {
      name: "Peso",
      multiRowValues: tableFields10.map((item, index) => {
        let _index =
          index +
          tableFields.length +
          tableFields2.length +
          tableFields3.length +
          tableFields4.length +
          tableFields5.length +
          tableFields6.length +
          tableFields7.length +
          tableFields8.length +
          tableFields9.length +
          1;
        return {
          rowName: item,
          values: [
            Column1[ModelOne].filter((i) => i.id == _index)[0].value,
            Column2[ModelTwo].filter((i) => i.id == _index)[0].value,
            Column3[ModelThree].filter((i) => i.id == _index)[0].value,
          ],
        };
      }),
    },
    {
      name: "Suspención",
      multiRowValues: tableFields11.map((item, index) => {
        let _index =
          index +
          tableFields.length +
          tableFields2.length +
          tableFields3.length +
          tableFields4.length +
          tableFields5.length +
          tableFields6.length +
          tableFields7.length +
          tableFields8.length +
          tableFields9.length +
          tableFields10.length +
          1;
        return {
          rowName: item,
          values: [
            Column1[ModelOne].filter((i) => i.id == _index)[0].value,
            Column2[ModelTwo].filter((i) => i.id == _index)[0].value,
            Column3[ModelThree].filter((i) => i.id == _index)[0].value,
          ],
        };
      }),
    },
  ];

  let cross = (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 16.6437L1 1.79688M13 1.79688L7.00002 9.22033L1 16.6438"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  let route = useLocation();
  const comproute = route.state.modeldata;

  const Comperhandeler = () => {
    console.log(comproute);
    const newindex = comproute.index;
    if (comproute.path.pathname === "/Model") {
      Navigate("/Model", {
        state: {
          newindex,
        },
      });
    }
  };

  const comparedata = () => {
    ReactGA.event({
      category: "Compare",
      action: "Compare/" + `${ModelOne}/` + `${ModelTwo}/` + `${ModelThree}`,
    });
    console.log(
      "🚀 ~ file: index.js:1508 ~ comparedata ~ ModelThree:",
      ModelThree
    );
  };
  useEffect(() => {
    comparedata();
  }, [ModelOne, ModelTwo, ModelThree]);

  return (
    <>
      <Helmet>
        <title> Nissan | Comparaciones</title>
      </Helmet>
      <div className="comparition-top-main-parent-div">
        <div className="comparition-top-main-parent-width-div">
          <div className="Comparition-main-div">
            <div className="Comparition-head-div">
              <div className="Comparition-head-txt-div">
                <div className="new_heading_text_comper">
                  <div>
                    <span className="Comparition-head-txt">Comparaciones</span>
                    <div className="border-bottom-for-compare-div"></div>
                  </div>
                  <span onClick={Comperhandeler}>{cross}</span>
                </div>
              </div>
              <div></div>
            </div>
            <div className="Comparition-body-div">
              <div className="Comparition-body-width-div">
                <div className="Comparition-car-card-main-div">
                  <div className="Comparition-car-card-head-div">
                    <span>Nissan ARIYA</span>
                  </div>

                  <div className="inputModel">
                    <select
                      className="selectTag"
                      value={ModelOne}
                      onChange={(e) => setModelOne(e.target.value)}>
                      {ModelOneName.map((item, index) => {
                        return (
                          <option
                            value={item}
                            key={index}
                            defaultChecked={index === 0 ? true : false}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="Comparition-car-card-body-div">
                    <div className="Comparition-car-card-body-icons-div">
                      <span>
                        {engineIcon} {CompareDataAriya[ModelOne].engine}
                      </span>
                      <span>
                        {batteryIcon} {CompareDataAriya[ModelOne].baterry}
                      </span>
                      <span>
                        {carIcon} {CompareDataAriya[ModelOne].car}
                      </span>
                    </div>
                    <div className="Comparition-car-card-body-icons-div">
                      <span>Alcance: {CompareDataAriya[ModelOne].Alcance}</span>
                      <span>MPGe: {CompareDataAriya[ModelOne].MPGe}</span>
                      <span>
                        MSRP:{" "}
                        <span className="compare-price-txt">
                          ${ConvertedMSRPModel1}
                        </span>
                      </span>
                    </div>
                    <div className="Comparition-car-card-body-image-div">
                      <img
                        src={CompareDataAriya[ModelOne].img}
                        alt="img"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>

                <div className="Comparition-car-card-main-div">
                  <div className="Comparition-car-card-head-div">
                    <span>Nissan ARIYA</span>
                  </div>

                  <div className="inputModel">
                    <select
                      className="selectTag"
                      value={ModelTwo}
                      onChange={(e) => setModelTwo(e.target.value)}>
                      {ModelOneName.map((item, index) => {
                        return (
                          <option
                            value={item}
                            key={index}
                            defaultChecked={index === 0 ? true : false}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="Comparition-car-card-body-div">
                    <div className="Comparition-car-card-body-icons-div">
                      <span>
                        {engineIcon} {CompareDataAriya[ModelTwo].engine}
                      </span>
                      <span>
                        {batteryIcon} {CompareDataAriya[ModelTwo].baterry}
                      </span>
                      <span>
                        {carIcon} {CompareDataAriya[ModelTwo].car}
                      </span>
                    </div>
                    <div className="Comparition-car-card-body-icons-div">
                      <span>Alcance: {CompareDataAriya[ModelTwo].Alcance}</span>
                      <span>MPGe: {CompareDataAriya[ModelTwo].MPGe}</span>
                      <span>
                        MSRP:{" "}
                        <span className="compare-price-txt">
                          ${ConvertedMSRPModel2}
                        </span>
                      </span>
                    </div>
                    <div className="Comparition-car-card-body-image-div">
                      <img
                        src={CompareDataAriya[ModelTwo].img}
                        alt="img"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>

                <div className="Comparition-car-card-main-div">
                  <div className="Comparition-car-card-head-div">
                    <span>Nissan ARIYA</span>
                  </div>

                  <div className="inputModel">
                    <select
                      className="selectTag"
                      value={ModelThree}
                      onChange={(e) => setModelThree(e.target.value)}>
                      {ModelOneName.map((item, index) => {
                        return (
                          <option
                            value={item}
                            key={index}
                            defaultChecked={index === 0 ? true : false}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="Comparition-car-card-body-div">
                    <div className="Comparition-car-card-body-icons-div">
                      <span>
                        {engineIcon} {CompareDataAriya[ModelThree].engine}
                      </span>
                      <span>
                        {batteryIcon} {CompareDataAriya[ModelThree].baterry}
                      </span>
                      <span>
                        {carIcon} {CompareDataAriya[ModelThree].car}
                      </span>
                    </div>
                    <div className="Comparition-car-card-body-icons-div">
                      <span>
                        Alcance: {CompareDataAriya[ModelThree].Alcance}
                      </span>
                      <span>MPGe: {CompareDataAriya[ModelThree].MPGe}</span>
                      <span>
                        MSRP:{" "}
                        <span className="compare-price-txt">
                          ${ConvertedMSRPModel3}
                        </span>
                      </span>
                    </div>
                    <div className="Comparition-car-card-body-image-div">
                      <img
                        src={CompareDataAriya[ModelThree].img}
                        alt="img"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Swiper
                effect={"cards"}
                grabCursor={true}
                modules={[EffectCards]}
                className="Comparition-body-width-div-risponsive">
                <SwiperSlide className="Comparition-car-card-main-div">
                  <div className="Comparition-car-card-head-div">
                    <span>Nissan ARIYA</span>
                  </div>

                  <div className="inputModel">
                    <select
                      className="selectTag"
                      value={ModelOne}
                      onChange={(e) => setModelOne(e.target.value)}>
                      {ModelOneName.map((item, index) => {
                        return (
                          <option
                            value={item}
                            key={index}
                            defaultChecked={index === 0 ? true : false}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="Comparition-car-card-body-div">
                    <div className="Comparition-car-card-body-icons-div">
                      <span>
                        {engineIcon} {CompareDataAriya[ModelOne].engine}
                      </span>
                      <span>
                        {batteryIcon} {CompareDataAriya[ModelOne].baterry}
                      </span>
                      <span>
                        {carIcon} {CompareDataAriya[ModelOne].car}
                      </span>
                    </div>
                    <div className="Comparition-car-card-body-icons-div">
                      <span>Alcance: {CompareDataAriya[ModelOne].Alcance}</span>
                      <span>MPGe: {CompareDataAriya[ModelOne].MPGe}</span>
                      <span>
                        MSRP:{" "}
                        <span className="compare-price-txt">
                          ${ConvertedMSRPModel1}
                        </span>
                      </span>
                    </div>
                    <div className="Comparition-car-card-body-image-div">
                      <img
                        src={CompareDataAriya[ModelOne].img}
                        alt="img"
                        width="100%"
                      />
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className="Comparition-car-card-main-div">
                  <div className="Comparition-car-card-head-div">
                    <span>Nissan ARIYA</span>
                  </div>

                  <div className="inputModel">
                    <select
                      className="selectTag"
                      value={ModelTwo}
                      onChange={(e) => setModelTwo(e.target.value)}>
                      {ModelOneName.map((item, index) => {
                        return (
                          <option
                            value={item}
                            key={index}
                            defaultChecked={index === 0 ? true : false}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="Comparition-car-card-body-div">
                    <div className="Comparition-car-card-body-icons-div">
                      <span>
                        {engineIcon} {CompareDataAriya[ModelTwo].engine}
                      </span>
                      <span>
                        {batteryIcon} {CompareDataAriya[ModelTwo].baterry}
                      </span>
                      <span>
                        {carIcon} {CompareDataAriya[ModelTwo].car}
                      </span>
                    </div>
                    <div className="Comparition-car-card-body-icons-div">
                      <span>Alcance: {CompareDataAriya[ModelTwo].Alcance}</span>
                      <span>MPGe: {CompareDataAriya[ModelTwo].MPGe}</span>
                      <span>
                        MSRP:{" "}
                        <span className="compare-price-txt">
                          ${ConvertedMSRPModel2}
                        </span>
                      </span>
                    </div>
                    <div className="Comparition-car-card-body-image-div">
                      <img
                        src={CompareDataAriya[ModelTwo].img}
                        alt="img"
                        width="100%"
                      />
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide className="Comparition-car-card-main-div">
                  <div className="Comparition-car-card-head-div">
                    <span>Nissan ARIYA</span>
                  </div>

                  <div className="inputModel">
                    <select
                      className="selectTag"
                      value={ModelThree}
                      onChange={(e) => setModelThree(e.target.value)}>
                      {ModelOneName.map((item, index) => {
                        return (
                          <option
                            value={item}
                            key={index}
                            defaultChecked={index === 0 ? true : false}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="Comparition-car-card-body-div">
                    <div className="Comparition-car-card-body-icons-div">
                      <span>
                        {engineIcon} {CompareDataAriya[ModelThree].engine}
                      </span>
                      <span>
                        {batteryIcon} {CompareDataAriya[ModelThree].baterry}
                      </span>
                      <span>
                        {carIcon} {CompareDataAriya[ModelThree].car}
                      </span>
                    </div>
                    <div className="Comparition-car-card-body-icons-div">
                      <span>
                        Alcance: {CompareDataAriya[ModelThree].Alcance}
                      </span>
                      <span>MPGe: {CompareDataAriya[ModelThree].MPGe}</span>
                      <span>
                        MSRP:{" "}
                        <span className="compare-price-txt">
                          ${ConvertedMSRPModel3}
                        </span>
                      </span>
                    </div>
                    <div className="Comparition-car-card-body-image-div">
                      <img
                        src={CompareDataAriya[ModelThree].img}
                        alt="img"
                        width="100%"
                      />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <div className="comparition-data-body-div">
          <div className="carnamesss">
            <div className="mainnames">
              <span>{ModelOne}</span>
              <span>{ModelTwo}</span>
              <span>{ModelThree}</span>
            </div>
          </div>

          <div className="pb-3"></div>

          <div className="tableone">
            {kheni2.map((item, i) => {
              return (
                <div className="w-100 pb-5" key={i}>
                  <div className="auroradiv">
                    <span className="Features-table-head-txt">{item.name}</span>
                  </div>
                  {item.multiRowValues.map((_item, index) => {
                    return (
                      <div className="auroradiv">
                        <span>{_item.rowName}</span>
                        <p className="m-0">{_item.values[0]}</p>
                        <p className="m-0">{_item.values[1]}</p>
                        <p className="m-0">{_item.values[2]}</p>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Compare;
