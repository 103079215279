import React, { useState, useEffect, useRef } from "react";
import "./LeafSection2.css";
import "../../../Gallery/Exterior/Exterior.css";
import Carousel from "react-bootstrap/Carousel";
import inter1 from "../../../../../Leaf/assets/images/GalleryInterior/img3.png";
import inter2 from "../../../../../Leaf/assets/images/GalleryInterior/img5.png";
import inter3 from "../../../../../Leaf/assets/images/Section2/3.png";
import inter4 from "../../../../assets/images/GalleryExterior/img2.png";
import inter5 from "../../../../assets/images/GalleryExterior/img5.png";
import inter6 from "../../../../assets/images/Section2/6.1.png";
import Modal from "react-bootstrap/Modal";
import downarrow from "../../../../assets/images/chevrondown.png";
import { useNavigate } from "react-router-dom";
import close from "../../../../../Leaf/assets/images/close.png";
import ReactGA from "react-ga4";

const Section2 = () => {
  const [Pic1ModalShow, setPic1ModalShow] = useState(false);
  const [Pic2ModalShow, setPic2ModalShow] = useState(false);
  const [Pic3ModalShow, setPic3ModalShow] = useState(false);
  const navigate = useNavigate();
  const pic1ref = useRef();
  const pic2ref = useRef();
  const pic3ref = useRef();
  const [show, setshow] = useState(false);
  const [image, setimage] = useState("");

  const menuref = useRef(null);
  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setshow(false);
        }
      }
    };
    document.addEventListener("scroll", handler);
    return () => {
      document.removeEventListener("scroll", handler);
    };
  }, []);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const Imagelink = (e) => {
    ReactGA.event({
      category: "Landing page",
      action: "See more images",
    });
    setimage(e.target.className);
    setshow(true);
    console.log(e.target);
    if (e.target.className === "fistbox_img") {
      setimage(inter1);
    } else if (e.target.className === "secondbox_img") {
      setimage(inter2);
    } else if (e.target.className === "thirdbox_img") {
      setimage(inter3);
    } else if (e.target.className === "fourbox_img") {
      setimage(inter4);
    } else if (e.target.className === "fivebox_img") {
      setimage(inter5);
    } else if (e.target.className === "sixbox_img") {
      setimage(inter6);
    }
  };
  return (
    <>
      <div className="gellerimaindiv">
        <span className="heding_text_span">
          ¡Reserva tu futuro eléctrico hoy! Ahora puedes reservar en línea tu
          Nissan LEAF, el nuevo crossover 100% eléctrico con hasta 304 millas de
          alcance y 389 HP. Con solo $500 de depósito podrás comenzar a
          disfrutar un experiencia electrificante. ¡Reserva aquí!
        </span>
        <h1 className="heading_text_h2">Galería </h1>
        <Carousel
          id="Galeria"
          interval={null}
          activeIndex={index}
          onSelect={handleSelect}>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="one_img_box">
                  <div className="fistbox_img" onClick={Imagelink}>
                    <p>Palanca de cambios ergonómica</p>
                  </div>
                  <div className="secondbox_img" onClick={Imagelink}>
                    <p>Pantalla táctil de 8"</p>
                  </div>
                </div>
                <div className="Two_img_box">
                  <div className="thirdbox_img" onClick={Imagelink}>
                    <p>Monitor Inteligente Around View® de visión periférica</p>
                  </div>
                  {/* <img onClick={Imagelink} src={inter4} alt="" /> */}
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="one_img_box">
                  <div className="fourbox_img" onClick={Imagelink}>
                    <p>Nueva fascia delantera</p>
                  </div>
                  <div className="fivebox_img" onClick={Imagelink}>
                    <p>Aros de 17”</p>
                  </div>
                </div>
                <div className="Two_img_box">
                  {/* <img onClick={Imagelink} src={inter3} alt="" /> */}
                  <div className="sixbox_img" onClick={Imagelink}>
                    <p>E-Pedal</p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>

        {/*================================ mobile view div ================================ */}
        <Carousel
          id="Galeriambile"
          interval={null}
          activeIndex={index}
          onSelect={handleSelect}>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="Two_img_box">
                  <div className="fistbox_img" onClick={Imagelink}>
                    <p>Palanca de cambios ergonómica</p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="Two_img_box">
                  <div className="secondbox_img" onClick={Imagelink}>
                    <p>Pantalla táctil de 8"</p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="Two_img_box">
                  <div className="thirdbox_img" onClick={Imagelink}>
                    <p>Monitor Inteligente Around View® de visión periférica</p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="Two_img_box">
                  <div className="fourbox_img" onClick={Imagelink}>
                    <p>Nueva fascia delantera</p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="Two_img_box">
                  <div className="fivebox_img" onClick={Imagelink}>
                    <p>Aros de 17”</p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container2">
              <div className="maindivimg">
                <div className="Two_img_box">
                  <div className="sixbox_img" onClick={Imagelink}>
                    <p>E-Pedal</p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
        <div className="viewarrowdiv">
          <div
            className="smajhniaraha"
            onClick={() => navigate("/LeafGaleria")}>
            <span>Ver más imágenes</span>
            <div>
              <img onClick={Imagelink} src={downarrow} alt="" />
            </div>
          </div>
        </div>
        {show && (
          <div className="model" ref={menuref}>
            <div className="closeicon">
              <img src={close} alt="" onClick={() => setshow(false)} />
              <img src={image} alt="" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Section2;
