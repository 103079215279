// import img1 from "../../../Ariya/asset/images/Engage FWD/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/1.png";
import img1 from "../../../Ariya/assets/images/Engage FWD/Exterior/Deep Ocean Blue Pearl - Black Diamond Pearl/1.png";
import img2 from "../../../Ariya/assets/images/Engage e-4orce/Exterior/Boulder Gray Pearl/1.png";
import img3 from "../../../Ariya/assets/images/Engage+ e-4orce/Exterior/Brilliant Silver - Black Diamond/1.png";
import img4 from "../../../Ariya/assets/images/VENTURE/Exterior/Black Diamond Pearl/1.png";
import img5 from "../../../Ariya/assets/images/EVOLVE+ FDW/Exterior/Northern Lights Metallic/1.png";
import img6 from "../../../Ariya/assets/images/Evolve+ e-4orce/Exterior/Passion Red/1.png";

const Model_data = [
  {
    id: 1,
    img: img1,
    title: "ENGAGE FWD",
  },
  {
    id: 2,
    img: img2,
    title: "ENGAGE e-4ORCE",
  },
  {
    id: 3,
    img: img3,
    title: "ENGAGE+ e-4ORCE",
  },
  {
    id: 4,
    img: img4,
    title: "VENTURE+ FWD",
  },
  {
    id: 5,
    img: img5,
    title: "EVOLVE+ FWD",
  },
  {
    id: 6,
    img: img6,
    title: "EVOLVE+ e-4ORCE",
  },
];

export default Model_data;
