import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Section3.css";
import "swiper/css";
import "swiper/css/effect-fade";
import { EffectFade } from "swiper";
import { EVOLVE } from "../../../../constants/Data";
import tick from "../../../../assets/images/colortickicon.png";
import ReactGA from "react-ga4";
function Section3() {
  const wasd = useRef(null);
  console.log("check car name ", EVOLVE);

  const AriyaColorCode = Object.keys(EVOLVE.exterior);

  const [color, setcolor] = useState("#ff0000");

  let Ext_name;

  const ExtcolorInfo = EVOLVE.exterior[color];

  if (ExtcolorInfo) {
    Ext_name = ExtcolorInfo.Ext_name;

    // Ext_code = colorInfo.Ext_code;
  }
  const colors = (item) => {
    // console.log("check colour", item);
    const carcolour = ExtcolorInfo.Ext_name;
    ReactGA.event({
      category: "Landing page",
      action: carcolour,
    });
    // console.log("Ext_colorInfo", carcolour);
    // const carcolour = (EVOLVE.exterior.Ext_code = item);
    // console.log(carcolour, "check cars colour");
    setcolor(item);
  };
  return (
    <div className="main_content_container_swiper">
      <div className="main_heading_text_sec3">
        <span className="heading_text_sec3">
          Haz clic para explorar a Nissan ARIYA® en 360 grados
        </span>
      </div>
      <div className="d-flex justify-centen-center align-items-center slider_box">
        <span type="button" onClick={() => wasd.current.swiper.slidePrev()}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.5156 19.0039L8.51563 12.0039L15.5156 5.00391"
              stroke="#464646"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>

        <Swiper
          ref={wasd}
          effect={"fade"}
          modules={[EffectFade]}
          id="mySwiper_sec3"
          loop={true}
          fadeEffect={{
            crossFade: true, // enables slides to cross fade
          }}>
          {EVOLVE.exterior[color].images.map((item, i) => {
            return (
              <SwiperSlide className="sec3_slider" key={i}>
                <img src={item} alt="not found" />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <span type="button" onClick={() => wasd.current.swiper.slideNext()}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.1797 8.33659L25.8464 20.0033L14.1797 31.6699"
              stroke="#464646"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className="last_div_box">
        <div className="button_div">
          <span>{Ext_name}</span>
          <div className="inner_button_div ">
            {AriyaColorCode.map((item, index) => {
              return (
                <button
                  onClick={() => colors(item)}
                  // onClick={() => console.log("hey======", item)}
                  style={
                    item == "#1e2f41"
                      ? {
                          background: item,
                        }
                      : item == "#000000"
                      ? {
                          background: item,
                        }
                      : item == "#001746"
                      ? {
                          background:
                            "linear-gradient(to right, #001746 50%, #000 50%)",
                        }
                      : item == "#696C71"
                      ? { background: item }
                      : item == "#676A6F"
                      ? {
                          background:
                            "linear-gradient(to right, #676A6F 50%, #000 50%)",
                        }
                      : item == "#DFE8E3"
                      ? {
                          background:
                            "linear-gradient(to right, #DFE8E3 50%, #000 50%)",
                        }
                      : item == "#ff0000"
                      ? {
                          background:
                            "linear-gradient(to right, #ff0000 50%, #000 50%)",
                        }
                      : null
                  }
                  key={index}
                  type="button">
                  {color === item ? <img src={tick} alt="" /> : null}
                </button>
              );
            })}
          </div>
        </div>
        <span className="ending_text_sec3">Modelo ilustrado: EVOLVE+ FWD</span>
      </div>
    </div>
  );
}
export default Section3;
