import React, { Fragment, useEffect, useState } from "react";
import "./ModalMain.css";
import Navbar from "../../../components/UI/Navbar";
import Footer from "../../../../Common/components/UI/Footer";
import Model1 from "../Model1";
import Model2 from "../Model2";
import Model3 from "../Model3";
import Model4 from "../Model4";
import Model5 from "../Model5";
import Model6 from "../Model6";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Button from "../../../../Common/components/UI/Button";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

export function ModelScreenAlert(props) {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered>
      <Modal.Body className="white text-center pt-3">
        <h4 className="pt-2 pb-2">Información importante</h4>
        <p className="pt-2 pb-2 Modal-text">
          Los precios que surgen en la página son válidos al momento en el que
          el Usuario reserva su vehículo mediante un depósito de Quinientos
          dólares, ($500.00). El precio de la reservación se honrará para el
          Usuario que reserve el vehículo mediante el pago referenciado. Sin
          embargo, los precios anunciados pueden cambiar en cualquier momento
          sin ningún tipo de aviso previo.
        </p>
        <div className="p-2"></div>
        <div className="LogOut-btns-parent-div">
          <Button variant="red" onClick={props.onHide}>
            Entendido
          </Button>
        </div>
        <div className="p-2"></div>
      </Modal.Body>
    </Modal>
  );
}

const ModalMain = () => {
  const data = localStorage.getItem("cardindex");
  const nav = useLocation();
  // console.log("🚀 ~ file: index.js:16 ~ ModalMain ~ nav:", nav);
  // const [steps, setsteps] = useState(parseInt(data));
  const [steps, setsteps] = useState(localStorage.getItem("cardindex"));
  // console.log("🚀 ~ file: index.js:18 ~ ModalMain ~ data:", data);
  // console.log("🚀 ~ file: index.js:18 ~ ModalMain ~ steps:", steps);
  let route = useLocation();
  // const maindata = route.state.newindex;

  // console.log("ghhg", maindata);
  // useEffect(() => {
  //   localStorage.("cardindex", maindata);
  //   // setsteps(maindata);
  // }, [maindata]);

  return (
    <>
      <Helmet>
        <title>Nissan |Explora ARIYA®</title>
        <meta
          name="description"
          content="Colores y especificaciones del modelo ARIYA®"
        />
        Explora ARIYA®
      </Helmet>

      <Navbar setsteps={setsteps} steps={steps} />
      <div className="cardetailpage" style={{ marginTop: "101px" }}>
        <div className="modelselectdiv">
          <span className="d-flex flex-column align-items-center justify-content-center">
            <span onClick={() => setsteps(0)}>ENGAGE FWD</span>
            <span className={steps == 0 ? "spanactive" : ""}></span>
          </span>
          <span className="d-flex flex-column align-items-center justify-content-center">
            <span onClick={() => setsteps(1)}>ENGAGE e-4ORCE</span>
            <span className={steps == 1 ? "spanactive" : ""}></span>
          </span>
          <span className="d-flex flex-column align-items-center justify-content-center">
            <span onClick={() => setsteps(2)}>ENGAGE+ e-4ORCE</span>
            <span className={steps == 2 ? "spanactive" : ""}></span>
          </span>
          <span className="d-flex flex-column align-items-center justify-content-center">
            <span onClick={() => setsteps(3)}>VENTURE+ FWD</span>
            <span className={steps == 3 ? "spanactive" : ""}></span>
          </span>
          <span className="d-flex flex-column align-items-center justify-content-center">
            <span onClick={() => setsteps(4)}>EVOLVE+ FWD</span>
            <span className={steps == 4 ? "spanactive" : ""}></span>
          </span>
          <span className="d-flex flex-column align-items-center justify-content-center">
            <span onClick={() => setsteps(5)}>EVOLVE+ e-4ORCE</span>
            <span className={steps == 5 ? "spanactive" : ""}></span>
          </span>
        </div>
        <div className="allmo">{steps == 0 ? <Model1 /> : null}</div>
        <div className="allmo">{steps == 1 ? <Model2 /> : null}</div>
        <div className="allmo">{steps == 2 ? <Model3 /> : null}</div>
        <div className="allmo">{steps == 3 ? <Model4 /> : null}</div>
        <div className="allmo">{steps == 4 ? <Model5 /> : null}</div>
        <div className="allmo">{steps == 5 ? <Model6 /> : null}</div>
      </div>
      <Footer />
    </>
  );
};

export default ModalMain;
