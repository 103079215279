import React, { useState, useEffect } from "react";
import "./Login.css";
import Button from "../../../../Common/components/UI/Button";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik, Field } from "formik";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-hot-toast";
import { LoginSchema } from "../../../schemas";
import Card from "../../../components/UI/Card";
import Wrapper from "../../../components/UI/Wrapper";
import axios from "../../../../Common/Api";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

const Login = () => {
  const location = useLocation();
  const loginn = () => {
    ReactGA.event({
      category: "Model",
      action: "INICIAR SESIÓN ",
    });
    window.dataLayer.push({
      event: "Iniciar-Sesion",
      user_id: "565625622562562562562627228828",
      action: "INICIAR SESIÓN ",
    });
  };
  const Olvidaste_contrasena = () => {
    ReactGA.event({
      category: "Model",
      action: "¿Olvidaste tu contraseña?",
    });
    window.dataLayer.push({
      event: "Iniciar-Sesion",
      user_id: "565625622562562562562627228828",
      action: "¿Olvidaste tu contraseña?",
    });
  };
  const Regístratee = () => {
    ReactGA.event({
      category: "Model",
      action: "¿No tienes cuenta? Regístrate.",
    });
    window.dataLayer.push({
      event: "Iniciar-Sesion",
      user_id: "565625622562562562562627228828",
      action: "¿No tienes cuenta? Regístrate.",
    });
  };
  const concesionarios = () => {
    ReactGA.event({
      category: "Model",
      action: "concesionarios Regístrate",
    });
    window.dataLayer.push({
      event: "Iniciar-Sesion",
      user_id: "565625622562562562562627228828",
      action:
        "INICIAR SESIÓN | ¿Olvidaste tu contraseña?| ¿No tienes cuenta? Regístrate.",
    });
  };

  const initialValues = {
    Email: "",
    Password: "",
  };

  const Navigate = useNavigate();

  const [Loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "/login",
        {
          Email: values.Email,
          Password: values.Password,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );

      console.log("🚀 response.......", response);
      // setIsError(response.data.message);
      if (response.data.status == "failed") {
        toast.error(`${response.data.message}`);
        setLoading(false);
      }
      if (response.data.status == "success") {
        setLoading(false);
        let result = response.data.data;
        localStorage.setItem("user-info", JSON.stringify(result));

        toast.success(`${response.data.message}`);

        setLoading(true);
        const res = await axios.get("/get-history", {
          headers: {
            Authorization: `Bearer ${response.data.data.Token}`,
          },
        });

        console.log("Get-history-api______________##########", res.data);

        if (
          (res.data.status == "success" &&
            res.data.data &&
            res.data.data.status == "Not_In_Inventory" &&
            res.data.data.track_status == "Deposit") ||
          (res.data.status == "success" &&
            res.data.data &&
            res.data.data.status == "No_Disponible_Fábrica" &&
            res.data.data.track_status == "Deposit")
        ) {
          console.log(
            "🚀 ~ file: index.js:65 ~ handleSubmit ~ res.data.data.status:",
            res.data.data.status
          );

          localStorage.removeItem("StaticCartData");
          setLoading(false);
          Navigate("/Deposit");
        } else if (
          res.data.status == "success" &&
          res.data.message == "Record"
        ) {
          // localStorage.removeItem("Register-Start");
          localStorage.removeItem("StaticCartData");
          setLoading(false);
          Navigate("/Perfil/History");
        } else if (
          res.data.status == "success" &&
          res.data.message == "No Record"
        ) {
          if (localStorage.getItem("StaticCartData")) {
            Navigate("/Interest");
          } else {
            Navigate("/");
          }
        }
      }
      // else {
      //   response.data.status = "failed";
      //   setLoading(false);
      //   Navigate("/");
      //   toast.failed(`${response.data.message}`);
      // }
    } catch (error) {
      // console.log("🚀 error", error)
      toast.error(`${error.response.data.message}`);
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Nissan | Accede a tu cuenta de reservaciones </title>
        <meta
          name="description"
          content="Comienza o continúa el proceso de reservación de tu vehículo eléctrico"
        />
      </Helmet>
      <Wrapper>
        <Card head={"Iniciar sesión"}>
          <div className="p-2"></div>
          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            onSubmit={handleSubmit}>
            {(props) => (
              <Form className="d-flex flex-column gap-3">
                <div className="d-flex flex-column w-100">
                  <div className="input--">
                    <span>Correo electrónico</span>
                    <Field
                      type="email"
                      name="Email"
                      placeholder="Ingresa tu correo electrónico"
                      className="input-field"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.Email}
                    />
                    <div className="p-1"></div>
                  </div>
                  {props.errors.Email && props.touched.Email ? (
                    <p className="form-error"> {props.errors.Email} </p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="d-flex flex-column">
                  <div className="input--">
                    <span>Contraseña</span>
                    <Field
                      type="password"
                      name="Password"
                      placeholder={"Ingresa tu contraseña"}
                      className="input-field"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.Password}
                    />
                    <div className="p-1"></div>
                  </div>
                  {props.errors.Password && props.touched.Password ? (
                    <p className="form-error"> {props.errors.Password} </p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="d-flex justify-content-center pt-3 pb-3">
                  <Button variant={"red"} type="submit" onClick={loginn}>
                    {"Iniciar sesión"}
                  </Button>
                </div>

                <div className="links-login">
                  <Link
                    onClick={Olvidaste_contrasena}
                    to="/Olvidaste-contrasena"
                    className="text-decoration-none">
                    <span className="concesionario-span">
                      ¿Olvidaste tu contraseña?
                    </span>
                  </Link>
                  <Link
                    onClick={Regístratee}
                    to="/Registrate"
                    // to={
                    //   localStorage.getItem("StaticCartData")
                    //     ? "/Registrate"
                    //     : "/Intro"
                    // }
                    className="text-decoration-none">
                    <span className="concesionario-span">
                      ¿No tienes cuenta? Regístrate.
                    </span>
                  </Link>
                </div>

                {/* <div className="d-flex text-center justify-content-center align-items-center w-100 white eresdiv">
                  <Link
                    to="/RegistrateDealer"
                    className="text-decoration-none"
                    onClick={concesionarios}>
                    <span className="concesionario-span">
                      ¿Eres un concesionario? Realiza la reserva aquí.
                    </span>
                  </Link>
                </div> */}
              </Form>
            )}
          </Formik>
        </Card>
        {!Loading && <div></div>}
        {Loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </Wrapper>
    </>
  );
};

export default Login;
