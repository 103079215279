import React, { useState, useEffect } from "react";
import "./Exterior.css";
import { useNavigate } from "react-router";
// import img1 from "../../../assets/images/GalleryExterior/img1.JPG";
import img1 from "../../../assets/images/GalleryExterior/img1.png";
import img2 from "../../../assets/images/GalleryExterior/img2.png";
import img3 from "../../../assets/images/GalleryExterior/img3.png";
import img4 from "../../../assets/images/GalleryExterior/img4.png";
import img5 from "../../../assets/images/GalleryExterior/img5.png";
import img6 from "../../../assets/images/GalleryExterior/img6.png";
import img7 from "../../../assets/images/GalleryExterior/img7.png";
import img8 from "../../../assets/images/GalleryExterior/img8.png";
import close from "../../../assets/images/close.png";
import loder1 from "../../../assets/images/heading_loder.png";
import loder2 from "../../../assets/images/lg_loder.png";
import loder3 from "../../../assets/images/sml_loder.png";

const Exterior = () => {
  const navigate = useNavigate();
  const [show, setshow] = useState(false);
  const [image, setimage] = useState("");
  const [loading, setLoading] = useState(true);

  const Imagelink = (e) => {
    setimage(e.target.src);
    setshow(true);
  };

  const ico = (
    <svg
      width="15"
      height="15"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 13.75V20Z"
        fill="black"
      />
      <path
        d="M15 13.75V20M15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15C26.25 21.2132 21.2132 26.25 15 26.25ZM15.0623 10V10.125L14.9377 10.125V10H15.0623Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  });

  return (
    <>
      {show && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshow(false)} />
            <img src={image} alt="" />
          </div>
        </div>
      )}
      <div style={{ height: "100%", width: "100%" }}>
        {loading ? (
          <img
            className="LOADimg"
            src={loder1}
            alt=""
            height="100%"
            width="100%"
          />
        ) : (
          <img src={img1} alt="img" height="100%" width="100%" />
        )}
      </div>
      <div className="d-flex justify-content-center align-items-center pt-4 pb-5">
        <div className="width-div-Gallery">
          <div className="two-img-in-line-gallery">
            <div className="w-100 h-100">
              {loading ? (
                <img
                  className="LOADimg"
                  src={loder3}
                  alt=""
                  height="100%"
                  width="100%"
                />
              ) : (
                <img
                  src={img2}
                  alt="img"
                  className="w-100 h-100"
                  onClick={Imagelink}
                />
              )}
            </div>
            <div className="w-100 h-100">
              {loading ? (
                <img
                  className="LOADimg"
                  src={loder3}
                  alt=""
                  height="100%"
                  width="100%"
                />
              ) : (
                <img
                  src={img3}
                  alt="img"
                  className="w-100 h-100"
                  onClick={Imagelink}
                />
              )}
            </div>
          </div>
          <div className="two-img-in-line-gallery">
            <div className="w-100 h-100">
              {loading ? (
                <img
                  className="LOADimg"
                  src={loder3}
                  alt=""
                  height="100%"
                  width="100%"
                />
              ) : (
                <img
                  src={img4}
                  alt="img"
                  className="w-100 h-100"
                  onClick={Imagelink}
                />
              )}
            </div>
            <div className="w-100 h-100">
              {loading ? (
                <img
                  className="LOADimg"
                  src={loder3}
                  alt=""
                  height="100%"
                  width="100%"
                />
              ) : (
                <img
                  src={img5}
                  alt="img"
                  className="w-100 h-100"
                  onClick={Imagelink}
                />
              )}
            </div>
          </div>

          <div className="w-100 h-100">
            {loading ? (
              <img
                className="LOADimg"
                src={loder2}
                alt=""
                height="100%"
                width="100%"
              />
            ) : (
              <img src={img6} alt="img" className="w-100 h-100" />
            )}
          </div>

          <div className="two-img-in-line-gallery">
            <div className="w-100 h-100">
              {loading ? (
                <img
                  className="LOADimg"
                  src={loder3}
                  alt=""
                  height="100%"
                  width="100%"
                />
              ) : (
                <img
                  src={img7}
                  alt="img"
                  className="w-100 h-100"
                  onClick={Imagelink}
                />
              )}
            </div>
            <div className="w-100 h-100">
              {loading ? (
                <img
                  className="LOADimg"
                  src={loder3}
                  alt=""
                  height="100%"
                  width="100%"
                />
              ) : (
                <img
                  src={img8}
                  alt="img"
                  className="w-100 h-100"
                  onClick={Imagelink}
                />
              )}
            </div>
          </div>

          <div className="verdiv">
            <div className="verbtn">
              <button onClick={() => navigate("/Variantes")}>
                Ver modelos
              </button>
              <button onClick={() => navigate("/Intro")}>Regístrate</button>
              {/* <button onClick={() => setAlertModal(true)}>Regístrate</button> */}
            </div>
            <div className="lasttext">
              <p>
                <span>{ico}</span>
                Las opciones pueden diferir según las regiones. Toda la
                información y las ilustraciones se basan en los datos
                disponibles en el momento de la publicación y están sujetos a
                cambios sin previo aviso. Favor de referirse a la hoja de
                producto para más información.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exterior;
