import { React, useEffect, useState } from "react";
import Wrapper from "../../components/UI/Wrapper";
import Card from "../../components/UI/Card";
import Button from "../../../Common/components/UI/Button";
import "../../components/Alert/Alert.css";
import { Link } from "react-router-dom";

import { useLocation } from "react-router-dom";
import axios from "../../../Common/Api";
import ReactGA from "react-ga4";

const ThankYou = () => {
  const Thankyouu = () => {
    const UserData = JSON.parse(localStorage.getItem("user-info"));
    // ReactGA.event({
    //   category: "Thank You",
    //   action: "Thank You",
    // });
    const evenatdata = {
      event: "reserva_exito",
      user_id: UserData?.id ? UserData?.id : " ",
      modelo: CartData?.data?.Model,
      price: CartData?.data?.Price,
      color: CartData?.data?.Ext_Color_Name,
      opcon: "¡Gracias!",
      action: "CONTINUAR",
    };
    ReactGA.event({
      event: evenatdata.event,
      action: evenatdata.action,
      event_params: {
        modelo: evenatdata.modelo,
        user_id: evenatdata.user_id,
        option: evenatdata.opcon,
        color: evenatdata.color,
        Price: evenatdata.price,
      },
    });
    window.dataLayer.push({
      event: evenatdata.event,
      user_id: evenatdata.user_id,
      modelo: evenatdata.modelo,
      price: evenatdata.price,
      color: evenatdata.color,
      opcon: evenatdata.opcon,
      action: evenatdata.action,
    });
  };
  const [Ordertrackk, setOrdertrack] = useState({});
  const location = useLocation();
  const { CartData } = location.state;
  console.log("🚀 ~ ThankYou ~ CartData:", CartData.data);

  const UserData = JSON.parse(localStorage.getItem("user-info"));
  const trackid = localStorage.getItem("trackid");

  const Ordertrack = async () => {
    try {
      // setLoading(true);
      const response = await axios.post(
        "get-order-status",
        {
          track_id: trackid,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData?.Token}`,
          },
        }
      );
      console.log(response, "order-status track");
      if (response) {
        setOrdertrack(response.data.data);
        // if (
        //   response.data.data.Step_3_Status == "Yes" &&
        //   OrderHistory.data.track_status == "Reservation"

        //   // response.data.data.Step_3_Status == "Yes" &&
        //   // OrderHistory.data.confirm_status != "TRUE" &&
        //   // checkstatus == true
        // ) {
        //   setFirstModalShow(true);
        //   setContent(false);
        // }

        // setLoading(false);
      }
      // console.log("responsessss . . . . track", response);
    } catch (error) {
      // console.log(error, "errorrr");
    }
  };
  return (
    <div>
      <Wrapper>
        <Card head={""}>
          <div id="akhaini">
            <h1>¡Gracias!</h1>
            <div>
              <p>
                Hemos recibido tu información, te mantendremos informado a
                través del correo electrónico que proporcionaste.
              </p>
            </div>
            <div></div>
            <div className="d-flex justify-content-center align-items-center">
              {/* <Link to={"/Registrate"}> */}
              <Link
                to={
                  localStorage.getItem("StaticCartData")
                    ? "/Iniciar-Sesion"
                    : "/Perfil/History"
                }>
                <Button variant="red" onClick={Thankyouu}>
                  Continuar
                </Button>
              </Link>
            </div>
          </div>
        </Card>
      </Wrapper>
    </div>
  );
};

export default ThankYou;
