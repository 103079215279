import "./Section4.css";
import React, { useState } from "react";
// import VideoSlider from "../UI/VideoSlider";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
import "swiper/css/effect-cards";
// import "swiper/css/navigation";
import { EffectCards } from "swiper";
// !, EffectCoverflow
import { Navigation } from "swiper";

import Modeldata from "../../../../components/Data/Variants";

import downarrow from "../../../../assets/images/chevrondown.png";
import { Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import info from "../../../../assets/images/Info.png";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const Section4 = () => {
  const navigate = useNavigate();
  const [indexx, setindex] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  localStorage.setItem("cardindex", indexx);

  function ModelModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        centered
        className="wasd-modal-ko-badla"
        style={{ zIndex: "10000" }}>
        <Modal.Header className="new_model" closeButton></Modal.Header>
        <Modal.Body className="new_model text-center">
          <div className="Dropdown-Car-info-body-Head">
            <i>Información importante</i>
          </div>
          <p className="Modal-text">
            <div>
              El precio ilustrado no incluye accesorios. El precio es el Precio
              de venta sugerido por el fabricante (MSRP, por sus siglas en
              inglés). El MSRP no incluye impuestos, título, placas, opciones ni
              cobro por destino. El concesionario fija el precio final.
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  const ico = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => {
        e.stopPropagation();
        setModalShow(true);
      }}
      style={{ cursor: "pointer" }}>
      <path
        d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.09998V8H12.0498Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const ico2 = (
    <svg
      width="17"
      height="17"
      viewBox="0 0 24 24"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => {
        e.stopPropagation();
        setModalShow(true);
      }}>
      <path
        d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.09998V8H12.0498Z"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  // const check = Modeldata;
  // console.log(check);
  let Compathname = useLocation();
  const modeldata = {
    path: Compathname,
    index: "0",
  };

  const handleclick = (index, name, price) => {
    setindex(index);
    ReactGA.event({
      category: "Landing page",
      action: `${name} - ${price}`,
    });
    console.log("check name ", name, price);

    setTimeout(() => {
      navigate("/Model", {
        state: { modeldata },
      });
    }, 100);
  };

  // componentDidMount() {
  //   const swiper = new Swiper('.swiperkhain', {
  //     // Configure the swiper options here
  //   });

  //   // Move to the first card when the slider button is clicked after reaching the last card
  //   document.querySelector('.swiper-button-next:after').addEventListener('click', () => {
  //     if (swiper.isEnd) {
  //       swiper.slideTo(0);
  //     } else {
  //       swiper.slideNext();
  //     }
  //   });

  //   // Move to the last card when the slider button is clicked after reaching the first card
  //   document.querySelector('.swiper-button-prev:after').addEventListener('click', () => {
  //     if (swiper.isBeginning) {
  //       swiper.slideTo(swiper.slides.length - 1);
  //     } else {
  //       swiper.slidePrev();
  //     }
  //   });
  // };

  return (
    <>
      <ModelModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="moulasdiv" id="Specs">
        <span>Variantes {ico}</span>
        <div className="swiperkhain">
          <div className="cardmaindivva">
            <Swiper
              effect={"coverflow"}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
              }}
              modules={[Navigation]}
              // !, EffectCoverflow
              slidesPerView={3}
              // slidesPerView={"auto"}
              spaceBetween={10}
              navigation={true}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: false,
              // }}
              // watchSlidesProgress={true}
              loop={true}
              initialSlide={0}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              id="mySwiper4"
              breakpoints={{
                0.0: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1450: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
                1920: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}>
              {Modeldata.map((curelem, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="modulcard">
                      <div className="cardimg">
                        <motion.img
                          initial={{ x: 70, opacity: 0 }}
                          whileInView={{ x: 0, opacity: 1 }}
                          transition={{ duration: 0.5 }}
                          src={curelem.img}
                          alt=""
                          onClick={() =>
                            handleclick(index, curelem.name, curelem.price)
                          }
                        />
                        {/* <span onClick={() => setModalShow(true)}> <img src={info} alt="" /></span> */}
                      </div>
                      <div
                        className="masinfo"
                        onClick={() =>
                          handleclick(index, curelem.name, curelem.price)
                        }>
                        <span>Más información</span>
                      </div>
                      <div
                        onClick={() =>
                          handleclick(index, curelem.name, curelem.price)
                        }
                        className="modulscontent">
                        <span>{curelem.name}</span>

                        <p className="msrp_div ">
                          {curelem.price}
                          <span>{ico2}</span>
                        </p>
                        <div className="km">
                          <div className="first">
                            <span>{curelem.icon3}</span>
                            {/* <img src={curelem.icon3} alt="" /> */}
                            <span>{curelem.vorte}</span>
                          </div>
                          <div className="first">
                            {curelem.icon2}
                            {/* <img src={curelem.icon2} alt="" /> */}
                            <span>{curelem.km}</span>
                          </div>
                          <div className="first">
                            {curelem.icon}
                            {/* <img src={curelem.icon} alt="" /> */}
                            <span>{curelem.rwd}</span>
                          </div>
                        </div>
                        <div className="mphdiv">
                          <div className="linediv3"></div>
                          <div className="mph">
                            <p className="pragraphs">Alcance:</p>
                            <span>
                              {curelem.Alcance}
                              <p className="pragraphs">millas</p>
                            </span>
                            <p className="pragraphs">MPGe:</p>
                            <p className="pragraphs">{curelem.MPGe} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>

        <div className="cardmaindivvv">
          <Swiper
            effect={"cards"}
            grabCursor={true}
            loop={false}
            modules={[EffectCards]}
            className="mySwiper"
            tabIndex={0}
            initialSlide={0}>
            {Modeldata.map((curelem, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="modulcard">
                    <div className="cardimg">
                      <img
                        src={curelem.img}
                        alt=""
                        onClick={() => handleclick(index, curelem.name)}
                      />
                      {/* <span onClick={() => setModalShow(true)}><img src={info} alt="" /></span> */}
                    </div>
                    <div
                      onClick={() => handleclick(index, curelem.name)}
                      className="masinfo">
                      <span>Más información</span>
                    </div>
                    <div
                      onClick={() => handleclick(index, curelem.name)}
                      className="modulscontent">
                      <span>{curelem.name}</span>
                      <p className="msrp_div ">
                        {curelem.price}
                        <span>{ico2}</span>
                      </p>
                      <div className="km">
                        <div className="first">
                          {curelem.icon3}
                          {/* <img src={curelem.icon3} alt="" /> */}
                          <span>{curelem.vorte}</span>
                        </div>
                        <div className="first">
                          {curelem.icon2}
                          {/* <img src={curelem.icon2} alt="" /> */}
                          <span>{curelem.km}</span>
                        </div>
                        <div className="first">
                          {curelem.icon}
                          {/* <img src={curelem.icon} alt="" /> */}
                          <span>{curelem.rwd}</span>
                        </div>
                      </div>
                      <div className="mphdiv">
                        <div className="linediv3"></div>
                        <div className="mph">
                          <p className="pragraphs">Alcance:</p>
                          <span>
                            {curelem.Alcance}
                            <p className="pragraphs">millas</p>
                          </span>
                          <p className="pragraphs">MPGe:</p>
                          <p className="pragraphs">{curelem.MPGe} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="viewarrowdiv">
          <div className="smajhniaraha" onClick={() => navigate("/Variantes")}>
            <span>Ver más variantes</span>
            <div>
              <img src={downarrow} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section4;
