import React, { useEffect, useState, useContext } from "react";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../Common/components/UI/Button";
import { AllImages } from "../../constants/images";
import logo from "../../assets/images/NissanBlackLogo.png";
import "./ReviewAndPay.css";
import { Modal } from "react-bootstrap";
import axios from "../../../Common/Api";
import Backdrop from "@mui/material/Backdrop";
import Loader from "../../components/UI/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";

import {
  Ariya_EngageFWD,
  Ariya_Engage_e_4orce,
  Ariya_Engage__e_4orce,
  VENTURE,
  EVOLVE,
  evolvepe4orce,
} from "../../constants/Data";
import { LEAFS, LeafSVPlus } from "../../../Leaf/constants/Data";
import ReactGA from "react-ga";

const ReviewAndPay = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  const Navigate = useNavigate();

  const [OrderHistory, setOrderHistory] = useState({});
  const { setTrems, setPolicy } = useContext(GlobalContext);

  // Get User Data
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const StaticCartData = JSON.parse(localStorage.getItem("StaticCartData"));
  // const UserData = JSON.parse(localStorage.getItem("user-info"));
  // Store Api Data in useState Variable
  const [CartData, setCartData] = useState([]);

  // Loader useState Variable
  const [Loading, setLoading] = useState(true);

  // Address add Modal useState Variable
  const [ModalShow, setModalShow] = useState(false);

  // User Profile Data useState Variable
  const [UserProfileData, setUserProfileData] = useState({});

  // function Alertmodel(props) {
  //   return (
  //     <Modal
  //       {...props}
  //       size="md"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       backdrop="static"
  //       centered>
  //       <Modal.Header
  //       //  closeButton
  //       ></Modal.Header>
  //       <Modal.Body className="white text-center">
  //         <h4 className="pt-2 pb-2">Alerta!</h4>
  //         <p className="Modal-text">
  //           Su registro está incompleto para continuar por favor registre su
  //           dirección
  //         </p>
  //         <Button
  //           variant={"red"}
  //           onClick={() => Navigate("/RegistrateAddress")}>
  //           Dirección de registro
  //         </Button>
  //         <div className="p-2"></div>
  //       </Modal.Body>
  //     </Modal>
  //   );
  // }

  // const AddtoCart = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.post(
  //       "/add-to-cart",
  //       {
  //         ExtColorName: StaticCartData.extColorName,
  //         IntColorName: StaticCartData.intColorName,
  //         ExtColorCode: StaticCartData.extColor,
  //         IntColorCode: StaticCartData.intColor,
  //         Price: StaticCartData.price,
  //         Model: StaticCartData.ModelName,
  //       },
  //       {
  //         headers: {
  //           "content-type": "application/json",
  //           Authorization: `Bearer ${UserData.Token}`,
  //         },
  //       }
  //     );
  //     console.log("🚀 AddtoCart ~ response:", response);
  //     if (response) {
  //       // setLoading(false);
  //       if (response.data.status == "success") {
  //         localStorage.removeItem("StaticCartData");
  //         // getCartData();
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     // getCartData();
  //     // setLoading(false);
  //   }
  // };

  // const getUserProfile = async () => {
  //   setLoading(true);
  //   try {
  //     const res = await axios.get("/get-user-profile", {
  //       headers: { Authorization: `Bearer ${UserData?.Token}` },
  //     });
  //     console.log("🚀 ~ getUserProfile ~ res:", res);
  //     setUserProfileData(res.data);
  //     // AddtoCart();
  //   } catch (error) {
  //     setLoading(false);
  //     // console.log(error);
  //   }
  // };

  const getHistoryData = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/get-history", {
        headers: {
          Authorization: `Bearer ${UserData.Token}`,
        },
      });
      console.log("🚀 ~ getHistoryData ~ res:", res.data);
      if (res) {
        setOrderHistory(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("user-info")) {
      Navigate("/Modelos");
    } else {
      getHistoryData();
      // getUserProfile();
      // AddtoCart();
    }
  }, []);

  // const getCartData = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await axios.get("/get-cart", {
  //       headers: {
  //         Authorization: `Bearer ${UserData.Token}`,
  //       },
  //     });
  //     console.log("🚀 getCartData ~ res:", res);
  //     if (res) {
  //       setCartData(res.data);
  //       setOrderHistory(res.data);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  var nf = new Intl.NumberFormat();

  let Pathnamee = useLocation();

  return (
    <div className="white Main-Sec-ContinuePayment">
      {Loading && (
        <div className="loder_div">
          <Loader />
        </div>
        // <div>
        //   <Backdrop
        //     sx={{
        //       color: "#fff",
        //       zIndex: (theme) => theme.zIndex.drawer + 1,
        //     }}
        //     open={true}>
        //     <CircularProgress color="inherit" />
        //   </Backdrop>
        // </div>
      )}
      {!Loading &&
        OrderHistory.status == "success" &&
        OrderHistory.message == "Record" && (
          <>
            <div
              className="pt-5 pb-5"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <img
                src={logo}
                style={{ cursor: "pointer", width: "30%" }}
                onClick={() => Navigate("/")}
              />
            </div>
            <div className="head-ContinuePay">Revisar y pagar</div>
            <div className="ContinuePay-body">
              <div className="ContinuePay-body-image  d-flex justify-content-center align-items-center w-100">
                <img
                  src={
                    OrderHistory.data.model == "ENGAGE FWD"
                      ? Ariya_EngageFWD.exterior[
                          OrderHistory.data.Ext_Color_Code
                        ].images[0]
                      : OrderHistory.data.model == "ENGAGE e-4ORCE"
                      ? Ariya_Engage_e_4orce.exterior[
                          OrderHistory.data.Ext_Color_Code
                        ].images[0]
                      : OrderHistory.data.model == "ENGAGE+ e-4ORCE"
                      ? Ariya_Engage__e_4orce.exterior[
                          OrderHistory.data.Ext_Color_Code
                        ].images[0]
                      : OrderHistory.data.model == "VENTURE+"
                      ? VENTURE.exterior[OrderHistory.data.Ext_Color_Code]
                          .images[0]
                      : OrderHistory.data.model == "EVOLVE+ FWD"
                      ? EVOLVE.exterior[OrderHistory.data.Ext_Color_Code]
                          .images[0]
                      : OrderHistory.data.model == "EVOLVE+ e-4ORCE"
                      ? evolvepe4orce.exterior[OrderHistory.data.Ext_Color_Code]
                          .images[0]
                      : OrderHistory.data.model == "LEAF S"
                      ? LEAFS.exterior[OrderHistory.data.Ext_Color_Code]
                          .images[0]
                      : OrderHistory.data.model == "LEAF SV Plus"
                      ? LeafSVPlus.exterior[OrderHistory.data.Ext_Color_Code]
                          .images[0]
                      : null
                  }
                  width="90%"
                />
              </div>
              <div className="ContinuePay-body-content">
                <div className="ModelName-Price-div">
                  <span>
                    Model {OrderHistory && OrderHistory.data.model}{" "}
                    {OrderHistory && OrderHistory.data.Mng_Year}
                  </span>
                </div>
                <div className="Purchase-price-specs-div">
                  <span className="price-txt">
                    <span className="dollor-txt">MSRP: $</span>{" "}
                    {nf.format(
                      parseInt(OrderHistory && OrderHistory.data.Price)
                    )}
                  </span>
                  <div className="specs-div">
                    <span>
                      Color exterior:{" "}
                      {OrderHistory && OrderHistory.data.Ext_Color_Name}
                    </span>
                    <span>
                      Color interior:{" "}
                      {OrderHistory && OrderHistory.data.Int_Color_Name}
                    </span>
                    <span>
                      <span className="dollor-txt">Depósito: $</span>
                      500
                    </span>
                    <div className="pb-3"></div>
                    <div className="additionalSpecs">
                      <button
                        className="additionalSpecs-btn"
                        type="button"
                        // onClick={() => alert("button click catched")}
                        onClick={() => {
                          return (
                            <>
                              {OrderHistory.data.model == "ENGAGE FWD"
                                ? Navigate("/Especificaciones/Engage/FWD", {
                                    state: {
                                      Pathnamee,
                                    },
                                  })
                                : OrderHistory.data.model == "ENGAGE e-4ORCE"
                                ? Navigate(
                                    "/Especificaciones/Engage/E-4FORCE",
                                    {
                                      state: {
                                        Pathnamee,
                                      },
                                    }
                                  )
                                : OrderHistory.data.model == "ENGAGE+ e-4ORCE"
                                ? Navigate(
                                    "/Especificaciones/Engage+/E-4FORCE",
                                    {
                                      state: {
                                        Pathnamee,
                                      },
                                    }
                                  )
                                : OrderHistory.data.model == "VENTURE+"
                                ? Navigate(
                                    "/Especificaciones/Engage/Venture+",
                                    {
                                      state: {
                                        Pathnamee,
                                      },
                                    }
                                  )
                                : OrderHistory.data.model == "EVOLVE+ FWD"
                                ? Navigate("/Especificaciones/Evolve+/FWD", {
                                    state: {
                                      Pathnamee,
                                    },
                                  })
                                : OrderHistory.data.model == "EVOLVE+ e-4ORCE"
                                ? Navigate(
                                    "/Especificaciones/Evolve+/e-4FORCE",
                                    {
                                      state: {
                                        Pathnamee,
                                      },
                                    }
                                  )
                                : OrderHistory.data.model == "LEAF-S"
                                ? Navigate("/Especificaciones/LEAF-S", {
                                    state: {
                                      Pathnamee,
                                    },
                                  })
                                : OrderHistory.data.model == "LEAF-SV-Pus"
                                ? Navigate("/Especificaciones/LEAF-SV-Pus", {
                                    state: {
                                      Pathnamee,
                                    },
                                  })
                                : null}
                            </>
                          );
                        }}>
                        Especificaciones adicionales
                      </button>
                    </div>
                  </div>
                </div>
                <div className="red_btn_Rpay w-100">
                  <Button
                    variant={"red"}
                    // onClick={
                    //   UserProfileData.data.AddressLine1 == null
                    //     ? () => setModalShow(true)
                    //     : () => Navigate("/Contrato")
                    // }
                    onClick={() => {
                      Navigate("/RegistratePayment");
                    }}>
                    <span className="btn-space">Continuar con el pago</span>
                  </Button>
                </div>
                {/* <div className="termsandpolicy">
                  <Link to={"/Terminos-y-Condiciones"} className="link_of_trem">
                    Terminos y Condiciones
                  </Link>
                  <Link className="link_of_trem">y</Link>
                  <Link to={"/Politica-Privacidad"} className="link_of_trem">
                    Politica Privacidad
                  </Link>
                </div> */}
                <div className="termsandpolicy">
                  <p onClick={() => setTrems(true)} className="link_of_trem">
                    Términos y Condiciones
                  </p>
                  <p className="link_of_trem">y</p>
                  <p onClick={() => setPolicy(true)} className="link_of_trem">
                    Política de Privacidad
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default ReviewAndPay;
