import React, { useEffect, useState } from "react";
import "./ChangePassword.css";
import { ProfileChangePassSchema } from "../../../schemas";
import Input from "../../../components/UI/Input";
import Button from "../../../../Common/components/UI/Button";
import { Col } from "react-bootstrap";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Toaster, toast } from "react-hot-toast";
import axios from "../../../../Common/Api";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const initialValues = {
  Password: "",
  RepeatPassword: "",
  OldPassword: "",
};

const ChangePassword = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  const Navigate = useNavigate();

  const [IsError, setIsError] = useState("");
  const [Loading, setLoading] = useState(false);

  const ForgotPassEmail = JSON.parse(localStorage.getItem("ForgotPass-Email"));

  useEffect(() => {
    if (!ForgotPassEmail) {
      Navigate("/Perfil/ChnagePassword");
    }
  }, []);

  // setTimeout(() => {
  //   setIsError("");
  //   setLoading(false);
  //   toast.success("Form submitted");
  // }, 500);

  // const onSubmit = async (values) => {
  //   // console.log(values);
  //   setLoading(true);

  const onSubmit = async (values) => {
    // console.log(values);
    setLoading(true);
    const UserData = JSON.parse(localStorage.getItem("user-info"));
    setLoading(true);
    try {
      const response = await axios.post(
        "/change-password",
        {
          Email: UserData.Email,
          OldPassword: values.OldPassword,
          NewPassword: values.Password,
          ConfirmPassword: values.RepeatPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${UserData.Token}`,
          },
        }
      );
      // console.log("response", response);
      setIsError(response.data.message);
      setLoading(false);
      if (response.data.status == "true") {
        localStorage.removeItem("user-info");
        Navigate("/Iniciar-Sesion");
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ProfileChangePassSchema,
      onSubmit,
    });

  return (
    <section className="white pt-4">
      <Col lg={8}>
        <form onSubmit={handleSubmit} className="mainform2">
          <span className="form-error-head"> {IsError} </span>
          <div className="inputs2">
            <div>
              <Input
                name="OldPassword"
                text={"Contraseña enviada"}
                type={"password"}
                placeholder={"Ingrese la contraseña "}
                value={values.OldPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.OldPassword && touched.OldPassword ? (
                <p className="form-error"> {errors.OldPassword} </p>
              ) : null}
            </div>
            <div>
              <Input
                name="Password"
                text={"Contraseña"}
                type={"password"}
                placeholder={"Ingrese la contraseña"}
                value={values.Password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.Password && touched.Password ? (
                <p className="form-error"> {errors.Password} </p>
              ) : null}
            </div>
            <div>
              <div>
                <Input
                  name="RepeatPassword"
                  text={"Repetir contraseña"}
                  type={"password"}
                  placeholder={"Repita la contraseña"}
                  value={values.RepeatPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.RepeatPassword && touched.RepeatPassword ? (
                  <p className="form-error"> {errors.RepeatPassword} </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="">
            <Button variant={"red"} type={"submit"}>
              Guardar
            </Button>
          </div>
        </form>
      </Col>
      {!Loading && <div></div>}
      {Loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </section>
  );
};

export default ChangePassword;
