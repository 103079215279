import { AllImages } from "./images";

export const Ariya_EngageFWD = {
  exterior: {
    "#000000": {
      Ext_name: "Black Diamond Pearl",
      Ext_code: "#000000",
      images: [
        AllImages.engageFWD.BlackPearl.img1,
        AllImages.engageFWD.BlackPearl.img2,
        AllImages.engageFWD.BlackPearl.img3,
        AllImages.engageFWD.BlackPearl.img4,
        AllImages.engageFWD.BlackPearl.img5,
        AllImages.engageFWD.BlackPearl.img6,
        AllImages.engageFWD.BlackPearl.img7,
        AllImages.engageFWD.BlackPearl.img8,
      ],
    },
    "#001746": {
      Ext_name: "Deep Ocean Blue Pearl - Black Diamond Pearl",
      Ext_code: "#001746",
      images: [
        AllImages.engageFWD.DeapOceanBlue.img1,
        AllImages.engageFWD.DeapOceanBlue.img2,
        AllImages.engageFWD.DeapOceanBlue.img3,
        AllImages.engageFWD.DeapOceanBlue.img4,
        AllImages.engageFWD.DeapOceanBlue.img5,
        AllImages.engageFWD.DeapOceanBlue.img6,
        AllImages.engageFWD.DeapOceanBlue.img7,
        AllImages.engageFWD.DeapOceanBlue.img8,
      ],
    },
    "#696C71": {
      Ext_name: "Gun Metallic",
      Ext_code: "#696C71",
      images: [
        AllImages.engageFWD.GunMetallic.img1,
        AllImages.engageFWD.GunMetallic.img2,
        AllImages.engageFWD.GunMetallic.img3,
        AllImages.engageFWD.GunMetallic.img4,
        AllImages.engageFWD.GunMetallic.img5,
        AllImages.engageFWD.GunMetallic.img6,
        AllImages.engageFWD.GunMetallic.img7,
        AllImages.engageFWD.GunMetallic.img8,
      ],
    },
    "#C7C6C4": {
      Ext_name: "Brilliant Silver - Black Diamond Pearl",
      Ext_code: "#C7C6C4",
      images: [
        AllImages.engageFWD.Silver.img1,
        AllImages.engageFWD.Silver.img2,
        AllImages.engageFWD.Silver.img3,
        AllImages.engageFWD.Silver.img4,
        AllImages.engageFWD.Silver.img5,
        AllImages.engageFWD.Silver.img6,
        AllImages.engageFWD.Silver.img7,
        AllImages.engageFWD.Silver.img8,
      ],
    },
    "#676A6F": {
      Ext_name: "Boulder Gray Pearl - Black Diamond Pearl",
      Ext_code: "#676A6F",
      images: [
        AllImages.engageFWD.BoulderGray.img1,
        AllImages.engageFWD.BoulderGray.img2,
        AllImages.engageFWD.BoulderGray.img3,
        AllImages.engageFWD.BoulderGray.img4,
        AllImages.engageFWD.BoulderGray.img5,
        AllImages.engageFWD.BoulderGray.img6,
        AllImages.engageFWD.BoulderGray.img7,
        AllImages.engageFWD.BoulderGray.img8,
      ],
    },
    "#DFE8E3": {
      Ext_name: "Everest White - Black Diamond Pearl",
      Ext_code: "#DFE8E3",
      images: [
        AllImages.engageFWD.EverestWhite.img1,
        AllImages.engageFWD.EverestWhite.img2,
        AllImages.engageFWD.EverestWhite.img3,
        AllImages.engageFWD.EverestWhite.img4,
        AllImages.engageFWD.EverestWhite.img5,
        AllImages.engageFWD.EverestWhite.img6,
        AllImages.engageFWD.EverestWhite.img7,
        AllImages.engageFWD.EverestWhite.img8,
      ],
    },
  },
  interior: {
    "#000000": {
      Int_name: "Charcoal",
      Int_code: "#000000",
      images: [
        AllImages.engageFWD.CharcoalLeatherette.img1,
        AllImages.engageFWD.CharcoalLeatherette.img2,
        AllImages.engageFWD.CharcoalLeatherette.img3,
        AllImages.engageFWD.CharcoalLeatherette.img4,
        AllImages.engageFWD.CharcoalLeatherette.img5,
        AllImages.engageFWD.CharcoalLeatherette.img6,
        AllImages.engageFWD.CharcoalLeatherette.img7,
        AllImages.engageFWD.CharcoalLeatherette.img8,
        AllImages.engageFWD.CharcoalLeatherette.img9,
        AllImages.engageFWD.CharcoalLeatherette.img10,
        AllImages.engageFWD.CharcoalLeatherette.img11,
        AllImages.engageFWD.CharcoalLeatherette.img12,
      ],
    },
    "#696C71": {
      Int_name: "Light Gray",
      Int_code: "#696C71",
      images: [
        AllImages.engageFWD.LightGrayLeatherette.img1,
        AllImages.engageFWD.LightGrayLeatherette.img2,
        AllImages.engageFWD.LightGrayLeatherette.img3,
        AllImages.engageFWD.LightGrayLeatherette.img4,
        AllImages.engageFWD.LightGrayLeatherette.img5,
        AllImages.engageFWD.LightGrayLeatherette.img6,
        AllImages.engageFWD.LightGrayLeatherette.img7,
        AllImages.engageFWD.LightGrayLeatherette.img8,
        AllImages.engageFWD.LightGrayLeatherette.img9,
        AllImages.engageFWD.LightGrayLeatherette.img10,
        AllImages.engageFWD.LightGrayLeatherette.img11,
        AllImages.engageFWD.LightGrayLeatherette.img12,
      ],
    },
  },
  specs: [
    {
      Head: "Mecánica",
      content: [
        "Motor - 160 kW",
        "Límite de RPM  - 13,520",
        "Caballos de fuerza - 214 HP",
        "Libras pie de torsión - 221 ft-lbs",
        "Capacidad de batería - 66 kWh",
        "Tipo de tracción - FWD",
        "Tipo de dirección  - Eléctrica del guía sensible a velocidad",
        "Frenos delanteros - Discos ventilados",
        "Frenos Traseros - Discos ventilados",
      ],
    },
    {
      Head: "Consumo",
      content: [
        "Consumo de energía (kW-horas por 100 millas) - 33",
        "Economía de energía en ciudad, carretera, combinado (MPGe) - 109/94/101",
        "Rango de millas por carga - 216",
      ],
    },
    {
      Head: "Exterior",
      content: [
        'Aros en aleación de aluminio de 19" con cubierta aerodinámica',
        "Gomas P235/55R19",
        "Luces delanteras en LED",
        "Luces diurnas en LED",
        "Luces delanteras inteligentes",
        "Luces traseras en LED",
        "Espejos retrovisores de color negro con ajuste eléctrico y sistema de calefacción",
        "Spoiler aerodinámico trasero",
        "Manijas exteriores en color del auto",
        "Puerto de carga con iluminación y seguridad",
        "Limpiaparabrisas de intermitencia sensible de velocidad",
        "Limpiaparabrisas trasero con limpiador",
        "Puerto de carga rápida (CCS)",
      ],
    },
    {
      Head: "Interior - Asientos",
      content: [
        "Capacidad para 5 pasajeros",
        "Asiento del conductor ajustable eléctrico de 8 posiciones con soporte lumbar",
        "Asiento del pasajero ajustable manual de 6 posiciones",
        "Asiento del conductor con sistema de memoria",
        "Sistema de calefacción en asientos delanteros y traseros",
      ],
    },
    {
      Head: "Interior - Infoentretenimiento",
      content: [
        'Monitor avanzado de asistencia al conductor de 12.3"',
        "Sistema de audio con 6 bocinas",
        "Sistema Bluetooth® para llamadas y transferencia de audio",
        "Controles de audio integrados al guía",
        "Puertos de USB: 2 delanteros y 2 traseros (2x tipo-A & 2x tipo-C)",
        'Monitor multitáctil a color de 12.3"',
        "Apple Carplay® inalámbrico & Android Auto™",
        "Sistema de navegación",
        "Asistencia de mensajería de texto",
      ],
    },
    {
      Head: "Comodidad - Conveniencia",
      content: [
        "A/C con control de clima automático de 2 zonas y ventanillas en la segunda fila",
        "Bolsillo en puertas delanteras con porta botellas (x4)",
        "Portavasos en consola central (x2)",
        "Salida para accesorios de 12V",
        "Ventanas eléctricas con bajado y subido automático",
        "Sistema de llave inteligente Nissan",
        "Manijas capacitivas con desbloqueo de acercamiento y bloqueo de alejamiento",
        "Sistema de encendido de motor por botón",
        "Sistema de cámara de reversa",
        "Alerta de Atención al Conductor Inteligente(I-DA)",
        "Sistema de Alerta de Puerta Trasera",
        "Viseras con extensores e iluminación para conductor y pasajero",
        "Asiento trasero reclinables",
        "Asistente personal virtual",
        "Modo E-Step",
        "Selector de modo de manejo",
        "Sistema de calefacción híbrido",
        "Batería con calentador y refrigeración",
        "Apertura automática del puerto de carga desde el interior",
        'Monitor avanzado de asistencia al conductor de 12."',
        "Guía de ajuste telescópico manual",
        "Consola central con almacenamiento",
        "Cable de carga portátil (120V EVSE)",
      ],
    },
    {
      Head: "Seguridad",
      content: [
        "Sistema avanzado de bolsas de aire (delanteras, laterales, tipo cortina) SAFETY",
        'Sistemas de cinturones de seguridad de 3 puntos "ALR" para conductor y "ALR" / "ELR" para todos los ocupantes',
        "Luz y sonido indicador de recordatorio para cinturones de seguridad",
        "Sistema de anclaje para asiento protector de niños (LATCH)",
        "Cabezales de asientos delanteros de altura ajustable",
        "Barras tubulares de acero para protección de puertas",
        "Zonas de absorción de impactos delanteros y traseros",
        "Columna del guía con sistema de absorción de energía",
        "Seguros en puertas traseras a prueba de niños",
        "Sistema de monitoreo de presión de gomas (TPMS) con aviso de presión ideal",
        "Luz de frenado central",
        "Sistema de seguridad del vehículo (VSS)",
        "Inmovilizador de motor",
        "Aviso de Colisión Frontal Inteligente (I-FCW)",
        "Frenado Automático de Emergencia con detector de Peatones (PAEB)",
        "Aviso de Cambio de Carril Involuntario (LDW)",
        "Intervención de Cambio de Carril Involuntario (I-LI)",
        "Alerta de Cruce de Tráfico Trasero (RCTA)",
        "Aviso de Punto Ciego (BSW)",
        "Intervención Inteligente de Punto Ciego (I-BSI)",
        "Asistencia de Luz Alta (HBA)",
        "Frenado Automático Trasero (RAB)",
        "ProPilot Assist w/ Navi-Link",
        "Control de viaje inteligente (ICC) con asistencia de control de distancia (DCA)",
        "Reconocimiento de letreros de tráfico",
        "Sistema de sensores delanteros y traseros",
        "Asistencia eléctrica de guía",
        "Control dinámico del vehículo (VDC)",
        "Sistema de control de tracción (TCS)",
        "Sistema de frenos antibloqueo (ABS) con distribución de fuerza de frenos electrónica (EBD)",
        "Sistema de asistencia de frenado (BA)",
        "Freno de emergencia eléctrico (ePKB)",
      ],
    },
    {
      Head: "Dimensiones",
      content: [
        "Distancia entre ejes (pulg.) 109.3",
        "Largo (pulg.) 182.9",
        "Ancho (pulg.) 85.5",
        "Alto (pulg.) 5.4",
        "Altura del vehículo al suelo (pulg.) 6.3",
        "Volumen interior (pies cúbicos) 124",
        "Volumen de pasajeros (pies cúbicos) 101.2",
        "Capacidad de carga (pies cúbicos) 22.8",
        "Espacio de cabeza delantero / trasero (pulg.) 39.6 / 38.3",
        "Espacio de hombro delantero / trasero (pulg.) 56.0 / 55.9",
        "Espacio de cadera delantero / trasero (pulg.) 56.0 / 52.5",
        "Espacio de piernas delantero / trasero (pulg.) 42.3 / 37.0",
      ],
    },
    {
      Head: "Pesos",
      content: [
        "Peso del vehículo (lbs.) 4,323",
        'Peso del vehículo con carga máxima "GVWR" (lbs.) 5,313',
        "Distribución de peso delantero / trasero (lbs.) 56 / 44",
      ],
    },
    {
      Head: "Suspensión",
      content: [
        'Delantera Independiente "MacPherson" con amortiguadores',
        "Trasera Independiente multi-link",
        "Barra estabilizadora Delantera y trasera",
      ],
    },
  ],
};

export const Ariya_Engage_e_4orce = {
  exterior: {
    "#000000": {
      Ext_name: "Black Diamond Pearl",
      Ext_code: "#000000",
      images: [
        AllImages.engageE4orce.BlackPearl.img1,
        AllImages.engageE4orce.BlackPearl.img2,
        AllImages.engageE4orce.BlackPearl.img3,
        AllImages.engageE4orce.BlackPearl.img4,
        // AllImages.engageE4orce.BlackPearl.img5,
        AllImages.engageE4orce.BlackPearl.img6,
        AllImages.engageE4orce.BlackPearl.img7,
        AllImages.engageE4orce.BlackPearl.img8,
      ],
    },
    "#001746": {
      Ext_name: "Deep Ocean Blue Pearl - Black Diamond Pearl",
      Ext_code: "#001746",
      images: [
        AllImages.engageE4orce.DeapOceanBlue.img1,
        AllImages.engageE4orce.DeapOceanBlue.img2,
        AllImages.engageE4orce.DeapOceanBlue.img3,
        AllImages.engageE4orce.DeapOceanBlue.img4,
        AllImages.engageE4orce.DeapOceanBlue.img5,
        AllImages.engageE4orce.DeapOceanBlue.img6,
        AllImages.engageE4orce.DeapOceanBlue.img7,
        AllImages.engageE4orce.DeapOceanBlue.img8,
      ],
    },
    "#696C71": {
      Ext_name: "Gun Metallic",
      Ext_code: "#696C71",
      images: [
        AllImages.engageE4orce.GunMetallic.img1,
        AllImages.engageE4orce.GunMetallic.img2,
        AllImages.engageE4orce.GunMetallic.img3,
        AllImages.engageE4orce.GunMetallic.img4,
        AllImages.engageE4orce.GunMetallic.img5,
        AllImages.engageE4orce.GunMetallic.img6,
        AllImages.engageE4orce.GunMetallic.img7,
        AllImages.engageE4orce.GunMetallic.img8,
      ],
    },
    "#C7C6C4": {
      Ext_name: "Brilliant Silver - Black Diamond Pearl",
      Ext_code: "#C7C6C4",
      images: [
        AllImages.engageE4orce.Silver.img1,
        AllImages.engageE4orce.Silver.img2,
        AllImages.engageE4orce.Silver.img3,
        AllImages.engageE4orce.Silver.img4,
        AllImages.engageE4orce.Silver.img5,
        AllImages.engageE4orce.Silver.img6,
        AllImages.engageE4orce.Silver.img7,
        AllImages.engageE4orce.Silver.img8,
      ],
    },
    "#676A6F": {
      Ext_name: "Boulder Gray Pearl - Black Diamond Pearl",
      Ext_code: "#676A6F",
      images: [
        AllImages.engageE4orce.BoulderGray.img1,
        AllImages.engageE4orce.BoulderGray.img2,
        AllImages.engageE4orce.BoulderGray.img3,
        AllImages.engageE4orce.BoulderGray.img4,
        AllImages.engageE4orce.BoulderGray.img5,
        AllImages.engageE4orce.BoulderGray.img6,
        AllImages.engageE4orce.BoulderGray.img7,
        AllImages.engageE4orce.BoulderGray.img8,
      ],
    },
    "#DFE8E3": {
      Ext_name: "Everest White - Black Diamond Pearl",
      Ext_code: "#DFE8E3",
      images: [
        AllImages.engageE4orce.EverestWhite.img1,
        AllImages.engageE4orce.EverestWhite.img2,
        AllImages.engageE4orce.EverestWhite.img3,
        AllImages.engageE4orce.EverestWhite.img4,
        AllImages.engageE4orce.EverestWhite.img5,
        AllImages.engageE4orce.EverestWhite.img6,
        AllImages.engageE4orce.EverestWhite.img7,
        AllImages.engageE4orce.EverestWhite.img8,
      ],
    },
  },
  interior: {
    "#000000": {
      Int_name: "Charcoal",
      Int_code: "#000000",
      images: [
        AllImages.engageFWD.CharcoalLeatherette.img1,
        AllImages.engageFWD.CharcoalLeatherette.img2,
        AllImages.engageFWD.CharcoalLeatherette.img3,
        AllImages.engageFWD.CharcoalLeatherette.img4,
        AllImages.engageFWD.CharcoalLeatherette.img5,
        AllImages.engageFWD.CharcoalLeatherette.img6,
        AllImages.engageFWD.CharcoalLeatherette.img7,
        AllImages.engageFWD.CharcoalLeatherette.img8,
        AllImages.engageFWD.CharcoalLeatherette.img9,
        AllImages.engageFWD.CharcoalLeatherette.img10,
        AllImages.engageFWD.CharcoalLeatherette.img11,
        AllImages.engageFWD.CharcoalLeatherette.img12,
      ],
    },
    "#696C71": {
      Int_name: "Light Gray",
      Int_code: "#696C71",
      images: [
        AllImages.engageFWD.LightGrayLeatherette.img1,
        AllImages.engageFWD.LightGrayLeatherette.img2,
        AllImages.engageFWD.LightGrayLeatherette.img3,
        AllImages.engageFWD.LightGrayLeatherette.img4,
        AllImages.engageFWD.LightGrayLeatherette.img5,
        AllImages.engageFWD.LightGrayLeatherette.img6,
        AllImages.engageFWD.LightGrayLeatherette.img7,
        AllImages.engageFWD.LightGrayLeatherette.img8,
        AllImages.engageFWD.LightGrayLeatherette.img9,
        AllImages.engageFWD.LightGrayLeatherette.img10,
        AllImages.engageFWD.LightGrayLeatherette.img11,
        AllImages.engageFWD.LightGrayLeatherette.img12,
      ],
    },
  },
  specs: [
    {
      Head: "Mecánica",
      content: [
        "Motor - 250 kW",
        "Límite de RPM  - 13,520",
        "Caballos de fuerza - 335 HP",
        "Libras pie de torsión - 413 ft-lbs",
        "Capacidad de batería - 66 kWh",
        "Tipo de tracción - e -4ORCE(AWD)",
        "Tipo de dirección  - Eléctrica del guía sensible a velocidad",
        "Frenos delanteros - Discos ventilados",
        "Frenos Traseros - Discos ventilados",
      ],
    },
    {
      Head: "Consumo",
      content: [
        "Consumo de energía (kW-horas por 100 millas) - 35",
        "Economía de energía en ciudad, carretera, combinado (MPGe) - 110/89/95",
        "Rango de millas por carga - 205",
      ],
    },
    {
      Head: "Exterior",
      content: [
        'Aros en aleación de aluminio de 19" con cubierta aerodinámica',
        "Gomas P235/55R19",
        "Luces delanteras en LED",
        "Luces diurnas en LED",
        "Luces delanteras inteligentes",
        "Luces traseras en LED",
        "Espejos retrovisores de color negro con ajuste eléctrico y sistema de calefacción",
        "Spoiler aerodinámico trasero",
        "Manijas exteriores en color del auto",
        "Puerto de carga con iluminación y seguridad",
        "Limpiaparabrisas de intermitencia sensible de velocidad",
        "Limpiaparabrisas trasero con limpiador",
        "Puerto de carga rápida (CCS)",
      ],
    },
    {
      Head: "Interior - Asientos",
      content: [
        " Asientos con terminación en piel sintética",
        "Asiento del conductor ajustable eléctrico de 8 posiciones con soporte lumbar",
        "Asiento del pasajero ajustable manual de 6 posiciones",
        "Asiento del conductor con sistema de memoria",
        "Sistema de calefacción en asientos delanteros y traseros",
      ],
    },
    {
      Head: "Interior - Infoentretenimiento",
      content: [
        'Monitor avanzado de asistencia al conductor de 12.3"',
        "Sistema de audio con 6 bocinas",
        "Sistema Bluetooth® para llamadas y transferencia de audio",
        "Controles de audio integrados al guía",
        "Puertos de USB: 2 delanteros y 2 traseros (2x tipo-A & 2x tipo-C)",
        'Monitor multitáctil a color de 12.3"',
        "Apple Carplay® inalámbrico & Android Auto™",
        "Sistema de navegación",
        "Asistencia de mensajería de texto",
      ],
    },
    {
      Head: "Comodidad - Conveniencia",
      content: [
        "A/C con control de clima automático de 2 zonas y ventanillas en la segunda fila",
        "Bolsillo en puertas delanteras con porta botellas (x4)",
        "Portavasos en consola central (x2)",
        "Salida para accesorios de 12V",
        "Ventanas eléctricas con bajado y subido automático",
        "Sistema de llave inteligente Nissan",
        "Manijas capacitivas con desbloqueo de acercamiento y bloqueo de alejamiento",
        "Sistema de encendido de motor por botón",
        "Sistema de cámara de reversa",
        "Alerta de Atención al Conductor Inteligente(I-DA)",
        "Sistema de Alerta de Puerta Trasera",
        "Viseras con extensores e iluminación para conductor y pasajero",
        "Asiento trasero reclinables",
        "Asistente personal virtual",
        "Modo E-Step",
        "Selector de modo de manejo",
        "Sistema de calefacción híbrido",
        "Batería con calentador y refrigeración",
        "Apertura automática del puerto de carga desde el interior",
        'Monitor avanzado de asistencia al conductor de 12."',
        "Guía de ajuste telescópico manual",
        "Consola central con almacenamiento",
        "Cable de carga portátil (120V EVSE)",
      ],
    },
    {
      Head: "Seguridad",
      content: [
        "Sistema avanzado de bolsas de aire (delanteras, laterales, tipo cortina) SAFETY",
        'Sistemas de cinturones de seguridad de 3 puntos "ALR" para conductor y "ALR" / "ELR" para todos los ocupantes',
        "Luz y sonido indicador de recordatorio para cinturones de seguridad",
        "Sistema de anclaje para asiento protector de niños (LATCH)",
        "Cabezales de asientos delanteros de altura ajustable",
        "Barras tubulares de acero para protección de puertas",
        "Zonas de absorción de impactos delanteros y traseros",
        "Columna del guía con sistema de absorción de energía",
        "Seguros en puertas traseras a prueba de niños",
        "Sistema de monitoreo de presión de gomas (TPMS) con aviso de presión ideal",
        "Luz de frenado central",
        "Sistema de seguridad del vehículo (VSS)",
        "Inmovilizador de motor",
        "Aviso de Colisión Frontal Inteligente (I-FCW)",
        "Frenado Automático de Emergencia con detector de Peatones (PAEB)",
        "Aviso de Cambio de Carril Involuntario (LDW)",
        "Intervención de Cambio de Carril Involuntario (I-LI)",
        "Alerta de Cruce de Tráfico Trasero (RCTA)",
        "Aviso de Punto Ciego (BSW)",
        "Intervención Inteligente de Punto Ciego (I-BSI)",
        "Asistencia de Luz Alta (HBA)",
        "Frenado Automático Trasero (RAB)",
        "ProPilot Assist w/ Navi-Link",
        "Control de viaje inteligente (ICC) con asistencia de control de distancia (DCA)",
        "Reconocimiento de letreros de tráfico",
        "Sistema de sensores delanteros y traseros",
        "Asistencia eléctrica de guía",
        "Control dinámico del vehículo (VDC)",
        "Sistema de control de tracción (TCS)",
        "Sistema de frenos antibloqueo (ABS) con distribución de fuerza de frenos electrónica (EBD)",
        "Sistema de asistencia de frenado (BA)",
        "Freno de emergencia eléctrico (ePKB)",
      ],
    },
    {
      Head: "Dimensiones",
      content: [
        "Distancia entre ejes (pulg.) 109.3",
        "Largo (pulg.) 182.9",
        "Ancho (pulg.) 85.5",
        "Alto (pulg.) 5.4",
        "Altura del vehículo al suelo (pulg.) 5.3",
        "Volumen interior (pies cúbicos) 124",
        "Volumen de pasajeros (pies cúbicos) 101.2",
        "Capacidad de carga (pies cúbicos) 22.8",
        "Espacio de cabeza delantero / trasero (pulg.) 39.6 / 38.3",
        "Espacio de hombro delantero / trasero (pulg.) 56.0 / 55.9",
        "Espacio de cadera delantero / trasero (pulg.) 56.0 / 52.5",
        "Espacio de piernas delantero / trasero (pulg.) 42.3 / 37.0",
      ],
    },
    {
      Head: "Pesos",
      content: [
        "Peso del vehículo (lbs.) 4,608",
        'Peso del vehículo con carga máxima "GVWR" (lbs.) 5,589',
        "Distribución de peso delantero / trasero (lbs.) 52 / 48",
      ],
    },
    {
      Head: "Suspensión",
      content: [
        'Delantera Independiente "MacPherson" con amortiguadores',
        "Trasera Independiente multi-link",
        "Barra estabilizadora Delantera y trasera",
      ],
    },
  ],
};
export const Ariya_Engage__e_4orce = {
  exterior: {
    "#000000": {
      Ext_name: "Black Diamond Pearl",
      Ext_code: "#000000",
      images: [
        AllImages.engageEE4orce.BlackPearl.img1,
        AllImages.engageEE4orce.BlackPearl.img2,
        AllImages.engageEE4orce.BlackPearl.img3,
        AllImages.engageEE4orce.BlackPearl.img4,
        AllImages.engageEE4orce.BlackPearl.img5,
        AllImages.engageEE4orce.BlackPearl.img6,
        AllImages.engageEE4orce.BlackPearl.img7,
        AllImages.engageEE4orce.BlackPearl.img8,
      ],
    },
    "#001746": {
      Ext_name: "Deep Ocean Blue Pearl-Black Diamond Pearl",
      Ext_code: "#001746",
      images: [
        AllImages.engageEE4orce.DeapOceanBlue.img1,
        AllImages.engageEE4orce.DeapOceanBlue.img2,
        AllImages.engageEE4orce.DeapOceanBlue.img3,
        AllImages.engageEE4orce.DeapOceanBlue.img4,
        AllImages.engageEE4orce.DeapOceanBlue.img5,
        AllImages.engageEE4orce.DeapOceanBlue.img6,
        AllImages.engageEE4orce.DeapOceanBlue.img7,
        AllImages.engageEE4orce.DeapOceanBlue.img8,
      ],
    },
    "#696C71": {
      Ext_name: "Gun Metallic",
      Ext_code: "#696C71",
      images: [
        AllImages.engageEE4orce.GunMetallic.img1,
        AllImages.engageEE4orce.GunMetallic.img2,
        AllImages.engageEE4orce.GunMetallic.img3,
        AllImages.engageEE4orce.GunMetallic.img4,
        AllImages.engageEE4orce.GunMetallic.img5,
        AllImages.engageEE4orce.GunMetallic.img6,
        AllImages.engageEE4orce.GunMetallic.img7,
        AllImages.engageEE4orce.GunMetallic.img8,
      ],
    },
    "#C7C6C4": {
      Ext_name: "Brilliant Silver - Black Diamond Pearl",
      Ext_code: "#C7C6C4",
      images: [
        AllImages.engageEE4orce.Silver.img1,
        AllImages.engageEE4orce.Silver.img2,
        AllImages.engageEE4orce.Silver.img3,
        AllImages.engageEE4orce.Silver.img4,
        AllImages.engageEE4orce.Silver.img5,
        AllImages.engageEE4orce.Silver.img6,
        AllImages.engageEE4orce.Silver.img7,
        AllImages.engageEE4orce.Silver.img8,
      ],
    },
    "#676A6F": {
      Ext_name: "Boulder Gray Pearl - Black Diamond Pearl",
      Ext_code: "#676A6F",
      images: [
        AllImages.engageEE4orce.BoulderGray.img1,
        AllImages.engageEE4orce.BoulderGray.img2,
        AllImages.engageEE4orce.BoulderGray.img3,
        AllImages.engageEE4orce.BoulderGray.img4,
        AllImages.engageEE4orce.BoulderGray.img5,
        AllImages.engageEE4orce.BoulderGray.img6,
        AllImages.engageEE4orce.BoulderGray.img7,
        AllImages.engageEE4orce.BoulderGray.img8,
      ],
    },
    "#DFE8E3": {
      Ext_name: "Everest White - Black Diamond Pearl",
      Ext_code: "#DFE8E3",
      images: [
        AllImages.engageEE4orce.EverestWhite.img1,
        AllImages.engageEE4orce.EverestWhite.img2,
        AllImages.engageEE4orce.EverestWhite.img3,
        AllImages.engageEE4orce.EverestWhite.img4,
        AllImages.engageEE4orce.EverestWhite.img5,
        AllImages.engageEE4orce.EverestWhite.img6,
        AllImages.engageEE4orce.EverestWhite.img7,
        AllImages.engageEE4orce.EverestWhite.img8,
      ],
    },
  },
  interior: {
    "#000000": {
      Int_name: "Charcoal",
      Int_code: "#000000",
      images: [
        AllImages.engageEE4orce.CharcoalLeatherette.img1,
        AllImages.engageEE4orce.CharcoalLeatherette.img2,
        AllImages.engageEE4orce.CharcoalLeatherette.img3,
        AllImages.engageEE4orce.CharcoalLeatherette.img4,
        AllImages.engageEE4orce.CharcoalLeatherette.img5,
        AllImages.engageEE4orce.CharcoalLeatherette.img6,
        AllImages.engageEE4orce.CharcoalLeatherette.img7,
        AllImages.engageEE4orce.CharcoalLeatherette.img8,
        AllImages.engageEE4orce.CharcoalLeatherette.img9,
        AllImages.engageEE4orce.CharcoalLeatherette.img10,
        AllImages.engageEE4orce.CharcoalLeatherette.img11,
        AllImages.engageEE4orce.CharcoalLeatherette.img12,
      ],
    },
    "#696C71": {
      Int_name: "Light Gray",
      Int_code: "#696C71",
      images: [
        AllImages.engageEE4orce.LightGrayLeatherette.img1,
        AllImages.engageEE4orce.LightGrayLeatherette.img2,
        AllImages.engageEE4orce.LightGrayLeatherette.img3,
        AllImages.engageEE4orce.LightGrayLeatherette.img4,
        AllImages.engageEE4orce.LightGrayLeatherette.img5,
        AllImages.engageEE4orce.LightGrayLeatherette.img6,
        AllImages.engageEE4orce.LightGrayLeatherette.img7,
        AllImages.engageEE4orce.LightGrayLeatherette.img8,
        AllImages.engageEE4orce.LightGrayLeatherette.img9,
        AllImages.engageEE4orce.LightGrayLeatherette.img10,
        AllImages.engageEE4orce.LightGrayLeatherette.img11,
        AllImages.engageEE4orce.LightGrayLeatherette.img12,
      ],
    },
  },
  specs: [
    {
      Head: "Mecánica",
      content: [
        "Motor - 290 kW",
        "Límite de RPM  - 13,520",
        "Caballos de fuerza - 389 HP",
        "Libras pie de torsión - 442 ft-lbs",
        "Capacidad de batería - 91 kWh",
        "Tipo de tracción -  e-4ORCE (AWD)",
        "Tipo de dirección  - Eléctrica del guía sensible a velocidad",
        "Frenos delanteros - Discos ventilados",
        "Frenos Traseros - Discos ventilados",
      ],
    },
    {
      Head: "Consumo",
      content: [
        "Consumo de energía (kW-horas por 100 millas) - 37",
        "Economía de energía en ciudad, carretera, combinado (MPGe) - 97/86/92",
        "Rango de millas por carga - 270",
      ],
    },
    {
      Head: "Exterior",
      content: [
        'Aros en aleación de aluminio de 19" con cubierta aerodinámica',
        "Gomas P235/55R19",
        "Luces delanteras en LED",
        "Luces diurnas en LED",
        "Luces delanteras inteligentes",
        "Luces traseras en LED",
        "Espejos retrovisores de color negro con ajuste eléctrico y sistema de calefacción",
        "Spoiler aerodinámico trasero",
        "Manijas exteriores en color del auto",
        "Puerto de carga con iluminación y seguridad",
        "Limpiaparabrisas de intermitencia sensible de velocidad",
        "Limpiaparabrisas trasero con limpiador",
        "Puerto de carga rápida (CCS)",
      ],
    },
    {
      Head: "Interior - Asientos",
      content: [
        "Asientos con terminación en piel sintética",
        "Asiento del conductor ajustable eléctrico de 8 posiciones con soporte lumbar",
        "Asiento del pasajero ajustable manual de 6 posiciones",
        "Asiento del conductor con sistema de memoria",
        "Sistema de calefacción en asientos delanteros y traseros",
      ],
    },
    {
      Head: "Interior - Infoentretenimiento",
      content: [
        "Monitor avanzado de asistencia al conductor de 12.3",
        "Sistema de audio con 6 bocinas",
        "Sistema Bluetooth® para llamadas y transferencia de audio",
        "Controles de audio integrados al guía",
        "Puertos de USB: 2 delanteros y 2 traseros (2x tipo-A & 2x tipo-C)",
        'Monitor multitáctil a color de 12.3"',
        "Apple Carplay® inalámbrico & Android Auto™",
        "Sistema de navegación",
        "Asistencia de mensajería de texto",
      ],
    },
    {
      Head: "Comodidad - Conveniencia",
      content: [
        "A/C con control de clima automático de 2 zonas y ventanillas en la segunda fila",
        "Bolsillo en puertas delanteras con porta botellas (x4)",
        "Portavasos en consola central (x2)",
        "Salida para accesorios de 12V",
        "Ventanas eléctricas con bajado y subido automático",
        "Sistema de llave inteligente Nissan",
        "Manijas capacitivas con desbloqueo de acercamiento y bloqueo de alejamiento",
        "Sistema de encendido de motor por botón",
        "Sistema de cámara de reversa",
        "Alerta de Atención al Conductor Inteligente(I-DA)",
        "Sistema de Alerta de Puerta Trasera",
        "Viseras con extensores e iluminación para conductor y pasajero",
        "Asiento trasero reclinables",
        "Asistente personal virtual",
        "Modo E-Step",
        "Selector de modo de manejo",
        "Sistema de calefacción híbrido",
        "Batería con calentador y refrigeración",
        "Apertura automática del puerto de carga desde el interior",
        'Monitor avanzado de asistencia al conductor de 12."',
        "Guía de ajuste telescópico manual",
        "Consola central con almacenamiento",
        "Cable de carga portátil (120V EVSE)",
      ],
    },
    {
      Head: "Seguridad",
      content: [
        "Sistema avanzado de bolsas de aire (delanteras, laterales, tipo cortina) SAFETY",
        'Sistemas de cinturones de seguridad de 3 puntos "ALR" para conductor y "ALR" / "ELR" para todos los ocupantes',
        "Luz y sonido indicador de recordatorio para cinturones de seguridad",
        "Sistema de anclaje para asiento protector de niños (LATCH)",
        "Cabezales de asientos delanteros de altura ajustable",
        "Barras tubulares de acero para protección de puertas",
        "Zonas de absorción de impactos delanteros y traseros",
        "Columna del guía con sistema de absorción de energía",
        "Seguros en puertas traseras a prueba de niños",
        "Sistema de monitoreo de presión de gomas (TPMS) con aviso de presión ideal",
        "Luz de frenado central",
        "Sistema de seguridad del vehículo (VSS)",
        "Inmovilizador de motor",
        "Aviso de Colisión Frontal Inteligente (I-FCW)",
        "Frenado Automático de Emergencia con detector de Peatones (PAEB)",
        "Aviso de Cambio de Carril Involuntario (LDW)",
        "Intervención de Cambio de Carril Involuntario (I-LI)",
        "Alerta de Cruce de Tráfico Trasero (RCTA)",
        "Aviso de Punto Ciego (BSW)",
        "Intervención Inteligente de Punto Ciego (I-BSI)",
        "Asistencia de Luz Alta (HBA)",
        "Frenado Automático Trasero (RAB)",
        "ProPilot Assist w/ Navi-Link",
        "Control de viaje inteligente (ICC) con asistencia de control de distancia (DCA)",
        "Reconocimiento de letreros de tráfico",
        "Sistema de sensores delanteros y traseros",
        "Asistencia eléctrica de guía",
        "Control dinámico del vehículo (VDC)",
        "Sistema de control de tracción (TCS)",
        "Sistema de frenos antibloqueo (ABS) con distribución de fuerza de frenos electrónica (EBD)",
        "Sistema de asistencia de frenado (BA)",
        "Freno de emergencia eléctrico (ePKB)",
      ],
    },
    {
      Head: "Dimensiones",
      content: [
        "Distancia entre ejes (pulg.) 109.3",
        "Largo (pulg.) 182.9",
        "Ancho (pulg.) 85.5",
        "Alto (pulg.) 5.4",
        "Altura del vehículo al suelo (pulg.) 5.3",
        "Volumen interior (pies cúbicos) 124",
        "Volumen de pasajeros (pies cúbicos) 101.2",
        "Capacidad de carga (pies cúbicos) 22.8",
        "Espacio de cabeza delantero / trasero (pulg.) 39.6 / 38.3",
        "Espacio de hombro delantero / trasero (pulg.) 56.0 / 55.9",
        "Espacio de cadera delantero / trasero (pulg.) 56.0 / 52.5",
        "Espacio de piernas delantero / trasero (pulg.) 42.3 / 37.0",
      ],
    },
    {
      Head: "Pesos",
      content: [
        "Peso del vehículo (lbs.) 4,899",
        'Peso del vehículo con carga máxima "GVWR" (lbs.) 5,930',
        "Distribución de peso delantero / trasero (lbs.) 50 / 50",
      ],
    },
    {
      Head: "Suspensión",
      content: [
        'Delantera Independiente "MacPherson" con amortiguadores',
        "Trasera Independiente multi-link",
        "Barra estabilizadora Delantera y trasera",
      ],
    },
  ],
};
export const VENTURE = {
  exterior: {
    "#000000": {
      Ext_name: "Black Diamond Pearl",
      Ext_code: "#000000",
      images: [
        AllImages.VENTURE.BlackPearl.img1,
        AllImages.VENTURE.BlackPearl.img2,
        AllImages.VENTURE.BlackPearl.img3,
        AllImages.VENTURE.BlackPearl.img4,
        // AllImages.VENTURE.BlackPearl.img5,
        AllImages.VENTURE.BlackPearl.img6,
        AllImages.VENTURE.BlackPearl.img7,
        AllImages.VENTURE.BlackPearl.img8,
      ],
    },
    "#001746": {
      Ext_name: "Deep Ocean Blue Pearl - Black Diamond Pearl",
      Ext_code: "#001746",
      images: [
        AllImages.VENTURE.DeapOceanBlue.img1,
        AllImages.VENTURE.DeapOceanBlue.img2,
        AllImages.VENTURE.DeapOceanBlue.img3,
        AllImages.VENTURE.DeapOceanBlue.img4,
        AllImages.VENTURE.DeapOceanBlue.img5,
        AllImages.VENTURE.DeapOceanBlue.img6,
        AllImages.VENTURE.DeapOceanBlue.img7,
        AllImages.VENTURE.DeapOceanBlue.img8,
      ],
    },
    "#696C71": {
      Ext_name: "Gun Metallic",
      Ext_code: "#696C71",
      images: [
        AllImages.VENTURE.GunMetallic.img1,
        AllImages.VENTURE.GunMetallic.img2,
        AllImages.VENTURE.GunMetallic.img3,
        AllImages.VENTURE.GunMetallic.img4,
        AllImages.VENTURE.GunMetallic.img5,
        AllImages.VENTURE.GunMetallic.img6,
        AllImages.VENTURE.GunMetallic.img7,
        AllImages.VENTURE.GunMetallic.img8,
      ],
    },
    "#C7C6C4": {
      Ext_name: "Brilliant Silver - Black Diamond Pearl",
      Ext_code: "#C7C6C4",
      images: [
        AllImages.VENTURE.Silver.img1,
        AllImages.VENTURE.Silver.img2,
        AllImages.VENTURE.Silver.img3,
        AllImages.VENTURE.Silver.img4,
        AllImages.VENTURE.Silver.img5,
        AllImages.VENTURE.Silver.img6,
        AllImages.VENTURE.Silver.img7,
        AllImages.VENTURE.Silver.img8,
      ],
    },
    "#676A6F": {
      Ext_name: "Boulder Gray Pearl - Black Diamond Pearl",
      Ext_code: "#676A6F",
      images: [
        AllImages.VENTURE.BoulderGray.img1,
        AllImages.VENTURE.BoulderGray.img2,
        AllImages.VENTURE.BoulderGray.img3,
        AllImages.VENTURE.BoulderGray.img4,
        AllImages.VENTURE.BoulderGray.img5,
        AllImages.VENTURE.BoulderGray.img6,
        AllImages.VENTURE.BoulderGray.img7,
        AllImages.VENTURE.BoulderGray.img8,
      ],
    },
    "#DFE8E3": {
      Ext_name: "Everest White - Black Diamond Pearl",
      Ext_code: "#DFE8E3",
      images: [
        AllImages.VENTURE.EverestWhite.img1,
        AllImages.VENTURE.EverestWhite.img2,
        AllImages.VENTURE.EverestWhite.img3,
        AllImages.VENTURE.EverestWhite.img4,
        AllImages.VENTURE.EverestWhite.img5,
        AllImages.VENTURE.EverestWhite.img6,
        AllImages.VENTURE.EverestWhite.img7,
        AllImages.VENTURE.EverestWhite.img8,
      ],
    },
  },
  interior: {
    "#000000": {
      Int_name: "Charcoal",
      Int_code: "#000000",
      images: [
        AllImages.VENTURE.CharcoalLeatherette.img1,
        AllImages.VENTURE.CharcoalLeatherette.img2,
        AllImages.VENTURE.CharcoalLeatherette.img3,
        AllImages.VENTURE.CharcoalLeatherette.img4,
        AllImages.VENTURE.CharcoalLeatherette.img5,
        AllImages.VENTURE.CharcoalLeatherette.img6,
        AllImages.VENTURE.CharcoalLeatherette.img7,
        AllImages.VENTURE.CharcoalLeatherette.img8,
        AllImages.VENTURE.CharcoalLeatherette.img9,
        AllImages.VENTURE.CharcoalLeatherette.img10,
        AllImages.VENTURE.CharcoalLeatherette.img11,
        AllImages.VENTURE.CharcoalLeatherette.img12,
      ],
    },
    "#696C71": {
      Int_name: "Light Gray",
      Int_code: "#696C71",
      images: [
        AllImages.VENTURE.LightGrayLeatherette.img1,
        AllImages.VENTURE.LightGrayLeatherette.img2,
        AllImages.VENTURE.LightGrayLeatherette.img3,
        AllImages.VENTURE.LightGrayLeatherette.img4,
        AllImages.VENTURE.LightGrayLeatherette.img5,
        AllImages.VENTURE.LightGrayLeatherette.img6,
        AllImages.VENTURE.LightGrayLeatherette.img7,
        AllImages.VENTURE.LightGrayLeatherette.img8,
        AllImages.VENTURE.LightGrayLeatherette.img9,
        AllImages.VENTURE.LightGrayLeatherette.img10,
        AllImages.VENTURE.LightGrayLeatherette.img11,
        AllImages.VENTURE.LightGrayLeatherette.img12,
      ],
    },
  },
  specs: [
    {
      Head: "Mecánica",
      content: [
        "Motor - 178 kW",
        "Límite de RPM  - 13,520",
        "Caballos de fuerza - 238 HP",
        "Libras pie de torsión - 221 ft-lbs",
        "Capacidad de batería - 91 kWh",
        "Tipo de tracción -  FWD",
        "Tipo de dirección  - Eléctrica del guía sensible a velocidad",
        "Frenos delanteros - Discos ventilados",
        "Frenos Traseros - Discos ventilados",
      ],
    },
    {
      Head: "Consumo",
      content: [
        "Consumo de energía (kW-horas por 100 millas) - 37",
        "Economía de energía en ciudad, carretera, combinado (MPGe) - 97/86/92",
        "Rango de millas por carga - 216",
      ],
    },
    {
      Head: "Exterior",
      content: [
        'Aros en aleación de aluminio de 19" con cubierta aerodinámica',
        "Gomas P235/55R19",
        "Luces delanteras en LED",
        "Luces diurnas en LED",
        "Luces delanteras inteligentes",
        "Luces traseras en LED",
        "Espejos retrovisores de color negro con ajuste eléctrico y sistema de calefacción",
        "Spoiler aerodinámico trasero",
        "Manijas exteriores en color del auto",
        "Puerto de carga con iluminación y seguridad",
        "Limpiaparabrisas de intermitencia sensible de velocidad",
        "Limpiaparabrisas trasero con limpiador",
        "Puerto de carga rápida (CCS)",
      ],
    },
    {
      Head: "Interior - Asientos",
      content: [
        "Asientos con terminación en piel sintética",
        "Asiento del conductor ajustable eléctrico de 8 posiciones con soporte lumbar",
        "Asiento del pasajero ajustable manual de 6 posiciones",
        "Asiento del conductor con sistema de memoria",
        "Sistema de calefacción en asientos delanteros y traseros",
      ],
    },
    {
      Head: "Interior - Infoentretenimiento",
      content: [
        "Monitor avanzado de asistencia al conductor de 12.3",
        "Sistema de audio con 6 bocinas",
        "Sistema Bluetooth® para llamadas y transferencia de audio",
        "Controles de audio integrados al guía",
        "Puertos de USB: 2 delanteros y 2 traseros (2x tipo-A & 2x tipo-C)",
        'Monitor multitáctil a color de 12.3"',
        "Apple Carplay® inalámbrico & Android Auto™",
        "Sistema de navegación",
        "Asistencia de mensajería de texto",
      ],
    },
    {
      Head: "Comodidad - Conveniencia",
      content: [
        "A/C con control de clima automático de 2 zonas y ventanillas en la segunda fila",
        "Bolsillo en puertas delanteras con porta botellas (x4)",
        "Portavasos en consola central (x2)",
        "Salida para accesorios de 12V",
        "Ventanas eléctricas con bajado y subido automático",
        "Sistema de llave inteligente Nissan",
        "Manijas capacitivas con desbloqueo de acercamiento y bloqueo de alejamiento",
        "Sistema de encendido de motor por botón",
        "Sistema de cámara de reversa",
        "Alerta de Atención al Conductor Inteligente(I-DA)",
        "Sistema de Alerta de Puerta Trasera",
        "Viseras con extensores e iluminación para conductor y pasajero",
        "Asiento trasero reclinables",
        "Asistente personal virtual",
        "Modo E-Step",
        "Selector de modo de manejo",
        "Sistema de calefacción híbrido",
        "Batería con calentador y refrigeración",
        "Apertura automática del puerto de carga desde el interior",
        'Monitor avanzado de asistencia al conductor de 12."',
        "Guía de ajuste telescópico manual",
        "Consola central con almacenamiento",
        "Cable de carga portátil (120V EVSE)",
      ],
    },
    {
      Head: "Seguridad",
      content: [
        "Sistema avanzado de bolsas de aire (delanteras, laterales, tipo cortina) SAFETY",
        'Sistemas de cinturones de seguridad de 3 puntos "ALR" para conductor y "ALR" / "ELR" para todos los ocupantes',
        "Luz y sonido indicador de recordatorio para cinturones de seguridad",
        "Sistema de anclaje para asiento protector de niños (LATCH)",
        "Cabezales de asientos delanteros de altura ajustable",
        "Barras tubulares de acero para protección de puertas",
        "Zonas de absorción de impactos delanteros y traseros",
        "Columna del guía con sistema de absorción de energía",
        "Seguros en puertas traseras a prueba de niños",
        "Sistema de monitoreo de presión de gomas (TPMS) con aviso de presión ideal",
        "Luz de frenado central",
        "Sistema de seguridad del vehículo (VSS)",
        "Inmovilizador de motor",
        "Aviso de Colisión Frontal Inteligente (I-FCW)",
        "Frenado Automático de Emergencia con detector de Peatones (PAEB)",
        "Aviso de Cambio de Carril Involuntario (LDW)",
        "Intervención de Cambio de Carril Involuntario (I-LI)",
        "Alerta de Cruce de Tráfico Trasero (RCTA)",
        "Aviso de Punto Ciego (BSW)",
        "Intervención Inteligente de Punto Ciego (I-BSI)",
        "Asistencia de Luz Alta (HBA)",
        "Frenado Automático Trasero (RAB)",
        "ProPilot Assist w/ Navi-Link",
        "Control de viaje inteligente (ICC) con asistencia de control de distancia (DCA)",
        "Reconocimiento de letreros de tráfico",
        "Sistema de sensores delanteros y traseros",
        "Asistencia eléctrica de guía",
        "Control dinámico del vehículo (VDC)",
        "Sistema de control de tracción (TCS)",
        "Sistema de frenos antibloqueo (ABS) con distribución de fuerza de frenos electrónica (EBD)",
        "Sistema de asistencia de frenado (BA)",
        "Freno de emergencia eléctrico (ePKB)",
      ],
    },
    {
      Head: "Dimensiones",
      content: [
        "Distancia entre ejes (pulg.) 109.3",
        "Largo (pulg.) 182.9",
        "Ancho (pulg.) 85.5",
        "Alto (pulg.) 5.4",
        "Altura del vehículo al suelo (pulg.) 6.3",
        "Volumen interior (pies cúbicos) 124",
        "Volumen de pasajeros (pies cúbicos) 101.2",
        "Capacidad de carga (pies cúbicos) 22.8",
        "Espacio de cabeza delantero / trasero (pulg.) 39.6 / 38.3",
        "Espacio de hombro delantero / trasero (pulg.) 56.0 / 55.9",
        "Espacio de cadera delantero / trasero (pulg.) 56.0 / 52.5",
        "Espacio de piernas delantero / trasero (pulg.) 42.3 / 37.0",
      ],
    },
    {
      Head: "Pesos",
      content: [
        "Peso del vehículo (lbs.) 4,528",
        'Peso del vehículo con carga máxima "GVWR" (lbs.) 5,644',
        "Distribución de peso delantero / trasero (lbs.) 53 / 47",
      ],
    },
    {
      Head: "Suspensión",
      content: [
        'Delantera Independiente "MacPherson" con amortiguadores',
        "Trasera Independiente multi-link",
        "Barra estabilizadora Delantera y trasera",
      ],
    },
  ],
};
export const EVOLVE = {
  exterior: {
    "#ff0000": {
      Ext_name: "PassionRed",
      Ext_code: "#ff0000",
      images: [
        AllImages.evolve.PassionRed.img1,
        AllImages.evolve.PassionRed.img2,
        AllImages.evolve.PassionRed.img3,
        AllImages.evolve.PassionRed.img4,
        // AllImages.evolve.PassionRed.img5,
        AllImages.evolve.PassionRed.img6,
        AllImages.evolve.PassionRed.img7,
        AllImages.evolve.PassionRed.img8,
      ],
    },

    "#000000": {
      Ext_name: "Black Diamond Pearl",
      Ext_code: "#000000",
      images: [
        AllImages.evolve.BlackPearl.img1,
        AllImages.evolve.BlackPearl.img2,
        AllImages.evolve.BlackPearl.img3,
        AllImages.evolve.BlackPearl.img4,
        // AllImages.evolve.BlackPearl.img5,
        AllImages.evolve.BlackPearl.img6,
        AllImages.evolve.BlackPearl.img7,
        AllImages.evolve.BlackPearl.img8,
      ],
    },
    "#001746": {
      Ext_name: "Deep Ocean Blue Pearl - Black Diamond Pearl",
      Ext_code: "#001746",
      images: [
        AllImages.evolve.DeapOceanBlue.img1,
        AllImages.evolve.DeapOceanBlue.img2,
        AllImages.evolve.DeapOceanBlue.img3,
        AllImages.evolve.DeapOceanBlue.img4,
        AllImages.evolve.DeapOceanBlue.img5,
        AllImages.evolve.DeapOceanBlue.img6,
        AllImages.evolve.DeapOceanBlue.img7,
        AllImages.evolve.DeapOceanBlue.img8,
      ],
    },
    "#696C71": {
      Ext_name: "Gun Metallic",
      Ext_code: "#696C71",
      images: [
        AllImages.evolve.GunMetallic.img1,
        AllImages.evolve.GunMetallic.img2,
        AllImages.evolve.GunMetallic.img3,
        AllImages.evolve.GunMetallic.img4,
        AllImages.evolve.GunMetallic.img5,
        AllImages.evolve.GunMetallic.img6,
        AllImages.evolve.GunMetallic.img7,
        AllImages.evolve.GunMetallic.img8,
      ],
    },
    "#1e2f41": {
      Ext_name: "NorthernLightsMetallic",
      Ext_code: "#1e2f41",
      images: [
        AllImages.evolve.NorthernLightsMetallic.img1,
        AllImages.evolve.NorthernLightsMetallic.img2,
        AllImages.evolve.NorthernLightsMetallic.img3,
        AllImages.evolve.NorthernLightsMetallic.img4,
        AllImages.evolve.NorthernLightsMetallic.img5,
        AllImages.evolve.NorthernLightsMetallic.img6,
        AllImages.evolve.NorthernLightsMetallic.img7,
        AllImages.evolve.NorthernLightsMetallic.img8,
      ],
    },
    "#676A6F": {
      Ext_name: "Boulder Gray Pearl - Black Diamond Pearl",
      Ext_code: "#676A6F",
      images: [
        AllImages.engageE4orce.BoulderGray.img1,
        AllImages.engageE4orce.BoulderGray.img2,
        AllImages.engageE4orce.BoulderGray.img3,
        AllImages.engageE4orce.BoulderGray.img4,
        AllImages.engageE4orce.BoulderGray.img5,
        AllImages.engageE4orce.BoulderGray.img6,
        AllImages.engageE4orce.BoulderGray.img7,
        AllImages.engageE4orce.BoulderGray.img8,
      ],
    },
    "#DFE8E3": {
      Ext_name: "Everest White - Black Diamond Pearl",
      Ext_code: "#DFE8E3",
      images: [
        AllImages.evolve.EverestWhite.img1,
        AllImages.evolve.EverestWhite.img2,
        AllImages.evolve.EverestWhite.img3,
        AllImages.evolve.EverestWhite.img4,
        AllImages.evolve.EverestWhite.img5,
        AllImages.evolve.EverestWhite.img6,
        AllImages.evolve.EverestWhite.img7,
        AllImages.evolve.EverestWhite.img8,
      ],
    },
  },
  interior: {
    "#000000": {
      Int_name: "Charcoal",
      Int_code: "#000000",
      images: [
        AllImages.evolve.CharcoalLeatherette.img1,
        AllImages.evolve.CharcoalLeatherette.img2,
        AllImages.evolve.CharcoalLeatherette.img3,
        AllImages.evolve.CharcoalLeatherette.img4,
        AllImages.evolve.CharcoalLeatherette.img5,
        AllImages.evolve.CharcoalLeatherette.img6,
        AllImages.evolve.CharcoalLeatherette.img7,
        AllImages.evolve.CharcoalLeatherette.img8,
        AllImages.evolve.CharcoalLeatherette.img9,
        AllImages.evolve.CharcoalLeatherette.img10,
        AllImages.evolve.CharcoalLeatherette.img11,
        AllImages.evolve.CharcoalLeatherette.img12,
      ],
    },
    "#696C71": {
      Int_name: "Light Gray",
      Int_code: "#696C71",
      images: [
        AllImages.evolve.LightGrayLeatherette.img1,
        AllImages.evolve.LightGrayLeatherette.img2,
        AllImages.evolve.LightGrayLeatherette.img3,
        AllImages.evolve.LightGrayLeatherette.img4,
        AllImages.evolve.LightGrayLeatherette.img5,
        AllImages.evolve.LightGrayLeatherette.img6,
        AllImages.evolve.LightGrayLeatherette.img7,
        AllImages.evolve.LightGrayLeatherette.img8,
        AllImages.evolve.LightGrayLeatherette.img9,
        AllImages.evolve.LightGrayLeatherette.img10,
        AllImages.evolve.LightGrayLeatherette.img11,
        AllImages.evolve.LightGrayLeatherette.img12,
      ],
    },
  },
  specs: [
    {
      Head: "Mecánica",
      content: [
        "Motor - 178 kW",
        "Límite de RPM  - 13,520",
        "Caballos de fuerza - 238 HP",
        "Libras pie de torsión - 221 ft-lbs",
        "Capacidad de batería - 91 kWh",
        "Tipo de tracción -  FWD",
        "Tipo de dirección  - Eléctrica del guía sensible a velocidad",
        "Frenos delanteros - Discos ventilados",
        "Frenos Traseros - Discos ventilados",
      ],
    },
    {
      Head: "Consumo",
      content: [
        "Consumo de energía (kW-horas por 100 millas) - 37",
        "Economía de energía en ciudad, carretera, combinado (MPGe) - 97/86/92",
        "Rango de millas por carga - 216",
      ],
    },
    {
      Head: "Exterior",
      content: [
        'Aros en aleación de aluminio de 19" con cubierta aerodinámica',
        "Gomas P235/55R19",
        "Luces delanteras en LED",
        "Luces diurnas en LED",
        "Luces delanteras inteligentes",
        "Luces traseras en LED",
        "Espejos retrovisores de color negro con ajuste eléctrico y sistema de calefacción",
        "Spoiler aerodinámico trasero",
        "Manijas exteriores en color del auto",
        "Puerto de carga con iluminación y seguridad",
        "Limpiaparabrisas de intermitencia sensible de velocidad",
        "Limpiaparabrisas trasero con limpiador",
        "Puerto de carga rápida (CCS)",
      ],
    },
    {
      Head: "Interior - Asientos",
      content: [
        "Capacidad para 5 pasajeros",
        "Asientos con terminación en tela",
        "Asiento del conductor ajustable manual de 6 posiciones",
        "Asiento del pasajero ajustable manual de 6 posiciones",
      ],
    },
    {
      Head: "Interior - Infoentretenimiento",
      content: [
        "Monitor avanzado de asistencia al conductor de 12.3",
        "Sistema de audio con 6 bocinas",
        "Sistema Bluetooth® para llamadas y transferencia de audio",
        "Controles de audio integrados al guía",
        "Puertos de USB: 2 delanteros y 2 traseros (2x tipo-A & 2x tipo-C)",
        'Monitor multitáctil a color de 12.3"',
        "Apple Carplay® inalámbrico & Android Auto™",
        "Sistema de navegación",
        "Asistencia de mensajería de texto",
      ],
    },
    {
      Head: "Comodidad - Conveniencia",
      content: [
        "A/C con control de clima automático de 2 zonas y ventanillas en la segunda fila",
        "Bolsillo en puertas delanteras con porta botellas (x4)",
        "Portavasos en consola central (x2)",
        "Salida para accesorios de 12V",
        "Ventanas eléctricas con bajado y subido automático",
        "Sistema de llave inteligente Nissan",
        "Manijas capacitivas con desbloqueo de acercamiento y bloqueo de alejamiento",
        "Sistema de encendido de motor por botón",
        "Sistema de cámara de reversa",
        "Alerta de Atención al Conductor Inteligente(I-DA)",
        "Sistema de Alerta de Puerta Trasera",
        "Viseras con extensores e iluminación para conductor y pasajero",
        "Asiento trasero reclinables",
        "Asistente personal virtual",
        "Modo E-Step",
        "Selector de modo de manejo",
        "Sistema de calefacción híbrido",
        "Batería con calentador y refrigeración",
        "Apertura automática del puerto de carga desde el interior",
        'Monitor avanzado de asistencia al conductor de 12."',
        "Guía de ajuste telescópico manual",
        "Consola central con almacenamiento",
        "Cable de carga portátil (120V EVSE)",
      ],
    },
    {
      Head: "Seguridad",
      content: [
        "Sistema avanzado de bolsas de aire (delanteras, laterales, tipo cortina) SAFETY",
        'Sistemas de cinturones de seguridad de 3 puntos "ALR" para conductor y "ALR" / "ELR" para todos los ocupantes',
        "Luz y sonido indicador de recordatorio para cinturones de seguridad",
        "Sistema de anclaje para asiento protector de niños (LATCH)",
        "Cabezales de asientos delanteros de altura ajustable",
        "Barras tubulares de acero para protección de puertas",
        "Zonas de absorción de impactos delanteros y traseros",
        "Columna del guía con sistema de absorción de energía",
        "Seguros en puertas traseras a prueba de niños",
        "Sistema de monitoreo de presión de gomas (TPMS) con aviso de presión ideal",
        "Luz de frenado central",
        "Sistema de seguridad del vehículo (VSS)",
        "Inmovilizador de motor",
        "Aviso de Colisión Frontal Inteligente (I-FCW)",
        "Frenado Automático de Emergencia con detector de Peatones (PAEB)",
        "Aviso de Cambio de Carril Involuntario (LDW)",
        "Intervención de Cambio de Carril Involuntario (I-LI)",
        "Alerta de Cruce de Tráfico Trasero (RCTA)",
        "Aviso de Punto Ciego (BSW)",
        "Intervención Inteligente de Punto Ciego (I-BSI)",
        "Asistencia de Luz Alta (HBA)",
        "Frenado Automático Trasero (RAB)",
        "ProPilot Assist w/ Navi-Link",
        "Control de viaje inteligente (ICC) con asistencia de control de distancia (DCA)",
        "Reconocimiento de letreros de tráfico",
        "Sistema de sensores delanteros y traseros",
        "Asistencia eléctrica de guía",
        "Control dinámico del vehículo (VDC)",
        "Sistema de control de tracción (TCS)",
        "Sistema de frenos antibloqueo (ABS) con distribución de fuerza de frenos electrónica (EBD)",
        "Sistema de asistencia de frenado (BA)",
        "Freno de emergencia eléctrico (ePKB)",
      ],
    },
    {
      Head: "Dimensiones",
      content: [
        "Distancia entre ejes (pulg.) 109.3",
        "Largo (pulg.) 182.9",
        "Ancho (pulg.) 85.5",
        "Alto (pulg.) 5.4",
        "Altura del vehículo al suelo (pulg.) 6.3",
        "Volumen interior (pies cúbicos) 124",
        "Volumen de pasajeros (pies cúbicos) 101.2",
        "Capacidad de carga (pies cúbicos) 22.8",
        "Espacio de cabeza delantero / trasero (pulg.) 39.6 / 38.3",
        "Espacio de hombro delantero / trasero (pulg.) 56.0 / 55.9",
        "Espacio de cadera delantero / trasero (pulg.) 56.0 / 52.5",
        "Espacio de piernas delantero / trasero (pulg.) 42.3 / 37.0",
      ],
    },
    {
      Head: "Pesos",
      content: [
        "Peso del vehículo (lbs.) 4,528",
        'Peso del vehículo con carga máxima "GVWR" (lbs.) 5,644',
        "Distribución de peso delantero / trasero (lbs.) 53 / 47",
      ],
    },
    {
      Head: "Suspensión",
      content: [
        'Delantera Independiente "MacPherson" con amortiguadores',
        "Trasera Independiente multi-link",
        "Barra estabilizadora Delantera y trasera",
      ],
    },
  ],
};
export const evolvepe4orce = {
  exterior: {
    "#1e2f41": {
      Ext_name: "Northern Lights Metallic",
      Ext_code: "#1e2f41",
      images: [
        AllImages.evolvepe4orce.NorthernLightsMetallic.img1,
        AllImages.evolvepe4orce.NorthernLightsMetallic.img2,
        AllImages.evolvepe4orce.NorthernLightsMetallic.img3,
        AllImages.evolvepe4orce.NorthernLightsMetallic.img4,
        AllImages.evolvepe4orce.NorthernLightsMetallic.img5,
        AllImages.evolvepe4orce.NorthernLightsMetallic.img6,
        AllImages.evolvepe4orce.NorthernLightsMetallic.img7,
        AllImages.evolvepe4orce.NorthernLightsMetallic.img8,
      ],
    },
    "#000000": {
      Ext_name: "Black Diamond Pearl",
      Ext_code: "#000000",
      images: [
        AllImages.evolvepe4orce.BlackPearl.img1,
        AllImages.evolvepe4orce.BlackPearl.img2,
        AllImages.evolvepe4orce.BlackPearl.img3,
        AllImages.evolvepe4orce.BlackPearl.img4,
        // AllImages.evolvepe4orce.BlackPearl.img5,
        AllImages.evolvepe4orce.BlackPearl.img6,
        AllImages.evolvepe4orce.BlackPearl.img7,
        AllImages.evolvepe4orce.BlackPearl.img8,
      ],
    },
    "#001746": {
      Ext_name: "Deep Ocean Blue Pearl - Black Diamond Pearl",
      Ext_code: "#001746",
      images: [
        AllImages.evolvepe4orce.DeapOceanBlue.img1,
        AllImages.evolvepe4orce.DeapOceanBlue.img2,
        AllImages.evolvepe4orce.DeapOceanBlue.img3,
        AllImages.evolvepe4orce.DeapOceanBlue.img4,
        AllImages.evolvepe4orce.DeapOceanBlue.img5,
        AllImages.evolvepe4orce.DeapOceanBlue.img6,
        AllImages.evolvepe4orce.DeapOceanBlue.img7,
        AllImages.evolvepe4orce.DeapOceanBlue.img8,
      ],
    },
    "#696C71": {
      Ext_name: "Gun Metallic",
      Ext_code: "#696C71",
      images: [
        AllImages.evolvepe4orce.GunMetallic.img1,
        AllImages.evolvepe4orce.GunMetallic.img2,
        AllImages.evolvepe4orce.GunMetallic.img3,
        AllImages.evolvepe4orce.GunMetallic.img4,
        AllImages.evolvepe4orce.GunMetallic.img5,
        AllImages.evolvepe4orce.GunMetallic.img6,
        AllImages.evolvepe4orce.GunMetallic.img7,
        AllImages.evolvepe4orce.GunMetallic.img8,
      ],
    },

    "#676A6F": {
      Ext_name: "Boulder Gray Pearl - Black Diamond Pearl",
      Ext_code: "#676A6F",
      images: [
        AllImages.evolvepe4orce.BoulderGray.img1,
        AllImages.evolvepe4orce.BoulderGray.img2,
        AllImages.evolvepe4orce.BoulderGray.img3,
        AllImages.evolvepe4orce.BoulderGray.img4,
        AllImages.evolvepe4orce.BoulderGray.img5,
        AllImages.evolvepe4orce.BoulderGray.img6,
        AllImages.evolvepe4orce.BoulderGray.img7,
        AllImages.evolvepe4orce.BoulderGray.img8,
      ],
    },
    "#DFE8E3": {
      Ext_name: "Everest White Peal Tricoat - Black Diamond Pearl",
      Ext_code: "#DFE8E3",
      images: [
        AllImages.evolvepe4orce.EverestWhite.img1,
        AllImages.evolvepe4orce.EverestWhite.img2,
        AllImages.evolvepe4orce.EverestWhite.img3,
        AllImages.evolvepe4orce.EverestWhite.img4,
        AllImages.evolvepe4orce.EverestWhite.img5,
        AllImages.evolvepe4orce.EverestWhite.img6,
        AllImages.evolvepe4orce.EverestWhite.img7,
        AllImages.evolvepe4orce.EverestWhite.img8,
      ],
    },
    "#BB1928": {
      Ext_name: "Passion Red - Black Diamond Pearl",
      Ext_code: "#BB1928",
      images: [
        AllImages.evolvepe4orce.PassionRed.img1,
        AllImages.evolvepe4orce.PassionRed.img2,
        AllImages.evolvepe4orce.PassionRed.img3,
        AllImages.evolvepe4orce.PassionRed.img4,
        AllImages.evolvepe4orce.PassionRed.img5,
        AllImages.evolvepe4orce.PassionRed.img6,
        AllImages.evolvepe4orce.PassionRed.img7,
        AllImages.evolvepe4orce.PassionRed.img8,
      ],
    },
  },
  interior: {
    "#000000": {
      Int_name: "Charcoal",
      Int_code: "#000000",
      images: [
        AllImages.evolvepe4orce.CharcoalLeatherette.img1,
        AllImages.evolvepe4orce.CharcoalLeatherette.img2,
        AllImages.evolvepe4orce.CharcoalLeatherette.img3,
        AllImages.evolvepe4orce.CharcoalLeatherette.img4,
        AllImages.evolvepe4orce.CharcoalLeatherette.img5,
        AllImages.evolvepe4orce.CharcoalLeatherette.img6,
        AllImages.evolvepe4orce.CharcoalLeatherette.img7,
        AllImages.evolvepe4orce.CharcoalLeatherette.img8,
        AllImages.evolvepe4orce.CharcoalLeatherette.img9,
        AllImages.evolvepe4orce.CharcoalLeatherette.img10,
        AllImages.evolvepe4orce.CharcoalLeatherette.img11,
        AllImages.evolvepe4orce.CharcoalLeatherette.img12,
      ],
    },
    "#696C71": {
      Int_name: "Light Gray",
      Int_code: "#696C71",
      images: [
        AllImages.evolvepe4orce.LightGrayLeatherette.img1,
        AllImages.evolvepe4orce.LightGrayLeatherette.img2,
        AllImages.evolvepe4orce.LightGrayLeatherette.img3,
        AllImages.evolvepe4orce.LightGrayLeatherette.img4,
        AllImages.evolvepe4orce.LightGrayLeatherette.img5,
        AllImages.evolvepe4orce.LightGrayLeatherette.img6,
        AllImages.evolvepe4orce.LightGrayLeatherette.img7,
        AllImages.evolvepe4orce.LightGrayLeatherette.img8,
        AllImages.evolvepe4orce.LightGrayLeatherette.img9,
        AllImages.evolvepe4orce.LightGrayLeatherette.img10,
        AllImages.evolvepe4orce.LightGrayLeatherette.img11,
        AllImages.evolvepe4orce.LightGrayLeatherette.img12,
      ],
    },
  },
  specs: [
    {
      Head: "Mecánica",
      content: [
        "Motor - 290 kW",
        "Límite de RPM  - 13,520",
        "Caballos de fuerza - 389 HP",
        "Libras pie de torsión - 442 ft-lbs",
        "Capacidad de batería - 91 kWh",
        "Tipo de tracción -  e-4ORCE (AWD)",
        "Tipo de dirección  - Eléctrica del guía sensible a velocidad",
        "Frenos delanteros - Discos ventilados",
        "Frenos Traseros - Discos ventilados",
      ],
    },
    {
      Head: "Consumo",
      content: [
        "Consumo de energía (kW-horas por 100 millas) - 37",
        "Economía de energía en ciudad, carretera, combinado (MPGe) - 97/86/92",
        "Rango de millas por carga - 216",
      ],
    },
    {
      Head: "Exterior",
      content: [
        'Aros en aleación de aluminio de 19" con cubierta aerodinámica',
        "Gomas P235/55R19",
        "Luces delanteras en LED",
        "Luces diurnas en LED",
        "Luces delanteras inteligentes",
        "Luces traseras en LED",
        "Espejos retrovisores de color negro con ajuste eléctrico y sistema de calefacción",
        "Spoiler aerodinámico trasero",
        "Manijas exteriores en color del auto",
        "Puerto de carga con iluminación y seguridad",
        "Limpiaparabrisas de intermitencia sensible de velocidad",
        "Limpiaparabrisas trasero con limpiador",
        "Puerto de carga rápida (CCS)",
      ],
    },
    {
      Head: "Interior - Asientos",
      content: [
        "  Capacidad para 5 pasajeros",
        "Asientos con terminación en piel sintética",
        "Asiento del conductor ajustable eléctrico de 8 posiciones con soporte lumbar",
        "Asiento del pasajero ajustable eléctrico de 8 posiciones",
        "Asiento del conductor con sistema de memoria",
        "Sistema de calefacción en asientos delanteros y traseros",
      ],
    },
    {
      Head: "Interior - Infoentretenimiento",
      content: [
        "Monitor avanzado de asistencia al conductor de 12.3",
        "Sistema de audio con 6 bocinas",
        "Sistema Bluetooth® para llamadas y transferencia de audio",
        "Controles de audio integrados al guía",
        "Puertos de USB: 2 delanteros y 2 traseros (2x tipo-A & 2x tipo-C)",
        'Monitor multitáctil a color de 12.3"',
        "Apple Carplay® inalámbrico & Android Auto™",
        "Sistema de navegación",
        "Asistencia de mensajería de texto",
      ],
    },
    {
      Head: "Comodidad - Conveniencia",
      content: [
        "A/C con control de clima automático de 2 zonas y ventanillas en la segunda fila",
        "Bolsillo en puertas delanteras con porta botellas (x4)",
        "Portavasos en consola central (x2)",
        "Salida para accesorios de 12V",
        "Ventanas eléctricas con bajado y subido automático",
        "Sistema de llave inteligente Nissan",
        "Manijas capacitivas con desbloqueo de acercamiento y bloqueo de alejamiento",
        "Sistema de encendido de motor por botón",
        "Sistema de cámara de reversa",
        "Alerta de Atención al Conductor Inteligente(I-DA)",
        "Sistema de Alerta de Puerta Trasera",
        "Viseras con extensores e iluminación para conductor y pasajero",
        "Asiento trasero reclinables",
        "Asistente personal virtual",
        "Modo E-Step",
        "Selector de modo de manejo",
        "Sistema de calefacción híbrido",
        "Batería con calentador y refrigeración",
        "Apertura automática del puerto de carga desde el interior",
        'Monitor avanzado de asistencia al conductor de 12."',
        "Guía de ajuste telescópico manual",
        "Consola central con almacenamiento",
        "Cable de carga portátil (120V EVSE)",
      ],
    },
    {
      Head: "Seguridad",
      content: [
        "Sistema avanzado de bolsas de aire (delanteras, laterales, tipo cortina) SAFETY",
        'Sistemas de cinturones de seguridad de 3 puntos "ALR" para conductor y "ALR" / "ELR" para todos los ocupantes',
        "Luz y sonido indicador de recordatorio para cinturones de seguridad",
        "Sistema de anclaje para asiento protector de niños (LATCH)",
        "Cabezales de asientos delanteros de altura ajustable",
        "Barras tubulares de acero para protección de puertas",
        "Zonas de absorción de impactos delanteros y traseros",
        "Columna del guía con sistema de absorción de energía",
        "Seguros en puertas traseras a prueba de niños",
        "Sistema de monitoreo de presión de gomas (TPMS) con aviso de presión ideal",
        "Luz de frenado central",
        "Sistema de seguridad del vehículo (VSS)",
        "Inmovilizador de motor",
        "Aviso de Colisión Frontal Inteligente (I-FCW)",
        "Frenado Automático de Emergencia con detector de Peatones (PAEB)",
        "Aviso de Cambio de Carril Involuntario (LDW)",
        "Intervención de Cambio de Carril Involuntario (I-LI)",
        "Alerta de Cruce de Tráfico Trasero (RCTA)",
        "Aviso de Punto Ciego (BSW)",
        "Intervención Inteligente de Punto Ciego (I-BSI)",
        "Asistencia de Luz Alta (HBA)",
        "Frenado Automático Trasero (RAB)",
        "ProPilot Assist w/ Navi-Link",
        "Control de viaje inteligente (ICC) con asistencia de control de distancia (DCA)",
        "Reconocimiento de letreros de tráfico",
        "Sistema de sensores delanteros y traseros",
        "Asistencia eléctrica de guía",
        "Control dinámico del vehículo (VDC)",
        "Sistema de control de tracción (TCS)",
        "Sistema de frenos antibloqueo (ABS) con distribución de fuerza de frenos electrónica (EBD)",
        "Sistema de asistencia de frenado (BA)",
        "Freno de emergencia eléctrico (ePKB)",
      ],
    },
    {
      Head: "Dimensiones",
      content: [
        "Distancia entre ejes (pulg.) 109.3",
        "Largo (pulg.) 182.9",
        "Ancho (pulg.) 85.5",
        "Alto (pulg.) 5.4",
        "Altura del vehículo al suelo (pulg.) 5.3",
        "Volumen interior (pies cúbicos) 119.1",
        "Volumen de pasajeros (pies cúbicos) 96.3",
        "Capacidad de carga (pies cúbicos) 22.8",
        "Espacio de cabeza delantero / trasero (pulg.) 37.9 / 38.3",
        "Espacio de hombro delantero / trasero (pulg.) 56.0 / 55.9",
        "Espacio de cadera delantero / trasero (pulg.) 56.0 / 52.5",
        "Espacio de piernas delantero / trasero (pulg.) 42.3 / 37.0",
      ],
    },
    {
      Head: "Pesos",
      content: [
        "Peso del vehículo (lbs.) 4,899",
        'Peso del vehículo con carga máxima "GVWR" (lbs.) 5,930',
        "Distribución de peso delantero / trasero (lbs.) 50 / 50",
      ],
    },
    {
      Head: "Suspensión",
      content: [
        'Delantera Independiente "MacPherson" con amortiguadores',
        "Trasera Independiente multi-link",
        "Barra estabilizadora Delantera y trasera",
      ],
    },
  ],
};

export const CompareDataAriya = {
  "ENGAGE FWD": {
    engine: "160 kW",
    baterry: "66 kWh",
    car: "FWD",
    Alcance: "216 millas",
    MPGe: "109/94/101",
    MSRP: 51228,
    img: AllImages.engageFWD.DeapOceanBlue.img1,
  },
  "ENGAGE e-4FORCE": {
    engine: "250 kW",
    baterry: "66 kWh",
    car: "e-4FORCE (AWD)",
    Alcance: "205 millas",
    MPGe: "101/89/95",
    MSRP: 55932,
    img: AllImages.engageE4orce.BoulderGray.img1,
  },
  "ENGAGE+ e-4FORCE": {
    engine: "290 kW",
    baterry: "91 kWh",
    car: "e-4FORCE (AWD)",
    Alcance: "270 millas",
    MPGe: "97/86/92",
    MSRP: 60635,
    img: AllImages.engageEE4orce.GunMetallic.img1,
  },
  "VENTURE+ FWD": {
    engine: "178 kW",
    baterry: "91 kWh",
    car: "FWD",
    Alcance: "304 millas",
    MPGe: "111/95/103",
    MSRP: 55932,
    img: AllImages.VENTURE.EverestWhite.img1,
  },
  "EVOLVE+ FWD": {
    engine: "178 kW",
    baterry: "91 kWh",
    car: "FWD",
    Alcance: "289 millas",
    MPGe: "105/91/98",
    MSRP: 59460,
    img: AllImages.evolve.PassionRed.img1,
  },
  "EVOLVE+ e-4FORCE": {
    engine: "290 kW",
    baterry: "91 kWh",
    car: "e-4FORCE (AWD)",
    Alcance: "270 millas",
    MPGe: "97/86/92",
    MSRP: 64163,
    img: AllImages.evolvepe4orce.BlackPearl.img1,
  },
};

export const features = [
  {
    head: "Colores exteriores",
    body: [
      {
        name: "Northern Lights Metallic",
        name: "Northern Lights Metallic",
        name: "Northern Lights Metallic",
        name: "Northern Lights Metallic",
      },
    ],
    // {
    //   "Northern Lights Metallic": [],
    //   "Black Diamond Pearl": [],
    //   "Gun Metallic": [],
    //   "Everest White - Black Diamond Pearl Pearl / Black Diamond": [],
    //   "Brilliant Silver / Black Diamond": [],
    //   "Passion Red / Black Diamond": [],
    //   "Boulder Gray / Black DiamondDeep": [],
    //   "Ocean Blue / Black Diamond": [],
    // },
  },
];

// "LEAF S": {
//   engine: "160 kW",
//   baterry: "66 kWh",
//   car: "FWD",
//   Alcance: "149 millas",
//   MPGe: "123/99/111",
//   MSRP: 33520,
//   img: "",
//   features: [],
// },
// "LEAF SV Pus": {
//   engine: "250 kW",
//   baterry: "66 kWh",
//   car: "e-4ORCE (AWD)",
//   Alcance: "212 millas",
//   MPGe: "121/98/109",
//   MSRP: 43150,
//   img: "",
//   features: [],
// },
