import { useState, useContext, useEffect } from "react";
import Wrapper from "../../components/UI/Wrapper";
import Card from "../../components/UI/Card";
import Button from "../../../Common/components/UI/Button";
import "../../components/Alert/Alert.css";
import { Link, useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

import { GlobalContext } from "../../../GlobalContext";

const Deposit = () => {
  const { setLoginModalshow } = useContext(GlobalContext);
  // const ChangeBtn = () => {
  //   setLoginModalshow(true);
  // };

  const initialValues = {
    Email: "",
    Password: "",
  };
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  const userData = localStorage.getItem("user-info");
  useEffect(() => {
    if (!userData) {
      // setLoginModalshow(true)
      navigate("/Iniciar-Sesion");
    }
  }, []);

  return (
    <div>
      <Wrapper>
        <Card head={""}>
          <div id="akhaini">
            <h1>¡Estás a un solo paso de reservar!</h1>
            <div>
              <p>
                Para completar tu reserva, necesitamos que nos proporciones tu
                dirección y método de pago para realizar tu depósito de $500. Tu
                seguridad y privacidad son nuestras principales prioridades, y
                toda la información que nos brindes será tratada con la más
                estricta confidencialidad. Si en algún momento prefieres
                reconsiderar la transacción, tienes la opción de cancelar antes
                de finalizar el proceso.
                {/* Al registrar tu cuenta podrás completar la reservación, ver el
                estatus de tu orden y recibir actualizaciones. ¡Registra tu
                cuenta ahora! */}
              </p>
            </div>
            <div></div>
            <div className="d-flex justify-content-center align-items-center">
              {/* <Link to={"/Registrate"}> */}
              <Link
                to={"/RegistrateAddress"}
                // to={
                //   localStorage.getItem("StaticCartData")
                //     ? "/Iniciar-Sesion"
                //     : "/Perfil/History"
                // }
              >
                <Button variant="red">Continuar</Button>
              </Link>
            </div>
          </div>
          {/* <Button variant="red" onClick={() => ChangeBtn()}>
            check
          </Button> */}
        </Card>
      </Wrapper>
    </div>
  );
};

export default Deposit;
