import React, { useEffect, useState } from "react";
import "./MyCard.css";
import { Col, Row } from "react-bootstrap";
import Button from "../../../../Common/components/UI/Button";
import Input from "../../../components/UI/Input";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useFormik } from "formik";
import axios from "../../../../Common/Api";
import visalogo from "../../../../Common/assets/images/visalogo.png";
import masterlogo from "../../../../Common/assets/images/masterlogo.png";
import { PaymentSchema } from "../../../schemas";
import Loader from "../../../components/UI/Loader";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const stripeKEY = process.env.REACT_APP_STRIP_KEY;
const stripePromise = loadStripe(stripeKEY);

const DeleteSVG = (
  <svg
    viewBox="0 0 24 24"
    width="60%"
    height="60%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M10 12V17"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"></path>{" "}
      <path
        d="M14 12V17"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"></path>{" "}
      <path
        d="M4 7H20"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"></path>{" "}
      <path
        d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"></path>{" "}
      <path
        d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"></path>{" "}
    </g>
  </svg>
);

const MyCardDesign = () => {
  // const carddata = {
  //   length: 2,
  // };
  const [StripeCardToken, setStripeCardToken] = useState([]);
  const [ContentOne, setContentOne] = useState(true);
  const [carddata, setcard] = useState([]);
  const [IsError, setIsError] = useState("");
  const [Loading, setLoading] = useState(false);
  const [BackDropLoading, setBackDropLoading] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const getcard = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-card-list", {
        headers: {
          Authorization: `Bearer ${UserData.Token}`,
        },
      });
      if (response) {
        setcard(response.data.data);
        setLoading(false);
        // console.log("Get card responce====>", response);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  // console.log(carddata);

  useEffect(() => {
    getcard();
  }, []);

  const initialValues = {
    CardName: "",
    CardNumber: "",
  };

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000000",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#000000",
        },
      },
      invalid: {
        iconColor: "#D40000",
        color: "#D40000",
      },
    },
  };
  const stripe = useStripe();
  const element = useElements();
  const onSubmit = async () => {
    setLoading(true);
    const card = element.getElement(CardElement);
    const result = await stripe.createToken(card, {
      name: values.CardName,
    });
    setStripeCardToken(result);

    if (result.error) {
      setLoading(false);
      // console.log("[error]", result.error);
      setIsError(result.error.message);
    } else {
      console.log("[Result]", result);
      try {
        const response = await axios.post(
          "/add-card",
          {
            CardToken: result.token.id,
          },
          {
            headers: {
              Authorization: `Bearer ${UserData.Token}`,
            },
          }
        );
        if (response) {
          console.log("Add card =======>", response);
          setLoading(false);
          setContentOne(true);
          getcard();
        }
      } catch (error) {
        setLoading(false);
        setIsError(error);
        console.log(error);
      }
    }
    console.log("result.....", result);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: PaymentSchema,
      onSubmit,
    });

  const deleteCard = async (CardId) => {
    // console.log(CardId);
    if (carddata.length == 1) {
      // console.log("You cannot delete all cards");
    } else {
      try {
        setBackDropLoading(true);
        const responce = await axios.post(
          "/delete-card",
          {
            card_id: CardId,
          },
          {
            headers: {
              Authorization: `Bearer ${UserData.Token}`,
            },
          }
        );
        if (responce) {
          setBackDropLoading(false);
          getcard();
        }
      } catch (error) {
        setBackDropLoading(false);
        // console.log(error);
      }
    }
  };
  let AMEX = (
    <svg
      width="35"
      height="24"
      viewBox="0 0 35 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="35" height="24" rx="2" fill="#016FD0" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.6627 17.8V20.408H31.4427L30.2527 18.976L29.016 20.408H19.8694V12.784H16.8516L20.6238 4H24.2949L25.1816 5.992V4H29.7394L30.5016 6.088L31.2482 4H34.6627V5.23999H32.026L30.8282 8.48801L30.5094 9.36799L28.9771 5.23999H26.3405V11.64L23.6027 5.23999H21.4794L18.726 11.64H20.5305L21.0127 10.408H24.0305L24.5127 11.64H26.3405H27.9272V7.472L27.9194 6.63199L28.2305 7.472L29.7471 11.64H31.256L32.7805 7.472L33.076 6.64V11.64H34.6627V14.144L32.9049 15.96L34.6627 17.8ZM21.1449 19.168V12.792H26.3405V14.176H22.7316V15.296H26.2705V16.672H22.7316V17.784H26.3405V19.168H21.1449ZM34.1805 19.168H32.1194L30.2371 17.088L28.3471 19.168H26.3405L29.2416 15.992L26.3405 12.792H28.4094L30.276 14.864L32.1505 12.792H34.1805L31.2716 15.968L34.1805 19.168Z"
        fill="white"
      />
      <path
        d="M22.5216 6.60799L22.2105 7.40802L21.5649 9.02397H23.4782L22.8327 7.40802L22.5216 6.60799Z"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="34"
        height="23"
        rx="1.5"
        stroke="black"
        stroke-opacity="0.2"
      />
    </svg>
  );
  let visa = (
    <svg
      width="35"
      height="24"
      viewBox="0 0 35 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="35" height="24" rx="2" fill="#1434CB" />
      <path
        d="M21.6214 7.31445C19.6886 7.31445 17.9613 8.34493 17.9613 10.2488C17.9613 12.4322 21.0247 12.583 21.0247 13.6799C21.0247 14.1417 20.5101 14.5552 19.6313 14.5552C18.384 14.5552 17.4517 13.9775 17.4517 13.9775L17.0529 15.8987C17.0529 15.8987 18.1268 16.3867 19.5526 16.3867C21.6658 16.3867 23.3287 15.3056 23.3287 13.3692C23.3287 11.062 20.2525 10.9157 20.2525 9.8976C20.2525 9.53581 20.6749 9.1394 21.5512 9.1394C22.54 9.1394 23.3468 9.55953 23.3468 9.55953L23.7371 7.70395C23.7371 7.70395 22.8594 7.31445 21.6214 7.31445ZM3.93743 7.4545L3.89062 7.73458C3.89062 7.73458 4.70379 7.88766 5.43618 8.19301C6.37919 8.54315 6.44637 8.74699 6.60519 9.3801L8.33583 16.2422H10.6558L14.2298 7.4545H11.9152L9.61865 13.4293L8.68153 8.36478C8.59559 7.78515 8.16026 7.4545 7.6274 7.4545H3.93743ZM15.1605 7.4545L13.3448 16.2422H15.552L17.3613 7.4545H15.1605ZM27.4707 7.4545C26.9385 7.4545 26.6565 7.74758 26.4496 8.25975L23.2159 16.2422H25.5305L25.9784 14.9118H28.7982L29.0705 16.2422H31.1128L29.3311 7.4545H27.4707ZM27.7718 9.82868L28.4578 13.1263H26.6198L27.7718 9.82868Z"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="34"
        height="23"
        rx="1.5"
        stroke="black"
        stroke-opacity="0.2"
      />
    </svg>
  );
  let Master = (
    <svg
      width="35"
      height="24"
      viewBox="0 0 35 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="35" height="24" rx="2" fill="#252525" />
      <path
        d="M14.5234 6.42285H20.4819V17.4352H14.5234V6.42285Z"
        fill="#FF5F00"
      />
      <path
        d="M14.902 11.9291C14.902 9.69161 15.9234 7.70707 17.4934 6.42295C16.3396 5.48904 14.883 4.9248 13.2941 4.9248C9.52984 4.9248 6.48438 8.05728 6.48438 11.9291C6.48438 15.8009 9.52984 18.9334 13.2941 18.9334C14.883 18.9334 16.3396 18.3692 17.4934 17.4353C15.9234 16.1706 14.902 14.1666 14.902 11.9291Z"
        fill="#EB001B"
      />
      <path
        d="M28.5247 11.9291C28.5247 15.8009 25.4792 18.9334 21.715 18.9334C20.126 18.9334 18.6695 18.3692 17.5156 17.4353C19.1046 16.1511 20.1071 14.1666 20.1071 11.9291C20.1071 9.69161 19.0856 7.70707 17.5156 6.42295C18.6695 5.48904 20.126 4.9248 21.715 4.9248C25.4792 4.9248 28.5247 8.07674 28.5247 11.9291Z"
        fill="#F79E1B"
      />
      <rect
        x="0.5"
        y="0.5"
        width="34"
        height="23"
        rx="1.5"
        stroke="white"
        stroke-opacity="0.2"
      />
    </svg>
  );
  let Discover = (
    <svg
      width="35"
      height="24"
      viewBox="0 0 35 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="35" height="24" rx="2" fill="#231F20" />
      <path
        d="M34.998 16C34.998 16 27.2144 21.5233 12.9609 24H34.998V16Z"
        fill="#F48120"
      />
      <path
        d="M3.95925 9.26758H2.59375V14.1879H3.95925C4.68114 14.1879 5.20299 14.0089 5.66395 13.6243C6.21189 13.1591 6.5337 12.4613 6.5337 11.7367C6.525 10.2785 5.47261 9.26758 3.95925 9.26758ZM5.05513 12.9712C4.75942 13.2396 4.38543 13.3648 3.7766 13.3648H3.52438V10.1085H3.7766C4.37673 10.1085 4.74202 10.2158 5.05513 10.5021C5.37694 10.7973 5.56828 11.2536 5.56828 11.7277C5.56828 12.2019 5.37694 12.676 5.05513 12.9712Z"
        fill="white"
      />
      <path
        d="M7.89156 9.26758H6.96094V14.1879H7.89156V9.26758Z"
        fill="white"
      />
      <path
        d="M10.1661 11.1545C9.60946 10.9398 9.44421 10.7966 9.44421 10.5372C9.44421 10.2241 9.73992 9.9915 10.14 9.9915C10.4183 9.9915 10.6532 10.1078 10.888 10.3941L11.375 9.74102C10.975 9.38318 10.4966 9.19531 9.96605 9.19531C9.1224 9.19531 8.47009 9.80364 8.47009 10.6088C8.47009 11.2887 8.7745 11.6376 9.64425 11.9596C10.0095 12.0938 10.1922 12.1833 10.2879 12.2369C10.4705 12.3622 10.5662 12.5411 10.5662 12.7469C10.5662 13.1494 10.2618 13.4446 9.84429 13.4446C9.40072 13.4446 9.04412 13.212 8.82669 12.7916L8.22656 13.391C8.65274 14.044 9.17459 14.3303 9.87908 14.3303C10.8445 14.3303 11.5316 13.6683 11.5316 12.7111C11.549 11.9149 11.2272 11.557 10.1661 11.1545Z"
        fill="white"
      />
      <path
        d="M11.8359 11.7366C11.8359 13.1858 12.9405 14.3041 14.3582 14.3041C14.7583 14.3041 15.1062 14.2236 15.5237 14.0178V12.8906C15.1497 13.2753 14.8192 13.4274 14.4017 13.4274C13.4624 13.4274 12.7927 12.7296 12.7927 11.7276C12.7927 10.7794 13.4798 10.0369 14.3582 10.0369C14.8018 10.0369 15.141 10.1979 15.5237 10.5915V9.46432C15.1149 9.24962 14.7757 9.16016 14.3756 9.16016C12.9753 9.16016 11.8359 10.3052 11.8359 11.7366Z"
        fill="white"
      />
      <path
        d="M22.9368 12.5776L21.6582 9.26758H20.6406L22.6671 14.3131H23.1716L25.2329 9.26758H24.224L22.9368 12.5776Z"
        fill="white"
      />
      <path
        d="M25.6562 14.1879H28.3003V13.3559H26.5869V12.0319H28.2394V11.1999H26.5869V10.1085H28.3003V9.26758H25.6562V14.1879Z"
        fill="white"
      />
      <path
        d="M32.0008 10.7256C32.0008 9.80418 31.3833 9.27637 30.3048 9.27637H28.9219V14.1966H29.8525V12.2196H29.9743L31.2615 14.1966H32.4096L30.9049 12.1212C31.6094 11.9691 32.0008 11.4771 32.0008 10.7256ZM30.1221 11.5397H29.8525V10.0457H30.1395C30.7222 10.0457 31.0354 10.2962 31.0354 10.7793C31.0354 11.2713 30.7222 11.5397 30.1221 11.5397Z"
        fill="white"
      />
      <path
        d="M18.3843 14.3664C19.7917 14.3664 20.9326 13.1928 20.9326 11.7452C20.9326 10.2976 19.7917 9.12402 18.3843 9.12402C16.9769 9.12402 15.8359 10.2976 15.8359 11.7452C15.8359 13.1928 16.9769 14.3664 18.3843 14.3664Z"
        fill="#F48120"
      />
      <rect
        x="0.5"
        y="0.5"
        width="34"
        height="23"
        rx="1.5"
        stroke="white"
        stroke-opacity="0.2"
      />
    </svg>
  );

  return (
    <section className="white pt-5 ms-3">
      {ContentOne ? (
        <>
          {!Loading && (
            <>
              <div className="getcarddata">
                {carddata &&
                  carddata.map((curelem, index) => {
                    return (
                      <div lg={12} key={index} className="d-flex gap-4">
                        <div lg={6}>
                          <Row>
                            <div className="d-flex gap-3">
                              <div className="brandlogo">
                                <span>
                                  {curelem.CardBrand === "Visa"
                                    ? visa
                                    : curelem.CardBrand === "MasterCard"
                                    ? Master
                                    : curelem.CardBrand === "American Express"
                                    ? AMEX
                                    : curelem.CardBrand === "Discover"
                                    ? Discover
                                    : null}
                                </span>
                                {/* <img
                                  src=
                                  {
                                    curelem.CardBrand === "Visa"
                                      ? { visa }
                                      : curelem.CardBrand === "MasterCard"
                                      ? { Master }
                                      : curelem.CardBrand === "AMEX"
                                      ? { AMEX }
                                      : curelem.CardBrand === "Discover"
                                      ? { Discover }
                                      : null
                                  }
                                  alt=""
                                /> */}
                              </div>
                              <span className="ProfileHead">
                                Ending {curelem.CardNumber}
                              </span>
                            </div>
                          </Row>

                          <Row className="pt-1">
                            <span className="HeadText">{curelem.CardName}</span>
                          </Row>
                        </div>
                        {carddata.length == 1 ? (
                          <div></div>
                        ) : (
                          <div>
                            <button
                              className="delete-btn"
                              onClick={() => deleteCard(curelem.CardId)}>
                              {DeleteSVG}
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>

              <div className="p-4"></div>
              <Col lg={12}>
                <Button variant={"red"} onClick={() => setContentOne(false)}>
                  Añadir tarjeta
                </Button>
              </Col>
            </>
          )}
          {Loading && <Loader />}

          {!BackDropLoading && <div></div>}
          {BackDropLoading && (
            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )}
        </>
      ) : (
        <div className="back-btn position-absolute">
          <svg
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "-45px",
              left: "-40px",
            }}
            onClick={() => setContentOne(true)}
            width="40"
            height="40"
            viewBox="0 0 108 108"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M38.07 57.1951L63.54 82.6201C63.9584 83.0419 64.4561 83.3766 65.0044 83.6051C65.5528 83.8335 66.141 83.9512 66.735 83.9512C67.3291 83.9512 67.9172 83.8335 68.4656 83.6051C69.014 83.3766 69.5117 83.0419 69.93 82.6201C70.7681 81.7769 71.2386 80.6364 71.2386 79.4476C71.2386 78.2587 70.7682 77.1182 69.93 76.2751L47.655 53.7751L69.93 31.5001C70.7682 30.657 71.2386 29.5164 71.2386 28.3276C71.2386 27.1387 70.7682 25.9982 69.93 25.1551C69.5133 24.7299 69.0163 24.3916 68.4678 24.1599C67.9194 23.9281 67.3304 23.8076 66.735 23.8051C66.1396 23.8076 65.5507 23.9281 65.0022 24.1599C64.4538 24.3916 63.9568 24.7299 63.54 25.1551L38.07 50.5801C37.6132 51.0015 37.2487 51.5129 36.9994 52.0821C36.75 52.6514 36.6213 53.2661 36.6213 53.8876C36.6213 54.509 36.75 55.1238 36.9994 55.693C37.2487 56.2623 37.6132 56.7737 38.07 57.1951Z"
              fill="#d40000"
            />
          </svg>
          <Col lg={8}>
            <form onSubmit={handleSubmit} className="mainform3">
              <div className="khaincard">
                <div className="cardelemnt2 p-0">
                  <Input
                    type={"text"}
                    text={"Nombre en la tarjeta"}
                    name="CardName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.CardName}
                  />
                  {errors.CardName && touched.CardName ? (
                    <p className="form-error2"> {errors.CardName} </p>
                  ) : null}
                </div>
                <div className="cardelemnt2" style={{ border: "1px solid" }}>
                  <CardElement
                    options={{ CARD_OPTIONS, hidePostalCode: true }}
                    name="CardNumber"
                  />
                </div>
                <p className="form-error2">{IsError}</p>
              </div>
              <div className="">
                <Button variant={"red"} type={"submit"}>
                  {!Loading ? " Guardar" : "Loading....."}
                </Button>
              </div>
            </form>
          </Col>
          {!Loading && <div></div>}
          {Loading && (
            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )}
        </div>
      )}
    </section>
    // <div>
    //   <div className="getcarddata">
    //     <div lg={12} className="d-flex gap-4">
    //       <div lg={6}>
    //         <Row>
    //           <div className="d-flex gap-3">
    //             <div className="brandlogo">
    //               {/* <img src={null} alt="" /> */}
    //               <svg
    //                 width="52"
    //                 height="32"
    //                 viewBox="0 0 52 32"
    //                 fill="none"
    //                 xmlns="http://www.w3.org/2000/svg"
    //               >
    //                 <rect width="52" height="32" rx="4" fill="#D9D9D9" />
    //               </svg>
    //             </div>
    //             <span className="ProfileHead">Ending 0990</span>
    //           </div>
    //         </Row>

    //         <Row className="pt-1">
    //           <span className="HeadText">Borrar</span>
    //         </Row>
    //       </div>
    //       {carddata.length > 1 ? (
    //         <div>
    //           <button
    //             className="delete-btn"
    //             // onClick={() => deleteCard(curelem.CardId)}
    //           >
    //             {DeleteSVG}
    //           </button>
    //         </div>
    //       ) : null}
    //     </div>
    //   </div>

    //   <div className="p-4"></div>
    //   <Col lg={12}>
    //     <Button
    //       variant={"red"}
    //       // onClick={() => setContentOne(false)}
    //     >
    //       Añadir tarjeta
    //     </Button>
    //   </Col>
    // </div>
  );
};

const MyCard = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  return (
    <>
      <Elements stripe={stripePromise}>
        <MyCardDesign />
      </Elements>
    </>
  );
};

export default MyCard;
