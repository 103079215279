import React, { useRef, useState, useEffect } from "react";
import "./Navbar.css";
import logo from "../../../assets/images/NissanNavbarLogo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Fade as Hamburger } from "hamburger-react";
import Dropdownmodel from "../../../page/Drop_Down_model";
import ReactGA from "react-ga4";

const Navbar = ({ steps, setsteps }) => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const Navigate = useNavigate();

  let menuref = useRef();

  const [open, setIsopen] = useState(false);
  const [onshow, setonshow] = useState(false);

  // const [show, setshow] = useState(false);

  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsopen(false);
          setonshow(false);
          // setshow(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  }, []);

  const toggle = (item) => {
    ReactGA.event({
      category: "Navbar",
      action: item,
    });
    setIsopen(false);
    // setshow(false);
  };

  const handlehide = () => {
    setIsopen(!open);
    // setshow(false);
  };

  const data = localStorage.getItem("user-info");
  const handlenavigate = () => {
    if (!data) {
      ReactGA.event({
        category: "Navbar",
        action: "Iniciar-Sesion",
      });
      Navigate("/Iniciar-Sesion");
    } else {
      ReactGA.event({
        category: "Navbar",
        action: "Perfil",
      });
      Navigate("/Perfil");
    }
  };

  const Carrito = () => {
    ReactGA.event({
      category: "Navbar",
      action: "Carrito",
    });
    Navigate("/Carrito");
  };
  const Variantes = () => {
    ReactGA.event({
      category: "Navbar",
      action: "Me interesa",
    });
    Navigate("/Leaf/Variantes");
  };

  return (
    <div ref={menuref} className="Main_navbar">
      <div className="mainnav">
        <nav id="navbar">
          <Link to="/" className="logo">
            <img src={logo} alt="" style={{ width: "100%" }} />
          </Link>

          <ul className={open ? "navlinks mobilemenu" : "navlinks"}>
            <li>
              <NavLink to="/Leaf/Inicio" onClick={toggle}>
                Inicio
              </NavLink>
            </li>
            <li onMouseMove={() => setonshow(true)}>
              {/* <Link to="Galeria" offset={-100} onClick={toggle}>
                Galería
              </Link> */}
              <NavLink to="/Leaf/Variantes" onClick={toggle}>
                Modelos
              </NavLink>
            </li>
            <li>
              {/* <Link to="Specs" offset={-100} onClick={toggle}>
                Modelos
              </Link> */}
              <NavLink to="/Leaf/Preguntas-frecuentes" onClick={toggle}>
                Preguntas frecuentes
              </NavLink>
            </li>
            {/* <li> */}
            {/* <Link to="Colores" offset={-100} onClick={toggle}>
                Colores
              </Link> */}
            {/* <NavLink to="/Colores" onClick={toggle}>
              Colores
              </NavLink> */}
            {/* </li> */}
            {/* <li>
              <NavLink to="/preguntas" onClick={toggle}>
                Preguntas frecuentes
              </NavLink>
            </li> */}
            <li>
              {/* <Link to="exterior" offset={-100} onClick={toggle}>
                360 exterior / interior
              </Link> */}
              <NavLink to={"/Leaf/Concesionarios-autorizados"} onClick={toggle}>
                {/* Concesionarios autorizados */}
                Concesionarios autorizados
              </NavLink>
            </li>
            <li>
              {/* {localStorage.getItem("user-info") ? ( */}
              <NavLink to="/Contactanos" onClick={toggle}>
                Contáctanos
              </NavLink>
              {/* ) : null} */}
            </li>

            <li>
              {localStorage.getItem("user-info") ? (
                <NavLink to="/Perfil" onClick={toggle}>
                  Perfil
                </NavLink>
              ) : null}
            </li>
            <div className="btns">
              {!localStorage.getItem("user-info") && (
                <button className="btn1" onClick={Variantes}>
                  Me interesa
                </button>
              )}
            </div>
          </ul>
          <div className="btns">
            <span className="btn2" onClick={Carrito}>
              Carrito
              {/* <button> {cartCount} </button> */}
              <button>{localStorage.getItem("StaticCartData") ? 1 : 0}</button>
            </span>
            {/* <div onClick={() => setAlertModal(true)}> */}
            <div onClick={() => handlenavigate()}>
              {/* <img src={usericon} alt="" /> */}
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.4561 13.3867C17.6384 12.423 18.5013 11.1016 18.9249 9.60626C19.3485 8.1109 19.3116 6.51594 18.8195 5.0433C18.3273 3.57065 17.4043 2.29354 16.1788 1.38964C14.9534 0.485738 13.4864 0 11.9821 0C10.4778 0 9.01085 0.485738 7.78541 1.38964C6.55997 2.29354 5.63695 3.57065 5.14477 5.0433C4.6526 6.51594 4.61573 8.1109 5.03931 9.60626C5.46289 11.1016 6.32585 12.423 7.50813 13.3867C5.48227 14.2275 3.71464 15.622 2.39367 17.4217C1.07271 19.2214 0.247932 21.3587 0.00727395 23.6058C-0.0101461 23.7698 0.00379671 23.9358 0.0483061 24.0943C0.0928155 24.2528 0.16702 24.4006 0.266682 24.5294C0.46796 24.7894 0.760716 24.956 1.08055 24.9925C1.40038 25.0289 1.72109 24.9322 1.97212 24.7237C2.22315 24.5152 2.38394 24.2119 2.41913 23.8806C2.68393 21.4385 3.80797 19.1831 5.5765 17.5452C7.34503 15.9074 9.63406 15.002 12.0062 15.002C14.3784 15.002 16.6674 15.9074 18.436 17.5452C20.2045 19.1831 21.3285 21.4385 21.5933 23.8806C21.6261 24.1876 21.7675 24.4711 21.9902 24.6764C22.2129 24.8817 22.5011 24.9943 22.7993 24.9925H22.9319C23.248 24.9548 23.537 24.7892 23.7357 24.5318C23.9345 24.2744 24.027 23.946 23.9931 23.6182C23.7513 21.3648 22.9221 19.222 21.5944 17.4196C20.2666 15.6171 18.4904 14.223 16.4561 13.3867ZM11.9821 12.4997C11.0281 12.4997 10.0955 12.2066 9.30221 11.6575C8.50896 11.1084 7.89069 10.328 7.52559 9.41486C7.1605 8.50175 7.06497 7.497 7.2511 6.52765C7.43722 5.55831 7.89663 4.66791 8.57124 3.96905C9.24585 3.27019 10.1054 2.79426 11.0411 2.60144C11.9768 2.40863 12.9466 2.50759 13.8281 2.88581C14.7095 3.26403 15.4628 3.90452 15.9929 4.72629C16.5229 5.54807 16.8058 6.51421 16.8058 7.50254C16.8058 8.82786 16.2976 10.0989 15.393 11.036C14.4884 11.9732 13.2614 12.4997 11.9821 12.4997Z"
                  fill="#C3002F"
                />
              </svg>
            </div>
          </div>
          <div className="menubtn">
            <Hamburger onToggle={handlehide} toggled={open} />
          </div>
        </nav>
        {/* <Outlet /> */}
      </div>
      <div className="drop_box">
        {screenSize.width > 1080 && onshow && (
          <Dropdownmodel setsteps={setsteps} steps={steps} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
