import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import ProfileSidebar from "../../components/UI/Sidebar";
import Navbar from "../../components/UI/Navbar";
import "./profile.css";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const ProfileIndex = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  return (
    <>
      <Helmet>
        <title>Nissan | Perfil</title>
      </Helmet>
      <div
        className="white"
        style={{ backgroundColor: "#fff", minHeight: "100vh", height: "100%" }}>
        <Navbar />
        <div style={{ marginTop: "101px" }}></div>
        <div className="d-flex gap-3 pt-4 pe-3 ps-3 pb-4">
          <ProfileSidebar />
          <div className="w-100">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileIndex;
