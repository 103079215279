import React, { useState, useEffect } from "react";
import "./RegisterAddress.css";
import Wrapper from "../../../../components/UI/Wrapper";
import Card from "../../../../components/UI/Card";
import Input from "../../../../components/UI/Input";
import Button from "../../../../../Common/components/UI/Button";
import { useFormik } from "formik";
import { RegisterAddressSchema } from "../../../../schemas";
import { Form } from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-hot-toast";
import axios from "../../../../../Common/Api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const RegisterAddress = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  const [options, setOptions] = useState([]);
  const [IsError, setIsError] = useState("");
  const [Loading, setLoading] = useState(false);

  const Navigate = useNavigate();

  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const StaticCartData = JSON.parse(localStorage.getItem("StaticCartData"));
  const getpueblo = async () => {
    try {
      const res = await axios.get("get-pueblo");
      setOptions(res.data.data);
      console.log("🚀 res.... pueblo", res);
    } catch (error) {
      // setGetApiError(error);
      console.log("🚀 Catch-error....", error);
    }
  };
  useEffect(() => {
    getpueblo();
  }, []);

  const onSubmit = async () => {
    // console.log("Form-values......", values);
    setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   toast.success("Form Submitted");
    //   setIsError("");
    // }, 500);
    try {
      const response = await axios.post(
        // "/register-step-2",
        "/customer-register-step-2",
        {
          RegsterId: UserData.id,
          Address_Line_1: values.Address,
          Address_Line_2: values.SecondAddress,
          Pueblo: values.Village,
          Country: values.Country,
          PostalCode: values.PostalCode,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      console.log("🚀 response..... step2", response);
      setIsError(response.data.message);
      if (response) {
        setLoading(false);
        if (
          response.data.status == "success"
          // && StaticCartData
        ) {
          toast.success(`Paso 2 Registro Exitoso`);
          Navigate("/Titular");
        }
        // else {
        //   toast.success(`Paso 2 Registro Exitoso`);
        //   Navigate("/Variantes");
        // }
        // if (response.data.status == "success" && StaticCartData) {
        //   Navigate("/Titular");
        //   toast.success(`Paso 2 Registro Exitoso`);
        // } else {
        //   // if (response.data.data.reg_id) {
        //   toast.success(`Paso 2 Registro Exitoso`);
        //   Navigate("/Modelos");
        // }
      }
    } catch (error) {
      setLoading(false);
      console.log("🚀error....", error);
    }
  };

  const initialValues = {
    Address: "",
    SecondAddress: "",
    Country: "",
    PostalCode: "",
    Village: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: RegisterAddressSchema,
      onSubmit,
    });

  return (
    <div>
      <Wrapper>
        <Card head={"Ingresa tu dirección"}>
          <span className="form-error-head"> {IsError} </span>
          <Form onSubmit={handleSubmit} className="mainform5">
            <div className="inputs5">
              <div className="w-100 d-flex flex-column justify-content-start">
                <Input
                  name="Address"
                  text={"Dirección"}
                  type={"text"}
                  placeholder={"Escribe tu dirección"}
                  value={values.Address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.Address && touched.Address ? (
                  <p className="form-error"> {errors.Address} </p>
                ) : null}
              </div>

              <div className="w-100 d-flex flex-column justify-content-start">
                <Input
                  name="SecondAddress"
                  text={"Segunda línea de dirección (opcional)"}
                  type={"text"}
                  placeholder={"Escribe tu dirección"}
                  value={values.SecondAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              <div className="w-100 d-flex flex-column justify-content-start">
                <div id="input2">
                  <span>Elige tu pueblo</span>
                  <select
                    name="Village"
                    text={"Pueblo"}
                    type={"text"}
                    placeholder={"Elige tu pueblo"}
                    value={values.Village}
                    onChange={handleChange}
                    onBlur={handleBlur}>
                    <option value="" disabled defaultChecked className="place">
                      Elige tu pueblo
                    </option>
                    {/* {options.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.label}
                    </option>
                  ))} */}

                    <optgroup>
                      {options.map((item, index) => {
                        // console.log(
                        //   "🚀 ~ file: index.js:169 ~ {options.map ~ item:",
                        //   item
                        // );
                        return (
                          <option value={item.pueblo_title} key={index}>
                            {item.pueblo_title}
                          </option>
                        );
                      })}
                    </optgroup>
                  </select>
                </div>

                {errors.Village && touched.Village ? (
                  <p className="form-error"> {errors.Village} </p>
                ) : null}
              </div>

              <div className="w-100 d-flex flex-column justify-content-start">
                <Input
                  name="Country"
                  text={"País"}
                  type={"text"}
                  placeholder={"Escribe tu país"}
                  value={values.Country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.Country && touched.Country ? (
                  <p className="form-error"> {errors.Country} </p>
                ) : null}
              </div>
              <div className="w-100 d-flex flex-column justify-content-start">
                <Input
                  name="PostalCode"
                  text={"Código postal"}
                  type={"number"}
                  placeholder={"Escribe el código postal"}
                  value={values.PostalCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.PostalCode && touched.PostalCode ? (
                  <p className="form-error"> {errors.PostalCode} </p>
                ) : null}
              </div>
            </div>
            <div className="">
              <Button variant="red" type={"submit"}>
                <span className="p-4">Continuar con la reserva</span>
              </Button>
            </div>
          </Form>
        </Card>
        {!Loading && <div></div>}
        {Loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </Wrapper>
    </div>
  );
};

export default RegisterAddress;
