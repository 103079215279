import React, { useState, useEffect } from "react";
import Footer from "../../components/UI/Footer";
import "./ContactUs.css";
import Button from "../../components/UI/Button";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik, Field } from "formik";
import { ContactUsSchema } from "../../../Ariya/schemas";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Navbar from "../../../Ariya/components/UI/Navbar";
import { toast } from "react-hot-toast";
import axios from "../../Api";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { event } from "react-ga";

const ContactUs = () => {
  const location = useLocation();
  const Contactuss = (props) => {
    ReactGA.event({
      category: "Model",
      action: "aa",
    });
  };
  const Navigate = useNavigate();
  const [IsError, setIsError] = useState("");
  const [Loading, setLoading] = useState(false);
  const [checkbox, setcheckbox] = useState(false);

  const initialValues = {
    FullName: "",
    Email: "",
    Phone: "",
    Subject: "",
    Comment: "",
    checkBox1: false,
    checkBox2: false,
    checkBox3: false,
  };

  console.log(initialValues, " check initialValues ");
  const UserData = JSON.parse(localStorage.getItem("user-info"));
  const handleSubmit = async (values, { resetForm, setValues }) => {
    console.log("ContactUS values...", values);
    setLoading(true);
    const evenatdata = {
      category: "form_contactanos",
      event: "form_contactanos",
      user_id: UserData?.id ? UserData?.id : " ",
      nombre_completo: values.FullName,
      correo_electronico: values.Email,
      Telefono: values.Phone,
      action: "ENVIAR",
    };
    ReactGA.event({
      category: evenatdata.category,
      action: evenatdata.action,
      event: evenatdata.event,
      event_params: {
        user_id: evenatdata.user_id,
        nombre_completo: evenatdata.nombre_completo,
        correo_electronico: evenatdata.correo_electronico,
        Telefono: evenatdata.Telefono,
      },
    });
    window.dataLayer.push({
      event: evenatdata.event,
      user_id: evenatdata.user_id,
      nombre_completo: evenatdata.nombre_completo,
      correo_electronico: evenatdata.correo_electronico,
      Telefono: evenatdata.Telefono,
      action: evenatdata.action,
    });

    // ReactGA.event({
    //   category: "Model",
    //   action: `${values.FullName} ${values.Phone} ${values.Email} Contact US`,
    // });
    try {
      console.log("values....", values);
      const response = await axios.post("/send-contactus", {
        Name: values.FullName,
        Email: values.Email,
        PhoneNumber: values.Phone,
        Subject: values.Subject,
        Message: values.Comment,
        check_box_1: values.checkBox1 && "yes",
        check_box_2: values.checkBox2 ? "yes" : "No",
        check_box_3: values.checkBox3 && "yes",
      });

      console.log("🚀 response.......", response);
      setIsError(response.data.message);

      if (response.data.status == "success") {
        setLoading(false);
        resetForm();
        setValues(initialValues);
        setcheckbox(false);
        values.checkBox1 = false;
        toast.success(`${response.data.message}`);
        // Navigate("/");
      }
    } catch (error) {
      console.log("🚀 error", error);
      if (error) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Nissan | Contáctanos para hacer tu reservación</title>
        <meta
          name="description"
          content="Llena el formulario y comienza el proceso de reservación de tu próximo vehículo eléctrico."
        />
      </Helmet>
      <Navbar />
      <div className="top-top"></div>
      <section className="ContactUs-Main-sec">
        <div className="container white d-flex flex-column align-items-center justify-content-center height-div">
          <div className="w-100 d-flex flex-column align-items-center justify-content-center pb-4">
            <span className="Heading-contactUs ">Contáctanos</span>
            <div className="border-bottom-divider-policy"></div>
          </div>
          <div className="pb-3">
            <span className="form-Success-head">{IsError}</span>
          </div>
          <div className="w-100">
            <Formik
              initialValues={initialValues}
              validationSchema={ContactUsSchema}
              onSubmit={handleSubmit}>
              {(props) => (
                <Form
                  onSubmit={props.handleSubmit}
                  className="d-flex flex-column gap-4">
                  <div className="d-flex flex-column w-100">
                    <div className="input--">
                      <span className="black">Nombre completo</span>
                      <Field
                        type="name"
                        name="FullName"
                        placeholder="Escribe tu nombre"
                        className="input-field"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.FullName}
                      />
                      <div className="p-1"></div>
                    </div>
                    {props.errors.FullName && props.touched.FullName ? (
                      <p className="form-error"> {props.errors.FullName} </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="d-flex flex-column">
                    <div className="input--">
                      <span className="black">Correo electrónico</span>
                      <Field
                        type="email"
                        name="Email"
                        placeholder={"Escribe tu correo electrónico"}
                        className="input-field"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.Email}
                      />
                      <div className="p-1"></div>
                    </div>
                    {props.errors.Email && props.touched.Email ? (
                      <p className="form-error"> {props.errors.Email} </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="d-flex flex-column">
                    <div className="input--">
                      <span className="black">Teléfono</span>
                      <Field
                        type="number"
                        name="Phone"
                        placeholder={"Escribe tu teléfono"}
                        className="input-field"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.Phone}
                      />
                      <div className="p-1"></div>
                    </div>
                    {props.errors.Phone && props.touched.Phone ? (
                      <p className="form-error"> {props.errors.Phone} </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="d-flex flex-column">
                    <div className="input--">
                      <span className="black">Asunto</span>
                      <Field
                        type="text"
                        name="Subject"
                        placeholder={"Escribe tu asunto "}
                        className="input-field"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.Subject}
                      />
                      <div className="p-1"></div>
                    </div>
                    {props.errors.Subject && props.touched.Subject ? (
                      <p className="form-error"> {props.errors.Subject} </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="d-flex flex-column">
                    <div className="input--textarea">
                      <span className="black">Comentarios</span>
                      <Field
                        as="textarea"
                        type="text"
                        name="Comment"
                        placeholder={"Déjanos tus comentarios"}
                        className="input-field"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.Comment}
                      />
                      <div className="p-1"></div>
                    </div>
                    {props.errors.Comment && props.touched.Comment ? (
                      <p className="form-error"> {props.errors.Comment} </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="w-100 ">
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-start align-items-cernter gap-2">
                        <div className="form-group">
                          <input
                            type="checkbox"
                            name="checkBox1"
                            // value={props.values.checkBox1}
                            checked={props.values.checkBox1}
                            // onChange={props.handleChange}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                          {/* <label htmlFor="id"></label> */}
                        </div>
                        <p>
                          Acepto recibir información al número de teléfono
                          provisto, vía llamadas, llamadas grabadas o de voz
                          artificial y/o mensaje de texto, sobre productos y
                          servicios de parte de Nissan de Puerto Rico, su
                          distribuidora Motorambar, Inc., y/o sus concesionarios
                          autorizados.
                        </p>
                      </div>
                      {props.errors.checkBox1 && props.touched.checkBox1 ? (
                        <p className="form-error"> {props.errors.checkBox1} </p>
                      ) : (
                        ""
                      )}
                      <div className="p-2"></div>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-start align-items-cernter gap-2">
                        <div className="form-group">
                          <input
                            type="checkbox"
                            name="checkBox2"
                            checked={props.values.checkBox2}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                        </div>
                        <p>
                          Suscribirme al servicio de correo electrónico sobre
                          productos y servicios de Nissan.
                        </p>
                      </div>
                      {/* {props.errors.checkBox2 && props.touched.checkBox2 ? (
                        <p className="form-error"> {props.errors.checkBox2} </p>
                      ) : (
                        ""
                      )} */}

                      <div className="p-2"></div>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-start align-items-cernter gap-2">
                        <div className="form-group">
                          <input
                            type="checkbox"
                            name="checkBox3"
                            checked={props.values.checkBox3}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                          />
                        </div>
                        <p>
                          Acepto los{" "}
                          <Link
                            to="/Terminos-y-Condiciones"
                            style={{ color: "black" }}>
                            Términos y Condiciones
                          </Link>{" "}
                          y
                          <Link
                            to="/Politica-Privacidad"
                            style={{ color: "black" }}>
                            {" "}
                            Política de Privacidad
                          </Link>
                          .
                        </p>
                      </div>
                      {props.errors.checkBox3 && props.touched.checkBox3 ? (
                        <p className="form-error"> {props.errors.checkBox3} </p>
                      ) : (
                        ""
                      )}
                      <div className="p-2"></div>
                    </div>
                    {/* <span>
                      {" "}
                      {
                        'Al hacer clic en "Enviar", acepto que se pongan en contacto conmigo en el número o correo electrónico proporcionado para ofrecerme más información sobre los productos. '
                      }{" "}
                    </span> */}
                  </div>

                  <div className="d-flex justify-content-center pt-3 pb-3">
                    <Button variant={"red"} type="submit">
                      {"Enviar"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        {!Loading && <div></div>}
        {Loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};

export default ContactUs;
