import React, { useEffect, useState } from "react";
import "./Section5.css";

import sliderback from "../../../../assets/images/Specifications/1.jpeg";
import sliderback1 from "../../../../assets/images/Specifications/2.jpeg";
import sliderback2 from "../../../../assets/images/Specifications/3.png";
import { Modal } from "react-bootstrap";
import overlay from "../../../../../Ariya/assets/images/overlayback.png";
// import overlay from "../../Assets/Images/overlayback.png";

const data = [
  {
    head: "Desempeño eléctrico",
    pra: "Para liberar completamente la potencia del motor 100% eléctrico de Nissan LEAF, solo presiona el acelerador y sentirás el torque inmediatamente.",
    img: sliderback,
  },
  {
    head: "Pantalla de información",
    pra: "El nuevo panel de instrumentos reúne a la perfección las tecnologías digitales y analógicas y te permite personalizar tu información. ",
    img: sliderback1,
  },
  {
    head: "Interior versátil",
    pra: "La división de los asientos traseros 60/40 te permite cargar un pasajero cómodamente mientras sigue teniendo gran cantidad de espacio para cargar artículos grandes",
    img: sliderback2,
  },
];

const Section5 = () => {
  const [current, setcurrent] = useState(0);
  const [sliderdata, setsliderdata] = useState(data[0]);
  const [active, setactive] = useState({
    activeObject: null,
    objects: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
  });

  useEffect(() => {
    const slider = data[current];
    setsliderdata(slider);
    setactive({ ...active, activeObject: active.objects[current] });
  }, [current]);

  const handleclick = (index) => {
    const slider = data[index];
    setsliderdata(slider);
    setactive({ ...active, activeObject: active.objects[index] });
    setcurrent(index);
  };

  const [showwww, setshowwww] = useState(false);
  const [Show2, setShow2] = useState(false);

  function ModelModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        centered
        className="wasd-modal-ko-badla"
        style={{ zIndex: "10000" }}>
        <Modal.Header className="new_model" closeButton></Modal.Header>
        <Modal.Body className="new_model text-center">
          <div className="Dropdown-Car-info-body-Head">
            Información importante
          </div>
          <p className="Modal-text">
            <div>
              La información que aparece depende del equipamiento del vehículo.
              Manejar es una actividad seria que requiere de toda tu atención.
              Si necesitas usar la función mientras manejas, asegúrate de tomar
              mucha precaución en todo momento para que puedas prestar toda tu
              atención a la operación del vehículo.
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  function ModelModal2(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        centered
        className="wasd-modal-ko-badla"
        style={{ zIndex: "10000" }}>
        <Modal.Header className="new_model" closeButton></Modal.Header>
        <Modal.Body className="new_model text-center">
          <div className="Dropdown-Car-info-body-Head">
            Información importante
          </div>
          <p className="Modal-text">
            <div>
              La carga y la capacidad de carga están limitadas por el peso y la
              distribución. Asegura siempre la carga. La carga se muestra con
              carácter ilustrativo únicamente. La carga y la capacidad de carga
              están limitadas por el peso y la distribución. Asegura siempre la
              carga.
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  const ico2 = (
    <svg
      width="17"
      height="17"
      viewBox="0 0 24 24"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.09998V8H12.0498Z"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    // React-Bootstrap carousel
    <div className="conntai">
      <ModelModal show={showwww} onHide={() => setshowwww(false)} />
      <ModelModal2 show={Show2} onHide={() => setShow2(false)} />
      <div className="section5">
        <div className="overdataimg">
          <img src={sliderdata.img} alt="" />
          <img className="over" src={overlay} alt="" />
        </div>

        <div className="conte">
          <div className="MainC">
            {data.map((curelem, i) => {
              return (
                <div
                  key={i}
                  className={i === current ? "slideactive" : "prahead"}
                  onClick={() => handleclick(i)}>
                  <h3 className="msrp_div ">{curelem.head}</h3>
                  <div
                    className={
                      i === current ? "borderactive" : "borderrrr"
                    }></div>
                  <div>
                    <p>
                      {curelem.pra}
                      {i === 0 ? null : (
                        <span
                          onClick={() => {
                            i === 1 ? setshowwww(true) : setShow2(true);
                            // setModalShow(true);
                          }}>
                          {ico2}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
