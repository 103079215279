import React, { useRef, useState } from "react";
import "./Section1.css";
// import banner from "../../../../assets/images/banner.png";
import banner from "../../../../assets/images/banner1.png";
// import video from "../../../../assets/videos/ARIYA-HOME-BANNER (1).mp4";
// import Button from "../../../../../Leaf/components/UI/Button";
import PauseImg from "../../../../assets/images/pouse.png";
import { useNavigate } from "react-router-dom";
// import fullscreen from '../../../../assets/images/fullscreenbtn.png';
import ReactGA from "react-ga4";

const Section1 = () => {
  const [play, setplay] = useState(true);
  const [click, setclick] = useState("0");
  const VideoRef1 = useRef();

  // const PlayPauseVideo1 = () => {
  //   if (VideoRef1.current.paused) {
  //     VideoRef1.current.play();
  //     setplay(true);
  //   } else {
  //     VideoRef1.current.pause();
  //     setplay(false);
  //   }
  // };

  // const fullscreens = () => {
  //   let elem = document.getElementById("myvideo")
  //   if (elem.requestFullscreen) {
  //     elem.requestFullscreen();
  //   } else if (elem.webkitRequestFullscreen) {
  //     elem.webkitRequestFullscreen();
  //   } else if (elem.msRequestFullscreen) {
  //     elem.msRequestFullscreen();
  //   }
  // }

  const handlenavigate = () => {
    ReactGA.event({
      category: "Landnignpage",
      action: "I am interested",
    });
    // localStorage.setItem("clicked",click)
    Navigate("/Leaf/Variantes");

    // const userInfo = localStorage.getItem("user-info")
    // if(userInfo == null){
    //   Navigate("/Model")
    // }else{
    //   Navigate("/Iniciar-Sesion")
    // }
  };

  const Navigate = useNavigate();

  return (
    <div>
      <div className="d-flex justify-content-center" id="Inicio">
        <div id="container1">
          {/* <video id="myvideo" ref={VideoRef1} autoPlay={true} muted loop={true}>
            <source src={video} />
          </video> */}
          <img src={banner} alt="" />
          {/* <div className="pfbtns"> */}
          {/* <div
              //  onClick={PlayPauseVideo1}
              className="playpousebtn"
            >
              {!play ? (
                <svg
                  className="play-svg"
                  viewBox="0 0 46 46"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M37.3751 21.3323L10.5418 5.84565C10.2504 5.67743 9.91988 5.58887 9.58343 5.58887C9.24698 5.58887 8.91647 5.67743 8.6251 5.84565C8.3326 6.01452 8.08993 6.25767 7.92164 6.5505C7.75335 6.84332 7.66542 7.17542 7.66676 7.51315V38.4865C7.66542 38.8242 7.75335 39.1563 7.92164 39.4491C8.08993 39.742 8.3326 39.9851 8.6251 40.154C8.91647 40.3222 9.24698 40.4108 9.58343 40.4108C9.91988 40.4108 10.2504 40.3222 10.5418 40.154L37.3751 24.6673C37.6701 24.5002 37.9154 24.2577 38.0861 23.9648C38.2568 23.6718 38.3467 23.3389 38.3467 22.9998C38.3467 22.6608 38.2568 22.3278 38.0861 22.0349C37.9154 21.7419 37.6701 21.4995 37.3751 21.3323V21.3323ZM11.5001 35.1707V10.829L32.5834 22.9998L11.5001 35.1707Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <img src={PauseImg} width={"100%"} alt="" />
              )}
            </div> */}
          {/* <div onClick={fullscreens} className="fullscreen">
              <img src={fullscreen} alt="" />
            </div> */}
          {/* </div> */}
          <div className="Details">
            <h4>Nissan LEAF </h4>
            <h1>
              Cero emisiones.
              <br />
              Pura emoción.
            </h1>
            <div>
              {/* <p>
                The all-electric EV6 is designed to inspire with a driver-centric
                cockpit, Dual Panoramic Screens, an available Augmented Reality
                Head-up Display, the spaciousness of a 5-passenger crossover, and up
                to 310 miles of EPA estimated all-electric range on a single charge.{" "}
              </p> */}
            </div>
            <button className="reserbtn" onClick={() => handlenavigate()}>
              Me interesa
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
