import React, { useEffect, useState } from "react";
import "./Specifications.css";
import { Accordion } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Ariya_EngageFWD,
  Ariya_Engage__e_4orce,
  Ariya_Engage_e_4orce,
  EVOLVE,
  VENTURE,
  evolvepe4orce,
} from "../../constants/Data";
import { LEAFS, LeafSVPlus } from "../../../Leaf/constants/Data";
import ReactGA from "react-ga";

const SpecsScreen = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  const Navigate = useNavigate();

  const PageURL = location.pathname;

  const specDataMap = {
    "/Especificaciones/Engage/FWD": {
      Name: "Engage FWD",
      data: Ariya_EngageFWD,
    },
    "/Especificaciones/Engage/E-4FORCE": {
      Name: "Engage e-4FORCE",
      data: Ariya_Engage_e_4orce,
    },
    "/Especificaciones/Engage+/E-4FORCE": {
      Name: "Engage+ e-4FORCE",
      data: Ariya_Engage__e_4orce,
    },
    "/Especificaciones/Engage/Venture+": {
      Name: "Engage Venture+",
      data: VENTURE,
    },
    "/Especificaciones/Evolve+/FWD": { Name: "Evolve+ FWD", data: EVOLVE },
    "/Especificaciones/Evolve+/e-4FORCE": {
      Name: "Evolve+ e-4FORCE",
      data: evolvepe4orce,
    },
    "/Especificaciones/LEAF-S": { Name: "LEAF S", data: LEAFS },
    "/Especificaciones/LEAF-SV-Pus": {
      Name: "LEAF SV Pus",
      data: LeafSVPlus,
    },
  };

  const [specData, setSpecData] = useState(specDataMap[PageURL]);

  useEffect(() => {
    setSpecData(specDataMap[PageURL]);
  }, [PageURL]);

  let cross = (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 16.6437L1 1.79688M13 1.79688L7.00002 9.22033L1 16.6438"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  let route = useLocation();
  const mainroute = route.state.Pathnamee;

  const Handlenavigate = () => {
    if (mainroute.pathname === "/ContinuePayment") {
      Navigate("/ContinuePayment");
    } else if (mainroute.pathname === "/Order-Detail") {
      Navigate("/Order-Detail");
    } else if (mainroute.pathname === "/Perfil/History") {
      Navigate("/Perfil/History");
    } else if (mainroute.pathname === "/Interest") {
      Navigate("/Interest");
    }
  };

  return (
    <div className="wraperdiv">
      <div className="carddivmain">
        <div>
          <h1>Especificaciones {specData.Name}</h1>
          {/* <button>{cross}</button> */}
          <span style={{ cursor: "pointer" }} onClick={Handlenavigate}>
            {cross}
          </span>
        </div>

        <div className="Espesification-all-data-div">
          <div className="Espesification-data-div">
            <Accordion
            // defaultActiveKey={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
            // alwaysOpen
            >
              {specData.data.specs.map((item, index) => {
                return (
                  <div className="w-100" key={index}>
                    <div className="divider-div-for-espesification-data-divs"></div>
                    <Accordion.Item
                      eventKey={index}
                      className="Accordian-item-personal-class">
                      <Accordion.Header className="Accordion-header-personal-class">
                        {item.Head}
                      </Accordion.Header>
                      <Accordion.Body className="padding-left-for-specs-data-txt">
                        {item.content.map((_item, i) => {
                          return (
                            <div key={i} className=" mb-2 w-100">
                              <span>{_item}</span>
                            </div>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                    <div className="pb-3"></div>
                  </div>
                );
              })}
            </Accordion>
          </div>
          <div className="Espesification-data-div"></div>
        </div>
      </div>
    </div>
  );
};

export default SpecsScreen;
