import React from "react";
import "./Input.css";

const Input = ({
  type,
  name,
  onChange,
  text,
  placeholder,
  onBlur,
  required,
}) => {
  return (
    <div className="maindivinput">
      <div id="input">
        <span>{text}</span>
        <input
          required={required}
          type={type}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

export default Input;
