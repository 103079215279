import React, { useEffect, useState } from "react";
import "./Section5.css";

import sliderback from "../../../../assets/images/specification/1.png";
import sliderback1 from "../../../../assets/images/specification/2.png";
import sliderback2 from "../../../../assets/images/specification/3.png";
import sliderback3 from "../../../../assets/images/specification/4.png";
import { Modal } from "react-bootstrap";
import overlay from "../../../../assets/images/overlayback.png";

const data = [
  {
    head: 'Pantalla doble de 12.3"',
    pra: 'La pantalla doble te permite deslizarte entre dos pantallas de 12.3", para que puedas poner la información importante justo delante de ti y conectarte con el mundo que te rodea de una forma totalmente nueva.',
    img: sliderback,
  },
  {
    head: "Controles táctiles hápticos",
    pra: "Lo digital se une a lo físico cuando los botones iluminados surgen de las superficies. Tu toque los activa y responden con un toque simulado como respuesta háptica.",
    img: sliderback1,
  },
  {
    head: "Hola Nissan",
    pra: "Simplemente pídelo y ARIYA® se encargará del resto. Encontrar un nuevo restaurante, hacer una llamada telefónica o ajustar la temperatura de la cabina son solo algunas de las cosas que puedes hacer. ",
    img: sliderback2,
  },
  {
    head: "Consola central eléctrica deslizante",
    pra: "Con solo pulsar un botón, toda la consola se desliza hacia delante y hacia atrás, haciendo espacio donde lo necesitas y adaptándose a las diferentes necesidades del conductor. ",
    img: sliderback3,
  },
];

const Section5 = () => {
  const [current, setcurrent] = useState(0);
  const [sliderdata, setsliderdata] = useState(data[0]);
  const [active, setactive] = useState({
    activeObject: null,
    objects: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
  });

  useEffect(() => {
    const slider = data[current];
    setsliderdata(slider);
    setactive({ ...active, activeObject: active.objects[current] });
  }, [current]);

  const handleclick = (index) => {
    const slider = data[index];
    setsliderdata(slider);
    setactive({ ...active, activeObject: active.objects[index] });
    setcurrent(index);
  };

  const [Model1, setModel1] = useState(false);
  const [Model2, setModel2] = useState(false);
  const [Model3, setModel3] = useState(false);

  function Modal1(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        centered
        className="wasd-modal-ko-badla"
        style={{ zIndex: "10000" }}
      >
        <Modal.Header className="new_model" closeButton></Modal.Header>
        <Modal.Body className="new_model text-center">
          <div className="Dropdown-Car-info-body-Head">
            Información importante
          </div>
          <p className="Modal-text">
            <div>
              Nunca programes el sistema mientras manejas. Es posible que los
              mapas del GPS no sean muy detallados en todas las áreas ni
              reflejen la situación actual de las carreteras.
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  function Modal2(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        centered
        className="wasd-modal-ko-badla"
        style={{ zIndex: "10000" }}
      >
        <Modal.Header className="new_model" closeButton></Modal.Header>
        <Modal.Body className="new_model text-center">
          <div className="Dropdown-Car-info-body-Head">
            Información importante
          </div>
          <p className="Modal-text">
            <div>
              La carga y la capacidad de carga están limitadas por su peso y
              distribución. Asegura siempre toda la carga.
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  function Modal3(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        centered
        className="wasd-modal-ko-badla"
        style={{ zIndex: "10000" }}
      >
        <Modal.Header className="new_model" closeButton></Modal.Header>
        <Modal.Body className="new_model text-center">
          <div className="Dropdown-Car-info-body-Head">
            Información importante
          </div>
          <p className="Modal-text">
            <div>
              La disponibilidad de la función depende del modelo del vehículo,
              del nivel de equipamiento, paquetes y opciones. Es posible que se
              necesite un dispositivo conectado compatible y la disponibilidad
              de las funciones puede depender de la capacidad del dispositivo.
              Consulta el Manual del Propietario del dispositivo conectado para
              ver más detalles. Disponibilidad tardía de algunas funciones.
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    // React-Bootstrap carousel
    <div className="conntai">
      <Modal1 show={Model1} onHide={() => setModel1(false)} />
      <Modal2 show={Model2} onHide={() => setModel2(false)} />
      <Modal3 show={Model3} onHide={() => setModel3(false)} />
      <div className="section5">
        <div className="overdataimg">
          <img src={sliderdata.img} alt="" />
          <img className="over" src={overlay} alt="" />
        </div>

        <div className="conte">
          <div className="MainC">
            {data.map((curelem, i) => {
              return (
                <div
                  key={i}
                  className={i === current ? "slideactive" : "prahead"}
                  onClick={() => handleclick(i)}
                >
                  <h3>{curelem.head}</h3>
                  <div
                    className={i === current ? "borderactive" : "borderrrr"}
                  ></div>
                  <div>
                    <p>
                      {curelem.pra}
                      {i === 2 ? (
                        <span onClick={() => setModel1(true)}>[*]</span>
                      ) : i === 3 ? (
                        <>
                          <span onClick={() => setModel2(true)}>[*]</span>

                          <span onClick={() => setModel3(true)}>[*]</span>
                        </>
                      ) : null}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
