import React, { useEffect, useState } from "react";
import tick from "../../../assets/images/colortickicon.png";
import Button from "../../../../Common/components/UI/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Accordion, Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import { evolvepe4orce } from "../../../constants/Data";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import "./styles.css";

// import required modules
import { EffectFade, Navigation } from "swiper";
import Navbar from "../../../components/UI/Navbar";

import ConfirmModelModal from "../../../../Common/components/UI/ConfirmModelModal";
import { ModelScreenAlert } from "../Main";
import axios from "../../../../Common/Api";
import ReactGA from "react-ga4";
import TrackPageView from "../../../../TrackPageView.js";

const Model2 = () => {
  const Navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  // const [disable, setdisable] = useState(false);
  const navigate = useNavigate();
  const [active, setactive] = useState(0);
  const [Loading, setLoading] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const [color, setcolor] = useState("#BB1928");
  const [_Int_color, set_Int_color] = useState("#000000");

  const [ConfirmModelModalState, setConfirmModelModalState] = useState(false);

  const [AlertModal, setAlertModal] = useState(false);

  const [HistoryData, setHistoryData] = useState({});

  let Ext_name;
  let Int_name;
  // let Ext_code;

  const ExtcolorInfo = evolvepe4orce.exterior[color];

  if (ExtcolorInfo) {
    Ext_name = ExtcolorInfo.Ext_name;
    // Ext_code = colorInfo.Ext_code;
  }

  const IntcolorInfo = evolvepe4orce.interior[_Int_color];

  if (IntcolorInfo) {
    Int_name = IntcolorInfo.Int_name;
    // Ext_code = colorInfo.Ext_code;
  }

  // let Int_name;
  // let Int_code;
  // let Int_images;
  // if (_Int_color == "#1B1B1B") {
  //   Int_name = "Interiores negros";
  //   Int_code = "#1B1B1B";
  //   Int_images = Interior_1;
  // }

  // let Aros_images;
  // if (_Aros_color == "#1B1B1B") {
  //   Aros_images = Aros1;
  // }

  const getOrderHistory = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/get-history", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${UserData.Token}`,
        },
      });
      if (res) {
        // console.log("OrderHistory...............", res);
        if (res.data.status == "success" && res.data.message == "Record") {
          setLoading(false);
          setHistoryData(res.data);
        } else {
          setLoading(false);
          // console.log("res Error....", res);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      getOrderHistory();
    }
  }, []);

  function Orderplaced(props) {
    function ChangeBtn() {
      setModalShow(false);
      navigate("/Perfil/History");
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="white text-center pt-3">
          <h4 className="pb-3">Pedido ya realizado</h4>
          <p className="Modal-text">
            Ya has realizado una reserva por lo que con este usuario no puedes
            volver a realizar la reserva
          </p>
          <div className="p-2"></div>
          <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
            <Button variant="red" onClick={ChangeBtn}>
              Ir al perfil
            </Button>
          </div>
          <div className="p-3"></div>
        </Modal.Body>
      </Modal>
    );
  }

  var nf = new Intl.NumberFormat();

  let Price = 64363;

  const CartData = {
    extColor: color,
    extColorName: Ext_name,
    intColor: _Int_color,
    intColorName: Int_name,
    price: Price,
    ModelName: "EVOLVE+ e-4ORCE",
    engine: "290",
    HorsePower: "389",
    battery: "270",
    image: evolvepe4orce.exterior[color].images[0],
  };

  const StaticAddToCart = () => {
    if (HistoryData.status == "success" && HistoryData.message == "Record") {
      setConfirmModelModalState(false);
      setModalShow2(true);
    } else if (
      HistoryData.status == "success" &&
      HistoryData.message == "No Record"
    ) {
      setLoading(true);
      // if (localStorage.getItem("Register-Start")) {
      //   // if (UserData.Id) {
      //   setTimeout(() => {
      //     setLoading(false);
      //     localStorage.setItem("StaticCartData", JSON.stringify(CartData));
      //     navigate("/Titular");
      //   }, 1000);
      // } else {
      setTimeout(() => {
        setLoading(false);
        localStorage.setItem("StaticCartData", JSON.stringify(CartData));
        navigate("/Carrito");
      }, 1000);
      // }
    } else {
      setLoading(true);
      // if (localStorage.getItem("Register-Start")) {
      //   setTimeout(() => {
      //     setLoading(false);
      //     localStorage.setItem("StaticCartData", JSON.stringify(CartData));
      //     navigate("/Titular");
      //   }, 1000);
      // } else {
      setTimeout(() => {
        setLoading(false);
        localStorage.setItem("StaticCartData", JSON.stringify(CartData));
        navigate("/Carrito");
      }, 1000);
      // }
    }
  };

  const ico = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 18.3333V26.6667M20 35C11.7157 35 5 28.2843 5 20C5 11.7157 11.7157 5 20 5C28.2843 5 35 11.7157 35 20C35 28.2843 28.2843 35 20 35ZM20.083 13.3333V13.5L19.917 13.5V13.3333H20.083Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const icooo = (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3503_28663)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.34056 13.2726C5.39072 13.3582 5.4689 13.4225 5.5579 13.4516C5.6469 13.4806 5.73944 13.472 5.81516 13.4276L12.5485 9.47798L11.9416 12.5605C11.9235 12.6525 11.9416 12.7523 11.9918 12.8379C12.042 12.9235 12.1203 12.9879 12.2094 13.017C12.2985 13.0461 12.3912 13.0375 12.467 12.993C12.5428 12.9485 12.5955 12.8719 12.6136 12.7799L13.3846 8.86104C13.3936 8.81549 13.3938 8.76754 13.3853 8.71993C13.3767 8.67232 13.3594 8.62599 13.3346 8.58358C13.3097 8.54117 13.2777 8.50353 13.2403 8.4728C13.2029 8.44208 13.161 8.41887 13.1168 8.40452L9.32006 7.16495C9.23094 7.13586 9.13829 7.14449 9.06248 7.18896C8.98667 7.23343 8.93391 7.31009 8.91581 7.40207C8.89772 7.49405 8.91576 7.59382 8.96598 7.67944C9.0162 7.76505 9.09448 7.82949 9.1836 7.85859L12.1703 8.83317L5.43693 12.7828C5.36122 12.8272 5.30853 12.9038 5.29045 12.9956C5.27238 13.0875 5.29041 13.1871 5.34056 13.2726ZM10.6865 4.07026C10.7366 4.15576 10.7547 4.25541 10.7366 4.34727C10.7185 4.43914 10.6658 4.5157 10.5901 4.56011L3.85674 8.50973L6.84346 9.48431C6.88758 9.49872 6.92951 9.52196 6.96685 9.5527C7.00419 9.58343 7.03621 9.62107 7.06107 9.66347C7.08594 9.70586 7.10317 9.75217 7.11177 9.79976C7.12038 9.84735 7.1202 9.89529 7.11124 9.94084C7.10228 9.98638 7.08472 10.0286 7.05955 10.0652C7.03439 10.1018 7.00212 10.1319 6.96458 10.1539C6.92704 10.176 6.88497 10.1894 6.84078 10.1935C6.79658 10.1977 6.75112 10.1924 6.707 10.178L2.91024 8.93839C2.86609 8.92403 2.82413 8.90083 2.78676 8.8701C2.7494 8.83938 2.71736 8.80173 2.69248 8.75932C2.66761 8.71692 2.65038 8.67058 2.6418 8.62297C2.63322 8.57536 2.63344 8.52741 2.64246 8.48187L3.41342 4.563C3.43152 4.47102 3.48427 4.39436 3.56008 4.3499C3.63589 4.30543 3.72855 4.29679 3.81767 4.32589C3.90678 4.35498 3.98506 4.41942 4.03528 4.50504C4.0855 4.59065 4.10354 4.69042 4.08545 4.78241L3.47852 7.86493L10.2119 3.91531C10.2876 3.8709 10.3802 3.86227 10.4692 3.89133C10.5582 3.92039 10.6363 3.98475 10.6865 4.07026Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_3503_28663">
          <rect
            width="10.5911"
            height="11.9607"
            fill="black"
            transform="translate(0.419922 6.19226) rotate(-30.3947)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const AriyaColorCode = Object.keys(evolvepe4orce.exterior);
  const AriyaIntColorCode = Object.keys(evolvepe4orce.interior);

  const ico2 = (
    <svg
      width="17"
      height="17"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.09998V8H12.0498Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  function ModelModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        centered
        className="wasd-modal-ko-badla"
        style={{ zIndex: "10000" }}>
        <Modal.Header className="new_model" closeButton></Modal.Header>
        <Modal.Body className="new_model text-center">
          <div className="Dropdown-Car-info-body-Head">
            <i>Información importante</i>
          </div>
          <p className="Modal-text">
            <div>
              El precio ilustrado no incluye accesorios. El precio es el Precio
              de venta sugerido por el fabricante (MSRP, por sus siglas en
              inglés). El MSRP no incluye impuestos, título, placas, opciones ni
              cobro por destino. El concesionario fija el precio final.
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }
  let Compathname = useLocation();
  const modeldata = {
    path: Compathname,
    index: "3",
  };
  const exterior = () => {
    const eventData = {
      event: "Models EVOLVE+ e-4ORCE",
      user_id: UserData?.id ? UserData?.id : " ",
      log_status: `${UserData ? true : false}`,
      category: "Models",
      action: "Exterior_EVOLVE+ e-4ORCE",
      option: "EVOLVE+ e-4ORCE_Exterior",
    };

    window.dataLayer.push(eventData);
    ReactGA.event({
      category: eventData.category,
      action: eventData.action,
      event_name: eventData.event_name,
      event_params: {
        user_id: eventData.user_id,
        log_status: eventData.log_status,
        option: eventData.option,
      },
    });
    // ReactGA.event({
    //   category: "Model",
    //   action: "Exterior_EVOLVE+ e-4ORCE",
    // });
    setactive(0);
  };
  const interior = () => {
    const eventData = {
      event: "Models EVOLVE+ e-4ORCE",
      user_id: UserData?.id ? UserData?.id : " ",
      log_status: `${UserData ? true : false}`,
      category: "Models",
      action: "Interior_EVOLVE+ e-4ORCE",
      option: "EVOLVE+ e-4ORCE_Interior",
    };

    window.dataLayer.push(eventData);
    ReactGA.event({
      category: eventData.category,
      action: eventData.action,
      event_name: eventData.event_name,
      event_params: {
        user_id: eventData.user_id,
        log_status: eventData.log_status,
        option: eventData.option,
      },
    });
    // ReactGA.event({
    //   category: "Model",
    //   action: "Interior_EVOLVE+ e-4ORCE",
    // });
    setactive(1);
  };
  const Espesifications = () => {
    const eventData = {
      event: "Models EVOLVE+ e-4ORCE",
      user_id: UserData?.id ? UserData?.id : " ",
      log_status: `${UserData ? true : false}`,
      category: "Models",
      action: "Espesifications_EVOLVE+ e-4ORCE",
      option: "EVOLVE+ e-4ORCE_Espesifications",
    };

    window.dataLayer.push(eventData);
    ReactGA.event({
      category: eventData.category,
      action: eventData.action,
      event_name: eventData.event_name,
      event_params: {
        user_id: eventData.user_id,
        log_status: eventData.log_status,
        option: eventData.option,
      },
    });
    // ReactGA.event({
    //   category: "Model",
    //   action: "Espesifications_EVOLVE+ e-4ORCE",
    // });
  };
  // ! ReactGA. OLD CODE HERE 16 APRIL
  // const meinteresa = () => {
  //   const eventData = {
  //     event: "Models EVOLVE+ e-4ORCE",
  //     user_id: UserData?.id ? UserData?.id : " ",
  //     log_status: `${UserData ? true : false}`,
  //     category: "Models",
  //     action: "Me interesa EVOLVE+ e-4ORCE",
  //     option: "EVOLVE+ e-4ORCE_Me interesa",
  //   };

  //   window.dataLayer.push(eventData);
  //   ReactGA.event({
  //     category: eventData.category,
  //     action: eventData.action,
  //     event_name: eventData.event_name,
  //     event_params: {
  //       user_id: eventData.user_id,
  //       log_status: eventData.log_status,
  //       option: eventData.option,
  //     },
  //   });
  //   // ReactGA.event({
  //   //   category: "Model",
  //   //   action: "Me interesa EVOLVE+ e-4ORCE",
  //   // });
  //   setConfirmModelModalState(true);
  // };
  // ! ReactGA. OLD CODE HERE 16 APRIL

  const meinteresa = () => {
    const eventData = {
      event: "Models EVOLVE+ e-4ORCE",
      user_id: UserData?.id ? UserData?.id : " ",
      log_status: `${UserData ? true : false}`,
      category: "Models EVOLVE+ e-4ORCE",
      action: "Me interesa EVOLVE+ e-4ORCE",
      option: "Me interesa",
      color: Ext_name,
    };
    console.log("first message show===>>", eventData);

    window.dataLayer.push({
      event: "me_interesa_reseva_paso1",
      user_id: UserData?.id ? UserData?.id : " ",
      modelo: "Models EVOLVE+ e-4ORCE ",
      price: Price,
      color: Ext_name,
      action: "Me interesa",
    });

    window.dataLayer.push(eventData);

    ReactGA.event({
      category: eventData.category,
      action: eventData.action,
      event_name: eventData.event_name,
      event_params: {
        user_id: eventData.user_id,
        log_status: eventData.log_status,
        option: eventData.option,
        color: eventData.color,
      },
    });

    // Set confirm model modal state
    setConfirmModelModalState(true);
  };
  const Compered = () => {
    const eventData = {
      event: "Models EVOLVE+ e-4ORCE",
      user_id: UserData?.id ? UserData?.id : " ",
      log_status: `${UserData ? true : false}`,
      category: "Models",
      action: "Compered EVOLVE+ e-4ORCE",
      option: "EVOLVE+ e-4ORCE_Compered",
    };

    window.dataLayer.push(eventData);
    ReactGA.event({
      category: eventData.category,
      action: eventData.action,
      event_name: eventData.event_name,
      event_params: {
        user_id: eventData.user_id,
        log_status: eventData.log_status,
        option: eventData.option,
      },
    });
    // ReactGA.event({
    //   category: "Model",
    //   action: "Compered EVOLVE+ e-4ORCE",
    // });
  };
  const colourselectered = () => {
    const eventData = {
      event: "Models EVOLVE+ e-4ORCE",
      user_id: UserData?.id ? UserData?.id : " ",
      log_status: `${UserData ? true : false}`,
      category: "Models",
      action: "EVOLVE+ e-4ORCE__" + `${Ext_name}`,
      option: "EVOLVE+ e-4ORCE_Exterior Color",
    };

    window.dataLayer.push(eventData);
    ReactGA.event({
      category: eventData.category,
      action: eventData.action,
      event_name: eventData.event_name,
      event_params: {
        user_id: eventData.user_id,
        log_status: eventData.log_status,
        option: eventData.option,
      },
    });
    // ReactGA.event({
    //   category: "Exterior Color",
    //   action: "EVOLVE+ e-4ORCE__" + `${Ext_name}`,
    // });
  };
  const intcolourselectered = () => {
    const eventData = {
      event: "Models EVOLVE+ e-4ORCE",
      user_id: UserData?.id ? UserData?.id : " ",
      log_status: `${UserData ? true : false}`,
      category: "Models",
      action: "EVOLVE+ e-4ORCE__" + `${Int_name}`,
      option: "EVOLVE+ e-4ORCE_Interior Color",
    };

    window.dataLayer.push(eventData);
    ReactGA.event({
      category: eventData.category,
      action: eventData.action,
      event_name: eventData.event_name,
      event_params: {
        user_id: eventData.user_id,
        log_status: eventData.log_status,
        option: eventData.option,
      },
    });
    // ReactGA.event({
    //   category: "Interior Color",
    //   action: "EVOLVE+ e-4ORCE__" + `${Int_name}`,
    // });
  };

  return (
    <>
      <TrackPageView title="EngageFWD" screenName="Modal EngageFWD" />
      {!Loading && <div></div>}
      {Loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <ConfirmModelModal
        extColor={color}
        extColorName={Ext_name}
        intColor={_Int_color}
        intColorName={Int_name}
        price={Price}
        ModelName="EVOLVE+ e-4ORCE"
        engine="290"
        HorsePower="389"
        battery="270"
        image={evolvepe4orce.exterior[color].images[0]}
        show={ConfirmModelModalState}
        onHide={() => setConfirmModelModalState(false)}
        AddtoCart={() => StaticAddToCart()}
      />
      <ModelModal show={modalShow} onHide={() => setModalShow(false)} />
      <ModelScreenAlert show={AlertModal} onHide={() => setAlertModal(false)} />
      <Orderplaced show={modalShow2} onHide={() => setModalShow2(false)} />
      <div className="mainmodelcontent">
        <div className="modelpic">
          {active === 0 ? (
            <div className="modelimgdiv">
              <Swiper
                navigation={true}
                effect={"fade"}
                modules={[EffectFade, Navigation]}
                id="mySwiper"
                fadeEffect={{
                  crossFade: true, // enables slides to cross fade
                }}>
                {evolvepe4orce.exterior[color].images.map((item, i) => {
                  return (
                    <SwiperSlide className="sec3_slider" key={i}>
                      <img src={item} alt="wasd" />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              {/* <motion.img
                initial={{ x: 70, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                src={images}
                alt=""
                className="modelimg"
              /> */}
            </div>
          ) : active === 1 ? (
            <Swiper
              navigation={true}
              effect={"fade"}
              modules={[EffectFade, Navigation]}
              id="mySwiper"
              fadeEffect={{
                crossFade: true, // enables slides to cross fade
              }}>
              {evolvepe4orce.interior[_Int_color].images.map((item, index) => {
                return (
                  <SwiperSlide className="sec3_slider" key={index}>
                    <img src={item} alt="wasd" />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : // <img src={Int_images} alt="" className="interiorimgg" />
          null}
        </div>
        <div className="bhaismajhnhi">
          {/* <div className="sixty">
            <a
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
              href="#"
              // href="https://worldwide.kia.com/int/vr-showroom/csa/ev6/lhd/index.html#/ev6/exterior"
              target={"_blank"}
            >
              <img src={threesixty} alt="" />
              ¡Conoce el EV6 en VR!
            </a>
            <span
            onClick={() => setModalShow(true)}
            >
              {ico}
            </span>
          </div> */}
          <div className="namemodel">
            <span>EVOLVE+e-4ORCE</span>
            <h5>
              <span>MSRP: $</span>
              {/* {curelem.price2} */}
              {nf.format(Price)}
              <p
                style={{ marginBottom: "2rem" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setModalShow(true);
                }}>
                {ico2}
              </p>
            </h5>
          </div>
        </div>
        <div className="buttonreserve">
          <div className="navinterior">
            <span
              onClick={exterior}
              className={active === 0 ? "activespan" : ""}>
              Exterior
            </span>
            <div className="linediv4"></div>
            <span
              onClick={interior}
              className={active === 1 ? "activespan" : ""}>
              Interior
            </span>
          </div>
          <Button
            type="button"
            // onClick={() => setAlertModal(true)}
            // onClick={() => StaticAddToCart()}
            onClick={meinteresa}
            variant={"reserbtn2"}>
            Me interesa
          </Button>
        </div>
        <div className="colorsdiv">
          {active === 0 ? (
            <>
              <span>{Ext_name}</span>
              <div>
                {AriyaColorCode.map((item, index) => {
                  return (
                    <button
                      onClick={() => {
                        colourselectered();
                        setcolor(item);
                      }}
                      // style={{ backgroundColor: item }}
                      style={
                        item == "#1e2f41"
                          ? {
                              background: item,
                            }
                          : item == "#000000"
                          ? {
                              background: item,
                            }
                          : item == "#001746"
                          ? {
                              background:
                                "linear-gradient(to right, #001746 50%, #000 50%)",
                            }
                          : item == "#696C71"
                          ? { background: item }
                          : item == "#676A6F"
                          ? {
                              background:
                                "linear-gradient(to right, #676A6F 50%, #000 50%)",
                            }
                          : item == "#DFE8E3"
                          ? {
                              background:
                                "linear-gradient(to right, #DFE8E3 50%, #000 50%)",
                            }
                          : item == "#BB1928"
                          ? {
                              background:
                                "linear-gradient(to right, #BB1928 50%, #000 50%)",
                            }
                          : null
                      }
                      key={index}
                      type="button">
                      {color === item ? <img src={tick} alt="" /> : null}
                    </button>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <span>{Int_name}</span>
              <div>
                {AriyaIntColorCode.map((item, index) => {
                  return (
                    <button
                      onClick={() => {
                        set_Int_color(item);
                        intcolourselectered();
                      }}
                      style={{ backgroundColor: item }}
                      key={index}
                      type="button">
                      {_Int_color === item ? <img src={tick} alt="" /> : null}
                    </button>
                  );
                })}
              </div>
              {/* <div>
                {curelem._CVE1_CVE2_int_colors.map((current, index) => {
                  return (
                    <button
                      onClick={() => set_Int_color(current)}
                      style={{ backgroundColor: current }}
                      key={index}
                      type="button"
                    >
                      {_Int_color === current ? (
                        <img src={tick} alt="" />
                      ) : null}
                    </button>
                  );
                })}
              </div> */}
            </>
          )}
          <div
            className="comparediv"
            onClick={() => {
              localStorage.setItem(
                "compare-screen-default-model",
                "EVOLVE+ e-4FORCE"
              );
              navigate("/Comparaciones", {
                state: { modeldata },
              });
            }}>
            <span>{icooo}</span>
            <span onClick={Compered}>Comparar con otros modelos</span>
          </div>
        </div>
        <div className="Espesification-div">Especificaciones</div>

        <div className="Espesification-all-data-div">
          <div className="Espesification-data-div" onClick={Espesifications}>
            <Accordion
            // defaultActiveKey={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
            // alwaysOpen
            >
              {evolvepe4orce.specs.map((item, index) => {
                return (
                  <div className="w-100" key={index}>
                    <div className="divider-div-for-espesification-data-divs"></div>
                    <Accordion.Item
                      eventKey={index}
                      className="Accordian-item-personal-class">
                      <Accordion.Header className="Accordion-header-personal-class">
                        {item.Head}
                      </Accordion.Header>
                      <Accordion.Body className="padding-left-for-specs-data-txt">
                        {item.content.map((_item, i) => {
                          return (
                            <div key={i} className=" mb-2 w-100">
                              <span>{_item}</span>
                            </div>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                    <div className="pb-3"></div>
                  </div>
                );
              })}
            </Accordion>
          </div>
          <div className="Espesification-data-div"></div>
        </div>

        <div className="reservebtn">
          <button
            type="button"
            //  onClick={() => StaticAddToCart()}
            onClick={() => Navigate("/Galeria")}
            // {() => setConfirmModelModalState(true)}
          >
            Ver imágenes
          </button>
        </div>
      </div>
    </>
  );
};

export default Model2;
