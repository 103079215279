import React, { useEffect, useRef, useState } from "react";
import { ContratoSchema } from "../../schemas";
import "./Contrato.css";
import { useFormik } from "formik";
import axios from "../../../Common/Api";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ScrollToTop from "../../components/ScrollTop";
import visalogo from "../../../Common/assets/images/visalogo.png";
import masterlogo from "../../../Common/assets/images/masterlogo.png";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import Button from "../../../Common/components/UI/Button";
import Loader from "../../components/UI/Loader";

const Contrato = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  const [modalShow, setModalShow] = useState(false);
  function Alertmodel(props) {
    return (
      <Modal
        {...props}
        size="md"
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
        <Modal.Body className="white text-center pt-3">
          <h4 className="pb-3">No se pudo completar el pago</h4>
          <p className="Modal-text">
            Se produjo un error con tu método de pago. Favor de actualizarlo y
            vuelve a intentarlo para poder completar el proceso de reserva.
          </p>

          <div className="p-2"></div>
          <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
            <Button
              variant="red"
              onClick={() => {
                setModalShow(false);
              }}>
              ok
            </Button>
          </div>
          <div className="p-3"></div>
        </Modal.Body>
      </Modal>
    );
  }

  const [desible, setdisable] = useState(false);
  const Navigate = useNavigate();
  // Get User Data
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  // Payment State
  const [PaymentShow, setPaymentShow] = useState(false);
  const [carddata, setcard] = useState([]);
  const [cardIndex, setcardIndex] = useState(0);

  // Loader useState Variable
  const [Loading, setLoading] = useState(true);

  // Store Api Data in useState Variable
  const [CartData, setCartData] = useState([]);

  // Api Error Data useState Variable
  const [ApiError, setApiError] = useState("");

  // Api User Profile Data useState Variable
  const [UserProfileData, setUserProfileData] = useState({});

  const [show, setshow] = useState(false);
  const scrollref = useRef();
  const initialValues = {
    checkbox: false,
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = scrollref.current.scrollTop || 0;
      const clientHeight = scrollref.current.clientHeight || 0;
      const scrollHeight = scrollref.current.scrollHeight || 0;
      const isAtBottom = scrollTop + clientHeight === scrollHeight || 0;
      setshow(isAtBottom);
    };
    if (scrollref?.current) {
      scrollref.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollref?.current) {
        scrollref.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const onSubmit = (values) => {
    setPaymentShow(true);
    setLoading(true);
    getcard();
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ContratoSchema,
      onSubmit,
    });

  const getorderhistoryData = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/get-history", {
        headers: {
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      console.log("🚀 ~ file: ContinuePayment.js:36 ~ getCartData ~ res", res);
      if (res.data.status === "success" && res.data.message == "Record") {
        setCartData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("🚀 ~ file: Contrato.js:114 ~ getCartData ~ error:", error);
      setLoading(false);
      setApiError(error);
    }
  };

  const getcard = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-card-list", {
        headers: {
          Authorization: `Bearer ${UserData.Token}`,
        },
      });
      if (response.data.status == "success") {
        setcard(response.data.data);
        setLoading(false);
        // console.log("Get card responce", response);
        if (response.data.message === "No Record Found") {
          setModalShow(true);
          setdisable(true);
        }
      } else {
        setcard(null);
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
    }
    // console.log(cardIndex);
  };

  const handlePayment = async (e) => {
    // e.preventDefault()
    setLoading(true);
    try {
      const res = await axios.post(
        "/pay-order",
        {
          order_id: CartData.data.track_id,
          // order_id: CartData.data.order_id,
          PaymentMethod: carddata[cardIndex].CardId,
        },
        // "{"order_id":"11","PaymentMethod":"card_1NxA7rK3MtKjG2AAhMmli2p1"}"
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData?.Token}`,
          },
        }
      );
      console.log("🚀 Res Payment......+++++++++s", res);
      //  Store.dispatch(setSessionFild(USERS_DATAS,res))
      if (
        res.data.status == "success" &&
        res.data.message == "Payment successfully."
      ) {
        setLoading(false);
        Navigate("/Order-Detail");
      } else {
        setLoading(false);
        // console.log("Error.......", res);
      }
    } catch (error) {
      setLoading(false);
      console.log("Error....", error);
      if (error.response.data.message === "something wrong.") {
        setModalShow(true);
      }
    }
  };

  // console.log(carddata)

  useEffect(() => {
    if (!localStorage.getItem("user-info")) {
      Navigate("/Iniciar-Sesion");
    }
    getorderhistoryData();
  }, []);

  return (
    <>
      {!PaymentShow ? (
        <div className="wraperdiv2">
          <form className="carddivmainn" onSubmit={handleSubmit}>
            <div className="acueh1">
              <h1>TÉRMINOS DE USO PARA RESERVAR UN VEHÍCULO</h1>
              {/* <span onClick={onClick}>{ico}</span> */}
            </div>
            <div
            // onScroll={onScroll}
            // className="pragrapscontrato"
            >
              <div
                className="contratocontent"
                id="scrollcontent"
                // style={{ height: "340px", overflowY: "scroll" }}
                ref={scrollref}>
                <p>
                  Usuario entiende y acepta que estos términos de uso para
                  reservar un vehículo solo aplican al Usuario que desea
                  solicitar que se reserve un vehículo EV que no se encuentre en
                  Puerto Rico. La definición de Precio, Reservación y Método de
                  Pago únicamente aplica a Usuario que reserva un vehículo y no
                  para Usuario que localiza un vehículo en Puerto Rico. Bajo
                  ningún concepto puede Usuario que localiza un vehículo
                  utilizar estas secciones de los términos de uso para hacer
                  reclamación alguna a concesionario o Compañía.
                </p>
                <p>
                  PRECIOS <br /> El Precio se compone del precio sugerido de
                  venta por el manufacturero más los arbitrios estimados que
                  debe pagar el vehículo. Este precio no incluye costos de
                  registración, tablilla, opciones instaladas por concesionario
                  o cualquier otro gasto incluido por el concesionario.
                </p>
                <p>
                  El Precio que surge en la página es válido al momento en el
                  que el Usuario reserva su vehículo mediante un depósito de
                  Quinientos dólares, ($500.00). El precio de la reservación se
                  honrará para el Usuario que reserve el vehículo mediante el
                  pago referenciado. Sin embargo, los precios anunciados pueden
                  cambiar en cualquier momento sin ningún tipo de aviso previo.
                </p>
                <p>RESERVACIÓN</p>
                <ul>
                  <li>
                    El Usuario entiende y acepta que solo puede proceder a
                    reservar un Vehículo de este no encontrarse en el inventario
                    de un concesionario.
                  </li>
                  <li>
                    El Usuario entiende y acepta que en la página EV Usuario
                    reserva un Vehículo de las especificaciones que escoge.
                  </li>
                  <li>
                    El Usuario entiende y acepta que esta página no es una en la
                    que se lleva a cabo una negociación o venta con un
                    Concesionario.
                  </li>
                  <li>
                    El Usuario entiende y acepta que el Precio que surge en esta
                    página incluye Arbitrios, pero no incluye costos de
                    registración, tablilla o accesorio alguno que pueda ser
                    instalado directamente por el vendedor, del Usuario desear
                    comprar estos accesorios, dicha transacción se lleva a cabo
                    en persona y se negocia directamente entre Usuario y
                    Concesionario.
                  </li>
                  <li>
                    El Usuario entiende y acepta que para hacer una Reservación
                    debe pagar el Monto solicitado.
                  </li>
                  <li>
                    El Usuario entiende y acepta que el Monto pagado en la
                    Reservación será deducido del precio final de la Venta, de
                    esta llevarse a cabo.
                  </li>
                  <li>
                    El Usuario entiende y acepta que el vehículo objeto de
                    reservación pudiera ser reservado directamente con el
                    manufacturero en Japón, por lo que puede transcurrir un
                    término de tiempo de hasta noventa (90) días para que el
                    vehículo llegue a Puerto Rico.
                  </li>
                  <li>
                    El Usuario entiende y acepta que si el vehículo tarda más de
                    noventa (90) días en llegar a Puerto Rico, a Usuario se le
                    dará la opción de solicitar extender la Reservación por un
                    término de treinta (30) días adicionales o dar el mismo por
                    terminado. Se le enviará al Usuario un correo electrónico
                    por el cual podrá informar su aceptación o denegación a la
                    extensión de tiempo. De dar la Reservación por terminada,
                    Nissan Puerto Rico reembolsará a Usuario el Monto total
                    pagado. De Usuario dar por terminada la Reservación, por
                    razón de haber transcurrido en exceso de noventa (90) días,
                    Usuario libera a Nissan Puerto Rico de toda responsabilidad
                    legal relacionada a la otorgación y cancelación de la
                    Reservación.
                  </li>
                  <li>
                    El Usuario entiende y acepta que Nissan Puerto Rico tiene el
                    derecho de cancelar esta reservación por cualquier razón.
                    Usuario libera de toda responsabilidad contractual o
                    extracontractual a Nissan Puerto Rico por esta reservación.
                    De Nissan Puerto Rico cancelar la Reservación, se
                    reembolsará a Usuario el Monto pagado.
                  </li>
                  <li>
                    El Usuario entiende y acepta que, al localizar el Vehículo,
                    se le informará y tendrá la oportunidad de confirmar o
                    cancelar la Reservación sin penalidad alguna
                  </li>
                  <li>
                    El Usuario entiende y acepta que, de cancelar la Reservación
                    luego de confirmada, se retendrá el Monto pagado como
                    penalidad al haber incurrido en gastos de transportación,
                    almacenaje y mantenimiento.
                  </li>
                  <li>
                    El Usuario entiende y acepta que, al ser informado que el
                    Vehículo se encuentra en posesión del concesionario
                    autorizado que escogió, tienen un término de treinta (30)
                    días para informar al concesionario que interesa llevar a
                    cabo la Venta.
                  </li>
                  <li>
                    El Usuario entiende y acepta que de no informar a
                    concesionario de su deseo de adquirir el Vehículo dentro del
                    término de treinta (30) días, la Reservación se dará por
                    cancelada y se aplicará la penalidad al reembolsar el Monto
                    a Usuario.
                  </li>
                  <li>
                    El Usuario entiende y acepta, de forma total y completa, que
                    libera de toda responsabilidad a Nissan Puerto Rico de
                    cualquier situación relacionada a la Venta.
                  </li>
                  <li>
                    El Usuario entiende y acepta que Nissan Puerto Rico, no
                    vende, arrienda o de forma alguna transfiere posesión o
                    dominio de los Vehículos objeto de la Reservación hecha en
                    la Página EV.
                  </li>
                  <li>
                    Esta reservación es un contrato que se rige por las leyes
                    del Estado Libre Asociado de Puerto Rico.
                  </li>
                </ul>
                <p>
                  MÉTODO DE PAGO <br />
                  La Reservación requiere el pago del Monto. Este pago se hará
                  por tarjeta de crédito y dicha transacción se someterá a un
                  Servicio de procesar pagos. Cuando agregue un método de pago,
                  tal como una tarjeta de crédito, es posible que ciertos datos,
                  tales como la ubicación del dispositivo, el número de
                  identificación del dispositivo o la información de la tarjeta,
                  se envíen a Nissan Puerto Rico y se compartan con nuestro
                  procesador de pagos. La información de la tarjeta se encripta
                  durante la transmisión, y Nissan Puerto Rico no tendrá acceso
                  al número de tarjeta. Cuando se use el método de pago en la
                  Página EV o aplicación alojada en los servidores de Nissan
                  Puerto Rico, la información necesaria para procesar el pago se
                  compartirá con nuestros procesadores globales y nuestros
                  socios bancarios para procesar su pago y cumplir con
                  reglamentación financiera para evitar fraudes y para
                  solucionar cualquier problema de pago. Nissan Puerto Rico
                  almacenará los pagos que se realicen utilizando su información
                  bancaria en un formato cifrado que impide accesos no
                  autorizados.
                </p>
              </div>
            </div>
            <div className="checkboxcontrato">
              <div className="maincheckbox">
                <p>
                  Al apretar el botón, Usuario, acepta los términos de este
                  contrato.
                </p>
                <label className="" htmlFor="acepto">
                  {/* <Checkbox name="termCheckbox" id={"html2"} /> */}
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id={"acepto"}
                      name="checkbox"
                      value={values.checkbox}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* <label htmlFor="id"></label> */}
                  </div>
                  <p>Acepto el Acuerdo para reservar el vehículo.</p>
                </label>
                {errors.checkbox && touched.checkbox ? (
                  <p className="form-error">{errors.checkbox}</p>
                ) : null}
              </div>
              <div className="contratobtn">
                <button
                  disabled={show == false ? "disable" : false}
                  type={"submit"}>
                  Continuar con el pago
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="SelectCard-parent-div">
          {Loading && (
            <div className="loder_div">
              <Loader />
            </div>
            // <div>
            //   <Backdrop
            //     sx={{
            //       color: "#fff",
            //       zIndex: (theme) => theme.zIndex.drawer + 1,
            //     }}
            //     open={true}

            //   >
            //     <CircularProgress color="inherit" />
            //   </Backdrop>
            // </div>
          )}
          {!Loading && (
            <>
              <ScrollToTop />
              <form
                // onSubmit={handlePayment}
                className="select-card-form-parent-div"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "2rem",
                }}>
                {carddata !== null ? (
                  <div style={{ color: "white" }} className="getcarddata">
                    <>
                      {carddata?.map((curelem, index) => {
                        return (
                          <div
                            lg={12}
                            key={index}
                            onClick={() => {
                              setcardIndex(index);
                            }}
                            className="d-flex justify-content-center pt-5 gap-4">
                            <div
                              lg={6}
                              style={
                                cardIndex == index
                                  ? {
                                      background: "#9f9f9f",
                                      padding: "10px 3rem",
                                      borderRadius: "10px",
                                      cursor: "pointer",
                                      border: "1px solid black",
                                    }
                                  : {
                                      borderRadius: "10px",
                                      padding: "10px 3rem",
                                      cursor: "pointer",
                                      border: "1px solid black",
                                      color: "black",
                                    }
                              }>
                              <Row>
                                <div className="d-flex gap-3">
                                  <div className="brandlogo">
                                    <img
                                      src={
                                        curelem.CardBrand === "Visa"
                                          ? visalogo
                                          : curelem.CardBrand === "MasterCard"
                                          ? masterlogo
                                          : null
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <span className="ProfileHead">
                                    Ending {curelem.CardNumber}
                                  </span>
                                </div>
                              </Row>
                              <Row className="pt-1">
                                <span className="HeadText">
                                  {curelem.CardName}
                                </span>
                              </Row>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    paddingTop: "10px",
                  }}>
                  <button
                    className="PaymentBtn"
                    type="button"
                    onClick={() => handlePayment()}
                    // disabled={desible ? "disable" : ""}
                    disabled={carddata == null ? "disable" : ""}>
                    Pagar
                  </button>
                  <button
                    className="PaymentBtn-add"
                    type="button"
                    onClick={() => Navigate("/AddnewCard")}>
                    Añadir nueva tarjeta
                  </button>
                </div>
              </form>
              <Alertmodel show={modalShow} onHide={() => setModalShow(false)} />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Contrato;
