import { useEffect } from "react";
import Navbar from "../../components/UI/Navbar";
import Footer from "../../../Common/components/UI/Footer";
import "./Dealers.css";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const Dealers = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  return (
    <>
      <Helmet>
        <title>Nissan |Concesionarios autorizados para LEAF </title>
        <meta
          name="description"
          content="Descubre nuestros puntos de venta alrededor de la Isla donde podrás adquirir tu próximo 
LEAF."
        />
      </Helmet>

      <Navbar />
      <div className="top-div"></div>
      <section className="Dealers-participantes-sec">
        <div className="Dealers-participantes-div">
          <div>
            <div className="top-sec-div"></div>
            <div className="w-100 d-flex flex-column align-items-center justify-content-center pb-4">
              <span className="Heading-contactUs">
                Concesionarios autorizados
              </span>
              <div className="border-bottom-divider-policy"></div>
            </div>
            <div className="form-span-div mt-5">
              <div>
                <span className="Area-span">Adriel Auto</span>
              </div>
              <div className="new_heading ">
                <span className="AutoGrupo-all-span">
                  Carretera #2 KM 16.6, Barrio Candelaria
                </span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">Toa Baja, PR, 00949</p>
              </div>
              <div className="mt-5">
                <span className="Centro-span">Aguadilla Motors</span>
              </div>
              <div className="new_heading">
                <span className=" AutoGrupo-all-span">
                  Carretera #2 km. 129.9, Barrio Victoria
                </span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">Aguadilla, PR, 00605</p>
              </div>
              <div className="mt-5">
                <span className="Centro-span">Autocentro</span>
              </div>
              <div className="new_heading">
                <span className=" AutoGrupo-all-span">
                  Avenida Luis Muñoz Rivera #1086.
                </span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">Río Piedras, PR, 00919</p>
              </div>
              <div className="mt-5">
                <span className="Centro-span">GS Motors Nissan</span>
              </div>
              <div className="new_heading">
                <span className=" AutoGrupo-all-span">
                  Carretera #3 km 26.7 Bo Jiménez,
                </span>
                <div>
                  <p className="AutoGrupo-all-span"> Río Grande, PR, 00745</p>
                </div>
              </div>

              <div className="mt-5">
                <span className="Centro-span">Medina Auto Sales</span>
              </div>
              <div className="new_heading">
                <span className=" AutoGrupo-all-span">
                  Carretera #1 Km. 29.7
                </span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">Caguas, PR, 00725</p>
              </div>

              <div className="mt-5">
                <span className="Centro-span">Yokomuro</span>
              </div>
              <div className="new_heading">
                <span className=" AutoGrupo-all-span">
                  Carretera 167 KM 18.6, Rexville
                </span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">Bayamón, PR, 00956</p>
              </div>
              <div className="margin-div"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Dealers;
