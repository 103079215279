import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Section3.css";
import tick from "../../../../assets/images/colortickicon.png";
// import "swiper/css";
// import "swiper/css/effect-fade";
import { EffectFade } from "swiper";
import { AllImages } from "../../../../constants/images";
import { LeafSVPlus } from "../../../../constants/Data";
import ReactGA from "react-ga4";

function Section3() {
  const [color, setcolor] = useState("#EFEFEF");
  let Ext_name;

  const ExtcolorInfo = LeafSVPlus.exterior[color];

  if (ExtcolorInfo) {
    Ext_name = ExtcolorInfo.Ext_name;
    // Ext_code = colorInfo.Ext_code;
  }

  const AriyaColorCode = Object.keys(LeafSVPlus.exterior);

  const arr = [
    AllImages.Leafs.SuperBlack.img1,
    AllImages.Leafs.SuperBlack.img2,
    AllImages.Leafs.SuperBlack.img3,
    AllImages.Leafs.SuperBlack.img4,
    AllImages.Leafs.SuperBlack.img5,
    AllImages.Leafs.SuperBlack.img6,
    AllImages.Leafs.SuperBlack.img7,
    AllImages.Leafs.SuperBlack.img8,
  ];
  const wasd = useRef(null);

  const colors = (item) => {
    console.log("check colour", item);
    const carcolour = ExtcolorInfo.Ext_name;
    ReactGA.event({
      category: "Landing page",
      action: carcolour,
    });
    console.log("Ext_colorInfo", carcolour);
    // const carcolour = (EVOLVE.exterior.Ext_code = item);
    // console.log(carcolour, "check cars colour");
    setcolor(item);
  };
  return (
    <div className="main_content_container_swiper">
      <div className="main_heading_text_sec3">
        <span className="heading_text_sec3">
          Haz clic para explorar a Nissan LEAF en 360 grados
        </span>
      </div>
      <div className="d-flex justify-centen-center align-items-center">
        <span type="button" onClick={() => wasd.current.swiper.slidePrev()}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.5156 19.0039L8.51563 12.0039L15.5156 5.00391"
              stroke="#464646"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <Swiper
          ref={wasd}
          // navigation={true}
          effect={"fade"}
          modules={[EffectFade]}
          loop={true}
          id="mySwiper"
          fadeEffect={{
            crossFade: true, // enables slides to cross fade
          }}>
          {LeafSVPlus.exterior[color].images.map((item, index) => {
            return (
              <SwiperSlide className="sec3_slider" key={index}>
                <img src={item} alt="wasd" />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <span type="button" onClick={() => wasd.current.swiper.slideNext()}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.1797 8.33659L25.8464 20.0033L14.1797 31.6699"
              stroke="#464646"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className="last_div_box">
        <div className="button_div">
          <span>{Ext_name}</span>
          <div className="inner_button_div ">
            {AriyaColorCode.map((item, index) => {
              return (
                <button
                  oonClick={() => colors(item)}
                  style={
                    item == "#DFE9E3"
                      ? {
                          background:
                            "linear-gradient(to right, #DFE9E3 50%, #000000 50%)",
                        }
                      : { background: item }
                  }
                  key={index}
                  type="button">
                  {color === item ? <img src={tick} alt="" /> : null}
                </button>
              );
            })}
          </div>
        </div>
        <span className="ending_text_sec3">Modelo ilustrado: LEAF SV Plus</span>
      </div>
    </div>
  );
}
export default Section3;
