import React, { useEffect, useState } from "react";
import Navbar from "../../components/UI/Navbar";
import "./Variants.css";
import Info from "../../assets/images/Info.png";
import Modeldata from "../../components/Data/Variants";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import Footer from "../../../Common/components/UI/Footer";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const Variants = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  const [indexx, setindex] = useState(0);
  localStorage.setItem("cardindex", indexx);
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  function ModelModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        // centered
        className="wasd-modal-ko-badla"
        style={{ zIndex: "10000" }}>
        <Modal.Header className="new_model" closeButton></Modal.Header>
        <Modal.Body className="new_model text-center">
          <div className="Dropdown-Car-info-body-Head">
            <i>Información importante</i>
          </div>
          <p className="Modal-text">
            <div>
              El precio ilustrado no incluye accesorios. El precio es el Precio
              de venta sugerido por el fabricante (MSRP, por sus siglas en
              inglés). El MSRP no incluye impuestos, título, placas, opciones ni
              cobro por destino. El concesionario fija el precio final.
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  const ico2 = (
    <svg
      width="17"
      height="17"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.09998V8H12.0498Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const ico = (
    <svg
      width="17"
      height="17"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3898_31882)">
        <path
          d="M6 5.58333V7.66667M6 9.75C3.92893 9.75 2.25 8.07107 2.25 6C2.25 3.92893 3.92893 2.25 6 2.25C8.07107 2.25 9.75 3.92893 9.75 6C9.75 8.07107 8.07107 9.75 6 9.75ZM6.02075 4.33333V4.375L5.97925 4.37499V4.33333H6.02075Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect
        x="1"
        y="1"
        width="10"
        height="10"
        stroke="black"
        stroke-miterlimit="0"
        strokeLinejoin="bevel"
      />
      <defs>
        <clipPath id="clip0_3898_31882">
          <rect x="1" y="1" width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const handleclick = (index) => {
    localStorage.setItem("data", "istrue");
    setindex(index);
    setTimeout(() => {
      navigate("/Model", {
        state: "active",
      });
    }, 100);
  };

  return (
    <>
      <Helmet>
        <title>Nissan | Modelos ARIYA® y sus especificaciones</title>
        <meta
          name="description"
          content="Descubre la opción de ARIYA® que mejor se adapta a tus gustos y necesidades."
        />
      </Helmet>
      <ModelModal show={modalShow} onHide={() => setModalShow(false)} />
      <Navbar />
      <div className="moulasdivv " id="Specs">
        <div className="w-100 d-flex flex-column align-items-center justify-content-center pb-4 ">
          <span className="Heading-contactUs">Variantes</span>
          <div className="border-bottom-divider-policy"></div>
        </div>
        <div className="cardmaindivv2">
          {Modeldata.map((curelem, index) => {
            return (
              <div className="modulcardd" key={index}>
                <div className="cardimg2">
                  <img
                    src={curelem.img}
                    alt=""
                    onClick={() => handleclick(index)}
                  />
                </div>

                <div className="masinfo" onClick={() => handleclick(index)}>
                  <span>Más información</span>
                </div>
                <div
                  className="modulscontent"
                  onClick={() => handleclick(index)}>
                  <span>{curelem.name}</span>
                  <p className="msrp_div ">
                    {curelem.price}
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalShow(true);
                      }}>
                      {ico}
                    </span>
                  </p>
                  <div className="kmm">
                    <div className="kmfirst">
                      <div className="first">
                        {curelem.icon3}
                        {/* <img src={curelem.icon3} alt="" /> */}
                        <span>{curelem.vorte}</span>
                      </div>
                      <div className="first">
                        {curelem.icon2}
                        {/* <img src={curelem.icon2} alt="" /> */}
                        <span>{curelem.km}</span>
                      </div>
                      <div className="first">
                        {curelem.icon}
                        {/* <img src={curelem.icon} alt="" /> */}
                        <span>{curelem.rwd}</span>
                      </div>
                    </div>
                    <div className="mphdiv2">
                      <div className="linediv3"></div>
                      <div className="mph">
                        <p className="pragraphs">Alcance:</p>
                        <span>
                          {curelem.Alcance}
                          <p className="pragraphs">millas</p>
                        </span>
                        <p className="pragraphs">MPGe:</p>
                        <p className="pragraphs">{curelem.MPGe} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="lasttext">
          <p>
            <span>{ico2}</span>
            {/* Disclaimers:  */}
            Las opciones pueden diferir según las regiones. Toda la información
            y las ilustraciones se basan en los datos disponibles en el momento
            de la publicación y están sujetos a cambios sin previo aviso. Favor
            de referirse a la hoja de producto para más información.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Variants;
