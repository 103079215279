import React, { useState, useEffect } from "react";
import "./Gallery.css";
import Exterior from "./Exterior";
import Interior from "./Interior";
import Navbar from "../../components/UI/Navbar";
import { useLocation } from "react-router-dom";
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";

const Gallery = () => {
  const [active, setactive] = useState(1);
  const location = useLocation();

  const ExteriorImage = () => {
    ReactGA.event({
      category: "See More Images",
      action: "Exterior",
    });
  };
  const InteriorImage = () => {
    ReactGA.event({
      category: "See More Images",
      action: "Interior",
    });
  };

  return (
    <>
      <Navbar />
      <div style={{ marginTop: "101px" }}></div>
      <nav className="gellnav">
        <ul>
          <li
            onClick={() => {
              ExteriorImage();
              setactive(1);
            }}>
            <span className={active === 1 ? "borderactive2" : null}>
              Exterior
            </span>
          </li>
          <div className="linediv"></div>
          <li
            onClick={() => {
              setactive(2);
              InteriorImage();
            }}>
            <span className={active === 2 ? "borderactive2" : null}>
              Interior
            </span>
          </li>
        </ul>
        <div>
          <span className="gelle">Galería</span>
        </div>
      </nav>
      {active === 1 && <Exterior />}
      {active === 2 && <Interior />}
    </>
  );
};

export default Gallery;
