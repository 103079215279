import { React, useEffect } from "react";
import Navbar from "../../components/UI/Navbar";
import Footer from "../../../Common/components/UI/Footer";
import "./Dealers.css";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const Dealers = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  const dealersData = [
    {
      id: 1,
      Dealersname: "Adriel Auto",
      Address: "Carretera #2 KM 16.6, Barrio Candelaria",
      city: "Toa Baja, PR, 00949",
    },
    {
      id: 2,
      Dealersname: "Aguadilla Motors",
      Address: "Carretera #2 KM. 129.9, Barrio Victoria",
      city: "Aguadilla, PR, 00605",
    },
    {
      id: 3,
      Dealersname: "Auto Grupo 65",
      Address: "Ave. 65 de Infantería 1183, El Escorial",
      city: "Carolina, PR, 00924",
    },
    {
      id: 4,
      Dealersname: "Auto Grupo Kennedy",
      Address: "Marginal Avenida Kennedy, KM. 3.2 Sector Bechara",
      city: "San Juan, PR, 00929",
    },
    {
      id: 5,
      Dealersname: "Autocentro",
      Address: "Avenida Luis Muñoz Rivera #1086 ",
      city: "Río Piedras, PR, 00919",
    },
    {
      id: 6,
      Dealersname: "GS Motors Nissan",
      Address: "Carretera #3 KM. 26.7 Bo Jiménez",
      city: "Río Grande, PR, 00745",
    },
    {
      id: 7,
      Dealersname: "Henry Motors",
      Address: "Avenida Las Américas #2037 Boulevard Luis A. Ferré",
      city: "Ponce, PR, 00717",
    },
    {
      id: 8,
      Dealersname: "Medina Auto Sales",
      Address: "Carretera #1 Km. 29.7 ",
      city: "Caguas, PR, 00725",
    },
    {
      id: 9,
      Dealersname: "Yokomuro",
      Address: "Carretera 167 KM. 18.6, Rexville",
      city: "Bayamón, PR, 00956",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Nissan | Concesionarios autorizados para ARIYA® </title>
        <meta
          name="description"
          content="Descubre nuestros puntos de venta alrededor de la Isla donde podrás adquirir tu próximo 
ARIYA®."
        />
      </Helmet>

      <Navbar />
      <div className="top-div"></div>
      <section className="Dealers-participantes-sec">
        <div className="Dealers-participantes-div">
          <div>
            <div className="top-sec-div"></div>
            <div className="w-100 d-flex flex-column align-items-center justify-content-center pb-4">
              <span className="Heading-contactUs">
                Concesionarios autorizados
              </span>
              <div className="border-bottom-divider-policy"></div>
            </div>
            {/* <div className="form-span-div mt-5">
              <div>
                <span className="Area-span">Adriel Auto</span>
              </div>
              <div className="new_heading ">
                <span className="AutoGrupo-all-span">
                  Carretera #2 KM 16.6, Barrio Candelaria
                </span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">Toa Baja, PR, 00949</p>
              </div>
              <div className="mt-5">
                <span className="Centro-span">Aguadilla Motors</span>
              </div>
              <div className="new_heading">
                <span className=" AutoGrupo-all-span">
                  Carretera #2 km. 129.9, Barrio Victoria
                </span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">Aguadilla, PR, 00605</p>
              </div>
              <div className="mt-5">
                <span className="Centro-span">Autocentro</span>
              </div>
              <div className="new_heading">
                <span className=" AutoGrupo-all-span">
                  Avenida Luis Muñoz Rivera #1086.
                </span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">Río Piedras, PR, 00919</p>
              </div>
              <div className="mt-5">
                <span className="Centro-span">GS Motors Nissan</span>
              </div>
              <div className="new_heading">
                <span className=" AutoGrupo-all-span">
                  Carretera #3 km 26.7 Bo Jiménez,
                </span>
                <div>
                  <p className="AutoGrupo-all-span"> Río Grande, PR, 00745</p>
                </div>
              </div>

              <div className="mt-5">
                <span className="Centro-span">Medina Auto Sales</span>
              </div>
              <div className="new_heading">
                <span className=" AutoGrupo-all-span">
                  Carretera #1 Km. 29.7
                </span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">Caguas, PR, 00725</p>
              </div>

              <div className="mt-5">
                <span className="Centro-span">Yokomuro</span>
              </div>
              <div className="new_heading">
                <span className=" AutoGrupo-all-span">
                  Carretera 167 KM 18.6, Rexville
                </span>
              </div>
              <div>
                <p className="AutoGrupo-all-span">Bayamón, PR, 00956</p>
              </div>
              <div className="margin-div"></div>
            </div> */}

            <div className="form-span-div mt-5">
              {dealersData.map((item, i) => {
                return (
                  <>
                    <div>
                      <span className="Area-span">{item.Dealersname}</span>
                    </div>
                    <div className="new_heading ">
                      <span className="AutoGrupo-all-span">{item.Address}</span>
                    </div>
                    <div>
                      <p className="AutoGrupo-all-span">{item.city}</p>
                    </div>
                  </>
                );
              })}
              <div className="margin-div"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Dealers;
