import * as Yup from "yup";

const phoneRegExp =
  /^(\+?\d{0,6})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{6}\)?)?$/;

export const LoginSchema = Yup.object({
  Email: Yup.string()
    .email()
    .required("El correo electrónico es un campo obligatorio."),
  Password: Yup.string().required("El contraseña es un campo obligatorio."),
});

export const RegisterYesSchema = Yup.object({
  Name: Yup.string().required("El nombre del titular es un campo obligatorio."),
  Surname: Yup.string().required("Los apellidos es un campo obligatorio."),
  Email: Yup.string()
    .email()
    .required("El correo electrónico es un campo obligatorio."),
  Number: Yup.string()
    .matches(phoneRegExp, "El teléfono debe ser adecuado.")
    .required("El teléfono es un campo obligatorio."),
  // Number: Yup.number()
  //   .positive("El teléfono debe ser adecuado.")
  //   .required("El teléfono es un campo obligatorio."),
  Password: Yup.string().required("El contraseña es un campo obligatorio."),
  ConfirmEmail: Yup.string()
    .required("Repita correo electrónico es un campo obligatorio.")
    .oneOf(
      [Yup.ref("Email"), null],
      "El correo electrónico ingresado no coincide con el anterior"
    ),
  DealerName: Yup.number().required(
    "El concesionario es un campo obligatorio."
  ),
  // .oneOf(
  //   ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  //   "El concesionario es un campo obligatorio."
  // )
  // .required("El concesionario es un campo obligatorio."),
  RepeatPassword: Yup.string()
    .required("Repetir la contraseña es un campo obligatorio.")
    .oneOf([Yup.ref("Password"), null], "Las contraseñas no coinciden."),
});

export const RegisterDealerSchema = Yup.object({
  Name: Yup.string().required("El nombre del titular es un campo obligatorio."),
  Surname: Yup.string().required("Los apellidos es un campo obligatorio."),
  Email: Yup.string()
    .email("El correo electrónico debe ser un correo electrónico válido")
    .required("El correo electrónico es un campo obligatorio."),
  ConfirmEmail: Yup.string()
    .email("El correo electrónico ingresado no coincide con el anterior")
    .oneOf([Yup.ref("Email"), null], "repita correo electrónica no coinciden")
    .required("repita correo electrónica es un campo obligatorio."),
  Number: Yup.string()
    .matches(phoneRegExp, "El teléfono debe ser adecuado.")
    .required("El teléfono es un campo obligatorio."),
  // Number: Yup.number()
  //   .positive("El teléfono debe ser adecuado.")
  //   .required("El teléfono es un campo obligatorio."),
  DealerName: Yup.number().required(
    "El concesionario es un campo obligatorio."
  ),
  // .oneOf(
  //   ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  // "El concesionario es un campo obligatorio."
  // ),
  // .required("El concesionario es un campo obligatorio."),
  Vendor: Yup.string().required("El vendedor es un campo obligatorio."),
  Password: Yup.string().required("El contraseña es un campo obligatorio."),
  RepeatPassword: Yup.string()
    .required("Repetir la contraseña es un campo obligatorio.")
    .oneOf([Yup.ref("Password"), null], "Las contraseñas no coinciden."),
});

export const RegisterAddressSchema = Yup.object({
  Address: Yup.string().required("La dirección es un campo obligatorio."),
  Country: Yup.string().required("El país es un campo obligatorio."),
  PostalCode: Yup.number().required(
    "El código postal es un campo obligatorio."
  ),
});

export const RegisterNoSchema = Yup.object({
  Name: Yup.string().required("Nombre es un campo obligatorio."),
  Surname: Yup.string().required("Apellidos es un campo obligatorio."),
  Email: Yup.string().required("Correo electrónico es un campo obligatorio."),
  Number: Yup.string()
    .matches(phoneRegExp, "El teléfono debe ser adecuado.")
    .required("Teléfono es un campo obligatorio."),
  // Number: Yup.number().required("Teléfono es un campo obligatorio."),
});

export const ForgotPassSchema = Yup.object({
  Email: Yup.string()
    .email()
    .required("El correo electrónico es un campo obligatorio."),
});

export const ContactUsSchema = Yup.object({
  FullName: Yup.string().required(
    "El nombre completo es un campo obligatorio."
  ),
  Email: Yup.string()
    .email()
    .required("El correo electrónico es un campo obligatorio."),
  Phone: Yup.string()
    .matches(phoneRegExp, "El teléfono debe ser adecuado.")
    .required("El teléfono es un campo obligatorio."),
  // Phone: Yup.number()
  //   .positive()
  //   .required("El teléfono es un campo obligatorio."),
  Subject: Yup.string().required("Asunto es un campo obligatorio.."),
  Comment: Yup.string().required("Comentarios es un campo obligatorio."),
});

export const UpdateProfileSchema = Yup.object({
  // AccountName: Yup.string().required("required"),
  // AccountSurnameName: Yup.string().required("required"),
  // AccountHolderPhoneNumber: Yup.string()
  //   .matches(phoneRegExp, "El teléfono debe ser adecuado.")
  //   .required("required"),
  // AccountHolderEmail: Yup.string()
  //   .email()
  //   .required("El correo electrónico es un campo obligatorio."),
  Name: Yup.string().required("required"),
  SurName: Yup.string().required("required"),
  Email: Yup.string()
    .email()
    .required("El correo electrónico es un campo obligatorio."),
  MobileNumber: Yup.string()
    .matches(phoneRegExp, "El teléfono debe ser adecuado.")
    .required("required"),
  AddressLine1: Yup.string().required("required"),
  // AddressLine2: Yup.string().required("required"),
  Country: Yup.string().required("required"),
  PostalCode: Yup.string().required("required"),
  DealerName: Yup.string()
    .oneOf(
      ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      "El concesionario es un campo obligatorio."
    )
    .required("El concesionario es un campo obligatorio."),
});
