import { React, useEffect } from "react";
import Wrapper from "../../../components/UI/Wrapper";
import Card from "../../../components/UI/Card";
import Button from "../../../../Common/components/UI/Button";
import "../../../components/Alert/Alert.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { colors } from "@mui/material";

const Intro = () => {
  const StaticCartData = JSON.parse(localStorage.getItem("StaticCartData"));
  console.log("🚀 ~ Intro ~ StaticCartData:", StaticCartData);
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const iintro = (props) => {
    const evenatdata = {
      event: "me_interesa_reseva_paso3",
      user_id: UserData?.id ? UserData?.id : " ",
      log_status: `${UserData ? true : false}`,
      price: StaticCartData.price,
      category: StaticCartData.ModelName,
      action: "CONTINUAR",
      option: "¡Estás a un solo paso!",
      color: StaticCartData.extColorName,
    };
    ReactGA.event({
      category: evenatdata.category,
      action: evenatdata.action,
      event: evenatdata.event,
      event_params: {
        user_id: evenatdata.user_id,
        log_status: evenatdata.log_status,
        option: evenatdata.option,
        color: evenatdata.color,
        Price: evenatdata.price,
      },
    });
    window.dataLayer.push({
      event: evenatdata.event,
      user_id: evenatdata.user_id,
      modelo: evenatdata.category,
      price: evenatdata.price,
      color: evenatdata.color,
      opcon: "¡Estás a un solo paso!",
      action: evenatdata.action,
    });
  };

  return (
    <div>
      <Wrapper>
        <Card head={""}>
          <div id="akhaini">
            <h1>¡Estás a un solo paso!</h1>
            <div>
              <p>
                Envíanos tu información de contacto para mantenerte informado
                sobre el modelo que te interesa reservar y su disponibilidad.
              </p>
            </div>
            <div></div>
            <div className="d-flex justify-content-center align-items-center">
              {/* <Link to={"/Registrate"}> */}
              <Link
                to={
                  localStorage.getItem("StaticCartData")
                    ? "/Iniciar-Sesion"
                    : "/Registrate"
                }>
                <Button variant="red" onClick={iintro}>
                  Continuar
                </Button>
              </Link>
            </div>
          </div>
        </Card>
      </Wrapper>
    </div>
  );
};

export default Intro;
